import { Avatar, Badge, Table, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { get, map } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { PRODUCT_STATUS } from '@Modules/Product/Interfaces/interfaces'
import { PRODUCT_STATUS_COLORS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'

interface IChildSkuListProps {
    skus: any[]
    loading: boolean
}

const ChildSkuList: React.FC<IChildSkuListProps> = ({ skus, loading }) => {
    const columns = [
        {
            className: '_product-list-product text-wrap',
            title: t('product:label.product'),
            dataIndex: ['sku', 'data'],
            key: 'product',
            width: '300px',
            render: (text: any, record: any) => {
                const images = get(text, 'images.0', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={images}
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0 }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={`/products/detail/${get(text, 'product_id')}/${get(text, 'id')}`}>
                                    <strong
                                        style={{ fontWeight: 500 }}
                                        className="text-link"
                                    >
                                        {get(text, 'name', '')}
                                    </strong>
                                </Link>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'data', 'code'],
            key: 'sku_code',
            ellipsis: {
                showTitle: false,
            },
            width: '180px',
            render: (sku: any) => (
                <Tooltip
                    placement="topLeft"
                    title={
                        <Paragraph
                            copyable={{
                                text: sku,
                            }}
                            className="ant-typography-tooltip"
                        >
                            {sku}
                        </Paragraph>
                    }
                >
                    {sku}
                </Tooltip>
            ),
        },
        {
            title: t('label.quantity'),
            dataIndex: 'quantity',
            className: '_product-info-quantity',
            key: 'quantity',
            width: '180px',
            render: (text: number) => {
                return <span>{text ? text : 0}</span>
            },
        },

        {
            className: '_product-list-warehouse',
            title: t('product:label.warehouse'),
            dataIndex: ['sku', 'data', 'warehouse_stocks', 'data'],
            key: 'warehouse',
            width: '220px',
            render: (text: any) => {
                if (text?.length > 0) {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks"
                                key={index}
                            >
                                {get(item, 'warehouse.data.name', '')}
                            </div>
                        )
                    })
                } else {
                    return <div className="warehouse_stocks">{t('label.none_stock')}</div>
                }
            },
        },
        {
            className: '_product-list-inventory',
            title: t('product:label.inventory'),
            dataIndex: ['sku', 'data', 'warehouse_stocks', 'data'],
            key: 'inventory',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'real_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-waiting-out',
            title: t('product:label.waiting_out'),
            dataIndex: ['sku', 'data', 'warehouse_stocks', 'data'],
            key: 'waiting_out',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'packing_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-waiting-in',
            title: t('product:label.waiting_in'),
            dataIndex: ['sku', 'data', 'warehouse_stocks', 'data'],
            key: 'waiting_in',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'purchasing_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-status',
            title: t('label.status'),
            dataIndex: ['sku', 'data', 'status'],
            key: 'status',
            width: '120px',
            render: (text: PRODUCT_STATUS) =>
                text ? (
                    <Badge
                        color={PRODUCT_STATUS_COLORS[text]}
                        text={t(`product:status.${text}`)}
                    />
                ) : null,
        },
    ]

    return (
        <div className="bg-white rounded-12 p-4 mt-4 _import-package-product-info box-shadow">
            <div className="d-flex justify-content-between flex-wrap mb-3">
                <h3>{`${t('product:label.child_sku_list')} `}</h3>
            </div>
            <Table
                className="_product-child-sku__list"
                rowKey={record => record?.id}
                dataSource={skus}
                columns={columns}
                scroll={{ x: 576 }}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: <p>{t('message.no_data_product')}</p>,
                }}
            />
        </div>
    )
}

export default ChildSkuList
