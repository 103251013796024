import { Button, Form, Input, Modal, Select } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'

import { IChannelResult } from '@Modules/Setting/Interfaces'
import useChannelOAuthUrlQuery from '@Modules/Setting/components/SalesChannel/useChannelOAuthUrlQuery'
import { CHANNEL } from '@Modules/Setting/services/constants'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { t } from '@System/i18n'
import notification from '@System/notification'
import SelectChannel from "@Modules/Setting/components/SalesChannel/SelectChannel";

interface IProps {
    refetch: () => void
    onCancel: () => void
    visible: boolean
}

const { Option } = Select

const AddConnectionModal: React.FC<IProps> = ({ refetch, onCancel, visible }) => {
    const [form] = Form.useForm()
    const [params, setParams] = useState({})
    const [marketplaceCode, setMarketplaceCode] = useState(CHANNEL.SHOPEE)
    const { isFetching: loadingOAuthUrl, refetch: getOAuthUrl } = useChannelOAuthUrlQuery(marketplaceCode, params, {
        onSuccess: ({ data }: { data: IChannelResult }) => {
            if ([CHANNEL.KIOTVIET, CHANNEL.SHOPBASE, CHANNEL.SAPO].includes(marketplaceCode)) {
                if (data.code === 200) {
                    notification.success(t('setting:sales_channel.message.create_account_success'))
                    onCancel()
                    refetch()
                } else {
                    notification.error(data.message)
                }
            } else {
                window.location.href = data?.url
            }
        },
    })

    useEffect(() => {
        form.setFieldsValue({ channel: marketplaceCode })
    }, [marketplaceCode])

    useEffect(() => {
        if (!isEmpty(params)) {
            getOAuthUrl()
        }
    }, [params])

    const handleOk = () => {
        form.validateFields().then(values => {
            let domain = undefined
            if (![CHANNEL.KIOTVIET, CHANNEL.SHOPBASE, CHANNEL.SAPO].includes(marketplaceCode)) {
                domain = window.location.hostname
            }

            setParams({ ...values, domain })
        })
    }

    const handleCancel = () => {
        setMarketplaceCode(CHANNEL.SHOPEE)
        onCancel()
    }

    const handleChange = (value: string) => {
        setMarketplaceCode(value)
    }

    return (
        <Modal
            centered
            className="_add-connection-modal"
            footer={
                <>
                    <Button
                        className="_cancel-btn"
                        onClick={handleCancel}
                    >
                        {t('btn.close')}
                    </Button>
                    <Button
                        className="_ok-btn"
                        type="primary"
                        loading={loadingOAuthUrl}
                        onClick={handleOk}
                    >
                        {t('setting:sales_channel.btn.add_connection')}
                    </Button>
                </>
            }
            title={t(`setting:sales_channel.title.add_connect`, {
                channel: t(`setting:sales_channel.channel_list.${marketplaceCode}`),
            })}
            visible={visible}
            onCancel={onCancel}
        >
            <Form
                layout="vertical"
                form={form}
            >
                <Form.Item
                    label={t('setting:sales_channel.label.choose_channel')}
                    name="channel"
                    rules={[{ required: true }]}
                >
                    <SelectChannel onChange={handleChange} value={marketplaceCode} />
                </Form.Item>
                {[CHANNEL.KIOTVIET, CHANNEL.SHOPBASE, CHANNEL.SAPO].includes(marketplaceCode) && (
                    <>
                        <Form.Item
                            label={t('label.shop_name')}
                            name="shop_name"
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.shop_name') }),
                                },
                            ]}
                        >
                            <Input placeholder={t('setting:place_holder.shop_name')} />
                        </Form.Item>

                        <Form.Item
                            label={t('label.client_id')}
                            name="client_id"
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.client_id') }),
                                },
                            ]}
                        >
                            <Input placeholder={t('setting:place_holder.client_id')} />
                        </Form.Item>
                        <Form.Item
                            label={t('label.secret_key')}
                            name="secret_key"
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.secret_key') }),
                                },
                            ]}
                        >
                            <Input.Password placeholder={t('setting:place_holder.secret_key')} />
                        </Form.Item>
                    </>
                )}
                {[CHANNEL.SHOPBASE].includes(marketplaceCode)  && (
                    <Form.Item
                        label={t('label.shared_secret')}
                        name="shared_secret"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('label.shared_secret') }),
                            },
                        ]}
                    >
                        <Input placeholder={t('setting:place_holder.shared_secret')} />
                    </Form.Item>
                )}
                {/* {marketplaceCode === CHANNEL.SAPO && (
                    <>
                        <Form.Item
                            label={t('label.api_key')}
                            name="api_ky"
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.api_key') }),
                                },
                            ]}
                        >
                            <Input placeholder={t('setting:place_holder.api_key')} />
                        </Form.Item>
                        <Form.Item
                            label={t('label.secret_key')}
                            name="secret_key"
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.secret_key') }),
                                },
                            ]}
                        >
                            <Input.Password placeholder={t('setting:place_holder.secret_key')} />
                        </Form.Item>
                    </>
                )} */}
                <Form.Item
                    label={t('label.export_warehouse')}
                    name="warehouse_id"
                    rules={[
                        {
                            required: true,
                            message: t('validation:required', { attribute: t('label.export_warehouse') }),
                        },
                    ]}
                >
                    <SelectWarehouse
                        className="_warehouse-id"
                        allowClear={true}
                        placeholder={t('order:placeholder.warehouse_id')}
                        onChange={(value: number) => form.setFieldsValue({ warehouse_id: value })}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddConnectionModal
