import { useQuery } from 'react-query';

import api from '@Modules/Order/services/api';

import processResponseError from '@System/api/processResponseError';

const usePurchasingOrderDetailQuery = id =>
    useQuery(['purchasing-order-detail', id], () => api.getPurchasingOrderDetail(id), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default usePurchasingOrderDetailQuery;
