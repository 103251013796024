import { SyncOutlined } from '@ant-design/icons'
import { Col, Row, Input, DatePicker, Modal } from 'antd'
import { Form } from 'antd'
import moment from 'moment'
import React from 'react'

import { auth } from '@Modules/Auth/services'
import SelectShop from '@Modules/Setting/components/SalesChannel/SelectShop'
import SelectShippingPartner from '@Modules/ShippingPartner/components/SelectShippingPartner'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { t } from '@System/i18n'
import { getVar, randomString } from '@System/support/helpers'
import InputNotSpace from '@Modules/App/Utilities/InputNotSpace'

const formItemProps = {
    className: 'd-flex flex-row',
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
}

export default function OrderInfo({ validatedRules, form }) {
    const user = auth.user()

    const generatorCode = () => {
        form.setFieldsValue({
            code: randomString(16),
        })
    }

    return (
        <div className="bg-white rounded-12 p-4 box-shadow">
            <Form.Item>
                <h3>{t('common:heading.create-order')}</h3>
            </Form.Item>
            <div className="tw-flex tw-items-center tw-mb-6">
                <Form.Item
                    {...formItemProps}
                    name="code"
                    label={t('order:label.order_code')}
                    rules={validatedRules.code}
                    className="tw-grow !tw-flex-row tw-w-full mb-0"
                >
                    <InputNotSpace
                        name="code"
                        className="_order-info-order-code"
                        placeholder={t('order:placeholder.code')}
                    />
                </Form.Item>
                <SyncOutlined className="tw-py-4 tw-px-4 tw-cursor-pointer hover:tw-text-blue-400" onClick={generatorCode}/>
            </div>
            <div className="tw-flex tw-items-center tw-mb-6">
                <Form.Item
                    {...formItemProps}
                    name="shipping_partner_id"
                    label={t('shipping:label.shipping_partner')}
                    className="tw-grow !tw-flex-row tw-w-full mb-0"
                >
                    <SelectShippingPartner
                        name="shipping_partner_id"
                        className="_order-info-shipping-partner-id"
                        placeholder={t('shipping:placeholder.shipping_partner')}
                        location_id={getVar(user, 'location_id', undefined)}
                    />
                </Form.Item>
                <div className="tw-w-12 tw-h-12"></div>
            </div>
            <div className="tw-flex tw-items-center tw-mb-6">
                <Form.Item
                    {...formItemProps}
                    name="freight_bill"
                    label={t('order:label.freight_bill_code')}
                    className="tw-grow !tw-flex-row tw-w-full mb-0"
                >
                    <Input
                        className="_order-info-freight-bill-code"
                        placeholder={t('order:placeholder.freight_bill_code')}
                    />
                </Form.Item>
                <div className="tw-w-12 tw-h-12"></div>
            </div>

            <div className="tw-flex tw-items-center tw-mb-6">
                <Form.Item
                    {...formItemProps}
                    name="warehouse_id"
                    label={t('order:label.warehouse_id')}
                    rules={validatedRules.warehouse_id}
                    className="tw-grow !tw-flex-row tw-w-full mb-0"
                >
                    <SelectWarehouse
                        className="_order-info-warehouse-id"
                        allowClear={true}
                        placeholder={t('order:placeholder.warehouse_id')}
                        onChange={value => form.setFieldsValue({ warehouse_id: value })}
                    />
                </Form.Item>
                <div className="tw-w-12 tw-h-12"></div>
            </div>

            <div className="tw-flex tw-items-center tw-mb-6">
                <Form.Item
                    {...formItemProps}
                    name="name_store"
                    label={t('order:label.store_id')}
                    className="tw-grow !tw-flex-row tw-w-full mb-0"
                >
                    <SelectShop
                        allowClear={true}
                        marketplaceCode={form.getFieldValue('channel') ? form.getFieldValue('channel') : undefined}
                        onChange={value => form.setFieldsValue([{ name: 'name_store', value }])}
                        value={form.getFieldValue('name_store') ? form.getFieldValue('name_store') : undefined}
                        placeholder={t('order:placeholder.channel')}
                        nameValue
                    />
                </Form.Item>
                <div className="tw-w-12 tw-h-12"></div>
            </div>
        </div>
    )
}
