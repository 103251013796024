import { Row, Col, Badge } from 'antd';
import { find } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { dateFormatter } from '@Modules/App/services';
import HistoryOrder from '@Modules/Order/components/HistoryOrder';
import { DOCUMENT_STATUS, ORDER_STATUS, ORDER_STATUS_COLORS } from '@Modules/Order/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function OrderInfo({ orderInfo }) {
    const { order, creator, shipping_partner, documents } = orderInfo || {};
    const status = getVar(order, 'status', '');
    const cancel_reason = getVar(order, 'cancel_reason', '');
    const cancel_note = getVar(order, 'cancel_note', '');
    const created_at = getVar(order, 'created_at', '');
    const order_freight_bills = get(orderInfo, 'order_freight_bills', []);

    const documentReturnGoodsStatus = get(
        find(documents, ['type', DOCUMENT_STATUS.IMPORTING_RETURN_GOODS]),
        'verified_at'
    );

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <h3 className="text-fz-16">{`${t('heading.detail_order')}: #${getVar(order, 'code', '')}`}</h3>
                    {status && (
                        <Badge
                            className="_order-status"
                            color={ORDER_STATUS_COLORS[status]}
                            text={t(`order:status.${status}`)}
                        />
                    )}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    className="d-md-flex align-items-center justify-content-end mt-3 mt-md-0 mb-2 mb-md-0"
                >
                    {!isEmpty(order) && <HistoryOrder orderInfo={order} />}
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row className="_order-detail-info-creator">
                        <Col xs={{ span: 8 }} className="pt-1 pb-1 text-color-label">
                            {t('label.creator')}:
                        </Col>
                        <Col xs={{ span: 16 }} className="d-flex align-items-center">
                            <p>{creator ? creator.name || creator.username : t('label.no_text')}</p>
                        </Col>
                    </Row>
                    <Row className="_order-detail-info-created-at mb-1">
                        <Col xs={{ span: 8 }} className="pt-1 text-color-label">
                            {t('label.created_day')}:
                        </Col>
                        <Col xs={{ span: 16 }} className="d-flex align-items-center">
                            <p>
                                {created_at ? dateFormatter.formatDate(created_at, 'DD/MM/YYYY') : t('label.no_text')}
                            </p>
                        </Col>
                    </Row>
                    {status === ORDER_STATUS.RETURN_COMPLETED && (
                        <Row className="_order-detail-info-created-at">
                            <Col xs={{ span: 8 }} className="pt-1 text-color-label">
                                {t('order:label.refund_date')}:
                            </Col>
                            <Col xs={{ span: 16 }} className="d-flex align-items-center">
                                <p>
                                    {documentReturnGoodsStatus
                                        ? dateFormatter.formatDate(documentReturnGoodsStatus, 'DD/MM/YYYY')
                                        : t('label.no_text')}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row className="_order-detail-info-shipping-partner">
                        <Col xs={{ span: 8 }} className="pt-1 pb-1 text-color-label">
                            {t('order:label.order_shipping_partner')}:
                        </Col>
                        <Col xs={{ span: 16 }} className="d-flex align-items-center">
                            <p>
                                {shipping_partner
                                    ? getVar(shipping_partner, 'name', t('label.no_text'))
                                    : getVar(order_freight_bills, '0.shipping_partner.name', t('label.no_text'))}
                            </p>
                        </Col>
                    </Row>
                    <Row className="_order-detail-info-freight-bill-code">
                        <Col xs={{ span: 8 }} className="pt-1 pb-1 text-color-label">
                            {t('order:label.freight_bill_code')}:
                        </Col>
                        <Col xs={{ span: 16 }}>
                            {order_freight_bills.length > 0
                                ? order_freight_bills.map((item, index) => {
                                      const freight_bill_code = getVar(item, 'freight_bill_code', '');
                                      return <div key={index}>{freight_bill_code}</div>;
                                  })
                                : t('label.no_text')}
                        </Col>
                    </Row>
                    <Row className="_order-detail-info-status-detail">
                        <Col xs={{ span: 8 }} className="pt-1 pb-1 text-color-label">
                            {t('label.status_detail')}:
                        </Col>
                        <Col xs={{ span: 16 }} className="d-flex align-items-center">
                            {status === ORDER_STATUS.CANCELED
                                ? cancel_reason
                                    ? cancel_reason === 'OTHER'
                                        ? `${t(`order:cancel_reason.${cancel_reason}`)} (${cancel_note})`
                                        : t(`order:cancel_reason.${cancel_reason}`)
                                    : t('noText')
                                : status
                                ? t(`order:status.${status}`)
                                : t('noText')}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
export default OrderInfo;
