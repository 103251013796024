import logoVela from '@Resources/img/logo-vela.svg';
import logo from '@Resources/img/logo.svg';
import { Image } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import getTheme from '@System/support/helpers';

import { router } from '../../../system/routing';
import { auth } from '../../Auth/services';
import useSize from '../Hooks/useSize';
import MenuItem from './MenuItem';
import MenuSub from './MenuSub';
import menu, { ExpandContext } from './configs';

function MenuLeft({ expanded, _onExpand, status, _onClick }) {
    const { isXSmall, isSmall } = useSize();
    const [selectedKeys, setSelectKeys] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setSelectKeys([router.currentRoute.name]);
    }, [location]);

    let theme = getTheme();

    return (
        <div className={`main-menu ${expanded ? 'expanded' : ''} ${status}`}>
            <div className="main-menu--member pt-2 pb-2 pl-3 pr-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="main-menu--member--user">
                        <Image
                            width={theme && theme.code === 'vela' ? 140 : 68}
                            src={theme && theme.code === 'vela' ? logoVela : logo}
                            preview={false}
                        />
                    </div>
                    <div className="main-menu--member--btn" onClick={_onExpand}>
                        <span></span>
                    </div>
                </div>
            </div>

            <ExpandContext.Provider
                value={{
                    showName: status !== '' || expanded || isXSmall || isSmall,
                    showIcon: status === '' && !expanded && !isXSmall && !isSmall,
                }}
            >
                <div className="main-menu--list pt-3">
                    {menu.length > 0 &&
                        menu
                            .filter(
                                item => lodash.isEmpty(item.allowModules) || (item.allowModules && auth.canAnyModuleMenu(item.allowModules))
                            )
                            .map((item, index) => {
                                if (item.submenu && item.submenu.length > 0) {
                                    const subItems = item.submenu.filter(
                                        sub =>
                                            lodash.isEmpty(sub.allowModules) || (sub.allowModules && auth.canAnyModuleMenu(sub.allowModules))
                                    );
                                    let parentSelected = false;
                                    subItems.map(sub => {
                                        if (selectedKeys.includes(sub.url)) {
                                            parentSelected = true;
                                        }
                                        return true;
                                    });
                                    return (
                                        <MenuSub
                                            name={item.name}
                                            key={index}
                                            icon={item.icon}
                                            onClick={_onExpand}
                                            parentSelected={parentSelected}
                                        >
                                            {subItems.map((sub, indexSub) => {
                                                return (
                                                    <MenuItem
                                                        selected={selectedKeys.includes(sub.url)}
                                                        key={index + '_' + indexSub}
                                                        icon={sub.icon}
                                                        name={sub.name}
                                                        url={sub.url}
                                                        onClick={_onClick}
                                                    />
                                                );
                                            })}
                                        </MenuSub>
                                    );
                                } else {
                                    return (
                                        <MenuSub key={index}>
                                            <MenuItem
                                                selected={selectedKeys.includes(item.url)}
                                                key={index}
                                                icon={item.icon}
                                                name={item.name}
                                                url={item.url}
                                                onClick={_onClick}
                                                isMenuSub={false}
                                            />
                                        </MenuSub>
                                    );
                                }
                            })}
                </div>
            </ExpandContext.Provider>
        </div>
    );
}

export default MenuLeft;
