import React from 'react'
import { Redirect } from 'react-router-dom'

import { ALLOW_MODULES } from '@Modules/App/MenuLeft/constants'
import Register from '@Modules/Auth/Register'
import { canAny, canAnyModuleMenu, canModuleMenu } from '@Modules/Auth/middleware/authorize'
import Overall from '@Modules/Dashboard/screens'
import Deposit from '@Modules/Dashboard/screens/Deposit'
import ProductCreateDropShipping from '@Modules/Dropshipping/screens/Create'
import ProductDetailDropShipping from '@Modules/Dropshipping/screens/Detail'
import ProductEditDropShipping from '@Modules/Dropshipping/screens/Edit'
import ListDropshipping from '@Modules/Dropshipping/screens/List'
import FinanceStatistic from '@Modules/Finance/screens/List'
import FinanceTransaction from '@Modules/Finance/screens/Transaction'
import CreateImportPackageByPurchase from '@Modules/ImportPackage/screens/Create/CreateImportPackageByPurchase'
import CreateImportPackageManual from '@Modules/ImportPackage/screens/Create/CreateImportPackageManual'
import CreatePackageDuplicate from '@Modules/ImportPackage/screens/Create/CreatePackageDuplicate'
import ImportPackageDetail from '@Modules/ImportPackage/screens/Detail'
import ImportPackageList from '@Modules/ImportPackage/screens/List'
import CreateOrder from '@Modules/Order/screens/Create'
import OrderDetail from '@Modules/Order/screens/Detail'
import EditOrder from '@Modules/Order/screens/Edit'
import ListOrder from '@Modules/Order/screens/List'
import ProductCreate from '@Modules/Product/screens/Create'
import ProductCreateCombo from '@Modules/Product/screens/CreateCombo'
import ProductDetailOrigin from '@Modules/Product/screens/Detail'
import DetailCombo from '@Modules/Product/screens/DetailCombo'
import ProductEdit from '@Modules/Product/screens/Edit'
import EditCombo from '@Modules/Product/screens/EditCombo'
import ListProduct from '@Modules/Product/screens/List'
import Quotation from '@Modules/Quotation/screens'
import PurchasingAccountList from '@Modules/Setting/screens/PurchasingAccount'
import SalesChannel from '@Modules/Setting/screens/SalesChannel/List'

import DefaultLayout from './layouts/Default'
import Error403 from './modules/App/Error/403'
import Error404 from './modules/App/Error/404'
import loadLanguage from './modules/App/middleware/loadLanguage'
import Login from './modules/Auth/Login'
import LoginCallback from './modules/Auth/LoginCallback'
import authenticate from './modules/Auth/middleware/authenticate'
import loadAuthUser from './modules/Auth/middleware/loadAuthUser'

const routes = {
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'register',
            path: '/register',
            component: Register,
        },
        {
            name: 'error-404',
            path: '/error-404',
            component: Error404,
        },
        {
            name: 'error-403',
            path: '/error-403',
            component: Error403,
        },
        {
            name: 'login.callback',
            path: '/login/callback',
            component: LoginCallback,
        },
        {
            name: 'quotation',
            path: '/quotation',
            component: Quotation,
        },
        {
            group: {
                layout: DefaultLayout,
                middleware: [authenticate, loadAuthUser],
                routes: [
                    {
                        name: 'home',
                        path: '/',
                        component: Overall,
                        middleware: [canModuleMenu([ALLOW_MODULES.DASHBOARD])],
                    },
                    // {
                    //     name: 'dashboard',
                    //     path: '/dashboard',
                    //     component: Overall,
                    // },
                    {
                        name: 'deposit',
                        path: '/deposit',
                        component: Deposit,
                    },
                    {
                        group: {
                            name: 'orders.',
                            prefix: '/orders',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListOrder,
                                    middleware: [canModuleMenu([ALLOW_MODULES.ORDER_MANAGE_ORDER])],
                                },
                                {
                                    name: 'detail',
                                    path: '/detail/:id',
                                    component: OrderDetail,
                                    middleware: [canModuleMenu([ALLOW_MODULES.ORDER_MANAGE_ORDER])],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: CreateOrder,
                                    middleware: [canModuleMenu([ALLOW_MODULES.ORDER_MANAGE_ORDER])],
                                },
                                {
                                    name: 'edit',
                                    path: '/edit/:id',
                                    component: EditOrder,
                                    middleware: [canModuleMenu([ALLOW_MODULES.ORDER_MANAGE_ORDER])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'products.',
                            prefix: '/products',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListProduct,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'detail',
                                    path: '/detail/:productId/:skuId',
                                    component: ProductDetailOrigin,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'edit',
                                    path: '/edit/:productId/:skuId',
                                    component: ProductEdit,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: ProductCreate,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'create-combo',
                                    path: '/create-combo',
                                    component: ProductCreateCombo,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'detail-combo',
                                    path: '/sku-combo/:id',
                                    component: DetailCombo,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                                {
                                    name: 'edit-combo',
                                    path: '/edit-combo/:id',
                                    component: EditCombo,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK])],
                                },
                            ],
                        },
                    },

                    {
                        group: {
                            name: 'dropshipping.',
                            prefix: '/dropshipping',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListDropshipping,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP])],
                                },
                                {
                                    name: 'detail',
                                    path: '/detail/:id',
                                    component: ProductDetailDropShipping,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP])],
                                },
                                {
                                    name: 'edit',
                                    path: '/edit/:id',
                                    component: ProductEditDropShipping,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP])],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: ProductCreateDropShipping,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'import-package.',
                            prefix: '/import-package',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/list',
                                    component: ImportPackageList,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS])],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: CreateImportPackageManual,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS])],
                                },
                                {
                                    name: 'create-duplicate',
                                    path: '/create-duplicate/:id',
                                    component: CreatePackageDuplicate,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS])],
                                },
                                {
                                    name: 'detail',
                                    path: '/detail/:id',
                                    component: ImportPackageDetail,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS])],
                                },
                                {
                                    name: 'create-by-purchase',
                                    path: '/create-by-purchase',
                                    component: CreateImportPackageByPurchase,
                                    middleware: [canModuleMenu([ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'finances.',
                            prefix: '/finances',
                            routes: [
                                {
                                    name: 'statistic',
                                    path: '/statistic',
                                    component: FinanceStatistic,
                                    middleware: [canModuleMenu([ALLOW_MODULES.FINANCE_FINANCE_ORDER])],
                                },
                                {
                                    name: 'transaction',
                                    path: '/transaction',
                                    component: FinanceTransaction,
                                    middleware: [canModuleMenu([ALLOW_MODULES.FINANCE_HISTORY_TRANSACTION])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'settings.',
                            prefix: '/settings',
                            routes: [
                                {
                                    name: 'account',
                                    path: '/account',
                                    component: PurchasingAccountList,
                                    middleware: [canModuleMenu([ALLOW_MODULES.SETUP_ACCOUNT])],
                                },
                                {
                                    name: 'sales_channel',
                                    path: '/sales-channel',
                                    component: SalesChannel,
                                    middleware: [canModuleMenu([ALLOW_MODULES.SETUP_STORE])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'stores.',
                            prefix: '/stores',
                            routes: [
                                {
                                    name: 'connect-callback',
                                    path: '/connect-callback',
                                    middleware: [canModuleMenu([ALLOW_MODULES.SETUP_STORE])],
                                    component: props => <Redirect to={`/settings/sales-channel${props.location.search}`} />
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
    defaultRoute: {
        component: Error404,
    },
    middleware: [loadLanguage],
}

export default routes
