import { Select } from 'antd'
import { get, map } from 'lodash'
import React, { useEffect, useState } from 'react'

import useStoresQuery from '@Modules/Setting/Hooks/useStoresQuery'
import { CHANNEL } from '@Modules/Setting/services/constants'

import { t } from '@System/i18n'

const { Option } = Select
interface ISelectChannel {
    onChange: (value: any) => void
    value: any
    placeholder?: string
    allowClear?: boolean
}

const SelectChannel: React.FC<ISelectChannel> = props => {
    let { onChange, value } = props
    let [stores, setStores] = useState<any[]>([])
    const { error, isError, isLoading, data } = useStoresQuery()

    useEffect(() => {
        setStores(map(get(data, 'data.stores', []), 'store'))
    }, [data])

    function _onChange(value: any) {
        onChange(value)
    }

    return (
        <Select
            {...props}
            loading={isLoading}
            onChange={_onChange}
            value={stores.length > 0 && value ? value : undefined}
            showSearch
            optionFilterProp="label"
            style={{ width: '100%' }}
            filterOption={(input, option) => {
                // @ts-ignore
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        >
            {Object.entries(CHANNEL).map(([key, value]) => {
                return (
                    <Option
                        key={key}
                        value={value}
                    >
                        {t(`setting:sales_channel.channel_list.${value}`)}
                    </Option>
                )
            })}
        </Select>
    )
}
export default SelectChannel
