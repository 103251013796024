import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SelectPurchasingOrder from '@Modules/Order/components/SelectPurchasingOrder';
import SelectPurchasingAccount from '@Modules/PurchasingManager/components/SelectPurchasingAccount';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { t } from '@System/i18n';

function ChoosePurchaseOrder({ form, setPurchasingOrderId }) {
    const [purchasingAccountId, setPurchasingAccountId] = useState();
    function onChange(name, value) {
        if (name === 'purchasing_account_id') {
            setPurchasingAccountId(value);
            setPurchasingOrderId(undefined);
            form.setFields([{ name: 'purchasing_order_id', value: undefined, errors: '' }]);
        } else {
            form.setFields([{ name, value: value ? value : undefined, errors: '' }]);
        }
    }
    return (
        <div className="_choose-purchase-order">
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t('label.account')}
                name="purchasing_account_id"
                rules={[{ required: true, message: t('product:message.required') }]}
            >
                <SelectPurchasingAccount
                    className="_account-id"
                    allowClear={true}
                    placeholder={t('placeholder.account')}
                    onChange={value => onChange('purchasing_account_id', value)}
                />
            </Form.Item>
            {purchasingAccountId && (
                <Form.Item
                    labelAlign={'left'}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    name="purchasing_order_id"
                    label={t('order:label.order_code')}
                    rules={[{ required: true, message: t('product:message.required') }]}
                >
                    <SelectPurchasingOrder
                        placeholder={t('order:placeholder.order_code')}
                        className="_purchasing-order-id"
                        id={purchasingAccountId}
                        onChange={value => {
                            setPurchasingOrderId(value);
                            onChange('purchasing_order_id', value);
                        }}
                    />
                </Form.Item>
            )}

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t('warehouse:label.receive_warehouse')}
                name="warehouse_id"
                rules={[{ required: true, message: t('product:message.required') }]}
            >
                <SelectWarehouse
                    className="_warehouse-id"
                    allowClear={true}
                    placeholder={t('warehouse:placeholder.receive_warehouse')}
                    onChange={value => onChange('warehouse_id', value)}
                />
            </Form.Item>
        </div>
    );
}

ChoosePurchaseOrder.propTypes = {
    formRules: PropTypes.object,
};

ChoosePurchaseOrder.defaultProps = {
    formRules: {},
};

export default ChoosePurchaseOrder;
