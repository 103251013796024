import ApiAuth from './ApiAuth';
import ApiUrl from './ApiUrl';
import ApiFactory from './ApiFactory';
import ResponseProcessor from './ResponseProcessor';
import config from '../../config/app';

let auth = new ApiAuth();
let url = new ApiUrl(config.apiUrl, auth);
let api = (new ApiFactory(config.apiUrl, auth)).make();
let responseProcessor = new ResponseProcessor();

const processResponse = responseProcessor.process.bind(responseProcessor);

export {
    auth,
    url,
    api,
    processResponse,
};