export const PRODUCT_STATUS = {
    ON_SELL: 'ON_SELL',
    STOP_SELLING: 'STOP_SELLING',
};

export const PRODUCT_STATUS_COLORS = {
    ON_SELL: '#06d087',
    STOP_SELLING: '#bdbdbd',
};

export const PRODUCT_EVENT = {
    RELOAD_PRODUCT_LIST: 'RELOAD_PRODUCT_LIST',
};

export const PRODUCT_HISTORY_CHANGE_COLOR = {
    DECREASE: '#FF4559',
    INCREASE: '#09B2AA',
};

export const IGNORE_ACTIONS = ["EXPORT_FOR_PICKING", "EXPORT_FOR_CHANGE_POSITION", "IMPORT_FOR_PICKING", "IMPORT_FOR_CHANGE_POSITION"]
