import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { filter, get } from 'lodash'
import React, { useState } from 'react'

import api from '@Modules/Product/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { convertBlobDataToExcel } from '@System/support/helpers'

function ExportListProduct({ filters }) {
    const [loading, setLoading] = useState(false)
    function handleDownload() {
        setLoading(true)

        const params = {
            ...filters,
            current_page: undefined,
            page: undefined,
            per_page: undefined,
        }
        api.exportListProduct(params)
            .then(res => {
                convertBlobDataToExcel(res.data, 'product list')
                notification.success(t('product:message.export_file.success'))
            })
            .catch(err => {
                processResponseError(err)
            })
            .finally(() => setLoading(false))
    }
    return (
        <Button
            onClick={handleDownload}
            type="primary"
            loading={loading}
            icon={<DownloadOutlined />}
            className="_btn-export-list-product"
        >
            {t('btn.export_excel')}
        </Button>
    )
}
export default ExportListProduct
