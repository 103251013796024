import { ArrowLeftOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Spin } from 'antd';
import { get, isEqual } from 'lodash';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import useDropshipDetailQuery from '@Modules/Dropshipping/Hooks/useDropshipDetailQuery';
import ModalUploadImageSKU from '@Modules/Dropshipping/components/Edit/ModalUploadImageSKU';
import PackingServiceInfo from '@Modules/Dropshipping/components/Edit/PackingServiceInfo';
import ProductInfo from '@Modules/Dropshipping/components/Edit/ProductInfo';
import ProductOptions from '@Modules/Dropshipping/components/Edit/ProductOptions';
import ProductVariations from '@Modules/Dropshipping/components/Edit/ProductVariations';
import WarehouseService from '@Modules/Dropshipping/components/Edit/WarehouseService';
import api from '@Modules/Dropshipping/services/api';
import { PRODUCT_EVENT } from '@Modules/Dropshipping/services/constants';
import ProductImages from '@Modules/Product/components/Edit/ProductImages';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

function ProductEditDropShipping(props) {
    const user = auth.user();
    const { match } = props;
    const productId = match.params.id;
    const [form] = Form.useForm();
    const [product, setProduct] = useState({});
    const [skus, setSkus] = useState({});
    const [options, setOptions] = useState({});
    const [services, setServices] = useState([]);
    const [images, setImages] = useState([]);
    const [localOptions, setLocalOptions] = useState([]);
    const [localVariations, setLocalVariations] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [serviceExport, setServiceExport] = useState([]);
    const [serviceImport, setServiceImport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentKey, setCurrentKey] = useState();
    const [visible, setVisible] = useState(false);

    const { error, isError, isFetching, data } = useDropshipDetailQuery(productId);
    useEffect(() => {
        setNavigator(t('title.product_edit_dropshipping'), [
            {
                name: t('title.product_list_dropshipping'),
                route: 'dropshipping.list',
            },
            {
                name: t('title.product_edit_dropshipping'),
            },
        ]);
        setDocumentTitle(t('title.product_edit_dropshipping'));
    }, []);

    useEffect(() => {
        setProduct(data?.data?.product);
        setServices(getVar(data, 'data.services', []));
        setSkus(getVar(data, 'data.skus', []));
        setOptions(getVar(data, 'data.options', []));
    }, [data]);

    useEffect(() => {
        setDocumentTitle(t('title_page.product_edit', { code: getVar(product, 'code', '') }));
        setImages(getVar(product, 'images', []));
    }, [product]);

    useEffect(() => {
        const newImages = refactorImage(images);
        if (!isEqual(fileList, newImages)) {
            setFileList(newImages);
        }
    }, [fileList, images]);
    // Tạo các thuộc tính đã có lưu vào localOptions khi options được load
    const initLocalOptions = useCallback(() => {
        let tmpOpts = [];
        if (options.length > 0) {
            options.forEach(opt => {
                let productOpt = get(opt, 'productOption', {}),
                    values = get(opt, 'options', []);
                tmpOpts.push({
                    id: productOpt.id,
                    label: productOpt.label,
                    values: values.map(value => ({ id: value.id, label: value.label })),
                });
            });
            setLocalOptions(tmpOpts);
        }
    }, [options]);

    // Tạo các biến thể đã có lưu vào localVariations sau khi skus được tải
    const initLocalVariations = useCallback(() => {
        let tmpVariations = [];

        if (skus.length > 0) {
            skus.forEach(sku => {
                let optValues = get(sku, 'optionValues', {}),
                    optSku = get(sku, 'sku', {});
                tmpVariations.push({
                    key: optSku.id,
                    id: optSku.id,
                    code: optSku.code,
                    ref: optSku.ref,
                    images: optSku.images,
                    status: get(optSku, 'status', null),
                    option_values: optValues.map(optValue => ({
                        id: optValue.id,
                        label: optValue.label,
                        option_label: '',
                    })),
                });
            });
        }
        setLocalVariations(tmpVariations);
    }, [skus]);

    useEffect(() => {
        if (options) initLocalOptions();
    }, [initLocalOptions, options]);

    useEffect(() => {
        if (skus) initLocalVariations();
    }, [initLocalVariations, skus]);

    const refactorImage = images => {
        const newImages = [];
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 });
        });
        return newImages;
    };

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    const creator_id = getVar(product, 'creator_id', '');

    if (!isEmpty(product) && user.id !== creator_id) {
        return <Error403 />;
    }

    const handleUpdate = async (name, value) => {
        let formData = new FormData();
        let status = '';
        let newData = {};

        if (name === 'file') {
            if (isArray(value)) {
                status = 'added';
                map(value, image => {
                    formData.append('files[]', image);
                });
            } else {
                status = 'removed';
                const remove_file = getVar(value, 'url', '');
                resetImageVarians(remove_file);
                if (remove_file) {
                    formData.append('removed_files[]', remove_file);
                }
            }
            api.updateProductDropshipping(productId, name === 'file' ? formData : newData)
                .then(res => {
                    if (status) {
                        notification.success(
                            status === 'added'
                                ? t('message.create_success', {
                                      attribute: t(`product:label.image`),
                                  })
                                : t('message.delete_success', {
                                      attribute: t(`product:label.image`),
                                  })
                        );
                    } else {
                        notification.success(
                            t('message.update_success_by_attribute', {
                                attribute: status ? t(`product:label.image.${status}`) : t(`product:label.${name}`),
                            })
                        );
                    }
                    setProduct(getVar(res, 'data.product', {}));
                    setServices(getVar(res, 'data.services', []));
                })
                .catch(err => {
                    const code = getVar(err, 'response.data.code', '');
                    if (code === 'INPUT_INVALID') {
                        renderError(getVar(err, 'response.data.data', {}));
                    } else {
                        notification.error(t('message.update_failed_by_attribute', { attribute: t(`product:label.${name}`) }));
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        form.setFieldsValue({
            [name]: value,
        });
    };
    const handleEditProduct = () => {
        form.setFieldsValue({
            options: localOptions,
            skus: localVariations,
        });
        form.validateFields().then(() => {
            let isValid = true;
            if (!checkOptions()) {
                form.setFields([
                    {
                        name: 'options',
                        errors: [t('product:message.properties_required')],
                    },
                ]);

                isValid = false;
            }
            if (!localOptions.every(ele => !isEmpty(ele.values)) || !checkOptions()) {
                notification.error(
                    t('errorMessages.required', {
                        attribute: `${t('common:title.properties')} ${t('common:title.dropshipping')}`,
                    })
                );
            } else if (isValid) {
                setLoading(true);
                api.updateProductDropshipping(productId, form.getFieldsValue(true))
                    .then(res => {
                        notification.success(t('message.update_success_by_attribute'), {
                            attribute: t(`common:title.dropshipping`),
                        });
                        setProduct(getVar(res, 'data.product', {}));
                        setServices(getVar(res, 'data.services', []));

                        url.redirectTo('dropshipping.detail', { id: productId });
                        events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_DETAIL_DROPSHIP, {});
                    })
                    .catch(err => {
                        const code = getVar(err, 'response.data.code', '');
                        if (code === 'INPUT_INVALID') {
                            renderError(getVar(err, 'response.data.data', {}));
                        } else {
                            notification.error(
                                t('message.update_failed_by_attribute', { attribute: t(`common:title.dropshipping`) })
                            );
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
    };
    const renderError = errors => {
        Object.keys(errors).forEach(item => {
            let key_errors = [];
            Object.keys(errors[item]).forEach(error => {
                let name = item;
                if (item === 'file') name = 'image';
                if (name === 'sku_code_exist' || name === 'sku_code_duplicated') {
                    key_errors.push(t(`product:message.${name}`, { attribute: error }));
                } else {
                    key_errors.push(t(`product:message.${error}`, { attribute: t(`product:label.${name}`) }));
                }
            });
            notification.error(key_errors.join(', '));
        });
    };

    const checkOptions = () => {
        return localOptions.every(option => !isEmpty(option.label) && option.values.length > 0);
    };

    const addOption = () => {
        if (checkOptions() && localOptions.length < 3) {
            let newOptions = [...localOptions];
            newOptions.push({ id: null, label: '', values: [] });
            setLocalOptions(newOptions);
        }
    };

    const handleOpenModal = key => {
        setCurrentKey(key);
        setVisible(true);
    };

    const handleCancelModal = () => {
        setVisible(false);
    };
    const resetImageVarians = url => {
        let new_arr = localVariations.map(val => {
            if (val.images !== null) {
                if (get(val, 'images[0]') === url) {
                    val = { ...val, images: null };
                }
            }
            return val;
        });
        setLocalVariations(new_arr);
    };
    return (
        <div className="site-content">
            <Spin spinning={isFetching}>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link className="_dropshipping-detail-back" to={url.to('dropshipping.detail', { id: productId })}>
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18 mt-1">{t('breadcrumb.product_edit')}</h3>
                    </div>
                    <Button loading={loading} type="primary" icon={<SaveOutlined />} onClick={handleEditProduct}>
                        {t('btn.save_product')}
                    </Button>
                </div>
                <div className="bg-light-gray">
                    <div className="bg-white rounded-12 p-4 box-shadow">
                        <div className="_dropshipping-edit-product-info">
                            <h3 className="mb-3">{t('title.product_info')}</h3>
                            <ProductInfo product={product} onChange={handleUpdate} form={form} />
                            <div className="d-flex align-items-center">
                                <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                                <span className="ml-2 number-circle _importing-document-product-list-total">{images.length}</span>
                                <i className="ml-2">{t('product:description.picture')}</i>
                            </div>
                        </div>
                        <div className="mt-4 _dropshipping-edit-picture">
                            <ProductImages
                                onChange={handleUpdate}
                                name={t('product:label.image')}
                                accept="image/jpeg, image/png"
                                images={images}
                                maxFileSize={5}
                                multiple={true}
                                productId={productId}
                            />
                        </div>
                    </div>
                    <div className="bg-white rounded-12 mt-4 p-4 _dropshipping-edit-packing-service-importing box-shadow">
                        <div className="d-flex justify-content-between">
                            <h3>{`${t('title.properties')}`}</h3>
                            <div className="d-flex justify-content-end align-items-center">
                                {localOptions.length < 3 && (
                                    <Button
                                        className="cursor-pointer mr-2 _dropshipping-option-add-btn"
                                        onClick={addOption}
                                        loading={loading}
                                    >
                                        <PlusOutlined /> {t('product:btn.option_add_btn')}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <ProductOptions
                            product={product}
                            localOptions={localOptions}
                            setLocalOptions={setLocalOptions}
                            checkOptions={checkOptions}
                            form={form}
                            handleUpdate={handleUpdate}
                        />

                        <ProductVariations
                            product={product}
                            form={form}
                            localOptions={localOptions}
                            localVariations={localVariations}
                            setLocalVariations={setLocalVariations}
                            setLocalOptions={setLocalOptions}
                            handleOpenModal={handleOpenModal}
                            handleUpdate={handleUpdate}
                        />
                    </div>

                    {/* <div className="bg-white rounded-12 mt-4 p-4 _dropshipping-edit-packing-service-importing">
                        <div className="d-flex justify-content-between">
                            <h3>{`${t('product:label.service_importing')}`}</h3>
                        </div>
                        <PackingServiceInfo
                            onChange={handleUpdate}
                            serviceSelected={services}
                            setServices={setServices}
                            setServiceImport={setServiceImport}
                            serviceExport={serviceExport}
                            serviceType={SERVICES_TYPE.IMPORT}
                        />
                    </div>

                    <div className="bg-white rounded-12 mt-4 p-4 _dropshipping-edit-packing-service-exporting box-shadow">
                        <div className="d-flex justify-content-between">
                            <h3>{`${t('product:label.service_exporting')}`}</h3>
                        </div>
                        <WarehouseService
                            serviceType={SERVICES_TYPE.EXPORT}
                            onChange={handleUpdate}
                            serviceSelected={services}
                            setServices={setServices}
                            serviceImport={serviceImport}
                            setServiceExport={setServiceExport}
                        />
                    </div> */}
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button loading={loading} type="primary" icon={<SaveOutlined />} onClick={handleEditProduct}>
                        {t('btn.save_product')}
                    </Button>
                </div>
                <ModalUploadImageSKU
                    visible={visible}
                    handleCancel={handleCancelModal}
                    fileList={fileList}
                    currentKey={currentKey}
                    localVariations={localVariations}
                    setLocalVariations={setLocalVariations}
                    setLocalOptions={setLocalOptions}
                    localOptions={localOptions}
                    handleUpdate={handleUpdate}
                />
            </Spin>
        </div>
    );
}
export default ProductEditDropShipping;
