import { SaveOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form } from 'antd';
import every from 'lodash/every';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo, useState } from 'react';

import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import ChoosePurchaseOrder from '@Modules/ImportPackage/components/Update/ChoosePurchaseOrder';
import OptionServices from '@Modules/ImportPackage/components/Update/OptionServices';
import PurchasingPackageList from '@Modules/ImportPackage/components/Update/PurchasingPackageList';
import PurchasingProductList from '@Modules/ImportPackage/components/Update/PurchasingProductList';
import api from '@Modules/ImportPackage/services/api';
import usePurchasingOrderDetailQuery from '@Modules/Order/Hooks/usePurchasingOrderDetailQuery';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

export default function CreateImportPackageByPurchase() {
    const [form] = Form.useForm();
    const [purchasingOrderId, setPurchasingOrderId] = useState();
    const [packages, setPackages] = useState([]);
    const [products, setProducts] = useState([]);
    const [serviceIds, setServiceIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const { data, isFetching, refetch } = usePurchasingOrderDetailQuery(purchasingOrderId);

    useEffect(() => {
        setNavigator(t('title.create_import_purchase_system'), [
            {
                name: t('title.list_import_package'),
                route: 'import-package.list',
            },
            {
                name: t('title.create_import_purchase_system'),
            },
        ]);
        setDocumentTitle(t('title.create_import_purchase_system'));
    }, []);

    useEffect(() => {
        if (purchasingOrderId) {
            refetch();
        }
    }, [purchasingOrderId]);

    useEffect(() => {
        setPackages(getVar(data, 'data.purchasing_order.purchasing_packages', []));
        setProducts(refactorProduct(getVar(data, 'data.purchasing_order.purchasing_order_items', [])));
    }, [data]);

    function refactorProduct(data) {
        const products = [];
        map(data, item => {
            products.push({
                purchasing_order_id: purchasingOrderId,
                purchasing_product_id: getVar(item, 'id', ''),
                purchasing_product_name: getVar(item, 'item_name', ''),
                image: getVar(item, 'variant_image', ''),
                sku_id: getVar(item, 'purchasing_variant.sku_id', undefined),
                sku_info: getVar(item, 'purchasing_variant.sku', {}),
                quantity: getVar(item, 'received_quantity', 0),
                variant_properties: getVar(item, 'variant_properties', []),
            });
        });
        return products;
    }
    function checkProductMapedSku() {
        return every(products, product => {
            const sku_id = getVar(product, 'sku_id', undefined);
            return !!sku_id;
        });
    }
    function createImportPackage() {
        form.validateFields().then(values => {
            const mapedSku = checkProductMapedSku();
            if (mapedSku) {
                const warehouse_id = values['warehouse_id'];
                setLoading(true);
                api.createImportPackageByPurchase(purchasingOrderId, {
                    id: purchasingOrderId,
                    services: serviceIds.length > 0 ? serviceIds : undefined,
                    is_putaway: 1,
                    warehouse_id,
                })
                    .then(res => {
                        setServiceIds([]);
                        form.resetFields();
                        notification.success(t('message.create_success', { attribute: t('product:label.import_package') }));
                        const purchasingPackage = getVar(res, 'data.purchasingPackage', {});
                        url.redirectTo('import-package.list');
                    })
                    .catch(err => {
                        const status = getVar(err, 'response.status', 0);
                        if (status === 400) {
                            renderError(getVar(err, 'response.data.data', {}));
                        } else {
                            notification.error(t('message.create_failed', { attribute: t('product:label.import_package') }));
                        }
                    })
                    .finally(() => setLoading(false));
            } else {
                notification.error(t('product:message.map_sku.warning'));
            }
        });
    }

    function renderError(errors) {
        const fields_enable = ['freight_bill_code', 'destination_warehouse_id', 'shipping_partner_id', 'weight'];
        const error_messages = [];
        Object.keys(errors).forEach(item => {
            let key_errors = [];
            Object.keys(errors[item]).forEach(error =>
                key_errors.push(t(`errorMessages.${error}`, { attribute: t(`product:label.${item}`) }))
            );
            if (fields_enable.includes(item)) {
                error_messages.push({ name: item, errors: key_errors });
            } else {
                notification.error(key_errors.join(', '));
            }
        });
        form.setFields(error_messages);
    }
    const selectedValues = useMemo(() => [], [])
    return (
        <div className="site-content">
            <Form form={form}>
                <div className="d-flex justify-content-between mb-2">
                    <h3 className="text-fz-18">{t('breadcrumb.create_import_purchase_system')}</h3>
                    <Form.Item shouldUpdate className="submit mb-0">
                        {() => (
                            <Button
                                className="_btn-create-import-package"
                                loading={loading}
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={createImportPackage}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    packages.length === 0 ||
                                    !checkProductMapedSku()
                                }
                            >
                                {t('btn.save_import_package')}
                            </Button>
                        )}
                    </Form.Item>
                </div>
                <div className="bg-light-gray">
                    <Row className="bg-white rounded-12 p-4 box-shadow">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <h4 className="mb-3">{t('title.choose_purchase_order')}</h4>
                            <ChoosePurchaseOrder setPurchasingOrderId={setPurchasingOrderId} form={form} />
                        </Col>
                    </Row>

                    <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
                        <PurchasingPackageList dataSource={packages} form={form} loading={isFetching} />
                    </div>

                    <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
                        <PurchasingProductList dataSource={products} form={form} loading={isFetching} setProducts={setProducts} />
                    </div>

                    <div className="bg-white rounded-12 p-4 mt-4 _option-services box-shadow">
                        <div className="d-flex">
                            <h4 className="">{`${t('title.option_service_import')}`}</h4>
                            <i className="ml-2">{t('placeholder.option_service_import')}</i>
                        </div>
                        <OptionServices form={form} setServiceIds={setServiceIds} quantity={sumBy(products, 'quantity')} selectedValues={selectedValues}/>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Form.Item shouldUpdate className="submit mb-0">
                        {() => (
                            <Button
                                className="_btn-create-import-package"
                                loading={loading}
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={createImportPackage}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    products.length === 0 ||
                                    packages.length === 0 ||
                                    !checkProductMapedSku()
                                }
                            >
                                {t('btn.save_import_package')}
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}
