import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input, AutoComplete } from 'antd'
import { get, isEmpty } from 'lodash'
import debounce from 'lodash/debounce'
import React, { useCallback, useState } from 'react'

import api from '@Modules/Product/services/api'
import { PRODUCT_STATUS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { getVar } from '@System/support/helpers'

import { updateCollectionItem } from '../../../App/services/helpers'

export default function AddProduct({ orderSkus, setOrderSkus, form }) {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState(false)

    const _onSearch = useCallback(
        debounce(value => {
            setOptions([])
            if (value !== '' && value !== null && value !== undefined) {
                setLoading(true)
                api.suggestProductAndCombo({ keyword: value })
                    .then(res => {
                        const skuCombos = getVar(res, 'data.sku_combos.data', [])
                        const sku = getVar(res, 'data.skus.data', [])
                        setOptions([...skuCombos, ...sku])
                    })
                    .catch(error => {
                        notification.error(t('message.server_connection_error'))
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }, 300),
        []
    )

    const getOptions = option => {
        let { code, name, id, skus } = option

        return {
            label: (
                <div className="tw-flex tw-items-center ">
                    <p className="tw-overflow-hidden tw-truncate tw-w-3/4 tw-text-ellipsis">{`${code} - ${name}`}</p>
                    {!isEmpty(get(skus, 'data', [])) && <span className="combo-tag ml-1">{t('label.combo')}</span>}
                </div>
            ),
            value: id,
            sku_id: id,
            className: '_product-dropdown-option',
        }
    }

    function _onChange(value) {
        setKeyword(value)
    }

    function _onSelect(value, option) {
        addSku(value)
        setOptions([])
        setKeyword('')
    }

    function addSku(sku_id) {

        let currentOrder = orderSkus.find(item => item.sku_id === sku_id)
        if (currentOrder) {
            // Nếu sku đã có trong danh sách thì thêm số lượng
            let { quantity } = currentOrder
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, { ...currentOrder, quantity: quantity + 1 }, 'sku_id'))
        } else {
            // Nếu chưa có thì thêm vào danh sách (Lưu cả danh sách giá sp theo từng seller)

            let sku = options.find(item => item.id === sku_id)

            if (sku) {
                let unit = getVar(sku, 'unit.name', '')
                let prices = getVar(sku, 'prices', [])
                let price = undefined
                let merchant_id = form.getFieldValue('merchant_id')
                if (merchant_id && prices.length > 0) {
                    let priceByMerchant = prices.find(item => item.merchant_id === merchant_id)
                    price = priceByMerchant ? priceByMerchant.retail_price : ''
                }
                const images = getVar(sku, 'images', [])

                const newData = [
                    ...orderSkus,
                    {
                        sku_id,
                        code: sku.code,
                        name: sku.name,
                        image: images.length > 0 ? images[0] : '',
                        product_id: sku.product_id,
                        unit,
                        price,
                        quantity: 1,
                        tax: sku.tax || 0,
                        discount_amount: 0,
                        prices,
                        skus:{
                            data: getVar(sku, 'skus.data', [])
                        }
                    },
                ]

                setOrderSkus(newData)
            }
        }
    }
    return (
        <>
            <AutoComplete
                options={options.map(getOptions)}
                style={{ width: '100%' }}
                onSearch={_onSearch}
                onSelect={_onSelect}
                onChange={_onChange}
                value={keyword}
                dropdownClassName="_dropdown-list-search"
            >
                <Input
                    className="_add-product-input-search"
                    size={'large'}
                    placeholder={t('product:placeholder.search_product_by_name_code')}
                    prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
                    allowClear={true}
                />
            </AutoComplete>
        </>
    )
}
