export const LANGUAGES = [
    {key: "vi", value: "VIETNAM", icon: "vietnam", code: "vietnam"},
    {key: "zh", value: "CHINA", icon: "china"},
    {key: "en", value: "ENGLISH", icon: "english"},
    // {key: "id", value: "INDONESIA", icon: "indonesia"},
    {key: "th", value: "THAILAND", icon: "thai", code: "thailand"}
];

export const MARKETS = [
    {key: "vi", value: "VIETNAM", icon: "vietnam", code: "vietnam"},
    {key: "th", value: "THAILAND", icon: "thai", code: "thailand"},
    {key: "kh", value: "CAMBODIA", icon: "cambodia", code: "cambodia"}
];

export const SHIPPING_PARTNER_INFO = {
    GHN: {color: "#E38225", wildcard: "N"},
    GHTK: {color: "#41BA7C", wildcard: "T"},
    JNTP: {color: "#EE1C25", wildcard: "J"},
    LWE: {color: "#1E7029", wildcard: "T"},
    SHIPPO: {color: "#029240", wildcard: "O"},
    SNAPPY: {color: "#1E2A4F", wildcard: "S"},
    GOSHIP: {color: "#ba1321", wildcard: "G"},
    JNTVN: {color: "#EE1C25", wildcard: "J"},
};

export const ERROR_ORDER_CODE = [
    'SKU_UNMAPPED',
    'MERCHANT_UNMAPPED',
    'TECHNICAL',
    { key: 'vi', value: 'VIETNAM', icon: 'vietnam' },
    { key: 'zh', value: 'CHINA', icon: 'china' },
    { key: 'en', value: 'ENGLISH', icon: 'english' },
    { key: 'id', value: 'INDONESIA', icon: 'indonesia' },
    { key: 'th', value: 'THAILAND', icon: 'thai' },
];

export const PAGE_SIZE_DESKTOP = 250;
export const PAGE_SIZE_MOBILE = 10;

export const pageSizeOptions = [1, 5, 10, 20, 50, 100, 250];
