import React from 'react'

export default function FlagCountryComponent(icon) {
    const VietNam = () => (
        <svg
            t="1617960224643"
            className="icon"
            viewBox="0 0 1560 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7693"
            width="24"
            height="24"
        >
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CE1126"
                p-id="7694"
            ></path>
            <path
                d="M780.190476 124.342857l95.085714 297.447619h312.076191l-251.12381 180.419048 95.085715 297.447619L780.190476 716.8l-251.123809 182.857143 95.085714-297.447619-251.12381-180.419048h312.076191z"
                fill="#FCD116"
                p-id="7695"
            ></path>
        </svg>
    )

    const China = () => (
        <svg
            t="1617960534639"
            className="icon"
            viewBox="0 0 1560 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8624"
            width="24"
            height="24"
        >
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#DE2910"
                p-id="8625"
            ></path>
            <path
                d="M1560.380952 129.219048v755.809523V138.971429v-9.752381z"
                fill="#57A863"
                p-id="8626"
            ></path>
            <path
                d="M275.504762 126.780952l29.257143 82.895238h87.771428l-73.142857 53.638096 29.257143 82.895238-73.142857-51.2-70.704762 51.2 26.819048-82.895238-70.704762-53.638096h87.771428zM472.990476 46.32381l4.876191 31.695238 31.695238 7.314285-29.257143 12.190477 2.438095 34.133333-21.942857-24.380953-29.257143 14.628572 14.628572-29.257143-21.942858-24.380952 31.695239 7.314285zM543.695238 151.161905l17.066667 26.819047 31.695238-4.87619-21.942857 24.380952 17.066666 26.819048-29.257142-12.190476-21.942858 24.380952 2.438096-34.133333-29.257143-12.190476 31.695238-7.314286zM533.942857 268.190476l9.752381 31.695238h31.695238l-26.819047 19.504762 9.752381 31.695238-24.380953-19.504762-26.819047 19.504762 9.75238-31.695238-24.380952-21.942857 31.695238 2.438095zM470.552381 360.838095l-4.876191 31.695238 31.695239 12.190477-31.695239 9.75238-2.438095 34.133334-17.066666-29.257143-31.695239 9.752381 21.942858-26.819048-17.066667-26.819047 29.257143 9.752381z"
                fill="#FFDE00"
                p-id="8627"
            ></path>
        </svg>
    )

    const English = () => (
        <svg
            t="1632815011007"
            className="icon"
            viewBox="0 0 1560 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1947"
            width="24"
            height="24"
        >
            <path
                d="M1560.380952 877.714286V146.285714L926.47619 512zM1487.238095 19.504762c-19.504762-12.190476-43.885714-19.504762-70.704762-19.504762H141.409524c-24.380952 0-48.761905 7.314286-70.704762 19.504762L780.190476 426.666667 1487.238095 19.504762zM70.704762 1004.495238c21.942857 12.190476 46.32381 19.504762 70.704762 19.504762h1277.561905c24.380952 0 48.761905-7.314286 70.704761-17.066667L780.190476 597.333333 70.704762 1004.495238zM0 146.285714v731.428572l633.904762-365.714286z"
                fill="#00237A"
                p-id="1948"
            ></path>
            <path
                d="M658.285714 497.371429L2.438095 119.466667c-2.438095 4.87619-2.438095 12.190476-2.438095 19.504762V146.285714l633.904762 365.714286 24.380952-14.628571zM902.095238 526.628571l655.847619 380.342858c0-7.314286 2.438095-12.190476 2.438095-19.504762V877.714286L926.47619 512l-24.380952 14.628571zM1543.314286 953.295238L853.333333 553.447619l-73.142857 41.447619 709.485714 409.6c21.942857-12.190476 41.447619-29.257143 53.638096-51.2zM17.066667 70.704762L707.047619 470.552381l73.142857-41.447619L70.704762 19.504762C48.761905 31.695238 31.695238 48.761905 17.066667 70.704762z"
                fill="#FFFFFF"
                p-id="1949"
            ></path>
            <path
                d="M17.066667 70.704762c-7.314286 14.628571-12.190476 31.695238-14.628572 48.761905L658.285714 497.371429l48.761905-29.257143L17.066667 70.704762zM853.333333 553.447619L1543.314286 950.857143c7.314286-14.628571 12.190476-29.257143 14.628571-46.32381L902.095238 526.628571l-48.761905 26.819048z"
                fill="#CF142B"
                p-id="1950"
            ></path>
            <path
                d="M755.809524 582.704762L48.761905 989.866667l21.942857 14.628571L780.190476 597.333333l-24.380952-14.628571zM17.066667 953.295238L707.047619 553.447619 633.904762 512 0 877.714286v7.314285c0 24.380952 7.314286 46.32381 17.066667 68.266667zM804.571429 441.295238L1511.619048 34.133333l-21.942858-14.628571L780.190476 426.666667l24.380953 14.628571zM1543.314286 73.142857L853.333333 470.552381l73.142857 41.447619L1560.380952 146.285714v-7.314285c0-24.380952-7.314286-46.32381-17.066666-65.828572z"
                fill="#FFFFFF"
                p-id="1951"
            ></path>
            <path
                d="M17.066667 953.295238c7.314286 14.628571 19.504762 26.819048 31.695238 39.009524L755.809524 585.142857l-48.761905-29.257143L17.066667 953.295238zM780.190476 512l-24.380952 41.447619-24.380953-12.190476-24.380952 12.190476 48.761905 29.257143 48.761905-29.257143z"
                fill="#CF142B"
                p-id="1952"
            ></path>
            <path
                d="M755.809524 553.447619l24.380952-41.447619-48.761905 29.257143zM1543.314286 73.142857c-7.314286-14.628571-19.504762-26.819048-31.695238-39.009524L804.571429 441.295238l48.761904 29.257143L1543.314286 73.142857z"
                fill="#CF142B"
                p-id="1953"
            ></path>
            <path
                d="M780.190476 512l24.380953-41.447619 24.380952 12.190476 24.380952-12.190476-48.761904-29.257143-48.761905 29.257143z"
                fill="#CF142B"
                p-id="1954"
            ></path>
            <path
                d="M780.190476 512l48.761905-29.257143-24.380952-12.190476z"
                fill="#CF142B"
                p-id="1955"
            ></path>
            <path
                d="M1560.380952 390.095238H902.095238V0H658.285714v390.095238H0v243.809524h658.285714v390.095238h243.809524V633.904762h658.285714z"
                fill="#FFFFFF"
                p-id="1956"
            ></path>
            <path
                d="M1560.380952 438.857143H853.333333V0h-146.285714v438.857143H0v146.285714h707.047619v438.857143h146.285714V585.142857h707.047619z"
                fill="#CF142B"
                p-id="1957"
            ></path>
        </svg>
    )

    const Indonesia = () => (
        <svg
            t="1632815066003"
            className="icon"
            viewBox="0 0 1560 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2208"
            width="24"
            height="24"
        >
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CC1126"
                p-id="2209"
            ></path>
            <path
                d="M0 885.028571C0 960.609524 63.390476 1024 141.409524 1024h1277.561905c78.019048 0 141.409524-60.952381 141.409523-138.971429V512H0v373.028571z"
                fill="#FFFFFF"
                p-id="2210"
            ></path>
        </svg>
    )

    const Thailand = () => (
        <svg
            t="1632815096040"
            className="icon"
            viewBox="0 0 1560 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2441"
            width="24"
            height="24"
        >
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CF0F15"
                p-id="2442"
            ></path>
            <path
                d="M0 151.161905h1560.380952v724.114285H0z"
                fill="#FFFFFF"
                p-id="2443"
            ></path>
            <path
                d="M0 307.2h1560.380952v409.6H0z"
                fill="#00247D"
                p-id="2444"
            ></path>
        </svg>
    )

    const Cambodia = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 625 400"
            className="icon"
            version="1.1"
            width="24"
            height="24"
        >
            <path
                fill="#032ea1"
                d="M0 0h625v400H0z"
            />
            <path
                fill="#e00025"
                d="M0 100h625v200H0z"
            />
            <g
                fill="#fff"
                stroke="#000"
                stroke-width=".96"
                transform="translate(117.143 -434.934)"
            >
                <g stroke-linejoin="bevel">
                    <path d="M139.02 623.46h113.8v43.788h-113.8z" />
                    <path d="M246.95 647.64h3.586v16.495h-3.586zM139.02 639.49h113.8v5.09h-113.8zM139.02 632.59h113.8v4.489h-113.8z" />
                    <path
                        stroke-width=".945"
                        d="M139.02 625.7h113.8v4.354h-113.8z"
                    />
                    <path d="M168.98 647.64h3.586v16.495h-3.586zM217.97 647.64h3.586v16.495h-3.586zM139.92 647.64h3.586v16.495h-3.586zM146.95 647.64h3.586v16.495h-3.586zM154.45 647.64h3.586v16.495h-3.586zM161.95 647.64h3.586v16.495h-3.586zM224.77 647.64h3.586v16.495h-3.586zM232.27 647.64h3.586v16.495h-3.586zM239.77 647.64h3.586v16.495h-3.586z" />
                    <path
                        stroke-linejoin="miter"
                        d="M94.509 669.55c1.76-.897 3.768-2.984 4.425-5.319h193.07c.657 2.335 2.665 4.422 4.425 5.319z"
                    />
                </g>
                <path d="M193 556.8s-.144-4.112 2.25-4.157c2.396.044 2.251 4.157 2.251 4.157zM180.51 611.34v-5.536c-.079-2.768-2.76-2.968-2.838-4.986 0 0-.346-3.027.452-4.408 1.084 4.047 3.056 3.304 3.056 1.64 0-1.453-1.117-2.804-3.408-6.33-.732-1.128-.28-4.66.747-5.923.398 3.07.853 4.54 2.171 4.54.805 0 1.462-.524 1.462-2.062 0-1.957-1.325-2.945-1.977-4.725-.76-2.078-.236-4.196 1.026-5.38.541 3.03.38 4.237 1.726 4.237 2.713-.858 0-4.786-.58-5.777-.666-1.155.897-3.46.897-3.46.863 2.69 1.14 2.91 2.07 2.66 1.174-.315 1.023-2.056-.41-3.463-.904-.889-.808-2.212.163-3.274.975 1.905 2.223 1.79 2.343.658l-.784-4.393h17.255l-.857 4.249c-.245 1.216 1.396 1.48 2.416-.514.972 1.062 1.067 2.385.163 3.274-1.432 1.407-1.584 3.148-.41 3.463.93.25 1.208.03 2.07-2.66 0 0 1.403 1.571.897 3.46-.58.99-3.293 4.919-.58 5.777 1.346 0 1.185-1.208 1.726-4.237 1.263 1.184 1.786 3.302 1.026 5.38-.651 1.78-1.976 2.768-1.976 4.725 0 1.538.657 2.062 1.461 2.062 1.319 0 1.774-1.47 2.172-4.54 1.026 1.263 1.479 4.795.747 5.922-2.291 3.527-3.408 4.878-3.408 6.33 0 1.665 1.971 2.408 3.056-1.64.797 1.382.451 4.41.451 4.41-.079 2.017-2.759 2.217-2.838 4.985v5.536zM187.73 565.32l-.397-3.125h15.838l-.397 3.125zM188.79 562.13l-.341-2.569h13.604l-.341 2.569zM190.95 559.47l-.227-2.569h9.058l-.227 2.569zM223.97 669.55c-2.027-.781-4.977-2.919-4.977-5.07v-24.297l2.638-3.358h-52.776l2.53 3.359v24.297c0 2.15-2.027 4.288-4.053 5.07z" />
                <path
                    stroke-linejoin="bevel"
                    d="M178.24 647.64h3.586v16.495h-3.586zM208.56 647.64h3.586v16.495h-3.586z"
                />
                <path d="M167.91 609.17v27.656h54.023v-5.957l.078-21.7c-2.21.869-2.597 2.872-2.597 2.872v11.777h-48.75V612.04s-.544-2.003-2.754-2.871z" />
                <path d="M214.6 669.55c-1.766-.781-5.612-2.919-5.612-5.07v-27.192c.378-1.518 2.436-2.414 3.761-3.358h-35.823c1.705.902 3.606 1.652 4.285 3.359v27.192c0 2.15-3.041 4.288-4.807 5.069z" />
                <path d="M219.41 634.22v-19.587h-4.91v-1.909h-38.84v1.909h-4.91v19.587zM206.86 669.55c-1.73-.781-4.249-2.919-4.249-5.07v-23.214l1.42-2.11h-17.69l1.42 2.11v23.215c0 2.15-2.519 4.288-4.249 5.07z" />
                <path
                    stroke-width=".981"
                    d="M190.71 639.16h8.966v30.377h-8.966z"
                />
                <path
                    stroke-linejoin="bevel"
                    d="M204.38 632.48c.021-2.001 5.838-2.091 8.832-3.795H177.26c2.994 1.704 8.675 1.844 8.675 3.795l1.217 3.882 14.883.598z"
                />
                <path d="M211.41 611.34c0-4.899.205-6.786 1.71-6.786v15.58c-3.69 1.34-6.325 6.05-6.325 6.05h-23.211s-2.634-4.71-6.325-6.05v-15.58c1.856 0 1.868 1.972 1.868 6.786zM213.12 609.33c0-5.618 4.851-6.25 4.851-6.25v5c-1.846-.08-2.737 1.578-2.737 4.044 0 2.466 1.487 2.506 1.487 2.506v14.19h-3.601z" />
                <path d="M177.28 609.33c0-5.618-4.851-6.25-4.851-6.25v5c1.846-.08 2.737 1.578 2.737 4.044 0 2.466-1.487 2.506-1.487 2.506v14.19h3.601z" />
                <g
                    fill="none"
                    stroke-width=".8"
                >
                    <path d="M186.84 570.62h17.128M184.81 575.97h20.921M182.78 582.46h24.904M180.66 590.39h29.536M179.98 599.43h30.426" />
                    <path
                        stroke-width=".96"
                        d="M170.75 628.92h48.661M186.19 628.93h18.036v6.629H186.19z"
                    />
                </g>
                <g>
                    <path d="M183.93 614.24c2.99 3.59 2.713 9.68 2.679 13.27h17.343c-.035-3.59-.312-9.68 2.678-13.27zM193.68 573.11l-2.376-1.224v-3.497c.971.292 2.062.411 2.168 1.96.338-2.21.948-2.103 1.907-2.964.958.861 1.568.753 1.906 2.963.107-1.548 1.197-1.667 2.169-1.96v3.498l-2.377 1.224z" />
                    <path d="M193.52 578.85l-3.946-2.713v-3.223c1.383.32 2.936.451 3.088 2.149.482-2.423 1.35-3.675 2.715-4.62 1.365.945 2.233 2.197 2.715 4.62.152-1.698 1.705-1.828 3.089-2.149v3.223l-3.947 2.713z" />
                    <path d="M193.24 587.8l-4.538-3.985v-4.736c1.591.471 3.377.663 3.552 3.157.553-3.56 1.552-5.4 3.121-6.787 1.57 1.388 2.568 3.227 3.122 6.787.175-2.494 1.96-2.686 3.551-3.157v4.736l-4.537 3.985zM201.61 593.09l-4.104 5.676h-4.646l-4.104-5.676zM186.39 602.62c2.053 1.122 2.81 3.357 3.03 7.553h11.55c.22-4.196.977-6.431 3.03-7.553z" />
                    <path
                        stroke-linejoin="bevel"
                        d="M204.17 593.09v-5.702c-1.95.537-3.213 1.691-3.776 3.258 0-1.893-2.49-6.257-5.207-8.43-2.724 2.432-5.257 6.38-5.206 8.43-.464-1.492-1.826-2.721-3.776-3.258v5.702z"
                    />
                    <path
                        stroke-linejoin="bevel"
                        d="M205.04 602.62v-5.702c-2.138.537-3.522 1.691-4.139 3.258 0-1.893-2.73-6.258-5.708-8.43-2.986 2.432-5.763 6.38-5.707 8.43-.508-1.492-2.002-2.721-4.139-3.258v5.702z"
                    />
                    <path
                        stroke-linejoin="bevel"
                        d="M207.44 614.32v-6.577c-2.66.687-4.312 2.653-5.15 3.8 0-3.561-4.02-8.99-7.1-10.768-3.16 1.825-7.101 7.435-7.101 10.768-.86-1.14-2.49-3.113-5.15-3.8v6.577z"
                    />
                    <path
                        stroke-linejoin="bevel"
                        d="M206 628.92v-6.762c-2.346.92-3.048 3.18-3.786 4.717.29-6.859-3.862-14.23-7.024-16.1-3.163 1.87-7.393 9.4-7.024 16.1-.758-1.527-1.44-3.797-3.786-4.717v6.762z"
                    />
                    <path d="M204.36 639.16v-6.762c-2.458.585-2.61 1.491-3.392 3.006.29-4.097-2.611-8.77-5.773-10.64-3.163 1.87-6.064 6.544-5.774 10.64-.782-1.515-.878-2.421-3.392-3.006v6.762z" />
                </g>
                <g id="a">
                    <path d="M98.935 664.23v-20.44c-.67-2.603-2.88-4.905-4.54-5.366V620.43l3.741 2.023 4.271 18.81v22.966z" />
                    <path d="M98.935 664.31v-20.519c-.67-2.603-2.88-4.905-4.54-5.366v-19.243c2.511 0 3.741 3.273 3.741 3.273l4.271 18.81v22.887z" />
                    <path d="M96.275 669.55c1.73-.781 4.249-2.919 4.249-5.07v-25.547l-1.185-2.108h43.522l-1.699 2.108v25.547c0 2.151 1.73 4.289 3.46 5.07z" />
                    <path d="M135.84 669.55c-1.73-.781-4.249-2.919-4.249-5.07v-24.297l3.62-3.359h-29.589l3.62 3.36v24.296c0 2.151-2.52 4.289-4.25 5.07z" />
                    <path d="M131.69 669.55c-1.73-.781-4.249-2.919-4.249-5.07v-21.965l2.37-3.359h-18.799l2.37 3.36v21.965c0 2.15-2.52 4.288-4.25 5.069z" />
                    <path d="M115.92 639.16h8.982v30.393h-8.982z" />
                    <path
                        stroke-linejoin="bevel"
                        d="M103.7 647.64h3.586v16.495H103.7zM134.45 647.64h3.586v16.495h-3.586zM100.64 619.82h4.32v16.909h-4.32zM100.64 616.64h4.295v3.139h-4.295z"
                    />
                    <path
                        stroke-width="1.034"
                        stroke-linejoin="bevel"
                        d="M136.17 623.46h6.16v13.054h-6.16z"
                    />
                    <path d="M104.89 636.6v-28.941c1.232 0 1.422 4.242 4.249 4.242 1.472 0 1.315-1.757.489-3.188-.732-1.268-1.637-3.018-.407-6.279.843 2.495 3.112 3.287 2.693 1.722-.72-2.688-2.795-3.13-1.239-7.302.54 3.48 2.773 3.327 2.231 1.304-.61-2.28-1.873-3.214-.316-6.428.873 3.65 2.052 3.435 2.052 1.171 0-3.347-.128-6.957 4.203-8.296 0 0 .25-3.068 1.812-3.068s1.813 3.068 1.813 3.068c4.33 1.34 4.202 4.95 4.202 8.296 0 2.264 1.18 2.478 2.052-1.17 1.558 3.213.295 4.147-.315 6.427-.543 2.023 1.69 2.177 2.23-1.304 1.557 4.172-.517 4.614-1.238 7.302-.42 1.565 1.85.773 2.693-1.722 1.23 3.26.325 5.011-.407 6.279-.826 1.43-.983 3.188.489 3.188 2.827 0 3.017-4.242 4.249-4.242V636.6zM98.124 614.74v22.079h2.511v-22.135c-.905-.457-1.755-.527-2.511.056z" />
                    <path d="M98.203 629.03c3.112 1.591 6.195 3.433 6.975 7.793h-6.975zM141.37 622.42v14.407h2.035v-14.443c-.635-.298-1.503-.344-2.035.036z" />
                    <path d="M143.41 628.92c-3.112 1.591-6.195 3.433-6.975 7.793h6.975z" />
                    <g>
                        <path d="M122.83 595.27l1.814-1.525v-1.953c-.664.065-1.026.293-1.516.936-.417-1.295-1.334-2.305-2.562-2.933-1.229.628-2.146 1.593-2.563 2.887-.49-.643-.852-.825-1.516-.89v1.953l1.815 1.525z" />
                        <path d="M123.8 600.16l.843-1.825v-2.539c-.664.066-1.026.297-1.516.949-.417-1.313-1.334-2.338-2.562-2.974-1.229.636-2.146 1.615-2.563 2.927-.49-.651-.852-.836-1.516-.902v2.539l.844 1.825z" />
                        <path d="M123.93 606.79l2.695-3.287v-3.243c-.986.111-1.524.503-2.252 1.606-.62-2.222-1.981-2.708-3.806-3.786-1.825 1.078-3.187 1.485-3.806 3.707-.729-1.103-1.267-1.416-2.252-1.527v3.243l2.694 3.287z" />
                        <path d="M124.72 613.3s3.14-2.74 3.327-4.244v-3.467c-1.217.143-2.368.459-3.267 1.883-.765-2.87-1.959-3.67-4.212-5.062-2.253 1.392-3.447 2.192-4.212 5.062-.899-1.425-2.05-1.74-3.267-1.883v3.467c.397 1.504 3.327 4.244 3.327 4.244z" />
                        <path d="M126.06 625.32s4.301-4.761 4.4-6.616v-5.406c-1.61.223-3.132 1.33-4.321 3.551-1.011-4.475-2.59-7.595-5.57-9.765-2.98 2.17-4.56 5.29-5.57 9.765-1.19-2.221-2.712-3.329-4.321-3.551v5.406c.308 1.855 4.4 6.616 4.4 6.616z" />
                        <path d="M126.06 632.43s3.676-3.742 4.4-5.366v-5.406c-1.61.223-3.132 1.33-4.321 3.551-1.011-4.475-2.59-7.037-5.57-9.207-2.98 2.17-4.56 4.732-5.57 9.207-1.19-2.221-2.712-3.329-4.321-3.551v5.406c.933 1.624 4.4 5.366 4.4 5.366z" />
                        <path d="M127.54 636.56c-1.073-4.668-1.996-8.13-7.163-11.641-5.167 3.51-6.09 6.973-7.163 11.641z" />
                        <path d="M130.2 639.16v-6.762c-2.346.92-4.467 2.28-5.249 3.795-.873-3.754-2.557-5.408-4.653-7.679-2.097 2.271-3.501 3.925-4.375 7.679-.782-1.515-2.902-2.875-5.249-3.795v6.762z" />
                    </g>
                </g>
                <use
                    transform="matrix(-1 0 0 1 390.74 0)"
                />
                <g>
                    <path d="M72.694 694.25h245.33v12.531H72.694z" />
                    <path d="M66.228 706.76h258.26v12.512H66.228zM85.576 675.51h219.56v8.11H85.576z" />
                    <path d="M79.157 683.56h232.4v10.622h-232.4zM89.412 669.28h211.89v6.174H89.412z" />
                    <path
                        d="M112.41 669.31h16.005v49.935H112.41z"
                        stroke-width="1.045"
                    />
                    <path
                        d="M115.95 669.31h8.917v49.936h-8.917z"
                        stroke-width="1.043"
                    />
                    <path
                        d="M187.08 669.31h16.006v49.935h-16.005z"
                        stroke-width="1.045"
                    />
                    <path
                        d="M190.73 669.31h8.917v49.936h-8.917z"
                        stroke-width="1.043"
                    />
                    <path
                        d="M262.19 669.31h16.005v49.935H262.19z"
                        stroke-width="1.045"
                    />
                    <path
                        d="M265.73 669.31h8.917v49.936h-8.917z"
                        stroke-width="1.043"
                    />
                </g>
                <g fill="none">
                    <path d="M98.935 664.23h193.07M115.79 712.97h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244M190.77 712.98h8.607m-8.607-6.253h8.607m-8.607-6.254h8.607m-8.607-6.253h8.607m-8.607-6.253h8.607m-8.607-6.253h8.607m-8.607-6.254h8.607M265.57 712.97h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244" />
                </g>
            </g>
        </svg>
    )

    const iconComponent = {
        vi: VietNam,
        zh: China,
        en: English,
        th: Thailand,
        id: Indonesia,
        kh: Cambodia,
    }
    return iconComponent[icon]
}
