import { CheckOutlined } from '@ant-design/icons';
import { Avatar, Button, Radio, Result, Typography } from 'antd';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput';
import { auth } from '@Modules/Auth/services';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

import logoVPBank from '../../../resources/img/deposit/logo_VPBank.svg';
import napasLogo from '../../../resources/img/deposit/napas.png';
import { CURRENCY_VND } from '../services/constants';
import { isEmpty } from 'lodash';

const { Title, Paragraph } = Typography;

const Deposit = () => {
    const [inputValue, setInputValue] = useState('');
    const [transfer, setTransfer] = useState(true);
    const user = auth.user();
    const [valueRadio, setValueRadio] = useState();
    const [visibleBtn, setVisibleBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const options =
        user?.currency?.code === 'VIE'
            ? [
                  { label: '100,000 VND', value: 100000 },
                  { label: '200,000 VND', value: 200000 },
                  { label: '500,000 VND', value: 500000 },
                  { label: '1,000,000 VND', value: 1000000 },
                  { label: t('label.other'), value: 5 },
              ]
            : [
                  { label: formatCurrency('100', user.currency && user.currency), value: 100 },
                  { label: formatCurrency('1000', user.currency && user.currency), value: 1000 },
                  { label: formatCurrency('3000', user.currency && user.currency), value: 3000 },
                  { label: formatCurrency('5000', user.currency && user.currency), value: 5000 },
                  { label: t('label.other'), value: 5 },
              ];

    const onChange = e => {
        if (e.target.value === 5) {
            if (!+inputValue || !inputValue) {
                setVisibleBtn(true);
            }
        } else {
            setVisibleBtn(false);
        }
        setValueRadio(e.target.value);
    };

    const handleChangeTransferLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setTransfer(!transfer);
            setLoading(false);
        }, 1500);
    };
    const handleChangeTransfer = () => {
        setTransfer(!transfer);
    };
    return (
        <div className="site-content">
            <div>
                <h3 className="text-fz-20">{t('label.deposit')}</h3>
            </div>
            {transfer ? (
                <>
                    <div className="bg-white rounded-12 p-4 mt-4">
                        <Title level={5} className="mb-4">
                            {t('step_deposit.step_1')}
                        </Title>
                        <p className="mt-3 mb-1">{t('step_deposit.deposit_guide')}</p>
                        <div className="mb-3">
                            <Radio.Group
                                onChange={onChange}
                                value={valueRadio}
                                className="radio-btn-deposit"
                                optionType="button"
                                options={options}
                            />
                            {valueRadio === 5 && (
                                <CurrencyInput
                                    value={inputValue}
                                    style={{ width: 170, padding: '4px 6px' }}
                                    currency={user?.currency?.code === 'VIE' ? CURRENCY_VND : user.currency}
                                    onChange={value => {
                                        setInputValue(value);
                                        if (!+value || !value) {
                                            setVisibleBtn(true);
                                        } else {
                                            setVisibleBtn(false);
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <Title level={5} className="mt-3 mb-4">
                            {t('step_deposit.step_2')}
                        </Title>
                        <Radio className="d-flex align-items-center deposit-radio-bank mb-4" checked>
                            <div className="d-flex align-items-center">
                                <Avatar shape="square" className="mr-2" size={36} src={napasLogo} />

                                <div>
                                    <p className="font-weight-bold">{t('step_deposit.transfer_money_account')}</p>
                                    <p>{t('step_deposit.waiting_minute')}</p>
                                </div>
                            </div>
                        </Radio>
                        <div className="deposit-box-bank m-4 p-3">
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <th>{t('label.bank')}:</th>
                                        <td>
                                            <img alt="VPBank" src={logoVPBank} width={60} height={40} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('deposit_info_bank.account')}:</th>
                                        <td>
                                            <Paragraph
                                                copyable={{
                                                    tooltips: ['Copy', 'Copied'],
                                                }}
                                            >
                                                {t('deposit_info_bank.info_account')}
                                            </Paragraph>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('order:label.bank_account')}:</th>
                                        <td>
                                            <Paragraph
                                                copyable={{
                                                    tooltips: ['Copy', 'Copied'],
                                                }}
                                            >
                                                {t('deposit_info_bank.account_number')}
                                            </Paragraph>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('deposit_info_bank.branch')}:</th>
                                        <td>
                                            <Paragraph
                                                copyable={{
                                                    tooltips: ['Copy', 'Copied'],
                                                }}
                                            >
                                                {t('deposit_info_bank.agency')}
                                            </Paragraph>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('label.amount_money')}:</th>
                                        <td>
                                            <Paragraph
                                                copyable={{
                                                    tooltips: ['Copy', 'Copied'],
                                                }}
                                            >
                                                {user && valueRadio === 5
                                                    ? formatCurrency(
                                                          inputValue ? inputValue : 0,
                                                          user?.currency?.code === 'VIE' ? CURRENCY_VND : user.currency
                                                      )
                                                    : !isEmpty(user.currency)
                                                    ? formatCurrency(valueRadio || 0, user.currency)
                                                    : valueRadio}
                                            </Paragraph>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('label.note')}:</th>
                                        <td>
                                            <Paragraph
                                                copyable={{
                                                    tooltips: ['Copy', 'Copied'],
                                                }}
                                            >
                                                {t('deposit_info_bank.note', { username: user?.merchant_code })}
                                            </Paragraph>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Button
                            type={'primary'}
                            icon={<CheckOutlined />}
                            onClick={handleChangeTransferLoading}
                            disabled={visibleBtn || !valueRadio}
                            loading={loading}
                        >
                            {t('btn.transferred')}
                        </Button>
                    </div>
                </>
            ) : (
                <div className="bg-white rounded-12 p-4 mt-4">
                    <Result
                        status="success"
                        title={t('message.waiting_system')}
                        subTitle={t('message.process_deposit')}
                        extra={[
                            <Button key="console">
                                <Link to={'/'} className="ml-2 btn-link">
                                    {t('label.home')}
                                </Link>
                            </Button>,
                            <Button key="buy" type="primary" onClick={handleChangeTransfer}>
                                {t('btn.continue_deposit')}
                            </Button>,
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

export default Deposit;
