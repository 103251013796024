import { Form, Button } from 'antd';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { dateFormatter } from '@Modules/App/services';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import { formatCurrency } from '@System/support/numberFormat';
import { auth } from '@Modules/Auth/services';

function ImportPackageInfo({ dataSource, services }) {
    const user = auth.user();
    const currency = getVar(user, 'currency', {});
    const created_at = getVar(dataSource, 'purchasing_package.created_at', '');
    const imported_at = getVar(dataSource, 'purchasing_package.imported_at', '');
    const receive_warehouse_address = getVar(dataSource, 'destination_warehouse.address', '');
    const weight = getVar(dataSource, 'purchasing_package.weight', '');
    return (
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item labelAlign={'left'} className="mb-1" label={t('label.created_day')}>
                <p>{created_at ? dateFormatter.full(created_at) : ''}</p>
            </Form.Item>
            <Form.Item labelAlign={'left'} className="mb-1" label={t('label.imported_at')}>
                <p>{imported_at ? dateFormatter.full(imported_at) : ''}</p>
            </Form.Item>

            <Form.Item labelAlign={'left'} className="mb-1" label={t('warehouse:label.receive_warehouse_address')}>
                <p>{receive_warehouse_address}</p>
            </Form.Item>

            <Form.Item labelAlign={'left'} className="mb-1" label={t('order:label.freight_bill_code')}>
                <p>{getVar(dataSource, 'purchasing_package.freight_bill_code', '')}</p>
            </Form.Item>

            <Form.Item labelAlign={'left'} className="mb-1" label={t('product:label.weight')}>
                <p>{`${weight ? `${weight * 1000} (g)` : t('label.no_text')}`}</p>
            </Form.Item>

            <Form.Item labelAlign={'left'} className="mb-1" label={t('label.import_fee')}>
                <p>
                    {services.length > 0 ? (
                        <>
                            { formatCurrency(sumBy(services, 'amount'), currency)}
                            <Button
                                type="link"
                                onClick={() => (window.location.href = '#import_package_detail_service_list')}
                            >{`(${t('label.view_detail')})`}</Button>
                        </>
                    ) : (
                        ''
                    )}
                </p>
            </Form.Item>
            <Form.Item labelAlign={'left'} className="mb-1" label={t('label.note')}>
                <p>
                    {getVar(dataSource, 'purchasing_package.note', '') ? getVar(dataSource, 'purchasing_package.note', '') : ''}
                </p>
            </Form.Item>
        </Form>
    );
}

ImportPackageInfo.propTypes = {};

ImportPackageInfo.defaultProps = {};

export default ImportPackageInfo;
