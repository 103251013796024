import { MinusOutlined } from '@ant-design/icons'
import { Avatar, Badge, Button, Table, Tooltip, InputNumber, Form, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import Paragraph from 'antd/lib/typography/Paragraph'
import { get, map } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { PRODUCT_STATUS } from '@Modules/Product/Interfaces/interfaces'
import { PRODUCT_STATUS_COLORS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'

import AddSkus from './AddSkus'

interface IChildSkuListProps {
    setSkus: React.Dispatch<React.SetStateAction<string[]>>
    skus: any[]
    form?: FormInstance
}

const ChildSkuList: React.FC<IChildSkuListProps> = ({ skus, setSkus, form }) => {
    const columns = [
        {
            className: '_product-list-product text-wrap',
            title: t('product:label.product'),
            dataIndex: ['sku', 'name'],
            key: 'product',
            width: '300px',
            render: (text: any, record: any) => {
                const images = get(record, 'sku.images.0', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={images}
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0 }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={`/products/detail/${record?.sku?.product_id}/${record?.sku?.id}`}>
                                    <strong
                                        style={{ fontWeight: 500 }}
                                        className="text-link"
                                    >
                                        {text}
                                    </strong>
                                </Link>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            ellipsis: {
                showTitle: false,
            },
            width: '180px',
            render: (sku: any) => (
                <Tooltip
                    placement="topLeft"
                    title={
                        <Paragraph
                            copyable={{
                                text: sku,
                            }}
                            className="ant-typography-tooltip"
                        >
                            {sku}
                        </Paragraph>
                    }
                >
                    {sku}
                </Tooltip>
            ),
        },
        {
            title: t('label.quantity'),
            dataIndex: 'quantity',
            className: '_product-info-quantity',
            key: 'quantity',
            width: '180px',
            render: (text: number, record: any, index: number) => {
                const id = get(record, 'sku.id')
                return (
                    <>
                        <Form.Item
                            name={['skus', index, 'quantity']}
                            initialValue={text ? text : 1}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('label.quantity') }),
                                },
                            ]}
                        >
                            <InputNumber
                                value={text}
                                className="text-right"
                                step={1}
                                min={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['skus', index, 'id']}
                            hidden
                            initialValue={id}
                        >
                            <Input />
                        </Form.Item>
                    </>
                )
            },
        },

        {
            className: '_product-list-warehouse',
            title: t('product:label.warehouse'),
            dataIndex: ['warehouse_stocks'],
            key: 'warehouse',
            width: '220px',
            render: (text: any) => {
                if (text?.length > 0) {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks"
                                key={index}
                            >
                                {get(item, 'name', '')}
                            </div>
                        )
                    })
                } else {
                    return <div className="warehouse_stocks">{t('label.none_stock')}</div>
                }
            },
        },
        {
            className: '_product-list-inventory',
            title: t('product:label.inventory'),
            dataIndex: ['warehouse_stocks'],
            key: 'inventory',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'real_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-waiting-out',
            title: t('product:label.waiting_out'),
            dataIndex: ['warehouse_stocks'],
            key: 'waiting_out',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'packing_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-waiting-in',
            title: t('product:label.waiting_in'),
            dataIndex: ['warehouse_stocks'],
            key: 'waiting_in',
            width: '120px',
            render: (text: any) => {
                return map(text, (item, index) => {
                    return (
                        <div
                            className="warehouse_stocks"
                            key={index}
                        >
                            {get(item, 'purchasing_quantity', t('label.not_available'))}
                        </div>
                    )
                })
            },
        },
        {
            className: '_product-list-status',
            title: t('label.status'),
            dataIndex: ['sku', 'status'],
            key: 'status',
            width: '120px',
            render: (text: PRODUCT_STATUS) =>
                text ? (
                    <Badge
                        color={PRODUCT_STATUS_COLORS[text]}
                        text={t(`product:status.${text}`)}
                    />
                ) : null,
        },
        {
            className: 'text-right _importing-document-product-action',
            width: '50px',
            dataIndex: ['sku', 'id'],
            render: (id: number, record: any, index: number) => {
                return (
                    <span
                        className="_span--delete cursor-pointer _importing-document-product-action-delete"
                        onClick={() => handleDeleteSku(id, ['skus', index, 'quantity'])}
                    >
                        <MinusOutlined />
                    </span>
                )
            },
        },
    ]

    const handleDeleteSku = (id: number, keyForm: any) => {
        setSkus(prev => {
            return [...prev.filter((item: any) => item?.sku?.id !== id)]
        })

        const skusForm = form?.getFieldValue("skus");
        form?.setFieldsValue({
            skus: skusForm.filter((item:any) => item.id !== id),
        })
    }

    return (
        <div className="bg-white rounded-12 p-4 mt-4 _import-package-product-info box-shadow">
            <div className="d-flex justify-content-between flex-wrap mb-3">
                <h3>{`${t('product:label.child_sku_list')} `}</h3>
                <AddSkus
                    skus={skus}
                    setSkus={setSkus}
                />
            </div>

            <Table
                className="_product-child-sku__list"
                rowKey={record => record?.sku?.id}
                dataSource={skus}
                columns={columns}
                scroll={{ x: 576 }}
                pagination={false}
                locale={{
                    emptyText: <p>{t('message.no_data_product')}</p>,
                }}
            />
        </div>
    )
}

export default ChildSkuList
