import { CloseCircleFilled } from '@ant-design/icons';
import { Modal, Spin, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useState } from 'react';

import { catchErrors } from '@Modules/App/services/helpers';
import api from '@Modules/Dropshipping/services/api';

import { t } from '@System/i18n';

export default function ValueItem({ value, removeValue }) {
    const { xs } = useBreakpoint();
    const [loading, setLoading] = useState(false);
    function deleteValue(event) {
        event.preventDefault();
        if (value.id) {
            checkDeleteValue();
        } else {
            removeValue();
        }
    }

    function checkDeleteValue() {
        setLoading(true);
        api.checkDeleteValue({ optionsValueIds: [value.id] })
            .then(() => removeValue())
            .catch(catchErrors(t('message.delete_failed', { attribute: t('product:label.properties_value') }), showErrors))
            .finally(() => setLoading(false));
    }
    function showErrors(errors) {
        if (errors.delete !== undefined && !errors.delete) {
            Modal.error({
                title: t('product:message.cant_delete'),
                content: t('product:message.delete_content'),
            });
        }
    }

    return (
        <Spin spinning={loading}>
             <Tag
            className={`_product-option-tag-item mr-2 ${xs && 'mb-2'}`}
            closable
            loading={loading}
            closeIcon={<CloseCircleFilled />}
            onClose={deleteValue}
        >
            {value.label}
        </Tag>
        </Spin>
       
    );
}
