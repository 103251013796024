import { Form, Input } from 'antd'
import { get, isEmpty } from 'lodash'
import React from 'react'

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput'
import { auth } from '@Modules/Auth/services'
import SelectCategories from '@Modules/Category/components/SelectCategories'

import { t } from '@System/i18n'

interface IComboInfoProps {
    detail?: any
    isEdit?:boolean
}

const ComboInfo: React.FC<IComboInfoProps> = ({ detail, isEdit }) => {
    const user = auth.user()
    const currency = get(user, 'currency', {})


    return (
        <>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 3 }}
                name="name"
                wrapperCol={{ span: 12 }}
                label={t('product:label.name')}
                rules={[{ required: true, message: t('product:message.required', { attribute: t('product:label.name') }) }]}
                initialValue={get(detail, 'name', '')}
            >
                <Input
                    disabled={isEdit}
                    className="_product-info-name"
                    placeholder={t('product:placeholder.name')}
                />
            </Form.Item>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 3 }}
                name="code"
                wrapperCol={{ span: 12 }}
                label={t('product:label.code')}
                initialValue={get(detail, 'code', '')}
            >
                <Input
                    disabled={isEdit}
                    className="_product-info-name"
                    placeholder={t('placeholder.blank_system_init')}
                />
            </Form.Item>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 3 }}
                name="category_id"
                wrapperCol={{ span: 12 }}
                label={t('product:label.category_id')}
                initialValue={get(detail, 'category_id', undefined)}
            >
                <SelectCategories
                    className="_product-info-category-id"
                    placeholder={t('product:placeholder.category_id')}
                    allowClear
                />
            </Form.Item>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 3 }}
                name="source"
                wrapperCol={{ span: 12 }}
                label={t('product:label.product_source')}
                initialValue={get(detail, 'source', undefined)}
            >
                <Input
                    className="_product-info-product_source"
                    placeholder={t('product:placeholder.product_source')}
                />
            </Form.Item>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 3 }}
                name="price"
                wrapperCol={{ span: 12 }}
                label={t('product:label.price')}
                initialValue={get(detail, 'price', undefined)}
            >
                <CurrencyInput
                    currency={currency}
                    name="price"
                    style={{ width: '100%' }}
                    className="_product-info-input-price"
                    placeholder={t('product:placeholder.price')}
                />
            </Form.Item>
        </>
    )
}

export default ComboInfo
