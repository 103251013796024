import _ from 'lodash';

import BaseServiceData from '@Modules/App/services/BaseServiceData';
import api from '@Modules/Warehouse/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

class ServiceWarehouses extends BaseServiceData {
    constructor() {
        super();
    }

    runApi() {
        return api
            .getListWarehouses({paginate: 0})
            .then(res => _.get(res, 'data.warehouses', []))
            .catch(err => {
                notification.error(t('common:message.fetch_fail', { attribute: t('label.warehouse').toLowerCase() }));
                return Promise.reject(err);
            });
    }
}

export default new ServiceWarehouses();
