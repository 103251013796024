import { Button, Checkbox, Modal, Select } from 'antd';
import { get } from 'lodash';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ChannelQuantitySetting, ISettings } from '@Modules/Setting/Interfaces';
import api from '@Modules/Setting/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

interface IModalSettingProps {
    handleHideModal: () => void;
    storeId: number;
    settings: ISettings;
    refetchList: () => void;
}

const { Option } = Select;

const ModalSetting: React.FC<IModalSettingProps> = ({ handleHideModal, storeId, settings, refetchList }) => {
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [syncType, setSyncType] = useState<ChannelQuantitySetting>('quantity');

    useEffect(() => {
        setSyncType(get(settings, 'quantity_type', 'quantity'));
        setCheckbox(Boolean(get(settings, 'sync_stock', false)));
    }, [settings]);

    const onChangeCheckbox = (e: any) => {
        setCheckbox(e.target.checked);
    };

    const onChangeSelect = (value: ChannelQuantitySetting) => {
        setSyncType(value);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const data: ISettings = {
            sync_stock: +checkbox,
            quantity_type: syncType,
        };

        try {
            await api.syncStockMerchant(storeId, data);
            handleHideModal();
            refetchList();
            notification.success(t('setting:sales_channel.message.sync_stock_success'));
        } catch (error) {
            notification.error(t('setting:sales_channel.message.sync_stock_failed'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={t('setting:sales_channel.btn.setting')}
            visible={true}
            onCancel={handleHideModal}
            className="custom-confirm-modal setting-chanel"
        >
            <div className="d-flex align-items-center">
                <Checkbox onChange={onChangeCheckbox} className="setting-chanel__checkbox" checked={checkbox} />
                <div>
                    <p className="cursor-pointer w-content" onClick={() => setCheckbox(prev => !prev)}>
                        {t('setting:sales_channel.message.auto_sync_stock_fbu')}
                    </p>
                    {t('setting:sales_channel.message.please_understand_on')}
                </div>
            </div>

            <div className="setting-chanel__select">
                <p>{t('setting:sales_channel.label.sync_type')}</p>
                <Select
                    value={syncType}
                    onChange={onChangeSelect}
                    placeholder={t('setting:sales_channel.placeholder.sync_type')}
                >
                    <Option value="quantity">{t('setting:sales_channel.label.temporarily_sync')}</Option>
                    <Option value="real_quantity">{t('setting:sales_channel.label.realistic_sync')}</Option>
                </Select>
            </div>

            <div className="confirm-btn-group">
                <Button className="mr-2" onClick={handleHideModal}>
                    {t('btn.back')}
                </Button>
                <Button type="primary" loading={loading} onClick={handleSubmit} disabled={isNil(syncType)}>
                    {t('btn.confirm')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalSetting;
