import { Col, Form, Row, Spin, Typography, Upload } from 'antd'
import { get, isEmpty } from 'lodash'
import React from 'react'

import { auth } from '@Modules/Auth/services'

import { t } from '@System/i18n'
import { formatCurrency } from '@System/support/numberFormat'

interface IInfoComboProps {
    loading: boolean
    detail: object
    images: any[]
}

const { Paragraph } = Typography

const InfoCombo: React.FC<IInfoComboProps> = ({ loading, detail, images }) => {
    const user = auth.user()
    const currency = get(user, 'currency', {})
    
    return (
        <Spin spinning={loading}>
            <div className="bg-white rounded-12 p-4 box-shadow mt-4">
                <h3 className="mb-3">{t('title.product_info')}</h3>
                <Form.Item
                    className="_product-detail-code"
                    labelAlign={'left'}
                    labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                    wrapperCol={{ span: 12 }}
                    label={t('product:label.code')}
                >
                    <Paragraph
                        copyable={{
                            text: get(detail, 'code', t('noText')),
                        }}
                    >
                        <span className="font-weight-500 text-dark">{get(detail, 'code', t('noText'))}</span>
                    </Paragraph>
                </Form.Item>

                <Form.Item
                    className="_product-detail-name"
                    labelAlign={'left'}
                    labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                    wrapperCol={{ span: 12 }}
                    label={t('product:label.name')}
                >
                    <span>{get(detail, 'name', t('noText'))}</span>
                </Form.Item>

                <Form.Item
                    className="_product-detail-category"
                    labelAlign={'left'}
                    labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                    wrapperCol={{ span: 12 }}
                    label={t('product:label.category_id')}
                >
                    <span>{get(detail, 'category.data.name', t('noText'))}</span>
                </Form.Item>
                <Form.Item
                    className="_product-detail-product_source-stock"
                    labelAlign={'left'}
                    labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                    wrapperCol={{ span: 12 }}
                    label={t('product:label.product_source')}
                >
                    <span>{get(detail, 'source', t('noText'))}</span>
                </Form.Item>
                <Form.Item
                    className="_product-detail-product_source-stock"
                    labelAlign={'left'}
                    labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                    wrapperCol={{ span: 12 }}
                    label={t('product:label.price')}
                >
                    <span>
                        {!isEmpty(currency)
                            ? formatCurrency(get(detail, 'price', t('noText')) || 0, currency)
                            : get(detail, 'price', t('noText'))}
                    </span>
                </Form.Item>

                <div className="d-flex align-items-center">
                    <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                    <span className="ml-2 number-circle _product-detail-picture-total">{images?.length}</span>
                    <i className="ml-2">{t('product:description.picture')}</i>
                </div>
                <div className="mt-4">
                    {isEmpty(images) ? (
                        <span>{t('product:label.no_images_yet')}</span>
                    ) : (
                        <Upload
                            className="_product-detail-list-product"
                            listType="picture-card"
                            fileList={images}
                            disabled={true}
                        />
                    )}
                </div>
            </div>
        </Spin>
    )
}

export default InfoCombo
