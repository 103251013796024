import createUseQuery from '@Modules/App/Hooks/createUseQuery';
import api from '@Modules/Setting/services/api';

import { CHANNEL } from '@Modules/Setting/services/constants';


import { t } from '@System/i18n';
import notification from '@System/notification';

const useChannelOAuthUrlQuery = (channel, params, settings) =>
    createUseQuery({
        name: ['marketplaces-oauth', channel, params],
        query: () => {
            if (
                channel === CHANNEL.SHOPEE ||
                channel === CHANNEL.LAZADA ||
                channel === CHANNEL.TIKI ||
                channel === CHANNEL.TIKTOKSHOP
            ) {
                return api.getOauthUrl(channel, params);
            }
            return api.createStores(params);
        },
        settings: {
            enabled: false,
            onError: () => {
                notification.error(t('setting:sales_channel.message.create_account_failed'));
            },
            ...settings,
        },
    });

export default useChannelOAuthUrlQuery;
