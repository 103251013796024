import { useQuery } from 'react-query';

import api from '@Modules/Setting/services/api';

import processResponseError from '@System/api/processResponseError';

const useWarehousePartnerListQuery = (storeId, enableDependOn) => {
    return useQuery(['warehouse_partner.get_list', storeId], () => api.getWarehousePartners(storeId?.storeID), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
        enabled: enableDependOn
    });
};

export default useWarehousePartnerListQuery;
