import auth from './auth.json'
import common from './common.json'
import dashboard from './dashboard.json'
import finance from './finance.json'
import order from './order.json'
import product from './product.json'
import register from './register.json'
import setting from './setting.json'
import shipping from './shipping.json'
import validation from './validation.json'
import warehouse from './warehouse.json'

export default {
    common,
    validation,
    auth,
    order,
    product,
    warehouse,
    shipping,
    finance,
    setting,
    dashboard,
    register,
}
