import lodash from "lodash";
import {t} from "@System/i18n";

const ERRORS = {
    NOT_EXIST: "not_exist",
    ALREADY_EXIST: "already_exist",
    REQUIRED: "required",
    GTE: "gte",
    NUMERIC: "numeric",
    INVALID: "invalid",
    IN: "in",
    NOT_INVALID: 'not_valid',
    DUPLICATED: 'duplicated'
};

export function renderError(error) {
    const line = lodash.get(error, "line", "");
    const content = lodash.get(error, "errors", {});
    let messages = [];
    Object.entries(content).forEach(entry => {
        const [key, errors] = entry;

        if (errors.hasOwnProperty(ERRORS.NOT_EXIST)) {
            let messageCode = ERRORS.NOT_EXIST;

            if (key === "warehouse_area_code")
                messageCode = "warehouse_area_code_not_exist";

            messages.push(t(`product:message.upload_file.${messageCode}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.ALREADY_EXIST)) {
            messages.push(t(`product:message.upload_file.${ERRORS.ALREADY_EXIST}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.REQUIRED)) {
            messages.push(t(`product:message.upload_file.${ERRORS.REQUIRED}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.GTE)) {
            messages.push(t(`product:message.upload_file.${ERRORS.GTE}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.NUMERIC)) {
            messages.push(t(`product:message.upload_file.${ERRORS.NUMERIC}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.IN)) {
            messages.push(t(`product:message.upload_file.${ERRORS.IN}`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.INVALID)) {
            messages.push(t(`product:message.upload_file.invalid_or_inactive`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.NOT_INVALID)) {
            messages.push(t(`product:message.upload_file.invalid`, {
                attribute: t(`product:label.${key}`)
            }));
        }

        if (errors.hasOwnProperty(ERRORS.DUPLICATED)) {
            messages.push(t(`product:message.upload_file.${ERRORS.DUPLICATED}`, {
                attribute: t(`product:label.${key}`)
            }));
        }
    });

    if (messages.length > 0)
        return t("product:message.upload_file.line", {line: line, content: messages.join(" ")});

    return t("product:message.upload_file.unknown_error");
}
