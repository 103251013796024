import IconEmpty from '@/resources/img/nodata.svg';
import { Image } from 'antd';
import React from 'react';

import { t, trans } from '@System/i18n';

function EmptyTableData({ loading, message, action }) {
    return (
        <>
            {!loading && <Image src={IconEmpty} preview={false}/>}
            <p>{t(`message.${loading ? 'loading' : message}`)}</p>
            {!loading && <p>{trans('message.no_data_action', { action })}</p>}
        </>
    );
}

export default EmptyTableData;
