import { Button, Col, Input, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';

import { t } from '@System/i18n';

import ValueItem from './ValueItem';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export default function OptionItem({ option, index, updateOptions, removeOption,form }) {
    const {lg} = useBreakpoint()
    let { values } = option;
    const inputRef = useRef(null);
    let [newValue, setNewValue] = useState('');

    const _onChange = e => {
        setNewValue(e.target.value);
    };

    const addValue = () => {
        if (newValue.trim() !== '') {
            updateOption({ values: [...values, { id: null, label: newValue }] });
            setNewValue('');
        }
    };

    const removeValue = index => {
        const newValues = values.filter((_, nth) => nth !== index);

        updateOption({ values: newValues });
    };
    const updateOption = data => updateOptions(index, { ...option, ...data });

    const deleteOption = () => {
        removeOption(index);
        form.setFields([{ name: 'options', errors: [] }]);
    };

    return (
        <Row className="my-3" gutter={15}>
            <Col lg={{ span: 4 }} span={24}>
                <Input
                    className="_product-option-item-name"
                    defaultValue={option.label}
                    onPressEnter={e => updateOption({ label: e.target.value })}
                    onBlur={e => updateOption({ label: e.target.value })}
                    placeholder={t('product:placeholder.option')}
                    ref={inputRef}
                />
            </Col>
            <Col lg={{ span: 20 }} span={24} className={`d-flex justify-content-between ${lg ? 'mt-0' : 'mt-2'}`}>
                <div className="option-values">
                    {!isEmpty(values) &&
                        values.map((value, index) => (
                            <ValueItem key={index} value={value} removeValue={() => removeValue(index)} />
                        ))}
                    <Input
                        className="_product-option-value"
                        value={newValue}
                        placeholder={t('product:placeholder.option_value')}
                        style={{ width: '220px' }}
                        onChange={_onChange}
                        onPressEnter={addValue}
                        onBlur={addValue}
                    />
                </div>
                <Button className="_product-option-item-btn-delete" onClick={deleteOption}>
                    {t('btn.delete')}
                </Button>
            </Col>
        </Row>
    );
}
