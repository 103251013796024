import { DeleteFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import ModalConfirm from '@Modules/App/Modal/ModalConfirm';
import api from '@Modules/Setting/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

interface IDeleteConnectionProps {
    className: string;
    storeId: number;
    onSuccessDelete: () => void;
}

const DeleteConnection: React.FC<IDeleteConnectionProps> = ({ className, storeId, onSuccessDelete }) => {
    const [loading, setLoading] = useState(false);
    const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);

    const confirmDeleteConnection = () => {
        setVisibleConfirm(true);
    };

    const handleDeleteConnect = async () => {
        setLoading(true);

        try {
            await api.deleteStore(storeId);
            setVisibleConfirm(false)
            onSuccessDelete();
        } catch (error) {
            notification.error(t('setting:sales_channel.message.delete.failed'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                className={className ? className : ''}
                danger
                disabled={loading}
                loading={loading}
                onClick={confirmDeleteConnection}
                icon={<DeleteFilled />}
            >
                {t('setting:sales_channel.btn.delete_connection')}
            </Button>
            <ModalConfirm
                onVisibleChange={setVisibleConfirm}
                visible={visibleConfirm}
                content={<p className="font-weight-500 text-fz-16">{t('setting:sales_channel.message.confirm_delete_connect')}</p>}
                okButtonProps={{ onClick: handleDeleteConnect, loading }}
            />
        </>
    );
};

export default DeleteConnection;
