import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal } from 'antd';
import i18next from 'i18next';
import get from 'lodash/get';
import React, { useState } from 'react';

import UploadFile from '@Modules/App/Upload/UploadFileV2';


import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { findIndex, isEmpty, isEqual, uniqWith } from 'lodash';
import api from '@Modules/Order/services/api';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ORDER_EVENT } from '@Modules/Order/services/constants';
import { renderError } from './errorsBillOfLading';

export default function ImportBillOfLading({ loading }) {
    const { xs } = useBreakpoint()

    const [visible, setVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    const [override, setOverride] = useState(false);
    const [file, setFile] = useState({})
    const [fileList, setFileList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false)

    function showModal() {
        setVisible(true);
    }

    function handleCancel() {
        setMessages([]);
        setOverride(false)
        setFileList([])
        setFile([])
        setVisible(false);
    }

    function beforeUpload(file) {
        setMessages([]);
        setFile(file)
    }

    const handleUpload = () => {
        setLoadingButton(true)
        const filename = get(file, 'name', '');
        let formData = new FormData();

        formData.append('file', file);
        formData.append('replace', override ? 1 : 0)

        api.uploadFreightBill(formData).then((res) => {
            const messages = uniqWith(get(res, 'data.errors', []), isEqual);
            const hasInvalid = findIndex(messages, ['errors', 'invalid']);

            if (hasInvalid === -1) {
                setMessages(messages);
                if (messages.length === 0) {
                    notification.success(
                        t('order:message.upload_file.success', {
                            filename,
                        })
                    );
                    events.dispatch(ORDER_EVENT.RELOAD_ORDER_LIST, {});
                    handleCancel();
                }
            } else {
                notification.error(
                    t('order:message.upload_file.fail', {
                        filename,
                    })
                );
            }
        }).catch(err => {
            notification.error(t('common:message.server_error'));
        }).finally(() => { setLoadingButton(false) });
    }

    return (
        <>
            <Button onClick={showModal} icon={<PlusCircleOutlined />} className={xs ? 'mb-2' : 'mr-2'} loading={loading}>
                {t('btn.upload_freight_bill_code')}
            </Button>
            {
                visible && <Modal
                    title={t('order:title.upload_freight_code_excel')}
                    visible={true}
                    onCancel={handleCancel}
                    footer={false}
                    maskClosable={false}
                    width={570}
                >
                    <div className="d-flex justify-content-between mb-2">
                        <div>
                            <p>{t('product:description.download_sample_file')}:</p>
                            <i>{t('product:description.updated_at', { date: '21/06/2022' })}</i>
                        </div>
                        <Button
                            type="primary"
                            href={process.env.PUBLIC_URL + `/sample_file/${i18next.language}/sample_upload_freight_bill_code.xlsx`}
                            icon={<DownloadOutlined />}
                        >
                            {t('btn.download_sample_file')}
                        </Button>
                    </div>
                    <div>
                        <p className="mb-2">{t('product:description.upload_sample_file')}: </p>

                        <UploadFile
                            onChange={beforeUpload}
                            accept=".xls,.xlsx"
                            fileType={[
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                            ]}
                            name="file import ma van don"
                            maxFileSize={1}
                            showUploadList={true}
                            setFileList={setFileList}
                            fileList={fileList}
                        />
                        {messages.length > 0 && (
                            <div className="mt-3">
                                {messages.map((item, index) => {
                                    return (
                                        <p key={index} className="text-danger">
                                            - {renderError(item)}
                                        </p>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="mt-3 mb-3">
                        <Checkbox checked={override} onChange={e => { setOverride(e.target.checked) }}>
                            {t('order:label.override_lading_code_exists')}
                        </Checkbox>
                    </div>
                    <div className="text-right">
                        <Button type="primary" onClick={handleUpload} disabled={isEmpty(file)} loading={loadingButton}>{t('order:btn.upload')}</Button>
                    </div>
                </Modal>
            }
        </>
    );
}
