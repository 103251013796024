import { Select } from 'antd'
import { get, map } from 'lodash'
import {t} from "@System/i18n";
import React, { useEffect, useState } from 'react'

import useStoresQuery from '@Modules/Setting/Hooks/useStoresQuery'

const { Option } = Select

export default function SelectShop(props) {
    let { onChange, marketplaceCode, allowClear, nameValue } = props
    let [stores, setStores] = useState([])
    const { error, isError, isLoading, data } = useStoresQuery()

    useEffect(() => {
        const stores = map(get(data, 'data.stores', []), 'store')
        if (marketplaceCode) {
            setStores(stores.filter(item => item.marketplace_code === marketplaceCode))
        } else {
            setStores(stores)
        }
    }, [data])

    function _onChange(value, option) {
        onChange(value, option)
    }

    return (
        <Select
            {...props}
            loading={isLoading}
            onChange={_onChange}
            value={stores.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            dropdownMatchSelectWidth={400}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {stores.map(item => {
                const storeId = get(item, 'id')
                const storeName = get(item, 'shop_name')
                const marketplaceStoreId = get(item, 'marketplace_store_id')
                const marketplaceCode = get(item, 'marketplace_code')
                return (
                    <Option
                        key={storeId}
                        value={nameValue ? storeName : storeId}
                    >
                        {`${storeName} (${t(`setting:sales_channel.channel_list.${marketplaceCode}`)})`}
                    </Option>
                )
            })}
        </Select>
    )
}
