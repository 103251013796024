import { useQuery } from 'react-query';

import api from '@Modules/Product/services/api';

import processResponseError from '@System/api/processResponseError';

const useProductDetailQuery = id =>
    useQuery(['product-detail', id], () => api.getProductDetail(id), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useProductDetailQuery;
