import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip, Badge, Checkbox } from 'antd';
import { get, isEmpty, map, uniqWith } from 'lodash';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';

import { auth } from '@Modules/Auth/services';
import ServiceServices from '@Modules/Services/services/ServiceServices';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function OptionServices({ quantity, setServiceIds, selectedValues = [] }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const user = auth.user();
    const currency = getVar(user, 'currency', {});

    const rowSelection = {
        selectedRowKeys,

        // onChange: (selectedRowKeys, selectedRows, info) => {
        //     const dataNew = filter(data, item => selectedValues.includes(item.service.id))
        //     const uniqIds = uniq([...selectedValues, ...selectedRowKeys])
        //     setSelectedRows(uniqWith([...selectedRows,...dataNew]));
        //     setServiceIds(uniqIds);
        //     setSelectedRowKeys(uniqIds);
        // },

        onSelectAll: (checked, selectedRows) => {
            const selectedRowKeys = map(selectedRows, 'service.id')
            if (checked) {
                const dataNew = filter(data, item => selectedValues.includes(item.service.id))
                const uniqIds = uniq([...selectedValues, ...selectedRowKeys])
                setSelectedRows(uniqWith([...selectedRows, ...dataNew]));
                setServiceIds(uniqIds);
                setSelectedRowKeys(uniqIds);
            }else{
                const dataNew = filter(data, item => item.service.is_required)
                const dataId = map(dataNew,'service.id')
                setSelectedRows(uniqWith([...selectedRows, ...dataNew]));
                setServiceIds(dataId);
                setSelectedRowKeys(dataId);
            }

        },
        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required');
            const id = get(record, 'service.id');

            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record, id)} />
                    )}
                </>
            );
        },
    };

    const handleChangeCheckbox = (checked, record, id) => {
        if (checked) {
            const otherRowKey = selectedRowKeys.filter(val => val !== id);

            setSelectedRows(selectedRows.filter(val => val.service.id !== id));
            setServiceIds(otherRowKey);
            setSelectedRowKeys(otherRowKey);
        } else {
            const rowKeyCheck = [...selectedRowKeys, id];
            setSelectedRows([...selectedRows, record]);
            setServiceIds(rowKeyCheck);
            setSelectedRowKeys(rowKeyCheck);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        ServiceServices.list({ type: SERVICES_TYPE.IMPORT })
            .then(res => {
                let refactorData = res.filter(item => !isEmpty(item?.servicePrices));
                setData(refactorData);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
            const selectedKeyArray = uniq([...selectedRowKeys, ...selectedValues]);
            setSelectedRowKeys(selectedKeyArray);
            setServiceIds(selectedKeyArray);
            setSelectedRows(filter(data, item => selectedKeyArray.includes(item.service.id)|| item.service.is_required));
    }, [selectedValues, data]);

    const columns = [
        {
            className: '_services-name',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'service',
        },
        {
            className: 'text-nowrap _temporary-price',
            title: (
                <div>
                    {`${t('product:label.temporary_price')} `}
                    <Tooltip title={t('product:placeholder.temporary_price')}>
                        <Badge className="ml-2" count={<QuestionCircleOutlined />} />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'temporary_price',
            key: 'temporary_price',
            render: (text, record) => {
                const defaultPrice = filter(getVar(record, 'servicePrices'), { is_default: true });
                const price = getVar(defaultPrice, '0.price');
                return <p>{`${price ? formatCurrency(price, currency) : 0} / ${t('label.has_product', { count: 1 })}`}</p>;
            },
        },
        {
            className: 'text-center _quantity',
            title: t('label.quantity'),
            render: () => {
                return quantity;
            },
        },
        {
            className: 'text-right _estimated-costs',
            title: (
                <div>
                    {`${t('product:label.estimated_costs')} `}
                    <Tooltip title={t('product:placeholder.estimated_costs')}>
                        <Badge className="ml-2" count={<QuestionCircleOutlined />} />
                    </Tooltip>
                </div>
            ),

            render: (text, record) => {
                const defaultPrice = filter(getVar(record, 'servicePrices'), { is_default: true });
                const price = getVar(defaultPrice, '0.price');
                return <p>{price ? formatCurrency(price * quantity, currency) : t('label.free')}</p>;
            },
        },
    ];

    return (
        <>
            {/* .filter(item => selectedValues.includes(item.service.id) || item.service.status === 'ACTIVE') */}
            <Table
                rowKey={record => record?.service?.id}
                rowSelection={rowSelection}
                loading={isLoading}
                dataSource={data.filter(item => item.service.status === 'ACTIVE')}
                columns={columns}
                pagination={false}
                scroll={{ x: 576 }}
                footer={() => {
                    const newList = selectedRows.map(item => {
                        return { service: item.service, servicePrices: item.servicePrices.filter(val => val.is_default) };
                    });

                    const sumPrice = sumBy(newList, item => {
                        return Number(getVar(item, 'servicePrices.0.price', 0));
                    });

                    return (
                        <div className="d-flex justify-content-between _service-options-total">
                            <strong>{t('label.estimated_sum')}</strong>
                            <strong>
                                {!isEmpty(currency) ? formatCurrency(sumPrice * quantity, currency) : sumPrice * quantity}
                            </strong>
                        </div>
                    );
                }}
                locale={{
                    emptyText: (
                        <p className="_service-option-no-data">{t(`message.${isLoading ? 'loading' : 'no_data_service'}`)}</p>
                    ),
                }}
            />
        </>
    );
}
export default OptionServices;