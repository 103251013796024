import { Checkbox, Tooltip } from 'antd';
import { filter, get, map } from 'lodash';
import React, { useEffect, useState } from 'react';

import { t } from '@System/i18n';

const ServiceList = ({ type, serviceSelected, setServiceSelected, services }) => {
    const [listService, setListService] = useState([]);

    useEffect(() => {
        const refactorService = () => {
            const newService = services.filter(item => item.service.type === type);
            setListService(newService);
        };
        refactorService();
    }, [services, type]);



    const onChangeCheckbox = checkedValues => {
        const serviceIds = map(listService, 'service.id');
        const otherServiceId = filter(serviceSelected, item => !serviceIds.includes(item));
        setServiceSelected([...otherServiceId, ...checkedValues]);
    };

    return (
        <>
            <h3>{t(`SERVICE_TYPE.${type}`)}</h3>
            <Checkbox.Group className="quotation-modal__checkbox" value={serviceSelected} onChange={onChangeCheckbox}>
                {listService.map(item => {
                    const disabledRow = get(item, 'service.is_required');
                    return disabledRow ? (
                        <Tooltip title={t('message.service_required')} key={item.service.id}>
                            <Checkbox disabled={disabledRow} value={item.service.id}>
                                <span className="checkbox-quotation__name"> {item.service.name}</span>
                            </Checkbox>
                        </Tooltip>
                    ) : (
                        <Checkbox value={item.service.id} key={item.service.id} className="checkbox-quotation">
                            <span className="checkbox-quotation__name"> {item.service.name}</span>
                        </Checkbox>
                    );
                })}
            </Checkbox.Group>
        </>
    );
};

export default ServiceList;
