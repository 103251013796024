import createUseQuery from '@Modules/App/Hooks/createUseQuery';
import api from '../services/api';


const useStoresQuery = (params, settings) =>
    createUseQuery({
        name: ['stores', params],
        query: () => api.getStores(params),
        settings: {
            ...settings,
        },
    });

export default useStoresQuery;
