import { Pagination, Select } from 'antd';
import _ from 'lodash';
import React from 'react';

import useDeepCompareEffect from '@Modules/App/Hooks/useDeepCompareEffect';

import { t } from '@System/i18n';

const { Option } = Select;
const sizeSettings = [1, 5, 10, 20, 50, 100, 250];

function PaginationWithParam({ pagination, filters, addFilter }) {
    useDeepCompareEffect(() => {
        if (pagination && filters && filters.page && filters.page > pagination.page_total && filters.page > 1) {
            addFilter({ ...filters, current_page: 1, page: 1 });
        }
    }, [pagination, filters]);

    function handleChange(page, pageSize) {
        addFilter({ ...filters, current_page: page, page, per_page: pageSize });
    }

    function handleSelect(size) {
        addFilter({ ...filters, current_page: 1, page: 1, per_page: size });
    }

    return !_.isEmpty(pagination) && pagination.page_total > 0 ? (
        <div className="customized-pagination p-3 d-flex justify-content-end align-items-center">
            <span className="ml-3 mr-2">{t('label.display')}</span>
            <Select onSelect={handleSelect} defaultValue={pagination.per_page} className="mr-3" value={pagination.per_page}>
                {sizeSettings.map(size => {
                    return (
                        <Option key={size} value={size}>
                            {size}
                        </Option>
                    );
                })}
            </Select>

            <Pagination
                current={pagination.current_page}
                defaultCurrent={1}
                total={pagination.total}
                pageSize={pagination.per_page}
                onChange={handleChange}
                showSizeChanger={false}
            />
        </div>
    ) : null;
}

export default PaginationWithParam;
