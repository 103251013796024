import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Modal, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { get, isEmpty, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import useDebounce from '@Modules/App/Hooks/useDebounce';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import { IOrderProduct } from '@Modules/Order/Interfaces';
import api from '@Modules/Product/services/api';
import { PRODUCT_STATUS } from '@Modules/Product/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';

interface IRecommendProductProps {
    products: IOrderProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IOrderProduct[]>>;
    form: FormInstance;
}

const RecommendProduct: React.FC<IRecommendProductProps> = ({ products, setProducts, form }) => {
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [options, setOptions] = useState([]);

    const keywordAfter = useDebounce(keyword, { wait: 500 });
    const isInspected = form.getFieldValue('inspected');

    useEffect(() => {
        const getOptionsData = () => {
            const params = {
                keyword: keywordAfter,
                paginate: 0,
                status: PRODUCT_STATUS.ON_SELL,
                sortBy: 'code',
                sort: 'asc',
            };

            setLoading(true);
            api.getProducts(params)
                .then(res => {
                    setOptions(get(res, 'data.products', []));
                })
                .catch(error => {
                    notification.error(t('message.server_connection_error'));
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        if (!isEmpty(keywordAfter)) {
            getOptionsData();
        }
    }, [keywordAfter]);

    const renderOptions = (option: any) => {
        let { sku } = option;
        return {
            label: `${sku.code} - ${sku.name}`,
            value: sku?.id.toString(),
            id: sku.id,
            code: sku.code,
            name: sku.name,
            image: sku?.images?.length > 0 ? sku.images[0] : '',
            className: '_product-dropdown-option',
        };
    };

    const onSelect = (option: any) => {
        addProduct(option);
        form.setFieldsValue({ product_quantity: products?.length });
        setKeyword('');
        setOptions([]);
    };

    const onChange = (value: string) => {
        setKeyword(value);
    };

    const addProduct = (option: any) => {
        const { id } = option;
        let currentOrder = products.find(item => item.id === id);

        if (currentOrder) {
            const newData = updateCollectionItem(
                products,
                id,
                { ...currentOrder, quantity: currentOrder?.quantity + 1 },
                'id'
            );
            const total = sumBy(newData, item => parseInt(item.quantity) * parseInt(item.price)) || 0;
            const discountAmount = form.getFieldValue('discount_amount');

            form.setFields([{ name: 'total_amount', value: total > discountAmount ? total - discountAmount : '0' }]);
            
            setProducts(newData);
        } else {
            setProducts([...products, { ...option, quantity: 1, price: 0 }]);
        }
    };

    return (
        <Tooltip
            title={isInspected && t('order:message.order_process_contact_warehouse')}
            children={
                <AutoComplete
                    options={options.map(renderOptions)}
                    onSelect={(value: any, option: any) => onSelect(option)}
                    className="auto_recommend_options"
                    onChange={onChange}
                    style={{ width: '100%' }}
                    dropdownClassName="_dropdown-list-search"
                    value={keyword}
                    notFoundContent={<p className="recommend_no_data"> {t('label.no_data')}</p>}
                    disabled={isInspected}
                    children={
                        <Input
                            className="_add-product-input-search"
                            size="large"
                            placeholder={t('product:placeholder.search_product_by_name_code')}
                            prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
                            disabled={loading}
                            allowClear={true}
                        />
                    }
                />
            }
        />
    );
};

export default RecommendProduct;
