import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useListOrderQuery from '@Modules/Order/Hooks/useListOrderQuery';
import OrderCreateMenu from '@Modules/Order/components/OrderCreateMenu';
import ImportBillOfLading from '@Modules/Order/components/UploadFile/ImportBillOfLading';
import { ORDER_EVENT } from '@Modules/Order/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';

import useFilter from '../../../App/Hooks/useFilter';
import { setNavigator, setDocumentTitle } from '../../../App/services';
import FormSearch from './FormSearch';
import List from './List';

export default function ListOrder({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {});
    const [pagination, setPagination] = useState({});
    const [orders, setOrders] = useState([]);
    const [sumSku, setSumSku] = useState(0);

    const { data, isFetching, refetch, isError, error } = useListOrderQuery(filters);

    useEffect(() => {
        setNavigator(t('title.list-orders'), [
            {
                name: t('breadcrumb.list-orders'),
            },
        ]);
        setDocumentTitle(t('title_page.order_list'));
        return events.listen(ORDER_EVENT.RELOAD_ORDER_LIST, () => refetch());
    }, []);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useEffect(() => {
        setOrders(get(data, 'data.orders', []));
        setPagination(get(data, 'data.pagination', {}));
        setSumSku(get(data, 'data.sku_sum', 0));
    }, [data]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.list-orders')}</h3>
                <div>
                    <ImportBillOfLading /> <OrderCreateMenu />
                </div>
            </div>

            <FormSearch onSearch={addFilter} filters={filters} loading={isFetching} />
            <List
                orders={orders}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={isFetching}
                sumSku={sumSku}
            />
        </div>
    );
}
