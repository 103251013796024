import { Select } from 'antd';
import React from 'react';

import { IMPORT_PACKAGE_STATUS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';

const { Option } = Select;

export default function SelectStatuses(props) {
    return (
        <Select
            {...props}
            value={props.value || undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {Object.keys(IMPORT_PACKAGE_STATUS).map(opt => {
                return (
                    <Option key={IMPORT_PACKAGE_STATUS[opt]} value={IMPORT_PACKAGE_STATUS[opt]}>
                        {t(`product:IMPORT_PACKAGE_STATUS.${IMPORT_PACKAGE_STATUS[opt]}`)}
                    </Option>
                );
            })}
        </Select>
    );
}
