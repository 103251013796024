import Axios from 'axios';

import config from '../../../config/app';

var instance = Axios.create({
    baseURL: config.apiUrl,
});

export default {
    getQuotation: data => instance.post('/public/services/estimate-fee', data),
    getListService: (params) => instance.get('public/services', {params, loading: false }),
};
