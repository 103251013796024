import { Button, Col, Input, Modal, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { catchErrors } from '@Modules/App/services/helpers';
import api from '@Modules/Dropshipping/services/api';

import { t } from '@System/i18n';

import ValueItem from '../Create/ValueItem';

export default function OptionItem({ option, index, updateOptions, removeOption,form }) {
    const { lg } = useBreakpoint();
    let { values } = option;
    const inputRef = useRef(null);
    let [newValue, setNewValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const _onChange = e => {
        setNewValue(e.target.value);
    };

    const addValue = () => {
        if (newValue.trim() !== '') {
            updateOption({ values: [...values, { id: null, label: newValue }] });
            setNewValue('');
        }
    };
    useEffect(() => {
        setInputValue(option.label);
    }, [option.label]);

    const removeValue = index => {
        const newValues = values.filter((_, nth) => nth !== index);
       
        updateOption({ values: newValues });
    };
    const updateOption = data => updateOptions(index, { ...option, ...data });

    const checkDeleteOption = () => {
        let valueIds = values.filter(value => value.id);
        api.checkDeleteValue({ optionsValueIds: valueIds.map(value => value.id) })
            .then(() => removeOption(index))
            .catch(catchErrors(t('message.delete_failed', { attribute: t('product:label.properties_value') }), showErrors));
    };

    const showErrors = errors => {
        if (errors.delete !== undefined && !errors.delete) {
            Modal.error({
                title: t('product:message.cant_delete'),
                content: t('product:message.delete_content'),
            });
        }
    };

    const deleteOption = () => {
        if (option.id) {
           
            checkDeleteOption();
        } else {
            removeOption(index);
        }
        form.setFields([{ name: 'options', errors: [] }]);
    };

    return (
        <Row className="my-3" gutter={15}>
            <Col lg={{ span: 4 }} span={24}>
                <Input
                    className="_product-option-item-name"
                    value={inputValue}
                    onPressEnter={e => updateOption({ label: e.target.value })}
                    onBlur={e => updateOption({ label: e.target.value })}
                    placeholder={t('product:placeholder.option')}
                    ref={inputRef}
                    onChange={e => setInputValue(e.target.value)}
                />
            </Col>
            <Col lg={{ span: 20 }} span={24} className={`d-flex justify-content-between ${lg ? 'mt-0' : 'mt-2'}`}>
                <div className="option-values">
                    {!isEmpty(values) &&
                        values.map((value, index) => (
                            <ValueItem key={index} value={value} removeValue={() => removeValue(index)} />
                        ))}
                    <Input
                        className="_product-option-value mr-2"
                        value={newValue}
                        placeholder={t('product:placeholder.option_value')}
                        style={{ width: '220px' }}
                        onChange={_onChange}
                        onPressEnter={addValue}
                        onBlur={addValue}
                    />
                </div>
                <Button className="_product-option-item-btn-delete" onClick={deleteOption}>
                    {t('btn.delete')}
                </Button>
            </Col>
        </Row>
    );
}
