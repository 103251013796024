import { api } from '@System/api';

export default {
    getShippingPartners: params =>
        api.get('/merchant/shipping-partners', {
            params: params,
        }),
    getShippingPartnerStampUrl: params =>
        api.get(`/shipping-partners/${params.id}/stamps`, {
            params,
        }),
};
