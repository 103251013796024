import _ from 'lodash';

import BaseServiceData from '@Modules/App/services/BaseServiceData';
import { auth } from '@Modules/Auth/services';
import api from '@Modules/Services/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

class ServiceServices extends BaseServiceData {
    constructor() {
        super();
    }
    list({ type }) {
        if (this.data) return Promise.resolve(this.data);

        if (this.promise) return this.promise;

        return this.fetchData({ type });
    }

    fetchData({ type }) {
        return (this.promise = this.runApi({ type }).then(data => (this.data = data)));
    }

    runApi({ type }) {
        const user = auth.user();
        return api
            .getListService({ type, country_id: user.location_id })
            .then(res => _.get(res, 'data.services', []))
            .catch(err => {
                notification.error(t('common:message.fetch_fail', { attribute: t('label.service').toLowerCase() }));
                return Promise.reject(err);
            });
    }
}

export default new ServiceServices();
