import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Modal } from 'antd';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import AddProduct from '@Modules/ImportPackage/components/Update/AddProduct';
import AddProductByFile from '@Modules/ImportPackage/components/Update/AddProductByFile';
import ImportPackageInfo from '@Modules/ImportPackage/components/Update/ImportPackageInfo';
import OptionServices from '@Modules/ImportPackage/components/Update/OptionServices';
import ProductList from '@Modules/ImportPackage/components/Update/ProductList';
import api from '@Modules/ImportPackage/services/api';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';
import useDetailImportPackageQuery from '@Modules/ImportPackage/Hooks/useDetailImportPackageQuery';
import { get, isNil } from 'lodash';


export default function CreatePackageDuplicate(props) {
    const { match } = props;
    const importPackageId = match.params.id;
    const [form] = Form.useForm();

    const [products, setProducts] = useState([]);
    const [serviceIds, setServiceIds] = useState([]);
    const [serviceIdsOfProduct, setServiceIdsOfProduct] = useState([]);
    const { data } = useDetailImportPackageQuery(importPackageId);
    const [loading, setLoading] = useState(false);
    const [packageData, setPackageData] = useState([])

    useEffect(() => {
        setNavigator(t('title.create_import_package_manual'), [
            {
                name: t('title.list_import_package'),
                route: 'import-package.list',
            },
            {
                name: t('title.create_import_package_manual'),
            },
        ]);
        setDocumentTitle(t('title.create_import_package_manual'));
    }, []);

    useEffect(() => {
        const getDataPackage = () => {
            setPackageData(get(data, 'data'))
        }
        getDataPackage()
    }, [data])

    useEffect(() => {
        if (products?.length === 0) {
            form.setFields([{ name: 'products', errors: [t('product:message.product_required')] }]);
        } else {
            form.setFields([{ name: 'products', errors: [''] }]);
        }
    }, [form, products]);


    useEffect(() => {
        form.setFields([
            { name: 'destination_warehouse_id', value: get(packageData, 'destination_warehouse.id') },
            { name: 'shipping_partner_id', value: get(packageData, 'purchasing_package.shipping_partner_id') },
            { name: 'freight_bill_code', value: isNil(get(packageData, 'purchasing_package.freight_bill_code', undefined)) ? undefined : get(packageData, 'purchasing_package.freight_bill_code', undefined) },
            { name: 'note', value: isNil(get(packageData, 'purchasing_package.note', undefined)) ? undefined : get(packageData, 'purchasing_package.note', undefined) },
            { name: 'weight', value: get(packageData, 'purchasing_package.weight') ? get(packageData, 'purchasing_package.weight') * 1000 : undefined }
        ])

        const refactorData = () => {
            const listServiceIds = map(get(packageData, 'services'), 'service.id')
            setServiceIdsOfProduct(listServiceIds)
            const listSkuIds = map(get(packageData, 'purchasing_package_items'), (item) => {
                return {
                    label: item.name,
                    image: get(item, 'images.0', ''),
                    quantity: item.quantity,
                    sku_code: get(item, 'code'),
                    value: get(item, 'code'),
                    sku_id: get(item, 'sku_id')
                }
            })
            setProducts(listSkuIds)
        }
        refactorData()

    }, [form, packageData])


    function addProduct(data) {
        const exists = find(products, ['sku_id', data.sku_id]);
        const listServiceInActive = filter(
            data.services,
            item => item.type === SERVICES_TYPE.IMPORT && item.status === 'INACTIVE'
        );
        if (isEmpty(exists)) {
            setProducts([...products, data]);
        } else {
            notification.error(t('product:message.added', { attribute: t('product:label.product') }));
        }
        if (!isEmpty(listServiceInActive)) {
            showListServiceInActive(listServiceInActive);
        }
    }

    function createImportPackage() {
        form.validateFields().then(values => {
            if (!isEmpty(products)) {
                let weight = undefined;
                if (getVar(values, 'weight', undefined)) {
                    weight = values.weight / 1000;
                }
                setLoading(true);

                api.createImportPackage({ ...values, weight, service_ids: serviceIds.length > 0 ? serviceIds : undefined })
                    .then(res => {
                        setProducts([]);
                        setServiceIds([]);
                        form.resetFields();
                        notification.success(t('message.create_success', { attribute: t('product:label.import_package') }));
                        const purchasingPackage = getVar(res, 'data.purchasingPackage', {});
                        url.redirectTo('import-package.list');
                    })
                    .catch(err => {
                        const status = getVar(err, 'response.status', 0);
                        if (status === 400) {
                            renderError(getVar(err, 'response.data.data', {}));
                        } else {
                            notification.error(t('message.create_failed', { attribute: t('product:label.import_package') }));
                        }
                    })
                    .finally(() => setLoading(false));
            }
        });
    }
    const showListServiceInActive = record => {
        Modal.confirm({
            title: '',
            icon: false,
            content: (
                <>
                    {record.map((item, index, arr) => {
                        return <b key={index}>{`${item.name}${index + 1 === arr.length ? '' : ','} `}</b>;
                    })}
                    {t('message.service_in_active_notice')}
                </>
            ),
            okText: t('btn.close'),
            cancelButtonProps: { style: { display: 'none' } },
        });
    };
    function renderError(errors) {
        const fields_enable = ['freight_bill_code', 'destination_warehouse_id', 'shipping_partner_id', 'weight'];
        const error_messages = [];
        Object.keys(errors).forEach(item => {
            let key_errors = [];
            Object.keys(errors[item]).forEach(error =>
                key_errors.push(t(`errorMessages.${error}`, { attribute: t(`product:label.${item}`) }))
            );
            if (fields_enable.includes(item)) {
                error_messages.push({ name: item, errors: key_errors });
            } else {
                notification.error(key_errors.join(', '));
            }
        });
        form.setFields(error_messages);
    }


    return (
        <div className="site-content">
            <Form form={form}>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link className="_product-detail-back  mb-3" to={url.to('import-package.list')}>
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18">{t('breadcrumb.create_import_package_manual')}</h3>
                    </div>

                    <Form.Item shouldUpdate className="submit mb-0">
                        {() => (
                            <Button
                                className="_import-package-btn-create"
                                loading={loading}
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={createImportPackage}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    products.length === 0
                                }
                            >
                                {t('btn.save_import_package')}
                            </Button>
                        )}
                    </Form.Item>
                </div>
                <div className="bg-light-gray">
                    <Row className="bg-white rounded-12 p-4 _import-package-info box-shadow">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <h4 className="mb-3">{t('title.import_package_info')}</h4>
                            <ImportPackageInfo form={form} />
                        </Col>
                    </Row>

                    <div className="bg-white rounded-12 p-4 mt-4 _import-package-product-info box-shadow">
                        <div className="d-flex justify-content-between flex-wrap mb-3">
                            <h4>
                                {`${t('heading.product_list')} `}
                                <span className="number-circle _importing-document-product-list-total">
                                    {products ? products?.length : 0}
                                </span>
                                {products?.length === 0 && (
                                    <i className="text-danger ml-2">{t('product:message.product_required')}</i>
                                )}
                            </h4>
                            <div className="mt-md-0 mt-3">
                                <AddProductByFile existsData={products} setProducts={setProducts} />
                                <AddProduct existsData={products} addProduct={addProduct} className="ml-2" />
                            </div>
                        </div>
                        <ProductList form={form} dataSource={products} setProducts={setProducts} />
                    </div>

                    <div className="bg-white rounded-12 p-4 mt-4 _option-service-import box-shadow">
                        <div className="d-flex">
                            <h4 className="">{`${t('title.option_service_import')}`}</h4>
                            <i className="ml-2">{t('placeholder.option_service_import')}</i>
                        </div>
                        <OptionServices
                            selectedValues={serviceIdsOfProduct}
                            form={form}
                            setServiceIds={setServiceIds}
                            quantity={sumBy(products, 'quantity')}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Form.Item shouldUpdate className="submit mb-0">
                        {() => (
                            <Button
                                className="_import-package-btn-create"
                                loading={loading}
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={createImportPackage}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    products.length === 0
                                }
                            >
                                {t('btn.save_import_package')}
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}
