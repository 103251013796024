import { Table } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import useFilter from '@Modules/App/Hooks/useFilter';
import { dateFormatter } from '@Modules/App/services';
import useProductHistoryQuery from '@Modules/Product/Hooks/useProductHistoryQuery';
import ExportProductHistory from '@Modules/Product/components/ExportFile/ExportProductHistory';
import { IGNORE_ACTIONS, PRODUCT_HISTORY_CHANGE_COLOR } from '@Modules/Product/services/constants';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const initFilter = {
    without_actions: IGNORE_ACTIONS,
};

const ProductHistory = ({ productId, history, location }) => {
    const { filters, addFilter } = useFilter(history, location, initFilter);

    const [historyProduct, setHistoryProduct] = useState([]);
    const [pagination, setPagination] = useState([]);

    const { isFetching: loading, data } = useProductHistoryQuery(productId, filters);

    useEffect(() => {
        setHistoryProduct(get(data, 'data.stock_logs', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    const getColumns = [
        {
            className: '_finances-amount',
            title: t('label.time'),
            dataIndex: ['stock_log', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_finances-collect-cod',
            title: t('label.history_action'),
            dataIndex: ['stock_log', 'action'],
            key: 'action',
            render: (text, record) => (text ? t(`product:ACTION_HISTORY.${text}`) : ''),
        },
        {
            className: '_finances-packing-costs',
            title: t('label.history_change'),
            dataIndex: ['stock_log', 'change'],
            key: 'change',
            render: (text, record) => {
                let real_quantity = get(record, 'stock_log.real_quantity');
                return text ? (
                    <p style={{ color: PRODUCT_HISTORY_CHANGE_COLOR[text] }}>{`${
                        text === 'INCREASE' ? '+' : text === 'DECREASE' ? '-' : ''
                    }${real_quantity}`}</p>
                ) : (
                    ''
                );
            },
        },
        {
            className: '_finances-remaining-amount',
            title: t('label.history_reason'),
            dataIndex: ['stock_log', 'action'],
            key: 'action',
            render: (text, record) => {
                let object_type = get(record, 'stock_log.object_type');
                let description = get(record, 'stock_log.payload.document');
                let order = get(record, 'stock_log.payload.order');
                let purchasing_package = get(record, 'stock_log.payload.purchasing_package');
                let explain = get(record, 'stock_log.payload.explain');
                const type = get(record, 'stock_log.payload.document.type');

                return text && object_type ? (
                    explain && object_type === 'DOCUMENT_SKU_INVENTORY' ? (
                        `${t('product:label.merchandise_inventory')}: ${explain}`
                    ) : type === 'IMPORTING_RETURN_GOODS' ? (
                        <p>{`${t(`product:ACTION_HISTORY.${text}`)} ${t(`product:TYPE_HISTORY.${type}`).toLowerCase()}`}</p>
                    ) : (
                        <p>
                            {`${t(`product:ACTION_HISTORY.${text}`)} ${
                                text === 'EXPORT_FOR_ORDER' && object_type === 'ORDER'
                                    ? t('common:label.order_detail').toLowerCase()
                                    : t(`product:TYPE_HISTORY.${object_type}`).toLowerCase()
                            }`}
                            <span style={{ color: '#fcb823' }}>
                                &nbsp;
                                {order ? (
                                    <Link target="_blank" to={url.to('orders.detail', { id: order.id })}>
                                        <strong style={{ color: '#fcb823', fontWeight: '400' }}>#{order.code}</strong>
                                    </Link>
                                ) : purchasing_package ? (
                                    <Link target="_blank" to={url.to('import-package.detail', { id: purchasing_package.id })}>
                                        <strong style={{ color: '#fcb823', fontWeight: '400' }}>
                                            #{purchasing_package.code}
                                        </strong>
                                    </Link>
                                ) : description && object_type !== 'SKU' && object_type !== 'DOCUMENT_SKU_INVENTORY' ? (
                                    '#' + description.code
                                ) : (
                                    ''
                                )}
                            </span>
                        </p>
                    )
                ) : type === 'IMPORTING_RETURN_GOODS' ? (
                    <p>{`${t(`product:ACTION_HISTORY.${text}`)} ${t(`product:TYPE_HISTORY.${type}`).toLowerCase()}`}</p>
                ) : (
                    ''
                );
            },
        },
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-1">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {t('title.stock_history')}
                        <span className="number-circle _importing-document-product-list-total pl-2 pr-2 ml-2">
                            {getVar(pagination, 'total', 0)}
                        </span>
                    </h3>
                    <ExportProductHistory params={filters} productId={productId} />
                </div>
                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3"
                        dataSource={historyProduct}
                        rowKey={record => record?.stock_log?.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <p>{t(`label.${loading ? 'loading' : 'no_data'}`)}</p>,
                        }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
};

export default ProductHistory;
