import { Avatar, Table, Row, Col } from 'antd'
import { get, map } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import useSize from '@Modules/App/Hooks/useSize'
import { ORDER_STATUS } from '@Modules/Order/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { classes, getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

function ProductList({ orderInfo }) {
    const { isMobile } = useSize()
    const { order_skus, currency, order, returned_skus } = orderInfo
    const status = getVar(order, 'status', '')

    const [data, setData] = useState([])

    useEffect(() => {
        const updateData = () => {
            const newData = map(order_skus, sku => {
                map(returned_skus, returnSku => {
                    if (get(sku, 'sku.id') === get(returnSku, 'id')) {
                        sku.sku['return_quantity'] = get(returnSku, 'quantity')
                    }
                })

                return {
                    ...sku,
                }
            })

            const dataCombo = map(get(orderInfo, 'order_sku_combo', []), item => {
                
                return {
                    sku: {
                        ...get(item, 'order_sku_combo', {}),
                        ...get(item, 'order_sku_combo.skuCombo', {}),
                        images: get(item, 'order_sku_combo.skuCombo.image'),
                    },
                    orderSku: {
                        quantity: get(item, 'order_sku_combo.quantity'),
                        price: get(item, 'order_sku_combo.price'),
                        order_amount: get(item, 'order_sku_combo.total_amount', {}),
                    },
                    skuCombo: map(get(item, 'order_skus', []), sku => {
                        return {
                            orderSku: {
                                ...sku,
                            },
                            sku: {
                                ...get(sku, 'sku', {}),
                            },
                        }
                    }),
                }
            })

         
            setData([...dataCombo, ...newData])
        }

        updateData()
    }, [orderInfo, order_skus, returned_skus])

    const columns = [
        {
            className: '_product-info-sku-id d-flex align-items-center',
            title: t('product:label.product'),
            dataIndex: ['sku', 'code'],
            width: 430,
            render: (text, record) => {
                const images = getVar(record, 'sku.images', [])
                const name = getVar(record, 'sku.name', [])
                const productId = getVar(record, 'sku.product_id', undefined)
                const skuId = getVar(record, 'sku.id', undefined)
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={images.length > 0 ? images[0] : ''}
                            className="mr-2"
                            size={36}
                            style={{ minWidth: '36px' }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={url.to('products.detail', { productId, skuId })}>
                                    <strong style={{ fontWeight: 500 }}>{text}</strong>
                                </Link>
                                <p style={{ color: '#999999', whiteSpace: 'pre-line' }}>{name}</p>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: 'text-right text-nowrap _product-info-quantity',
            title: t('label.quantity'),
            dataIndex: ['orderSku', 'quantity'],
            width: 180,
        },
        {
            className: 'text-right text-nowrap _product-info-quantity',
            title: t('order:label.amount_refund'),
            dataIndex: ['sku', 'return_quantity'],
            hidden: status !== ORDER_STATUS.RETURN_COMPLETED,
        },

        {
            className: 'text-right text-nowrap _product-info-price',
            title: t('order:label.price'),
            dataIndex: ['orderSku', 'price'],
            width: 180,
            render: text => <p>{!isEmpty(currency) ? formatCurrency(text || 0, currency) : text}</p>,
        },
        {
            className: 'text-right text-nowrap _product-info-sum-price',
            title: t('order:label.sum_price'),
            dataIndex: ['orderSku', 'order_amount'],
            width: 130,
            render: text => <p>{!isEmpty(currency) ? formatCurrency(text || 0, currency) : text}</p>,
        },
    ]

    const renderColumns = columns.filter(item => !item.hidden)

    
    const expandedRowRender = (record, data, parentComboCode) => {
        return map(data, item => {
            const image = getVar(item, 'sku.image', '')
            const name = getVar(item, 'sku.name', [])
            const code = getVar(item, 'sku.code', [])
            const skuId = getVar(item, 'sku.id', [])
            const productId = getVar(item, 'sku.product_id', [])

            const quantity = getVar(item, 'orderSku.quantity', 0)

            // const price = getVar(record, 'orderSku.price', 0)

            return (
                <div
                    className={`d-flex tw-ml-16 ${parentComboCode}`}
                    style={{ borderBottom: '1px solid #e5e7f1' }}
                    key={code}
                >
                    <td style={{ padding: '15px 10px', width: window.innerWidth > 1700 ? 430 : 400 }}>
                        <div className="d-flex align-items-center">
                            <Avatar
                                shape="square"
                                src={image}
                                className="mr-2"
                                size={36}
                                style={{ minWidth: '36px' }}
                            />
                            <div className="d-flex flex-wrap">
                                <span>
                                    <Link
                                        to={url.to('products.detail', {
                                            productId: productId,
                                            skuId: skuId,
                                        })}
                                    >
                                        <strong style={{ fontWeight: 500 }} className={`${code} _child-code`}>{code}</strong>
                                    </Link>
                                    <p style={{ color: '#999999', whiteSpace: 'pre-line' }} className={`${code} _child-name`}>{name}</p>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td
                        className="ant-table-cell d-flex align-items-center tw-w-60"
                        style={{ padding: '15px 10px' }}
                    >
                        <span className={classes(`tw-px-2 tw-w-full tw-text-end ${code} _child-quantity`,{
                            "tw-mr-14":window.innerWidth < 1700,
                            "tw-mr-4":window.innerWidth > 1700
                        })} >{quantity}</span>
                    </td>
                    {/* <td
                        className="ant-table-cell d-flex align-items-center tw-w-52"
                        style={{ padding: '15px 10px' }}
                    >
                        <span className={classes("tw-px-2 tw-w-full tw-text-end",{
                            "tw-mr-20":window.innerWidth < 1700,
                            "tw-mr-6":window.innerWidth > 1700
                        })}>
                            {isEmpty(currency) ? price : formatCurrency(price || 0, currency)}
                        </span>
                    </td> */}
                </div>
            )
        })
    }
        

    return (
        <Table
            rowKey={record => record?.sku?.id}
            dataSource={data}
            columns={renderColumns}
            scroll={{ x: 576 }}
            pagination={false}
            expandable={{
                expandedRowRender: record => {
                    return expandedRowRender(record, record?.skuCombo, get(record, 'sku.code', ''))
                },

                rowExpandable: record => {
                    const isCombo = !isEmpty(getVar(record, 'skuCombo', []))
                    return isCombo
                },
            }}
            footer={() => (
                
                
                <>
                    <Row
                        gutter={20}
                        className="text-right _product-info-total-sum-price d-flex justify-content-between"
                    >
                        <Col
                            xs={{ span: 12 }}
                            xl={{ span: 18 }}
                        >
                            <p>{t('order:label.total_sum_price')}:</p>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 6 }}
                        >
                            <p>
                                {!isEmpty(currency)
                                    ? formatCurrency(getVar(order, 'order_amount', 0), currency)
                                    : getVar(order, 'order_amount', 0)}
                            </p>
                        </Col>
                    </Row>
                    <Row className="text-right _product-info-discount d-flex justify-content-between">
                        <Col
                            xs={{ span: 12 }}
                            xl={{ span: 18 }}
                        >
                            <p>{t('order:label.discount')}:</p>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 6 }}
                        >
                            <p>
                                {!isEmpty(currency)
                                    ? formatCurrency(getVar(order, 'discount_amount', 0), currency)
                                    : getVar(order, 'discount_amount', 0)}
                            </p>
                        </Col>
                    </Row>
                    <Row className="text-right _product-info-total-payment d-flex justify-content-between">
                        <Col
                            xs={{ span: 12 }}
                            xl={{ span: 18 }}
                        >
                            <strong style={{ fontWeight: 500 }}>{t('order:label.total_payment')}:</strong>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 6 }}
                        >
                            <strong style={{ fontWeight: 500 }}>
                                {!isEmpty(currency)
                                    ? formatCurrency(getVar(order, 'total_amount', 0), currency)
                                    : getVar(order, 'total_amount', 0)}
                            </strong>
                        </Col>
                    </Row>

                    <Row className="text-right _product-info-code-payment d-flex justify-content-between">
                        <Col
                            xs={{ span: 12 }}
                            xl={{ span: 18 }}
                        >
                            <strong style={{ fontWeight: 500 }}>{t('order:label.cod')}:</strong>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 6 }}
                        >
                            <strong style={{ fontWeight: 500 }}>
                                {!isEmpty(currency) ? formatCurrency(getVar(order, 'cod', 0), currency) : getVar(order, 'cod', 0)}
                            </strong>
                        </Col>
                    </Row>
                </>
            )}
            locale={{
                emptyText: <p className="_no-data">{t('message.no_data_product')}</p>,
            }}
        />
    )
}
export default ProductList
