import { api } from '@System/api';

export default {
    getLocations: params =>
        api.get('/merchant/locations', {
            params: params,
        }),
    getMarkets: () => api.get('/merchant/locations/active', { singleRequest: true, loading: false }),
    getCountrySellerInfo: () => api.get(`/merchant/country`, { singleRequest: true, loading: false }),
};
