import { Form, Input } from 'antd';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SelectShippingPartner from '@Modules/ShippingPartner/components/SelectShippingPartner';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses';

import { t } from '@System/i18n';
const { TextArea } = Input;

function ImportPackageInfo({ form }) {
    const [warehousesInfo, setWarehousesInfo] = useState([]);
    const [destinationWarehouseId, setDestinationWarehouseId] = useState();

    useEffect(() => {
        ServiceWarehouses.list().then(res => {
            setWarehousesInfo(res);
        });
    }, []);

    function onChange(name, value) {
        form.setFields([{ name, value: value ? value : undefined, errors: '' }]);
        if (name === 'destination_warehouse_id') {
            setDestinationWarehouseId(value);
        }
    }
    const receive_warehouse = find(warehousesInfo, ['warehouse.id', destinationWarehouseId]);
    return (
        <>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t('warehouse:label.receive_warehouse')}
                name="destination_warehouse_id"
                rules={[{ required: true, message: t('product:message.required', {attribute: t('warehouse:label.receive_warehouse')}) }]}
            >
                <SelectWarehouse
                    className="_import-package-info-destination-warehouse-id"
                    allowClear={true}
                    placeholder={t('warehouse:placeholder.receive_warehouse')}
                    onChange={value => onChange('destination_warehouse_id', value)}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t('warehouse:label.receive_warehouse_address')}
            >
                <p>{!isEmpty(receive_warehouse) ? receive_warehouse?.warehouse?.address : ''}</p>
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="shipping_partner_id"
                label={t('shipping:label.shipping_partner')}
            >
                <SelectShippingPartner
                    className="_import-package-info-shipping-partner-id"
                    allowClear={true}
                    onChange={value => onChange('shipping_partner_id', value)}
                    placeholder={t('shipping:placeholder.shipping_partner')}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="freight_bill_code"
                label={t('order:label.freight_bill_code')}
            >
                <Input
                    className="_import-package-info-freight-bill-code"
                    onChange={e => onChange('freight_bill_code', e.target.value)}
                    placeholder={t('order:placeholder.freight_bill_code')}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="note"
                label={t('common:label.note')}
            >
                <TextArea
                    onChange={e => onChange('note', e.target.value)}
                    placeholder={t('order:placeholder.note')}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="weight"
                label={t('product:label.weight')}
                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
            >
                <Input onChange={e => onChange('weight', e.target.value)} placeholder={t('product:placeholder.weight')} />
            </Form.Item>
        </>
    );
}

ImportPackageInfo.propTypes = {
    formRules: PropTypes.object,
};

ImportPackageInfo.defaultProps = {
    formRules: {},
};

export default ImportPackageInfo;
