import { Button, Modal, Spin } from 'antd';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';

import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';

import api from '../services/api';
import ServiceList from './ServiceList';

const listType = [SERVICES_TYPE.IMPORT, SERVICES_TYPE.EXPORT, SERVICES_TYPE.STORAGE, SERVICES_TYPE.IMPORTING_RETURN_GOODS];

const ModalService = ({ setVisible, handleRequestQuotation }) => {
    const [serviceSelected, setServiceSelected] = useState([]);
    const [listService, setListService] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getService = () => {
            setLoading(true);

            api.getListService({status: 'ACTIVE'})
                .then(res => {
                    setListService(get(res, 'data.services'));
                })
                .catch(error => processResponseError(error))
                .finally(() => setLoading(false));
        };
        getService();
    }, []);

    useEffect(() => {
        setServiceSelected(
            map(
                listService.filter(item => item.service.is_required),
                'service.id'
            )
        );
    }, [listService]);

    return (
        <Modal
            title={<h3>{t('label.service_select')}</h3>}
            visible={true}
            onCancel={() => setVisible(false)}
            className={'quotation-modal'}
            maskClosable={false}
            width={390}
            footer={[
                <Button type="primary" key="submit" className="btn" onClick={() => handleRequestQuotation(serviceSelected)}>
                    {t('btn.quotation_request')}
                </Button>,
            ]}
        >
            <Spin spinning={loading}>
                <div className="quotation-modal__body">
                    {listType.map((type, index) => (
                        <ServiceList
                            type={type}
                            key={index}
                            setServiceSelected={setServiceSelected}
                            serviceSelected={serviceSelected}
                            services={listService}
                        />
                    ))}
                </div>
            </Spin>
        </Modal>
    );
};

export default ModalService;
