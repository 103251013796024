import { useQuery } from 'react-query';



import processResponseError from '@System/api/processResponseError';
import api from '../services/api';

const useDropshipDetailQuery = id =>
    useQuery(['product-detail', id], () => api.getProductDropshippingDetail(id), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useDropshipDetailQuery;
