import SelectService from '@Modules/Services/components/SelectServices';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';
import { t } from '@System/i18n';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';



function TransportServiceInfo({ onChange }) {
  const [form] = Form.useForm();

  function onSubmit(name, value) {
    const errors = form.getFieldError(name);
    if (errors.length === 0) {
      if (value) {
        onChange(name, Number(value) / 1000);
      }
    }
  }

  return (
    <div className="mt-4">
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item labelAlign={'left'}  label={t('product:label.service')}>
          <SelectService
            className={'_transporting-service-id'}
            onChange={value => onChange('services[]', value)}
            type={SERVICES_TYPE.TRANSPORT}
            placeholder={t('product:placeholder.transport_service')}
            allowClear
          />
        </Form.Item>

        <Form.Item
          labelAlign={'left'}
          label={`${t('product:label.weight')}`}
          name="weight"
          rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
        >
          <Input
            className={'_product-weight'}
            onBlur={e => onSubmit('weight', e.target.value)}
            placeholder={t('product:placeholder.weight')}
          />
        </Form.Item>

        <Form.Item labelAlign={'left'} label={`${t('product:label.dimension')} (mm)`}>
          <Row>
            <Col span={8}>
              <Form.Item name="length" rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}>
                <Input
                  className={'_product-length'}
                  onBlur={e => onSubmit('length', e.target.value)}
                  placeholder={t('product:placeholder.length')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="pl-2"
                name="width"
                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
              >
                <Input
                  className={'_product-width'}
                  onBlur={e => onSubmit('width', e.target.value)}
                  placeholder={t('product:placeholder.width')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="pl-2"
                name="height"
                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
              >
                <Input
                  className={'_product-height'}
                  onBlur={e => onSubmit('height', e.target.value)}
                  placeholder={t('product:placeholder.height')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
export default TransportServiceInfo;
