import { DownloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Modal } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import api from '@Modules/Product/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { convertBlobDataToExcel } from '@System/support/helpers'

const dateFormat = 'DD/MM/YYYY'
const { RangePicker } = DatePicker

function ExportListReport({ filters }) {
    const [loading, setLoading] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [rangeDate, setRangeDate] = useState({
        'session[from]': undefined,
        'session[to]': undefined,
    })

    const session_from = get(rangeDate, 'session[from]', '')
    const session_to = get(rangeDate, 'session[to]', '')

    const showModal = () => {
        setIsVisible(true)
    }

    const handleCancel = () => {
        setIsVisible(false)
    }

    function onChangeDate(dates) {
        setRangeDate({
            'session[from]': dates ? dates[0] : '',
            'session[to]': dates ? dates[1] : '',
        })
    }

    function handleDownload() {
        const data = {
            'session[from]': moment(session_from).format('YYYY-MM-DD 00:00:00'),
            'session[to]': moment(session_to).format('YYYY-MM-DD 23:59:59'),
        }
        setLoading(true)

        api.exportFileReport(data)
            .then(res => {
                convertBlobDataToExcel(res.data, `Báo cáo Xuất - Nhập - Tồn kho ${session_from} ${session_to}`)
                notification.success(t('product:message.export_file.success'))
                handleCancel()
            })
            .catch(err => {
                processResponseError(err)
            })
            .finally(() => setLoading(false))
    }
    return (
        <>
            <Button
                onClick={showModal}
                type="primary"
                icon={<DownloadOutlined />}
                className="_btn-export-list-report mr-2"
            >
                {t('product:btn.export_report')}
            </Button>
            {isVisible && (
                <Modal
                    title={t('product:btn.export_report')}
                    visible={true}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.confirm')}
                    className={'custom-confirm-modal normal'}
                    closable
                    onCancel={handleCancel}
                >
                    <div>
                        <span>{t('product:label.time_session_report')}</span>
                        <RangePicker
                            className="_products-search-created-at mt-2"
                            value={session_from && session_to ? [moment(session_from), moment(session_to)] : undefined}
                            format={dateFormat}
                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                            onChange={date => onChangeDate(date)}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="confirm-btn-group">
                        <Button
                            className={'mr-2'}
                            onClick={handleCancel}
                        >
                            {t('btn.back')}
                        </Button>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={handleDownload}
                            disabled={!session_from && !session_to}
                        >
                            {t('product:btn.export_file_report')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    )
}
export default ExportListReport
