import { QuestionCircleOutlined } from '@ant-design/icons'
import { Table, Tooltip, Typography } from 'antd'
import { find, get, isEmpty, sumBy } from 'lodash'
import React, { useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { auth } from '@Modules/Auth/services'

import { t } from '@System/i18n'
import { formatCurrency } from '@System/support/numberFormat'

const { Text } = Typography
const ImportServiceInfo = ({ dataSource }) => {
    const user = auth.user()
    let getColumns = [
        {
            className: '_dropshipping-list-service-name text-wrap',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            width: '50%',
            render: (text, record) => (text ? text : ''),
        },
        {
            className: '_dropshipping-list-estimated_costs text-left',
            title: (
                <p className="flex align-items-center">
                    {t('product:label.estimated_costs')}
                    <Tooltip title={t('message.cost_depends_on_size_service_used')}>
                        <QuestionCircleOutlined className="ml-1 cursor-pointer" />
                    </Tooltip>
                </p>
            ),
            width: '50%',
            dataIndex: 'itemPrices',
            key: 'code',
            render: (text, record) => {
                const price = get(text, 'price')
                return (
                    <span>
                        {!isEmpty(user.currency) ? formatCurrency(price, user.currency) : price}
                    </span>
                )
            },
        },
    ]

    return (
        <>
            <Table
                className="product-list-table pb-3"
                dataSource={dataSource}
                rowClassName="_dropshipping-product-row"
                rowKey={record => record?.service?.id}
                columns={getColumns}
                pagination={false}
                scroll={{ x: true }}
                summary={pageData => {
                    const getTrueValue = pageData.map(val => val.itemPrices).flat()
                    const sumVal = !isEmpty(getTrueValue) ? sumBy(getTrueValue, 'price') : 0
                    
                    return (
                        !isEmpty(pageData) && (
                            <Table.Summary.Row>
                                <Table.Summary.Cell className="font-weight-bold">{t('label.estimated_sum')}</Table.Summary.Cell>
                                <Table.Summary.Cell className="font-weight-bold">
                                    <Text>{!isEmpty(user.currency) ? formatCurrency(sumVal, user.currency) : sumVal}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        )
                    )
                }}
                locale={{ emptyText: <TableEmpty className="_dropshipping-list-empty" /> }}
            />
        </>
    )
}

export default ImportServiceInfo
