import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import api from '@Modules/Product/services/api';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel } from '@System/support/helpers';

function ExportProductHistory({ params, productId }) {
    const [loading, setLoading] = useState(false);
    function handleDownload() {
        setLoading(true);
        api.exportProductHistory({ ...params, product_id: productId })
            .then(res => {
                convertBlobDataToExcel(res.data, 'product_history');
                notification.success(t('product:message.export_file.success'));
            })
            .catch(err => {
                processResponseError(err);
            })
            .finally(() => setLoading(false));
    }
    return (
        <Button
            onClick={handleDownload}
            type="primary"
            loading={loading}
            icon={<DownloadOutlined />}
            className="_btn-export-list-product"
        >
            {t('btn.export_excel')}
        </Button>
    );
}
export default ExportProductHistory;
