import { Layout, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { tracker } from '@Config/tracker';

import useSize from '@Modules/App/Hooks/useSize';
import { auth } from '@Modules/Auth/services';

import PageHeader from '../modules/App/Header';
import MenuLeft from '../modules/App/MenuLeft';
import ProfileMobile from '../modules/App/ProfileMobile';
import { get } from 'lodash';
import { t } from '@System/i18n';
import { WarningOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import {getEnv} from "@System/support/helpers";

const { Content } = Layout;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

export default function DefaultLayout({ children }) {
    const { isXSmall, isSmall } = useSize();
    const [isShown, setIsShown] = useState(false);
    const [expanded, setExpanded] = useState((!isXSmall && !isSmall) ? (!!JSON.parse(localStorage.getItem('menu_expanding') || false)) : false);
    const [status, setStatus] = useState('');
    const CARE_SOFT_DOMAIN = auth.tenantSettingOfUser('CARE_SOFT_DOMAIN', null);
    const CARE_SOFT_DOMAIN_ID = auth.tenantSettingOfUser('CARE_SOFT_DOMAIN_ID', null);
    const history = useHistory();

    useEffect(() => {
        if (CARE_SOFT_DOMAIN && CARE_SOFT_DOMAIN_ID) {
            loadJsAsync('https://webchat.caresoft.vn:8090/js/CsChat.js?v=4.0', function () {
                let t = { domain: CARE_SOFT_DOMAIN, domainId: CARE_SOFT_DOMAIN_ID };
                window.embedCsChat(t);
            });
        }
    }, [CARE_SOFT_DOMAIN, CARE_SOFT_DOMAIN_ID]);

    function loadJsAsync(t, e) {
        let n = document.createElement('script');
        n.type = 'text/javascript';
        n.src = t;
        n.addEventListener(
            'load',
            function (t) {
                e(null, t);
            },
            !1
        );
        let a = document.getElementsByTagName('head')[0];
        a.appendChild(n);
    }

    function _onExpand() {
        setExpanded(preState => !preState);
        if (!isXSmall && !isSmall) localStorage.setItem('menu_expanding', !expanded);
    }

    function _onClick() {
        if (expanded && (isXSmall || isSmall)) _onExpand();
    }

    const user = auth.user();
    if (user && getEnv('REACT_APP_TRACKING')) {
        tracker.setUserID(user.email);
    }

    useEffect(() => {
        if (!sessionStorage.getItem('warning_amount') && get(user, 'warning_out_money')) {
            warning()
        }
    }, [user])

    const warning = () => {
        Modal.confirm({
            title: t('message.warning_amount_message'),
            icon: <WarningOutlined style={{color: '#DA292E'}}/>,
            content: t('message.warning_amount_message_content'),
            okText:t('label.deposit'),
            cancelText:t('btn.remind_me_later'),
            width:450,
            closable:true,
            onOk: () => {
                history.push('/deposit')
                sessionStorage.setItem('warning_amount', 'on');
            },
            onCancel:()=>{
                sessionStorage.setItem('warning_amount', 'on');
            }
        });
    };

    return (
        <QueryClientProvider client={queryClient}>
            <div id="page-body">
                <div id="page-menu">
                    <MenuLeft expanded={expanded} _onExpand={_onExpand} status={status} _onClick={_onClick} />
                </div>
                <div id="page-content" className={expanded ? 'page-content-expanded' : ''}>
                    <Layout className="min-h-100">
                        <PageHeader _onExpand={_onExpand} isShown={isShown} setIsShown={setIsShown} />
                        <Content className="d-flex flex-column">{children}</Content>
                    </Layout>
                </div>
                <ProfileMobile isShown={isShown} setIsShown={setIsShown} />
            </div>
        </QueryClientProvider>
    );
}
