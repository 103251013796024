import { CheckCircleFilled } from '@ant-design/icons';
import { Form, Input, Row, Col, Tooltip } from 'antd';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';

import SelectService from '@Modules/Services/components/SelectServices';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const inputsName = ['weight', 'length', 'width', 'height'];
function TransportServiceInfo({ services, onChange, product, skuInfo }) {
    const [serviceId, setServiceId] = useState(undefined);
    const [form] = Form.useForm();
    const confirm_weight_volume = isEmpty(skuInfo) ? false : getVar(skuInfo, 'confirm_weight_volume', false);

    useEffect(() => {
        updateFormItem(product);
    }, [product]);

    useEffect(() => {
        if (confirm_weight_volume) {
            updateFormItem(skuInfo);
        }
    }, [confirm_weight_volume]);

    function updateFormItem(data) {
        const fieldsInfo = [];
        inputsName.map(name => {
            const value = getVar(data, name, undefined);
            fieldsInfo.push({ name, value: value ? value * 1000 : undefined, errors: '' });
        });
        form.setFields(fieldsInfo);
    }

    function onSubmit(name, value) {
        const errors = form.getFieldError(name);
        if (errors.length === 0) {
            if (value) {
                onChange(name, Number(value) / 1000);
            }
        }
    }

    function onChangeService(value) {
        const serviceIds = map(
            filter(services, item => item.service.type === SERVICES_TYPE.EXPORT),
            'id'
        );
        serviceIds.push(value);
        onChange('services', serviceIds);
    }

    useEffect(() => {
        const serviceInfo = find(services,  item => item.service.type === SERVICES_TYPE.TRANSPORT);
        if (!isEmpty(serviceInfo)) {
            setServiceId(getVar(serviceInfo, 'service.id', undefined));
        }
    }, [services]);

    return (
        <div className="mt-4">
            <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item labelAlign={'left'} label={t('product:label.service')}>
                    <SelectService
                        className={'_transporting-service-id'}
                        value={serviceId ? parseInt(serviceId) : undefined}
                        onChange={value => onChangeService(value)}
                        type={SERVICES_TYPE.TRANSPORT}
                        placeholder={t('product:placeholder.transport_service')}
                    />
                </Form.Item>

                <Form.Item
                    labelAlign={'left'}
                    label={
                        <>
                            {confirm_weight_volume && (
                                <Tooltip title={t('product:message.confirmed_weight_volume')} className="mr-2">
                                    <CheckCircleFilled style={{ color: 'green' }} />
                                </Tooltip>
                            )}
                            {t('product:label.weight')}
                        </>
                    }
                    name="weight"
                    rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
                >
                    <Input
                        disabled={!!confirm_weight_volume}
                        className={'_product-weight'}
                        onBlur={e => onSubmit('weight', e.target.value)}
                        placeholder={t('product:placeholder.weight')}
                    />
                </Form.Item>

                <Form.Item
                    labelAlign={'left'}
                    label={
                        <>
                            {confirm_weight_volume && (
                                <Tooltip title={t('product:message.confirmed_weight_volume')} className="mr-2">
                                    <CheckCircleFilled style={{ color: 'green' }} />
                                </Tooltip>
                            )}
                            {`${t('product:label.dimension')} (mm)`}
                        </>
                    }
                >
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="length"
                                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
                            >
                                <Input
                                    disabled={!!confirm_weight_volume}
                                    className={'_product-length'}
                                    onBlur={e => onSubmit('length', e.target.value)}
                                    placeholder={t('product:placeholder.length')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="pl-2"
                                name="width"
                                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
                            >
                                <Input
                                    disabled={!!confirm_weight_volume}
                                    className={'_product-width'}
                                    onBlur={e => onSubmit('width', e.target.value)}
                                    placeholder={t('product:placeholder.width')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="pl-2"
                                name="height"
                                rules={[{ pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) }]}
                            >
                                <Input
                                    disabled={!!confirm_weight_volume}
                                    className={'_product-height'}
                                    onBlur={e => onSubmit('height', e.target.value)}
                                    placeholder={t('product:placeholder.height')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
}
export default TransportServiceInfo;