import { Badge, Modal, Table } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import useFilter from '@Modules/App/Hooks/useFilter';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { dateFormatter } from '@Modules/App/services';
import useDropshipDetailPriceQuery from '@Modules/Dropshipping/Hooks/useDropshipDetailPriceQuery';
import { PRODUCT_EVENT, SERVICES_PRICE_COLORS } from '@Modules/Dropshipping/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

import ModalPriceDetail from './ModalPriceDetail';

const HistoryPriceList = ({ productId, history, location, currency, productActivePrice }) => {
    const { filters, addFilter } = useFilter(history, location, {});
    const [productPriceHistory, setProductPriceHistory] = useState([]);
    const [pagination, setPagination] = useState({});
    const { isFetching, data, refetch } = useDropshipDetailPriceQuery(productId, filters);

    useEffect(() => {
        refetch();
        events.listen(PRODUCT_EVENT.RELOAD_PRODUCT_HISTORY_DROPSHIP, () => refetch());
    }, [refetch]);

    useEffect(() => {
        setProductPriceHistory(data?.data?.product_prices);
        setPagination(get(data, 'data.pagination'));
    }, [data]);

    let columns = [
        {
            className: '_quote-create-service-box-history-table-cost_price',
            title: t('product:label.quotation'),
            dataIndex: ['product_price', 'id'],
            key: 'id',
            render: text => <p>#{text ? text : '---'}</p>,
        },
        {
            className: '_quote-create-service-box-history-table-packing_service',
            title: t('product:label.costs'),
            dataIndex: ['price_details'],
            key: 'price_details',
            render: (text, record) => {
                let min = Math.min.apply(
                    null,
                    text.map(item => item.total_price)
                );

                let max = Math.max.apply(
                    null,
                    text.map(item => item.total_price)
                );

                return (
                    <p>
                        {formatCurrency(min, currency)} - {formatCurrency(max, currency)}{' '}
                    </p>
                );
            },
        },
        {
            className: '_quote-create-service-history-box-table-transport_service',
            title: t('common:label.time'),
            dataIndex: ['product_price', 'created_at'],
            key: 'created_at',
            render: text => dateFormatter.full(text),
        },
        {
            className: '_quote-create-service-history-box-table-sum',
            title: t('common:label.status'),
            dataIndex: ['product_price', 'status'],
            key: 'status',
            render: text => {
                return text ? (
                    <Badge color={SERVICES_PRICE_COLORS[text]} text={t(`product:SERVICES_PRICE_STATUS.${text}`)} />
                ) : null;
            },
        },
        {
            className: '_quote-create-history-service-box-table-sum ',
            title: t('product:label.action'),
            dataIndex: ['product_price', 'id'],
            key: 'action',
            render: text => {
                return (
                    <span
                        className="font-weight-bold cursor-pointer"
                        style={{ color: '#f9a400' }}
                        onClick={() => {
                            showConfirm(text);
                        }}
                    >
                        {t('product:btn.view_quotation')}
                    </span>
                );
            },
        },
    ];

    const showConfirm = id => {
        Modal.confirm({
            title: t('product:label.quotation_detail'),
            icon: false,
            width: '1200px',
            cancelText: t('product:btn.close'),
            okButtonProps: { style: { display: 'none' } },
            cancelButtonProps: { className: 'dropshipping-new-price-history-modal_btn-cancel' },
            content: (
                <ModalPriceDetail
                    id={id}
                    currency={currency}
                    productActiveCurrent={productActivePrice}
                    refetchHistory={refetch}
                />
            ),
        });
    };

    return (
        <div className="bg-white rounded-12 p-4 mb-4 box-list-history-history_price box-shadow">
            <div className="d-flex justify-content-between mb-4">
                <h3>{`${t('product:title.history_prices')}`}</h3>
            </div>
            <Table
                className="_dropship-service-list_new-table-history-service-box pb-3 mt-4"
                rowKey={record => record?.product_price?.id}
                columns={columns}
                dataSource={productPriceHistory}
                scroll={{ x: true }}
                pagination={false}
                loading={isFetching}
                locale={{ emptyText: <TableEmpty className="_product-list-empty" /> }}
            />
            <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
        </div>
    );
};

export default HistoryPriceList;
