class BaseServiceData {

    constructor() {
        this.data = null;
        this.promise = null;
    }

    list() {
        if (this.data)
            return Promise.resolve(this.data);

        if (this.promise)
            return this.promise;

        return this.fetchData();
    }

    fetchData() {
        return this.promise = this.runApi().then(data => this.data = data);
    }

    runApi() {}

    all() {
        return this.data ? this.data : [];
    }

}

export default BaseServiceData;
