import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useListImportPackageQuery from '@Modules/ImportPackage/Hooks/useListImportPackageQuery';
import ImportPackageCreateMenu from '@Modules/ImportPackage/components/ImportPackageCreateMenu';

import { t } from '@System/i18n';

import FormSearch from './FormSearch';
import List from './List';

export default function ImportPackageList({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {});
    const [pagination, setPagination] = useState({});
    const [purchasingPackages, setPurchasingPackages] = useState([]);
    const { data, isFetching, refetch, isError, error } = useListImportPackageQuery(filters);

    useEffect(() => {
        setNavigator(t('menu.list_import_package'), [
            {
                name: t('breadcrumb.list_import_package'),
            },
        ]);
        setDocumentTitle(t('title.list_import_package'));
    }, []);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useEffect(() => {
        setPurchasingPackages(get(data, 'data.purchasing_packages', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    if (isError) {
        const status = get(error,'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.list_import_package')}</h3>
                <ImportPackageCreateMenu />
            </div>

            <FormSearch onSearch={addFilter} filters={filters} loading={isFetching} />
            <List
                purchasingPackages={purchasingPackages}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={isFetching}
                refetch={refetch}
            />
        </div>
    );
}
