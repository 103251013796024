import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import React, { useState, useEffect } from 'react';

import UploadMulti from '@Modules/App/Upload/UploadMulti';

import { t } from '@System/i18n';

const fileType = ['image/jpeg', 'image/png'];

function UpdateFile(props) {
    const { maxFileSize, onChange, images } = props;
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const newImages = refactorImage(images);
        if (!isEqual(fileList, newImages)) {
            setFileList(newImages);
        }
    }, [images]);

    function refactorImage(images) {
        const newImages = [];
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 });
        });
        return newImages;
    }

    function uploadFile() {
        form.validateFields().then(values => {
            onChange('file', values['file']);
            form.resetFields();
        });
    }

    function beforeUpload(file) {
        if (fileType) {
            if (fileType.indexOf(file.type.toLowerCase()) < 0) {
                return false;
            }
        }

        if (maxFileSize) {
            const validMaxSize = file.size / 1024 / 1024 > maxFileSize;
            if (validMaxSize) {
                return false;
            }
        }
        return true;
    }

    function handleUpload(files) {
        let listFile = [];
        let error = false;
        files.forEach(file => {
            const validFile = beforeUpload(file);
            if (validFile) {
                listFile.push(file);
            } else {
                error = true;
            }
        });
        if (!error) {
            if (listFile.length > 0) {
                form.setFields([{ name: 'file', value: listFile }]);
            }
            uploadFile();
        } else {
            form.resetFields();
        }
    }

    return (
        <div className="d-flex text-wrap align-items-left">
            <div style={{ cursor: 'pointer' }}>
                <Form size="default" form={form} >
                    <Form.Item name="file" className="mb-0" >
                        <UploadMulti
                            className="_product-images-upload"
                            listType="picture-card"
                            onChange={handleUpload}
                            accept="image/jpeg,image/png"
                            fileType={fileType}
                            maxFileSize={maxFileSize}
                            showUploadList={false}
                        >
                            <div className="_product-image-upload">
                                <CloudUploadOutlined />
                                <div className="mt-3">{t('label.upload_image')}</div>
                            </div>
                        </UploadMulti>
                    </Form.Item>
                </Form>
            </div>
            <Upload
                className="_product-detail-list-product"
                listType="picture-card"
                fileList={fileList}
                openFileDialogOnClick={false}
                onChange={e => onChange('file', e.file)}
            />
        </div>
    );
}

export default UpdateFile;
