import { Avatar, Form, Input, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { v4 } from 'uuid';

import { t } from '@System/i18n';

const EditableContext = React.createContext(null);

const ProductVariations = ({
    product,
    localOptions,
    localVariations,
    setLocalVariations,
    form,
    handleOpenModal,
    handleUpdate,
}) => {
    const { lg } = useBreakpoint();
    const prevLocalOptions = useRef([]);
    const prevLocalVariations = useRef([]);

    useEffect(() => {
        if (_.isEmpty(prevLocalOptions.current)) {
            prevLocalOptions.current = localOptions;
        } else {
            createVariations();
            prevLocalOptions.current = localOptions;
            prevLocalVariations.current = localVariations;
        }

        // eslint-disable-next-line
    }, [localOptions]);

    const columns = [
        {
            title: t('product:label.sku'),
            className: '_product-column',
            dataIndex: ['option_values'],
            key: ['option_values'],
            width: '50%',
            render: (text, record, index) => {
                return product ? (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={record.images && record.images[0] }
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0, cursor: 'pointer' }}
                            onClick={() => handleOpenModal(record.key)}
                        />

                        <div style={{ fontWeight: '500' }}>
                            <p className="_product-name">{product.name}</p>
                            {text.map((item, index) => (
                                <span key={index} style={{ fontWeight: '500', color: '#A0A3BD' }}>
                                    {index !== 0 ? ', ' + item.label : item.label}
                                </span>
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                );
            },
        },
        {
            title: t('product:label.sku_code'),
            className: '_sku-column',
            dataIndex: 'code',
            key: 'code',
            width: '320px',
            editable: true,
        },
    ];
    const createVariations = () => {
        let variations = [];
        /**
         * Tạo lại mảng các biến thể theo thuộc tính mới được thêm vào
         */

        localOptions.forEach(option => {
            let { label, values, images } = option;
            if (!_.isEmpty(label) && !_.isEmpty(values)) {
                if (variations.length === 0) {
                    values.forEach(value =>
                        variations.push({
                            id: null,
                            code: null,
                            images,
                            option_values: [{ id: value.id, label: value.label, option_label: option.label }],
                        })
                    );
                } else {
                    let new_variations = [];
                    values.forEach(value => {
                        variations.forEach(variation => {
                            let { option_values } = variation;
                            new_variations.push({
                                id: variation.id,
                                code: null,
                                images,
                                option_values: [
                                    ...option_values,
                                    { id: value.id, label: value.label, option_label: option.label },
                                ],
                            });
                        });
                    });
                    variations = [...new_variations];
                }
            }
        });
        /**
         * Cập nhật lại id và code cho các biến thể mới được tạo lại dựa vào các biến thể cũ
         * Lấy ra biến thể cũ có các giá trị thuộc tính trùng với biến thể mới để cập nhật
         */
        let existedSkuIds = [];
        let variationsFormat = variations.map(item => {
            let currentItem = localVariations.find(localItem => {
                let gteCheck = item.option_values.length >= localItem.option_values.length,
                    optValues = _.get(gteCheck ? localItem : item, 'option_values', []),
                    optValueIds = _.map(_.get(gteCheck ? item : localItem, 'option_values', []), 'id');

                return !existedSkuIds.includes(localItem.id) && optValues.every(value => optValueIds.includes(value.id));
            });

            if (currentItem) {
                existedSkuIds.push(currentItem.id);

                return {
                    ...item,
                    key: currentItem.id,
                    id: currentItem.id,
                    code: currentItem.code,
                    images: currentItem.images,
                    ref: currentItem.ref,
                };
            } else {
                return { ...item, key: v4() };
            }
        });
        setLocalVariations(_.sortBy(variationsFormat, ['id']));
    };
    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ title, editable, children, dataIndex, record, index, handleSave, handleBlur, ...restProps }) => {
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editable) {
                form.setFieldsValue({ [dataIndex]: record[dataIndex] });
            }
        }, [editable]);

        const save = () => {
            form.validateFields().then(values => {
                let { code } = values;

                handleSave({ ...record, ...values }, index);
            });
        };

        let childNode = children;
        if (editable) {
            childNode = (
                <Form.Item name={dataIndex} className="mb-0" key={dataIndex}>
                    <Input
                        className="_product-variants-weight-input"
                        onPressEnter={save}
                        onBlur={save}
                        disabled={record && !!record.id}
                        placeholder={t('product:placeholder.sku_blank_system')}
                        style={{ width: lg ? '50%' : '320px' }}
                    />
                </Form.Item>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const onChangeSku = (data, index) => {
        const { code } = data;
        const newLocalVariations = [...localVariations];
        newLocalVariations[index] = { ...data, code };
        form.setFieldsValue({ skus: newLocalVariations });

        handleUpdate('skus', newLocalVariations);
        setLocalVariations(newLocalVariations);
    };

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                index,
                handleSave: onChangeSku,
            }),
        };
    });
    return (
        <div>
            <div className="mt-4">
                <h3 className="mb-4">{t('product:title.variations_list')}</h3>
  
                {localVariations.length > 0 && (
                    <Table
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            },
                        }}
                        border={true}
                        className="list-products-table mt-3"
                        columns={tableColumns}
                        dataSource={localVariations}
                        pagination={false}
                        rowKey={record => record?.key}
                        
                        scroll={{ x: true }}
                    />
                )}
                {localVariations.length === 0 && <p className="text-center mt-4 mb-5">{t('product:message.not_info_sku')}</p>}
            </div>
        </div>
    );
};

export default ProductVariations;
