import { CaretDownOutlined } from '@ant-design/icons';
import { Table, Avatar, Badge } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import { FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS } from '@Modules/Finance/services/constants';
import { FINANCE_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';
import OrderCreateMenu from '@Modules/Order/components/OrderCreateMenu';

import { t } from '@System/i18n';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import DynamicTable from '@Modules/App/DynamicTable';

export default function List({ orders, pagination, addFilter, filters, loading }) {
    const getColumns = [
        {
            className: '_finances-info',
            title: t('order:label.order'),
            dataIndex: ['order', 'code'],
            key: 'order_code',
            disabled:true,
            render: (text, record) => {
                const count = getVar(record, 'skus', []).length;
                const images = getVar(record, 'product.images', []);
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" className="mr-2" size={36} src={images.length > 0 ? images[0] : ''} />
                        <div>
                            <span>
                                <Link params={{ id: get(record, 'order.id') }} to={'orders.detail'}>
                                    <strong style={{ fontWeight: 500 }} className="text-link">{`#${text}`}</strong>
                                </Link>
                            </span>
                            <p style={{ color: '#999999' }}>{t('label.has_product', { count })}</p>
                        </div>
                    </div>
                );
            },
        },

        {
            className: '_finances-amount text-right',
            title: t('finance:label.amount'),
            dataIndex: ['order', 'order_amount'],
            key: 'amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_finances-collect-cod text-right',
            title: t('finance:label.collect_cod'),
            dataIndex: ['order', 'paid_amount'],
            key: 'collect_cod',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_finances-packing-costs text-right',
            title: t('finance:label.packing_amount'),
            dataIndex: 'service_amount',
            key: 'packing_costs',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_finances-shipping-costs text-right',
            title: t('finance:label.shipping_amount'),
            dataIndex: ['order', 'shipping_amount'],
            key: 'shipping_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_finances-remaining-amount text-right',
            title: t('finance:label.remaining_amount'),
            dataIndex: 'remaining_amount',
            key: 'remaining_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_finances-service-import-return-goods-amount text-right',
            title: t('finance:label.return_goods_amount'),
            dataIndex: ['order', 'service_import_return_goods_amount'],
            key: 'service_import_return_goods_amount',
            render: (text, record) => {
                const status = getVar(
                    record,
                    'order.finance_service_import_return_goods_status',
                    FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS.UNPAID
                );
                const currency = getVar(record, 'currency', {});
                if (status === FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS.PAID) {
                    return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
                } else {
                    return '';
                }
            },
        },
        {
            className: '_finances-cost_of_goods text-right',
            title: t('order:label.cost_of_goods'),
            dataIndex: ['order', 'cost_of_goods'],
            key: 'cost_of_goods',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text || 0, currency) : text) : '';
            },
        },
        {
            className: '_finances-finance-status text-right',
            title: t('label.status'),
            dataIndex: ['order', 'finance_status'],
            key: 'finance_status',
            render: text =>
                text ? <Badge color={FINANCE_STATUS_COLORS[text]} text={t(`product:FINANCE_STATUS.${text}`)} /> : null,
        },
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        <div className="d-flex justify-content-between align-items-center">
                            {t('order:title.order_list')}
                            <span className="number-circle _importing-document-product-list-total">
                                {getVar(pagination, 'total', 0)}
                            </span>
                        </div>
                    </h3>
                </div>
                <div className="data-box--body">
                <DynamicTable
                    initColumns={getColumns}
                    dataSource={orders}
                    rowKey={record => record?.order.id}
                    className="order-list-table pb-3"
                    loading={loading}
                    locale={{
                        emptyText: (
                            <EmptyTableData
                                loading={loading}
                                message={'no_data_order'}
                                action={
                                    <OrderCreateMenu>
                                        <strong className="cursor-pointer pagination-customize">
                                            {t('order:btn.import_order')}
                                            <CaretDownOutlined className="ml-1" />
                                        </strong>
                                    </OrderCreateMenu>
                                }
                            />
                        ),
                    }}
                    scroll={{ x: true }}
                />
                    {/* <Table
                        className="order-list-table pb-3"
                        dataSource={orders}
                        rowKey={record => record?.order?.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_order'}
                                    action={
                                        <OrderCreateMenu>
                                            <strong className="cursor-pointer pagination-customize">
                                                {t('order:btn.import_order')}
                                                <CaretDownOutlined className="ml-1" />
                                            </strong>
                                        </OrderCreateMenu>
                                    }
                                />
                            ),
                        }}
                        scroll={{ x: true }}
                    /> */}
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
