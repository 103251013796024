import React, {useState, useEffect} from 'react';
import {message} from 'antd';
import {api} from '../services';
import Error403 from '../../App/Error/403';
import queryString from 'query-string';
import { getVar } from "@System/support/helpers";

export default function Login({location}) {
    const [error, setError] = useState(false);
    const params = queryString.parse(location.search, { arrayFormat: 'bracket' });
    const ref = getVar(params, "ref", undefined);

    useEffect(login, []);

    function login() {
        const hideLoading = message.loading('Loading...', 0);

        api.login({domain: window.location.host, ref})
            .then(res => window.location = res.data.url)
            .catch(() => setError(true))
            .finally(() => hideLoading());
    }

    return error ? <Error403/> : null;
}
