import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import ImportCreateOrders from '@Modules/Order/components/UploadFile/ImportCreateOrders';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import ImportCreateOrdersDropshipping from "@Modules/Order/components/UploadFile/ImportCreateOrdersDropshipping";

export default function OrderCreateMenu({ children }) {
    const selectedAction = (
        <Menu className="menu-dropdown-action">
            <Menu.Item key={1} className=" mb-3 mb-xl-0">
                <Link to={url.to('orders.create')}>
                    <Button className="_btn-create-order-manual" type={'link'} icon={<PlusCircleOutlined />}>
                        {t('btn.create_order_manual')}
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={2} className=" mb-3 mb-xl-0">
                <ImportCreateOrders btnType="link" />
            </Menu.Item>
            <Menu.Item key={3} className=" mb-3 mb-xl-0">
                <ImportCreateOrdersDropshipping btnType="link" />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
            {children ? (
                children
            ) : (
                <Button icon={<PlusOutlined />} type="primary">
                    {t('order:btn.import_order')}
                </Button>
            )}
        </Dropdown>
    );
}
