import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import useListServiceByTypeQuery from '@Modules/Services/Hooks/useListServiceByTypeQuery';
import { getVar } from "@System/support/helpers";

const { Option } = Select;

export default function SelectService(props) {
    let { onChange } = props;
    let [services, setServices] = useState([]);
    const { error, isError, isLoading, data } = useListServiceByTypeQuery(props.type);

    useEffect(() => {
        const services = getVar(data, 'data.services', []);
        if(!isEmpty(services)) {
            setServices(services);
        }
    }, [data]);

    function _onChange(value, option) {
        if (typeof onChange === 'function') onChange(value, option);
    }

    return (
        <Select
            loading={isLoading}
            {...props}
            onChange={_onChange}
            value={services.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {services.map(item => (
                <Option key={item.service.id} value={item.service.id}>
                    {item.service.name}
                </Option>
            ))}
        </Select>
    );
}
