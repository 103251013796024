import { Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';

import ServiceLocation from '@Modules/Location/services/ServiceLocation';
import { VIETNAM_CODE } from '@Modules/Location/services/constants';

import { t } from '@System/i18n';
import { SelectLocationKey } from '@Modules/Order/Interfaces';

interface ISelectWard {
    countryCode: string;
    onChange: (value: any, option: any, key: SelectLocationKey) => void;
    value?: number;
    districtCode: string;
}

const ServiceLocationApi = new ServiceLocation();
const { Option } = Select;

const SelectWard: React.FC<ISelectWard> = ({ countryCode, onChange, value, districtCode }) => {
    const [wards, setWards] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWard = () => {
            setLoading(true);

            ServiceLocationApi.list('WARD', districtCode)
                .then(res => {
                    setWards(res);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        if (districtCode) {
            fetchWard();
        }
    }, [districtCode]);

    return (
        <Select
            loading={loading}
            disabled={loading}
            className="_location-receiver-ward-id"
            placeholder={t(`warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'ward_id' : 'other_ward_id'}`)}
            showSearch
            optionFilterProp="label"
            value={wards.length > 0 && value ? value : undefined}
            onChange={(value: any, option: any) => onChange(value, option.code, 'ward')}
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {wards.length > 0 &&
                wards.map((opt: any) => {
                    return (
                        <Option key={opt.id} value={opt.id} code={opt.code}>
                            {opt.label}
                        </Option>
                    );
                })}
        </Select>
    );
};

export default SelectWard;
