import { api } from '@System/api';

export default {
    getProductDropshippingDetail: productId =>
        api.get(`merchant/product-drop-ship/${productId}`, { singleRequest: true, loading: false }),
    updateProductDropshipping: (productId, data) => api.post(`merchant/product-drop-ship/${productId}`, data),
    createProductDropshipping: data => api.post(`merchant/product-drop-ship`, data),
    getProductDropshipping: params => api.get('merchant/product-drop-ship', { params, singleRequest: true, loading: false }),
    checkDeleteValue: data => api.post('merchant/options/check-delete-value', data),
    changeStatusProductDropship: data => api.put(`merchant/product-drop-ship/status`, data),
    getDropshippingDetailPrice: (productId, params) =>
        api.get(`merchant/products/${productId}/prices`, { params, singleRequest: true, loading: false }),
    getDropshippingDetailOnePrice: productPriceId =>
        api.get(`merchant/product-prices/${productPriceId}`, { singleRequest: true, loading: false }),
    changeStatusQuotation: priceId => api.put(`merchant/product-prices/${priceId}/active`, {}),
    createOrdersDropshippingByUploadFile: (data) => api.post(`merchant/dropship-orders/import`, data)
};
