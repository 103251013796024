import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography, Badge, Avatar, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import useAntdTable from '@Modules/App/Hooks/useAntdTable';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useAccountPurchasingListQuery from '@Modules/Setting/Hooks/useAccountPurchasingListQuery';
import useDeletePurchasingAccountMutation from '@Modules/Setting/Hooks/useDeletePurchasingAccountMutation';
import AddPurchasingAccountModal from '@Modules/Setting/components/Account/AddPurchasingAccount';
import List from '@Modules/Setting/screens/PurchasingAccount/List';
import { PURCHASING_ACCOUNT_STATUS } from '@Modules/Setting/services/constants';

import { t } from '@System/i18n';

const { Paragraph } = Typography;

function PurchasingAccountList() {
    const [visible, setVisible] = useState(false);
    const { purchasing_accounts, isFetching, search, refetch } = useAntdTable(useAccountPurchasingListQuery, {});

    const { mutateAsync: deletePurchasingAccount } = useDeletePurchasingAccountMutation({
        onSuccess: () => refetch(),
    });

    const { clear, submit } = search;

    const handleSearch = params => {
        submit(params);
    };
    useEffect(() => {
        setNavigator(t('title.account_connect'), [
            {
                name: t('breadcrumb.account_connect'),
            },
        ]);
        setDocumentTitle(t('title_page.account_connect'));
    }, []);

    function handleSuccessAddPurchasingAccount() {
        refetch();
        setVisible(false);
    }

    const handleDelete = index => {
        Modal.confirm({
            cancelText: t('btn.cancel'),
            className: '_confirm-delete-purchasing-account-modal',
            okText: t('btn.confirm'),
            title: t('setting:purchasing_account.delete.title'),
            content: (
                <div>
                    <Paragraph className="mb-3">{t('setting:purchasing_account.delete.confirm')}</Paragraph>

                    <div className="d-flex align-items-center mb-3">
                        <Avatar className="mr-2" size={36} />
                        <div className="d-flex flex-column">
                            {purchasing_accounts[index]?.username}
                            <Badge
                                color={PURCHASING_ACCOUNT_STATUS[purchasing_accounts[index]?.status?.toUpperCase()]?.color}
                                text={t(`setting:purchasing_account.status.${purchasing_accounts[index]?.status}`)}
                            />
                        </div>
                    </div>
                </div>
            ),
            onOk: () => deletePurchasingAccount(purchasing_accounts[index]?.id),
        });
    };

    function handleOpenModal() {
        setVisible(prevState => !prevState);
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.account_connect')}</h3>
                <Button className="_add-btn" type="primary" onClick={handleOpenModal}>
                    <PlusOutlined />
                    {t('btn.add_account')}
                </Button>
            </div>
            <List
                accounts={purchasing_accounts}
                onSearch={handleSearch}
                loading={isFetching}
                onDelete={handleDelete}
                handleOpenModal={handleOpenModal}
            />
            <AddPurchasingAccountModal visible={visible} onCancel={handleOpenModal} onOk={handleSuccessAddPurchasingAccount} />
        </div>
    );
}
export default PurchasingAccountList;
