import { Tooltip } from 'antd';
import React from 'react';

import { ExpandContext } from './configs';

export default function MenuSub({ children, name, icon, parentSelected = false }) {
    return (
        <ExpandContext.Consumer>
            {({ showName, showIcon }) => {
                const renderMenu = showIcon ? (
                    <Tooltip placement="right" title={name}>
                        <li
                            className={`main-menu--list--title cursor-pointer ${showIcon ? 'text-center text-fz-18' : ''} ${parentSelected ? "selected" : ''}`}
                        >
                            <span className={showName ? 'mr-2' : ''}>{icon ? icon : ''}</span> {showName ? name : ''}
                        </li>
                    </Tooltip>
                ) : (
                    <li
                        className={`main-menu--list--title cursor-pointer ${showIcon ? 'text-center text-fz-18' : ''} ${parentSelected ? "selected" : ''}`}
                    >
                        <span className={showName ? 'mr-2' : ''}>{icon ? icon : ''}</span> {showName ? name : ''}
                    </li>
                );

                return (
                    <ul>
                        {name && renderMenu}
                        {children}
                    </ul>
                );
            }}
        </ExpandContext.Consumer>
    );
}
