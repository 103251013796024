import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { get, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import api from '@Modules/Product/services/api'

import { processResponse } from '@System/api'
import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import { url } from '@System/routing'

import ChildSkuList from './ChilSkuList'
import InfoCombo from './InfoCombo'

interface IDetailComboProps {
    match: any
}

const DetailCombo: React.FC<IDetailComboProps> = ({ match }) => {
    const { id } = match.params
    const [detail, setDetail] = useState({})
    const [skus, setSkus] = useState([])
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState<any[]>([])

    useEffect(() => {
        setNavigator(t('title.product_detail'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.product_detail'),
            },
        ])
        setDocumentTitle(t('title.product_detail'))
    }, [])

    useEffect(() => {
        const getDetail = () => {
            setLoading(true)
            api.getSkuComboDetail(id)
                .then(res => {
                    setDetail(get(res, 'data.data', {}))
                    setSkus(get(res, 'data.data.skus.data', {}))
                    setImages(refactorImage(get(res, 'data.data.image', [])))
                })
                .catch(error => {
                    processResponseError(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        getDetail()
    }, [id])

    const refactorImage = (images: any[]) => {
        const newImages: any[] = []
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 })
        })
        return newImages
    }

    return (
        <div className="site-content _product-detail">
            <div className="d-flex justify-content-between align-items-center">
                <Link
                    className="_product-detail-back"
                    to={url.to('products.list')}
                >
                    <ArrowLeftOutlined /> {t('btn.back')}
                </Link>

                <Link
                    className="_btn-edit"
                    to={`/products/edit-combo/${id}`}
                >
                    <Button
                        type={'primary'}
                        icon={<EditOutlined />}
                    >
                        {t('btn.product_edit')}
                    </Button>
                </Link>
            </div>

            <InfoCombo
                loading={loading}
                detail={detail}
                images={images}
            />
            <ChildSkuList
                skus={skus}
                loading={loading}
            />
        </div>
    )
}

export default DetailCombo
