import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useListProductQuery from '@Modules/Product/Hooks/useListProductQuery'
import useListSkuComboQuery from '@Modules/Product/Hooks/useListSkuComboQuery'
import CreateProductButton from '@Modules/Product/components/CreateProductButton'
import CreateProductByImport from '@Modules/Product/components/CreateProductByImport'
import QuickFilter from '@Modules/Product/screens/List/QuickFilter'
import { PRODUCT_EVENT, PRODUCT_STATUS } from '@Modules/Product/services/constants'

import { events } from '@System/events'
import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

import FormSearch from './FormSearch'
import List from './List'
import ProductList from './ProductList'

const initialFilters = {
    code: undefined,
    name: undefined,
    nearly_sold_out: undefined,
    not_yet_in_stock: undefined,
    out_of_stock: undefined,
    status: PRODUCT_STATUS.ON_SELL,
    'created_at[from]': undefined,
    'created_at[to]': undefined,
    lack_of_export_goods: undefined,
    warehouse_id: undefined,
}
export default function ListProduct({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, initialFilters)
    const [activeKey, setActiveKey] = useState('product-list')

    const { data, isFetching, isError, error, refetch } = useListProductQuery(filters, activeKey === 'product-list')

    const {
        data: dataSkuCombo,
        isFetching: loadingSkuCombo,
        // isError: isErrorSkuCombo,
        // error: errorSkuCombo,
        // refetch: refetchSkuCombo,
    } = useListSkuComboQuery(filters, activeKey === 'combo-list')

    const handleSearch = params => {
        addFilter(params)
    }

    useEffect(() => {
        setNavigator(t('common:title.list-products'), [
            {
                name: t('common:breadcrumb.list-products'),
            },
        ])
        setDocumentTitle(t('title.list-products'))
        return events.listen(PRODUCT_EVENT.RELOAD_PRODUCT_LIST, () => refetch())
    }, [])


    // useEffect(() => {
    //     const { status, nearly_sold_out, not_yet_in_stock, out_of_stock } = filters;
    //     const input =
    //         nearly_sold_out || not_yet_in_stock || out_of_stock
    //             ? { ...filters, status: undefined }
    //             : {
    //                   ...filters,
    //                   status: status ? status : PRODUCT_STATUS.ON_SELL,
    //                   nearly_sold_out: undefined,
    //                   not_yet_in_stock: undefined,
    //                   out_of_stock: undefined,
    //               };
    //     addFilter(input);
    // }, []);

    if (isError) {
        const status = get(error, 'response.status')
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    const handleChangeTab = activeKey => {
        setActiveKey(activeKey)
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.list-products')}</h3>
                {/* <CreateProductByImport /> */}
                <CreateProductButton />
            </div>

            <FormSearch
                filters={filters}
                loading={isFetching}
                onSearch={handleSearch}
                initialFilters={initialFilters}
                isCombo={activeKey === 'combo-list'}
            />

            <QuickFilter
                addFilter={addFilter}
                filters={filters}
                className={'mt-4'}
                data={activeKey === 'product-list' ? data : dataSkuCombo}
                loading={isFetching}
                isCombo={activeKey === 'combo-list'}
                // pagination={pagination}
            />

            <List
                filters={filters}
                onSearch={handleSearch}
                history={history}
                location={location}
                dataProduct={data}
                dataSkuCombo={dataSkuCombo}
                loadingProduct={isFetching}
                activeKey={activeKey}
                handleChangeTab={handleChangeTab}
                loadingSkuCombo={loadingSkuCombo}
            />
        </div>
    )
}
