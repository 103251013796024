import { DeleteOutlined, MinusOutlined } from '@ant-design/icons'
import { Avatar, Table, Form, Input, Button, InputNumber } from 'antd'
import { cloneDeep, filter, get, map } from 'lodash'
import find from 'lodash/find'
import findLastIndex from 'lodash/findLastIndex'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'

import { updateCollectionItem } from '@Modules/App/services/helpers'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

import CreateBatchOfGoods from '../Create/CreateBatchOfGoods'
import SelectBatchOfGoods from '../Create/SelectBatchOfGoods'

function ProductList({ dataSource, form, setProducts }) {
    useEffect(() => {
        form.setFieldsValue({
            [['batch_of_goods']]: map(dataSource, 'batch_of_goods'),
        })
    }, [dataSource])

    const columns = [
        {
            className: '_product-list-sku-name',
            title: t('product:label.sku_code'),
            dataIndex: 'sku_code',
            render: (text, record) => {
                const image = getVar(record, 'image', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            src={image}
                            shape="square"
                            className="mr-2"
                            size={36}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-name',
            title: t('product:label.name'),
            dataIndex: 'label',
        },
        {
            className: 'text-right text-nowrap _product-list-batch_number',
            title: t('product:label.batch_number'),
            dataIndex: 'batch_number',
            // width: '200px',
            render: (text, record, indexRow) => {
                const batch_of_goods = get(record, 'batch_of_goods', [])
                const isBatch = get(record, 'is_batch', false)

                const selectedList = map(
                    filter(batch_of_goods, item => item.id),
                    'id'
                )

                return !isBatch
                    ? '---'
                    : map(batch_of_goods, (item, idx) => {
                          return (
                              <Form.Item
                                  name={['batch_of_goods', indexRow, idx, 'id']}
                                  className="mb-2"
                                  key={idx}
                                  rules={[
                                      {
                                          required: true,
                                          message: t('order:message.required', {
                                              attribute: t('product:label.batch_number'),
                                          }),
                                      },
                                  ]}
                              >
                                  <SelectBatchOfGoods
                                      skuId={record.sku_id}
                                      onChange={(value, option) => onChangeBatch(indexRow, idx, value, option)}
                                      selectedList={selectedList}
                                  />
                              </Form.Item>
                          )
                      })
            },
        },

        {
            className: 'text-right text-nowrap _product-list-quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            // width: '200px',
            render: (text, record, indexRow) => {
                console.log('record', record, text)
                const batch_of_goods = get(record, 'batch_of_goods', [])
                const isBatch = get(record, 'is_batch', false)
                if (!isBatch) form.setFields([{ name: ['skus', indexRow, 'quantity'], value: text }]);
                return isBatch ? (
                    map(batch_of_goods, (item, idx) => {
                        console.log('item', item)
                        return (
                            <div
                                className="d-flex align-items-center justify-content-end"
                                key={idx}
                            >
                                <Form.Item
                                    name={['batch_of_goods', indexRow, idx, 'quantity']}
                                    className="mb-2"
                                    initialValue={item.quantity}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order:message.required', { attribute: t('label.quantity') }),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={1}
                                        placeholder={t('placeholder.product_quantity')}
                                        className="w-100"
                                        onBlur={e => updateQuantityBatch(indexRow, idx, e.target.value)}
                                    />
                                </Form.Item>
                                {idx > 0 ? (
                                    <DeleteOutlined
                                        className="mb-2 ml-2 text-danger cursor-pointer"
                                        onClick={() => onDeleteRowBatch(indexRow, idx)}
                                    />
                                ) : (
                                    <DeleteOutlined className="text-light mb-2 ml-2" />
                                )}
                            </div>
                        )
                    })
                ) : (
                    <div className="d-flex align-items-center justify-content-end">
                        <Form.Item
                            name={['skus', indexRow, 'quantity']}
                            className="mb-2"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('label.quantity') }) }]}
                        >
                            <InputNumber
                                min={1}
                                placeholder={t('placeholder.product_quantity')}
                                className="w-100"
                                onBlur={e => updateQuantity('quantity', e.target.value, record)}
                            />
                        </Form.Item>
                        <DeleteOutlined className="text-light ml-2" />
                    </div>
                )
            },
        },
        {
            title: t('label.action'),
            className: 'text-right _importing-document-product-action',
            dataIndex: 'sku_id',
            render: (value, record, indexRow) => {
                const isBatch = get(record, 'is_batch', false)
                const firstBatch = get(record, 'batch_of_goods.0.id')

                return (
                    <div className="d-flex align-items-center justify-content-end">
                        {isBatch && (
                            <>
                                {firstBatch && (
                                    <Button
                                        className="mr-2"
                                        onClick={() => addBatchRow(indexRow)}
                                    >
                                        {t('product:btn.add_batch')}
                                    </Button>
                                )}
                                <CreateBatchOfGoods skuId={value} />
                            </>
                        )}
                        <Button
                            onClick={() => handleDeleteRow(record)}
                            danger
                        >
                            {t('btn.delete')}
                        </Button>
                    </div>
                )
            },
        },
    ]

    function updateQuantity(name, value, data) {
        const productInfo = find(dataSource, ['sku_id', data.sku_id])
        if (!isEmpty(productInfo) && productInfo[name] !== value) {
            const products = updateCollectionItem(dataSource, data.sku_id, { [name]: value ? Number(value) : 0 }, 'sku_id')
            console.log('products', products)
            setProducts(products)
        }
    }

    // function handleDeleteRow(record) {
    //     const products = [...dataSource]
    //     const lastIndex = findLastIndex(products, ['sku_id', record.sku_id])
    //     products.splice(lastIndex, 1)
    //     setProducts(products)
    // }

    // const tableColumns = columns.map(col => {
    //     if (!col.editable) {
    //         return col;
    //     }

    //     return {
    //         ...col,
    //         onCell: (record, index) => ({
    //             record,
    //             editable: col.editable,
    //             dataIndex: col.dataIndex,
    //             index,
    //             updateQuantity,
    //         }),
    //     };
    // });

    // const EditableCell = ({ editable, children, dataIndex, index, record, updateQuantity, ...restProps }) => {
    //     useEffect(() => {
    //         if (editable) {
    //             form.setFields([{ name: ['package_items', index, dataIndex], value: record[dataIndex] }]);
    //         }
    //     }, [editable]);

    //     let childNode = children;
    //     if (editable) {
    //         form.setFields([{ name: ['package_items', index, 'sku_id'], value: record['sku_id'] }]);
    //         childNode = (
    //             <>
    //                 <Form.Item
    //                     className="mb-0"
    //                     name={['package_items', index, dataIndex]}
    //                     rules={[
    //                         { required: true, message: t('product:message.required') },
    //                         { pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) },
    //                     ]}
    //                 >
    //                     <Input
    //                         className={`_product-list-input-${dataIndex}`}
    //                         onPressEnter={e =>
    //                             updateQuantity(
    //                                 dataIndex,
    //                                 Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '',
    //                                 record
    //                             )
    //                         }
    //                         onBlur={e =>
    //                             updateQuantity(
    //                                 dataIndex,
    //                                 Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '',
    //                                 record
    //                             )
    //                         }
    //                     />
    //                 </Form.Item>
    //                 <Form.Item
    //                     className="mb-0"
    //                     hidden={true}
    //                     name={['package_items', index, "sku_id"]}
    //                 >
    //                     <Input/>
    //                 </Form.Item>
    //             </>
    //         );
    //     }
    //     return <td {...restProps}>{childNode}</td>;
    // };

    function updateQuantityBatch(indexRow, indexBatch, value) {
        const newData = cloneDeep(dataSource)

        newData[indexRow].batch_of_goods[indexBatch] = {
            ...newData[indexRow].batch_of_goods[indexBatch],
            quantity: value ? Number(value) : 0,
        }

        setProducts(newData)
    }

    const onDeleteRowBatch = (rowIndex, index, name) => {
        const newData = cloneDeep(dataSource)
        newData[rowIndex].batch_of_goods.splice(index, 1)

        setProducts([...newData])
    }

    function addBatchRow(rowIndex) {
        const newData = cloneDeep(dataSource)

        newData[rowIndex].batch_of_goods = [
            ...newData[rowIndex].batch_of_goods,
            {
                id: undefined,
                quantity: 1,
            },
        ]
        setProducts([...newData])
    }

    const onChangeBatch = (indexRow, indexBatch, value, option) => {
        const newData = cloneDeep(dataSource)

        newData[indexRow].batch_of_goods[indexBatch] = {
            ...newData[indexRow].batch_of_goods[indexBatch],
            id: value,
            sku_child_id: option.sku_child_id,
        }

        setProducts(newData)
    }

    function handleDeleteRow(record) {
        const newData = [...dataSource]
        const lastIndex = findLastIndex(newData, ['sku_id', record.sku_id])
        newData.splice(lastIndex, 1)
        setProducts(newData)
    }

    return (
        <Table
            // components={{
            //     body: {
            //         cell: EditableCell,
            //     },
            // }}
            className="_product-list"
            rowKey={record => record.sku_id}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 576 }}
            pagination={false}
            locale={{
                emptyText: <p>{t('message.no_data_product')}</p>,
            }}
        />
    )
}
export default ProductList
