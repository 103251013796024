import createUseMutation from '@Modules/App/Hooks/createUseMutation';
import api from "@Modules/Setting/services/api";


const useAddPurchasingAccountMutation = createUseMutation({
    namespace: 'setting:purchasing_account.message.add_account',
    mutation: data => api.addPurchaseAccount(data)
});

export default useAddPurchasingAccountMutation;
