import { notification } from 'antd';

import api from '@Modules/Location/services/api';

import { getVar } from '@System/support/helpers';

import { t } from '../../../system/i18n';
import BaseServiceData from '../../App/services/BaseServiceData';

class ServiceSellerCountryInfo extends BaseServiceData {
    runApi() {
        return api
            .getCountrySellerInfo()
            .then(res => getVar(res, 'data', {}))
            .catch(err => {
                notification.error({
                    message: t('common:message.fetch_fail', { attribute: t('label.seller_country').toLowerCase() }),
                });
                return Promise.reject(err);
            });
    }
}

export default new ServiceSellerCountryInfo();
