import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import useListPurchasingOrderQuery from '@Modules/Order/Hooks/useListPurchasingOrderQuery';

import { getVar } from '@System/support/helpers';

const { Option } = Select;

export default function SelectPurchasingOrder(props) {
    const { id } = props;
    const [orders, setOrders] = useState([]);
    const { data, isFetching, refetch } = useListPurchasingOrderQuery(id);
    useEffect(() => {
        refetch();
    }, [id]);

    useEffect(() => {
        setOrders(getVar(data, 'data.purchasing_orders', []));
    }, [data]);
    return (
        <Select
            dropdownClassName="_purchasing-order-dropdown"
            loading={isFetching}
            {...props}
            value={orders.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {orders.length > 0 &&
                orders.map((order, index) => {
                    const id = getVar(order, 'id', undefined);
                    const code = getVar(order, 'code', '');
                    return (
                        <Option key={index} value={id}>
                            {code}
                        </Option>
                    );
                })}
        </Select>
    );
}
