/* eslint-disable import/no-anonymous-default-export */
import { api } from '@System/api'

export default {
    getProducts: params => api.get('/merchant/products', { params, singleRequest: true, loading: true }),
    createProductByUploadFile: data => api.post('/merchant/products/import-excel', data),
    getProductDetail: productId => api.get(`/merchant/products/${productId}`, { singleRequest: true, loading: false }),
    updateProductDetail: (productId, data) => api.post(`/merchant/products/${productId}`, data),
    mapSkuToVariant: (id, itemId, data) => api.post(`/merchant/purchasing-orders/${id}/items/${itemId}/mapping`, data),
    createProductManual: data => api.post(`/merchant/products`, data),
    getProductHistory: (productId, params) => api.get(`merchant/stock-logs?product_id=${productId}`, { params }),
    getStorageCost: (sku_id, params) => api.get(`merchant/skus/${sku_id}/storage-fee-daily`, { params }),
    exportListProduct: params =>
        api.get('/merchant/products/export', {
            params,
            singleRequest: true,
            loading: false,
            responseType: 'blob',
        }),
    updateSafeStock: (skuId, data) => api.put(`merchant/skus/${skuId}/safety-stock`, data),
    exportProductHistory: params =>
        api.get('merchant/stock-logs/export', {
            params,
            singleRequest: true,
            loading: false,
            responseType: 'blob',
        }),
    updateStatusSellProduct: (skuId, data) => api.put(`merchant/products/${skuId}/stop-sell`, data),
    getServicePack: params => api.get(`merchant/service-pack`, { params }),
    createSkuCombo: data => api.post(`/merchant/sku-combos`, data),
    getSkuComboList: params => api.get(`merchant/sku-combos`, { params, singleRequest: true }),
    getSkuComboDetail: id => api.get(`/merchant/sku-combos/${id}`, { singleRequest: true }),
    updateSkuCombo: (id, data) => api.post(`/merchant/sku-combos/${id}/update`, data),
    updateImageSkuCombo: (id, data) => api.post(`/merchant/sku-combos/${id}/images`, data),
    exportFileReport: params =>
        api.get('/merchant/products/download-stock-io', {
            params,
            singleRequest: true,
            loading: false,
            responseType: 'blob',
        }),
    suggestProductAndCombo: params => api.get(`/merchant/autocomplete/skus-all`, { params, singleRequest: true }),
}
