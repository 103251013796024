import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Table, Tooltip, Typography } from 'antd';
import { filter, find, get, isEmpty, map, sumBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import useListServiceByTypeQuery from '@Modules/Services/Hooks/useListServiceByTypeQuery';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import { auth } from '@Modules/Auth/services';
import api from '@Modules/Services/services/api';
import processResponseError from '@System/api/processResponseError';

const { Text } = Typography;

const ImportServiceInfo = ({ type, onChange, serviceSelected, setServiceSelected }) => {
    const user = auth.user();

    const [loading, setLoading] = useState(false)
    const [serviceList, setServiceList] = useState([]);
    const [currency, setCurrency] = useState({});
    const [checkedList, setCheckedList] = useState([]);
    const [dataSource, setDataSource] = useState([])
    // const { isFetching, data, getListService } = useListServiceByTypeQuery(type);

    const getListService = useCallback(
        () => {
            setLoading(true)
            api.getListService({ type, country_id: user.location_id }).then((res) => {
                const data = res.data
                const services = getVar(data, 'services', []);

                setServiceList(filter(services, item => !isEmpty(item.servicePrices)));
                setCurrency(get(data, 'currency'));
            }).catch((err) => { processResponseError(err) }).finally(() => { setLoading(false) })

        },
        [type, user.location_id],
    )

    useEffect(() => {

        getListService()

    }, [getListService]);

    useEffect(() => {
        const refactor = () => {

            const idSelected = map(
                filter(map(serviceSelected, 'service'), item => item.type === type),
                'id'
            )

            const serviceActive = filter(serviceList, item => item.service.status === 'ACTIVE' || idSelected.includes(item.service.id))

            setDataSource(serviceActive);
        }
        refactor()

    }, [serviceList, serviceSelected, type])

    useEffect(() => {
        setCheckedList(
            map(
                filter(map(serviceSelected, 'service'), item => item.type === type),
                'id'
            )
        );
    }, [serviceList, serviceSelected, type]);

    let columns = [
        {
            className: '_dropshipping-list-service-name text-wrap',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            width: '50%',
            render: (text, record) => (text ? text : ''),
        },
        {
            className: '_dropshipping-list-estimated_costs text-left',
            title: (
                <p className="flex align-items-center">
                    {t('product:label.estimated_costs')}
                    <Tooltip title={t('message.cost_depends_on_size_service_used')}>
                        <QuestionCircleOutlined className="ml-1 cursor-pointer" />
                    </Tooltip>
                </p>
            ),
            dataIndex: ['service'],
            key: 'prices',
            width: '50%',
            render: (text, record) => {
                const defaultPrice = find(record.servicePrices, ['is_default', true]);
                let res = filter(serviceSelected, item => item.service.id === text.id);
                return (
                    <p>
                        {res
                            ? !isEmpty(currency)
                                ? formatCurrency(
                                    !isEmpty(get(res, '0.service_prices')) ? get(res, '0.service_prices.0.price') : defaultPrice.price,
                                    currency
                                )
                                : ''
                            : '---'}
                    </p>
                );
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys: checkedList,

        onChange: (selectedRowKeys, selectedRows) => {
            setCheckedList(selectedRowKeys);
            const listId = map(serviceList, 'service.id');
            const otherServiceId = map(
                filter(serviceSelected, item => !listId.includes(item.service.id)),
                'service.id'
            );
            getListService();
            onChange('services', [...otherServiceId, ...selectedRowKeys]);
        },

        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required');
            const id = get(record, 'service.id');

            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked={disabledRow} disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record, id)} />
                    )}
                </>
            );
        },
    };

    const handleChangeCheckbox = (checked, selectedRows, id) => {
        const listId = map(serviceSelected, 'service.id');

        if (checked) {
            const newListId = listId.filter(item => item !== id);
            onChange('services', newListId);

        } else {
            onChange('services', [...listId, id]);

        }
        getListService();
    };

    return (
        <>
            <Table
                className="product-list-edit-table pb-3"
                dataSource={dataSource}
                rowClassName="_dropshipping-product-row"
                rowKey={record => record?.service?.id}
                columns={columns}
                loading={loading}
                pagination={false}
                rowSelection={{
                    ...rowSelection,
                }}
                summary={pageData => {
                    const serviceByType = filter(serviceSelected, item => item.service.type === type);
                    const perPrice = map(serviceByType, 'service_prices').flat();
                    const sumPrice = sumBy(perPrice, 'price');

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">{t('label.estimated_sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">
                                <Text>{!isEmpty(currency) ? formatCurrency(sumPrice, currency ? currency : null) : '---'}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className="_dropshipping-list-empty" /> }}
            />
        </>
    );
};

export default ImportServiceInfo;