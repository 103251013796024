import { ArrowLeftOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, Row, Tabs } from 'antd'
import { createBrowserHistory } from 'history'
import { filter, get, isEmpty, map } from 'lodash'
import has from 'lodash/has'
import omit from 'lodash/omit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import useGeneratorKey, { IdempotencyStorage } from '@Modules/Order/Hooks/useGeneratorKey'
import OrderInfo from '@Modules/Order/components/Create/OrderInfo'
import PaymentInfo from '@Modules/Order/components/Create/PaymentInfo'
import ProductInfo from '@Modules/Order/components/Create/ProductInfo'
import ReceivedInfo from '@Modules/Order/components/Create/ReceivedInfo'
import { renderError } from '@Modules/Order/components/Create/errors'
import LeavePageBlocker from '@Modules/Order/components/LeavePageBlocker'
import api from '@Modules/Order/services/api'
import { INIT_ORDER, VALIDATED_RULES } from '@Modules/Order/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { getVar, randomString } from '@System/support/helpers'

import useValidatedMessages from '../../../../system/hooks/useValidatedMessages'
import { Link, url } from '../../../../system/routing'
import { setDocumentTitle, setNavigator } from '../../../App/services'
import { catchErrors } from '../../../App/services/helpers'

const { TabPane } = Tabs

function CreateOrder() {
    const { uniqueKey, generatorKey } = useGeneratorKey()

    const [form] = Form.useForm()
    const validateMessages = useValidatedMessages()
    const [loading, setLoading] = useState(false)
    const [orderSkus, setOrderSkus] = useState([])
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        setNavigator(t('title.create-order'), [
            { name: t('breadcrumb.orders'), route: 'orders.list' },
            { name: t('breadcrumb.create-order') },
        ])
        setDocumentTitle(t('title.create-order'))
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            code: randomString(16),
        })
    }, [])

    function _onSubmit() {
        form.validateFields().then(values => {
            setLoading(true)
            let data = { ...values }
            const newOrderSkus = filter(
                orderSkus.map(item => (item.tax === null ? omit(item, ['tax']) : item)),
                item => isEmpty(getVar(item, 'skus.data', []))
            )

            const orderSkuCombos = map(
                filter(
                    orderSkus.map(item => (item.tax === null ? omit(item, ['tax']) : item)),
                    item => !isEmpty(getVar(item, 'skus.data', []))
                ),
                item => ({ ...item, id: item?.sku_id })
            )

            const formData = { ...data, orderSkus: newOrderSkus, orderSkuCombos }

            handleCheckingBeforeCreateOrder(formData)
        })
    }
    function createOrder(data) {
        setDirty(true)
        setLoading(true)
        api.createOrder({ ...data })
            .then(res => {
                notification.success(t('message.create_success', { attribute: t('order:label.order') }))
                let order = getVar(res, 'data.order', {})
                setDirty(false)
                url.redirectTo('orders.detail', { id: order.id })
            })
            .catch(res => {
                const code = get(res, 'response.data.code')
                if (code === 'REQUEST_PROCESSED') {
                    notification.warning(t('message.request_process'))
                } else {
                    generatorKey()
                    setDirty(false)
                    catchErrors(t('common:message.create_failed', { attribute: t('common:label.order_detail') }), showErrors)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function renderMessageWarning(warningOrders) {
        const warningByKeys = {}
        warningOrders.map(data => {
            const warnings = getVar(data, 'warnings', [])
            const sku_code = getVar(data, 'sku_code', '')
            warnings.map(item => {
                if (!has(warningByKeys, item.message)) {
                    warningByKeys[item.message] = []
                }
                warningByKeys[item.message].push(sku_code)
            })
        })
        return Object.keys(warningByKeys)
            .map(item => t(`order:message.upload_file.${item}`, { sku_code: warningByKeys[item].join(', ') }))
            .join(', ')
    }

    function handleCheckingBeforeCreateOrder(data) {
        api.checkingBeforeCreateOrder(data)
            .then(res => {
                const warningOrders = getVar(res, 'data.warning_orders', [])
                const errors = getVar(res, 'data.errors', [])
                if (errors.length > 0) {
                    showErrors(errors)
                    setLoading(false)
                } else {
                    if (warningOrders.length > 0) {
                        Modal.confirm({
                            title: t('order:title.warning_lack_of_stock'),
                            icon: <ExclamationCircleOutlined />,
                            okText: t('btn.ok'),
                            cancelText: t('btn.cancel'),
                            content: t('order:message.warning_create_order', {
                                content: renderMessageWarning(warningOrders),
                            }),
                            onCancel() {
                                setLoading(false)
                            },
                            onOk() {
                                createOrder(data)
                            },
                        })
                    } else {
                        createOrder(data)
                    }
                }
            })
            .catch(err => {
                // renderError(err)
                renderError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // Hiển thị lỗi với từng field tương ứng
    function showErrors(errors) {
        let error_messages = []

        Object.keys(errors).forEach(item => {
            let error = errors[item]

            if (item !== 'sku_errors') {
                for (let prop in error) {
                    error_messages.push({
                        name: prop,
                        errors: [t(`order:message.${error[prop]}`, { attribute: t(`order:label.${prop}`) })],
                    })
                }
            }

            // Hiển thị từng dòng sản phẩm nếu bị lỗi
            if (item === 'sku_errors') {
                let error_lines = []
                for (let prop in error) {
                    let line = prop.replace(/^\D+/g, '')
                    let errObj = error[prop]
                    for (let opt in errObj) {
                        errObj[opt].forEach(key => {
                            error_lines.push(
                                t('order:message.line', {
                                    line,
                                    content: t(`order:message.${opt}`, { attribute: t(`order:label.${key}`) }),
                                })
                            )
                        })
                    }
                }
                error_messages.push({ name: 'product_quantity', errors: error_lines })
            }
        })

        form.setFields(error_messages)
    }

    return (
        <>
            {dirty && <LeavePageBlocker when={dirty} />}
            <div className="site-content bg-gray create-order">
                <Form
                    initialValues={INIT_ORDER}
                    className={'form'}
                    validateMessages={validateMessages}
                    form={form}
                    layout="vertical"
                >
                    <div className="title-page pb-1 d-flex justify-content-between align-items-center">
                        <h3>
                            <Link
                                to={'orders.list'}
                                className="_order-list-back"
                            >
                                <ArrowLeftOutlined /> {t('btn.back')}
                            </Link>
                        </h3>
                        <Button
                            type="primary"
                            onClick={_onSubmit}
                            loading={loading}
                            className="_btn-create-order"
                        >
                            <PlusOutlined /> {t('order:btn.create_order')}
                        </Button>
                    </div>

                    <Row
                        gutter={20}
                        className="mt-3 mb-3"
                    >
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 17 }}
                        >
                            <OrderInfo
                                validatedRules={VALIDATED_RULES}
                                form={form}
                            />
                            <Tabs
                                className="heading-fz-14 customize-order-ant-tabs mt-3 _create-order-tab"
                                defaultActiveKey={1}
                                type="card"
                            >
                                <TabPane
                                    tab={t('order:title.order_info')}
                                    key="1"
                                    className="_create-order-tab-product-info bg-white rounded-12"
                                >
                                    <ProductInfo
                                        orderSkus={orderSkus}
                                        setOrderSkus={setOrderSkus}
                                        validatedRules={VALIDATED_RULES}
                                        form={form}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 7 }}
                        >
                            <ReceivedInfo
                                validatedRules={VALIDATED_RULES}
                                form={form}
                            />
                            <PaymentInfo
                                validatedRules={VALIDATED_RULES}
                                form={form}
                            />
                        </Col>
                    </Row>
                    <div className="text-right">
                        <Button
                            type="primary"
                            onClick={_onSubmit}
                            loading={loading}
                            className="_btn-create-order"
                        >
                            <PlusOutlined /> {t('order:btn.create_order')}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}
export default CreateOrder
