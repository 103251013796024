import i18next from 'i18next'

import { t } from '@System/i18n'

export const ORDER_EVENT = {
    RELOAD_ORDER_LIST: 'RELOAD_ORDER_LIST',
    RELOAD_ORDER_DETAIL: 'RELOAD_ORDER_DETAIL',
}

export const ORDER_STATUS = {
    WAITING_INSPECTION: 'WAITING_INSPECTION',
    WAITING_CONFIRM: 'WAITING_CONFIRM',
    WAITING_PROCESSING: 'WAITING_PROCESSING',
    WAITING_PICKING: 'WAITING_PICKING',
    WAITING_PACKING: 'WAITING_PACKING',
    WAITING_DELIVERY: 'WAITING_DELIVERY',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    FINISH: 'FINISH',
    CANCELED: 'CANCELED',
    RETURN: 'RETURN',
    RETURN_COMPLETED: 'RETURN_COMPLETED',
    FAILED_DELIVERY: 'FAILED_DELIVERY',
}

export const ORDER_STATUS_COLORS = {
    WAITING_INSPECTION: '#ff4559',
    WAITING_CONFIRM: '#ff4559',
    WAITING_PROCESSING: '#ed513d',
    WAITING_PICKING: '#ed6e52',
    WAITING_PACKING: '#ffa634',
    WAITING_DELIVERY: '#ffa634',
    DELIVERING: '#008df9',
    DELIVERED: '#09b8ad',
    FINISH: '#06d087',
    CANCELED: '#bdbdbd',
    RETURN: '#bd0313',
    RETURN_COMPLETED: '#06d087',
    FAILED_DELIVERY: '#ffa634',
}

export const PAYMENT_TYPE = {
    PAYMENT_TYPE_ADVANCE_PAYMENT: 'ADVANCE_PAYMENT', // Thanh toán trước
    PAYMENT_TYPE_COD: 'COD', // COD
    PAYMENT_TYPE_DEFERRED_PAYMENT: 'DEFERRED_PAYMENT', // Thanh toán sau
}

export const PAYMENT_METHOD = {
    METHOD_CASH: 'CASH', // Tiền mặt
    METHOD_BANK_TRANSFER: 'BANK_TRANSFER', // Chuyển khoản
}

export const SAMPLE_IMPORT_CREATE_ORDER_FILE = [
    {
        key: 'default',
        name: 'default',
        urlPath: `/sample_file/${i18next.language}/sample_import_create_order_default_file.xlsx`,
    },
]

export const SAMPLE_IMPORT_CREATE_ORDER_DROPSHIPPING_FILE = [
    {
        key: 'default',
        name: 'default',
        urlPath: `/sample_file/${i18next.language}/sample_import_create_order_dropshipping_default_file.xlsx`,
    },
]

export const ORDER_LOG_KEYS = {
    ORDER_CREATE: 'ORDER.CREATE',
    ORDER_UPDATE: 'ORDER.UPDATE',
    ORDER_CHANGE_STATUS: 'ORDER.CHANGE_STATUS',
    ORDER_INSPECTION: 'ORDER.INSPECTION',
    ORDER_CREATE_PACKAGE: 'ORDER.CREATE_PACKAGE',
    ORDER_PAYMENT_CONFIRM: 'ORDER.PAYMENT_CONFIRM',
    ORDER_CANCEL: 'ORDER.CANCEL',
    ORDER_CHANGE_FREIGHT_BILL: 'ORDER.CHANGE_FREIGHT_BILL',
    ORDER_CHANGE_PACKAGE_STATUS: 'ORDER.CHANGE_PACKAGE_STATUS',
    ORDER_UPDATE_SKUS: 'ORDER.UPDATE_SKUS',
    CHANGE_SHIPPING_PARTNER: 'ORDER.CHANGE_SHIPPING_PARTNER',
    ORDER_UPDATE_ATTRIBUTES: 'ORDER.UPDATE_ATTRIBUTES',
    ORDER_REMOVE_SKUS: 'ORDER.REMOVE_SKUS',
    ORDER_ADD_SKUS: 'ORDER.ADD_SKUS',
    ORDER_UPDATE_BATCH: "ORDER.UPDATE_BATCH",
    ORDER_COMPLETE_BATCH: "ORDER.COMPLETE_BATCH"
}

export const INIT_ORDER = {
    code: '',
    order_amount: '', // Tiền hàng
    discount_amount: 0, // Số tiền chiết khấu
    shipping_amount: 0, // Phí vận chuyển
    total_amount: '', // Tổng tiền khách phải trả
    debit_amount: '', // Số tiền khách còn thiếu
    description: '', // Mô tả thêm cho đơn hàng
    receiver_name: '', //
    receiver_phone: '',
    receiver_country_id: undefined,
    receiver_province_id: undefined,
    receiver_district_id: undefined,
    receiver_ward_id: undefined,
    receiver_address: '',
    keyword: '',
    product_quantity: '',
    payment_type: PAYMENT_TYPE.PAYMENT_TYPE_COD,
}

export const VALIDATED_RULES = {
    code: [
        { max: 255, type: 'string' },
        {
            required: true,
            message: t('validation:required', {
                attribute: t('order:label.code'),
            }),
        },
    ],
    receiver_name: [{ required: true, max: 255, type: 'string' }],
    receiver_phone: [{ required: true, max: 255, type: 'string' }],
    receiver_address: [
        {
            required: true,
            message: t('validation:required', {
                attribute: t('order:label.receiver_address'),
            }),
        },
        { max: 255, type: 'string' },
    ],
    receiver_country_id: [{ required: true, type: 'number' }],
    product_quantity: [{ required: true, message: t('order:message.no_products') }],
    total_amount: [
        {
            required: true,
            message: t('validation:required', {
                attribute: t('order:label.total_amount'),
            }),
        },
    ],
    warehouse_id: [{ required: true, type: 'number' }],
}

export const INIT_PRODUCT_ORDER = [
    {
        code: '',
        name: '',
        price: '',
        quantity: '',
        id: '',
        image: '',
        product_id: '',
    },
]

export const ORDER_DETAIL_KEYS = [
    'code',
    'order_amount',
    'discount_amount',
    'shipping_amount',
    'total_amount',
    'debit_amount',
    'description',
    'receiver_name',
    'receiver_phone',
    'receiver_address',
    'shipping_partner_id',
    'warehouse_id',
    'freight_bill',
    'inspected',
]

export const EDIT_STATUS = [ORDER_STATUS.WAITING_PROCESSING, ORDER_STATUS.WAITING_PICKING]

export const DOCUMENT_STATUS = {
    IMPORTING_RETURN_GOODS: 'IMPORTING_RETURN_GOODS',
}
export const CURRENCY_DEFAULT = {
    code: 'NONE',
    decimal_separator: '.',
    format: '{amount}',
    precision: 2,
    thousands_separator: ',',
}
export const FINANCE_SHIPPING_STATUS = {
    INIT: 'INIT',
    WAITING_COLLECT: 'WAITING_COLLECT',
    RECONCILIATION: 'RECONCILIATION',
    COLLECTED: 'COLLECTED',
}