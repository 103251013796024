import { Col, Row, Table } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import BarcodeWidget from '@Modules/App/Utilities/BarcodeWidget';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const PackingSlipStamp = forwardRef(({ data }, ref) => {
    const stampSize = {
        width: 220,
    };
    const columns = [
        {
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'code'],
            render: text => {
                return (
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: t('product:label.name'),
            dataIndex: ['sku', 'name'],
        },
        {
            className: 'text-right text-nowrap',
            title: t('product:label.declare_quantity'),
            dataIndex: 'quantity',
        },
    ];
    const freightBillCode = getVar(data, 'purchasing_package.freight_bill_code', '');
    const receive_warehouse_name = getVar(data, 'destination_warehouse.name', '');
    const receive_warehouse_address = getVar(data, 'destination_warehouse.address', '');
    const weight = getVar(data, 'purchasing_package.weight', '');
    const sellerName = getVar(data, 'merchant.name', '');
    const sellerCode = getVar(data, 'merchant.code', '');
    const products = getVar(data, 'purchasing_package_items', []);
    const importPackageCode = getVar(data, 'purchasing_package.code', '');

    return (
        <div className="A4" ref={ref}>
            <div className={clsx('sheet stamp _packing-slip-stamp page-break p-4')} key={data?.id}>
                <div className="shipping-info">
                    <div className=" text-center">
                        <p className="mb-0">
                            <b>{t('label.packing_slip').toUpperCase()}</b>
                        </p>
                    </div>

                    <div className="pt-3 pb-3 text-center">
                        {importPackageCode && (
                            <BarcodeWidget value={importPackageCode} height={60} width={stampSize.width} fontSize={16} />
                        )}
                    </div>

                    <div className="pb-2">
                        <Row gutter={[0, 16]}>
                            <Col span={8}>
                                <strong>{t('warehouse:label.receive_warehouse_address')}:</strong>
                            </Col>
                            <Col span={16}>
                                <p className="mb-0">{receive_warehouse_name}</p>
                                <p className="mb-0">{receive_warehouse_address}</p>
                            </Col>

                            <Col span={8}>
                                <strong>{t('order:label.freight_bill_code')}:</strong>
                            </Col>
                            <Col span={16}>
                                <p>{freightBillCode || t('label.no_text')}</p>
                            </Col>

                            <Col span={8}>
                                <strong>{t('product:label.weight')}:</strong>
                            </Col>
                            <Col span={16}>
                                <p>{`${weight ? `${weight * 1000} (g)` : t('label.no_text')}`}</p>
                            </Col>

                            <Col span={8}>
                                <strong>Seller:</strong>
                            </Col>
                            <Col span={16}>{(sellerName ? sellerName : '') + (sellerCode ? ' - ' + sellerCode : '')}</Col>
                        </Row>
                    </div>
                </div>
                <div className="pt-3">
                    <div className="mb-3 text-center title">
                        <h3>{`${t('heading.product_list').toUpperCase()} `}</h3>
                    </div>
                    <div className="mb-3">
                        <div>{`${t('label.total_product')}: ${products ? products.length : 0}`}</div>
                    </div>
                    <Table
                        bordered
                        className="packing-slip-table"
                        rowKey={record => record.sku_id}
                        dataSource={products}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: <p>{t('message.no_data_product')}</p>,
                        }}
                    />
                </div>
            </div>
        </div>
    );
});

PackingSlipStamp.propTypes = {
    data: PropTypes.object,
};

PackingSlipStamp.defaultProps = {
    data: {},
};

export default PackingSlipStamp;
