import { CaretDownOutlined } from '@ant-design/icons';
import { Table, Avatar, Badge, Tooltip, Modal } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import React from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import { dateFormatter } from '@Modules/App/services';
import { FINANCE_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';
import ExportListOrder from '@Modules/Order/components/ExportFile/ExportListOrder';
import OrderCreateMenu from '@Modules/Order/components/OrderCreateMenu';
import { ORDER_STATUS_COLORS, PAYMENT_TYPE } from '@Modules/Order/services/constants';
import { SHIPPING_PARTNER_INFO } from '@Modules/ShippingPartner/services/constants';

import { t, trans } from '@System/i18n';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import { sumBy, uniqBy } from 'lodash';
import ModalListSku from '@Modules/Order/components/List/ModalListSku';
import DynamicTable from '@Modules/App/DynamicTable';


export default function List({ orders, pagination, addFilter, filters, loading, sumSku }) {

    const openModalListSku = (dataList, currency) => {
        Modal.confirm({
            title: t('title.product_list'),
            icon: false,
            closable: true,
            width: 700,
            content: <ModalListSku dataList={dataList} currency={currency} />,
            okButtonProps: { style: { display: 'none' } },
            cancelText: t('btn.close'),
        });
    };


    const getColumns = [
        {
            className: '_orders-info',
            title: t('order:label.order'),
            dataIndex: ['order', 'code'],
            key: 'order',
            disabled: true,
            render: (text, record) => {
                const orderCurrency = getVar(record, 'currency', null);
                const order_skus = getVar(record, 'order_skus', []);
                const image = getVar(record, 'images.0', '');
                const count = sumBy(order_skus, 'quantity')

{/* <span className="combo-tag ml-1">{t('label.combo')}</span> */}
                return (
                    <div className="d-flex align-items-center">
                        <Avatar src={image} shape="square" className="mr-2" size={36} />
                        <div>
                            <span>
                                <Link params={{ id: get(record, 'order.id') }} to={'orders.detail'}>
                                    <strong style={{ fontWeight: 500 }} className="text-link">{`#${text}`}</strong>
                                </Link>
                            </span>
                            <p className='cursor-pointer text-quantity' onClick={() => openModalListSku(order_skus, orderCurrency)}>{t('label.has_product', { count })}</p>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_orders-store-name',
            title: t('setting:sales_channel.label.store'),
            dataIndex: ['order', 'name_store'],
        },
        {
            className: '_orders-customer',
            title: t('order:label.customer'),
            dataIndex: ['order', 'receiver_name'],
            key: 'customer',
            render: text => {
                return (
                    <strong style={{ fontWeight: 500 }} className="text-link">
                        {text}
                    </strong>
                );
            },
        },
        {
            className: '_orders-total-amount text-right',
            title: t('order:label.total_price'),
            dataIndex: ['order', 'order_amount'],
            key: 'order_amount',
            render: (text, record) => {
                const orderCurrency = getVar(record, 'currency', null);
                return (
                    <strong style={{ fontWeight: 500 }} className="text-link">
                        {formatCurrency(text || 0, orderCurrency)}
                    </strong>
                );
            },
        },
        {
            className: '_orders-payment text-right',
            title: t('order:label.payment'),
            dataIndex: ['order', 'payment_type'],
            key: 'payment_type',
            render: (text, record) => {
                const orderCurrency = getVar(record, 'currency', null);
                const cod = isEmpty(orderCurrency)
                    ? getVar(record, 'order.cod', 0)
                    : formatCurrency(getVar(record, 'order.cod', 0), orderCurrency);
                return (
                    <div>
                        <span>{values(PAYMENT_TYPE).includes(text) ? t(`order:payment_type.${text}`) : ''}</span>
                        <p>{cod}</p>
                    </div>
                );
            },
        },
        {
            className: '_orders-freight-bill',
            title: t('order:label.freight_bill'),
            dataIndex: ['order_freight_bills', 'freight_bill_code'],
            key: 'freight_bill_code',
            render: (text, record) => {
                const order_freight_bills = get(record, 'order_freight_bills', []);
                const freight_bill = get(record, 'order.freight_bill', '');
                const shippingPartnerName = getVar(record, 'shipping_partner.name', '');
                const shippingPartnerCode = getVar(record, 'shipping_partner.code', '');
                const shipping_partner = get(SHIPPING_PARTNER_INFO, shippingPartnerCode, {});
                return (
                    <>
                        {!isEmpty(order_freight_bills) ? (
                            order_freight_bills.map((item, index) => {
                                const code = get(item, 'shipping_partner_code', '');
                                const name = get(item, 'shipping_partner_name', '');
                                const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {});
                                return (
                                    <div className="d-flex align-items-center" key={index}>
                                        <Tooltip title={name}>
                                            <Avatar
                                                style={{ backgroundColor: `${get(shipping_partner_info, 'color', '')}` }}
                                                className="flex-shrink-0 mr-1 "
                                                size={20}
                                            >
                                                {get(shipping_partner_info, 'wildcard', '')}
                                            </Avatar>
                                        </Tooltip>{' '}
                                        <span key={index}>{getVar(item, 'freight_bill_code')}</span>
                                    </div>
                                );
                            })
                            // ) : freight_bill ? (
                            //     <div className="d-flex align-items-center">
                            //         <Tooltip title={shippingPartnerName}>
                            //             <Avatar
                            //                 style={{ backgroundColor: `${get(shipping_partner, 'color', '')}` }}
                            //                 className="flex-shrink-0 mr-1 "
                            //                 size={20}
                            //             >
                            //                 {get(shipping_partner, 'wildcard', '')}
                            //             </Avatar>
                            //         </Tooltip>{' '}
                            //         <span>{freight_bill}</span>
                            //     </div>
                        ) : (
                            <i>{t('label.not_available')}</i>
                        )}
                    </>
                );
            },
        },
        {
            className: '_orders-sku-info text-wrap',
            title: 'SKU',
            dataIndex: 'order_skus',
            key: 'order_skus',
            render: (text, record) => {
                const length = uniqBy(text, 'code').length;

                const isCombo = get(record, 'order_sku_combo', []).length

                return <div className='d-flex flex-column justify-content-center'>
                    {isCombo ? <span className="combo-tag mb-1" style={{maxWidth: 80}}>{t('label.combo')}</span> : null}
                    <div className='d-flex'> { uniqBy(text, 'code').map((item, index) => {
                        return isEmpty(item) ? (
                            ''
                        ) : ( 
                                <Tooltip key={index} title={item?.name}>
                                    {`${item?.code}${length - index > 1 ? ', ' : ''} `}
                                </Tooltip>
                        );
                    })}</div> 
                </div>
                
               
            },
        },
        {
            className: '_orders-created-at',
            title: t('label.created_at'),
            dataIndex: ['order', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_orders-status',
            title: t('label.order_status'),
            dataIndex: ['order', 'status'],
            key: 'order.status',
            render: text => (text ? <Badge color={ORDER_STATUS_COLORS[text]} text={t(`order:status.${text}`)} /> : null),
        },

        {
            className: '_orders-finance-status',
            title: t('label.finance_status'),
            dataIndex: ['order', 'finance_status'],
            key: 'order.finance_status',
            render: text =>
                text ? <Badge color={FINANCE_STATUS_COLORS[text]} text={t(`product:FINANCE_STATUS.${text}`)} /> : null,
        },
    ];
    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className="text-fz-18">
                            {t('order:title.order_list')}  <span className="_importing-document-product-list-total ml-1">
                                ({
                                    trans('order:label.sum_order_and_sku', { order: <b>{getVar(pagination, 'total', 0)}</b>, sku: <b>{sumSku}</b> })
                                })
                            </span>
                        </h3>

                    </div>
                    <div className='d-flex align-items-center flex-wrap justify-content-end'>
                        <ExportListOrder filters={filters} />
                        
                    </div>
                </div>
                <div className="data-box--body">
                <DynamicTable
                    initColumns={getColumns}
                    dataSource={orders}
                    rowKey={record => record?.order.id}
                    className="order-list-table pb-3"
                    loading={loading}
                    locale={{
                        emptyText: (
                            <EmptyTableData
                                loading={loading}
                                message={'no_data_order'}
                                action={
                                    <OrderCreateMenu>
                                        <strong className="cursor-pointer pagination-customize">
                                            {t('order:btn.import_order')}
                                            <CaretDownOutlined className="ml-1" />
                                        </strong>
                                    </OrderCreateMenu>
                                }
                            />
                        ),
                    }}
                    scroll={{ x: true }}
                />
                    {/* <Table
                        className="order-list-table pb-3"
                        dataSource={orders}
                        rowKey={record => record?.order.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_order'}
                                    action={
                                        <OrderCreateMenu>
                                            <strong className="cursor-pointer pagination-customize">
                                                {t('order:btn.import_order')}
                                                <CaretDownOutlined className="ml-1" />
                                            </strong>
                                        </OrderCreateMenu>
                                    }
                                />
                            ),
                        }}
                        scroll={{ x: true }}
                    /> */}
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
