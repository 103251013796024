import { Input } from 'antd';
import React, { useState, useEffect } from 'react';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function CustomerInfo({ orderInfo }) {
    const { order } = orderInfo;
    const [receiverAddress, setReceiverAddress] = useState();
    const receiver_name = getVar(order, 'receiver_name', '');
    const receiver_phone = getVar(order, 'receiver_phone', '');
    const note = getVar(order, 'description', '');

    useEffect(() => {
        const receiver_address = getVar(order, 'receiver_address', '');
        const countryName = getVar(orderInfo, 'receiver_country.label', '');
        const provinceName = getVar(orderInfo, 'receiver_province.label', '');
        const districtDame = getVar(orderInfo, 'receiver_district.label', '');
        const wardName = getVar(orderInfo, 'receiver_ward.label', '');

        setReceiverAddress(
            `${receiver_address || t('label.no_text')}${wardName ? ', ' + wardName : ''}${
                districtDame ? ', ' + districtDame : ''
            }${provinceName ? ', ' + provinceName : ''}${countryName ? ', ' + countryName : ''}`
        );
    }, [orderInfo]);

    return (
        <>
            <div>
                <h3 className="text-fz-16">{t('order:label.customer')}</h3>
                <div className="mt-2 _customer-info-name">
                    <p>
                        <strong style={{ fontWeight: 400 }}>{receiver_name}</strong>
                    </p>
                </div>

                <div className="mt-2 _customer-info-phone">
                    <p>
                        <strong style={{ fontWeight: 400 }}>
                            <a href={`tel:${receiver_phone}`}>{receiver_phone}</a>
                        </strong>
                    </p>
                </div>

                <div className="mt-2 _customer-info-address">
                    <p>
                        <strong style={{ fontWeight: 400 }}>{receiverAddress}</strong>
                    </p>
                </div>
            </div>

            <div className="mt-4 _customer-info-note">
                <h3 className="text-fz-16">{t('label.note')}</h3>
                <Input.TextArea value={note} disabled/>
            </div>
        </>
    );
}
export default CustomerInfo;
