import { message } from 'antd';
import lodash from 'lodash';

import { getVar } from '@System/support/helpers';

import { HTTP_STATUS } from '../../../system/api/constants';
import { t } from '../../../system/i18n';
import { auth, api } from '../services';

let loadedUser = false;

// eslint-disable-next-line import/no-anonymous-default-export
export default (payload, next) => {
    if (!auth.isLogged() || auth.user()) {
        return next(payload);
    }

    // Chỉ hiển thị loading khi bắt đầu vào page
    let hideLoading = !loadedUser ? message.loading('Loading...', 0) : () => { };
    loadedUser = true;

    let res = api.user().finally(() => hideLoading());
  

    res.then(async res => {
        let balance = 0;
        let warning_out_money = false;

        let currency = {};
        await api.balance().then(res_balance => {
            balance = getVar(res_balance, 'data.account.balance', 0);
            currency = getVar(res_balance, 'data.currency', {});
            warning_out_money = getVar(res_balance, 'data.merchant.warning_out_money.status', false)
        }).catch(() => {
            balance = 0;
        });
        const user = getVar(res, 'data.user', {});
        const location_id = getVar(res, 'data.merchant.location_id', undefined);
        const merchant_code = getVar(res, 'data.merchant.code', undefined);
        const allowed_modules = getVar(res, 'data.allowed_modules',[])
        auth.setUser({ ...res.data, user: { ...user, balance, currency, location_id, merchant_code, warning_out_money, allowed_modules} });
        next(payload);
    });

    res.catch(({ response }) => {
        if (lodash.get(response, 'status') === HTTP_STATUS.UNAUTHENTICATED) {
            message.error(t('auth:message.token_expired'), 4);
            auth.login();
        }
    });
};
