import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import api from '../services/api';

const useListProductQuery = (params, activeCall = true) => {
    return useQuery(['ListProductQuery', params], () => api.getProducts(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
        enabled: activeCall,
    });
};

export default useListProductQuery;
