import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

import OptionItem from './OptionItem';

const { Title } = Typography;

const ProductOptions = ({ localOptions, setLocalOptions, form }) => {
    function updateOptions(index, newOption) {
        let updatedOptions = localOptions.map((option, nth) => {
            return index === nth ? newOption : option;
        });

        setLocalOptions(updatedOptions);
        form.setFields([{ name: 'options', errors: [] }]);
    }

    function removeOption(index) {
        let newOptions = localOptions.filter((_, nth) => index !== nth);
        setLocalOptions(newOptions);
    }
    return (
        <div className="product-options mt-4 _product-option">
            <div>
                {localOptions.length > 0 &&
                    localOptions.map((option, index) => (
                        <OptionItem
                            key={index}
                            index={index}
                            option={option}
                            updateOptions={updateOptions}
                            removeOption={removeOption}
                            form={form}
                        />
                    ))}
                {localOptions.length === 0 && <p className="text-center mt-4 mb-5">{t('product:message.not_options')}</p>}
                <Form.Item
                    className="hidden_input _product-option-input-options"
                    label={null}
                    name="options"
                    // rules={[{ required: true, message: t('product:message.required') }]}
                />
            </div>
        </div>
    );
};

export default ProductOptions;
