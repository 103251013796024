import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Spin } from 'antd'
import { get, isArray, isEmpty, map } from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import ChildSkuList from '@Modules/Product/components/CreateCombo/ChildSkuList'
import ComboInfo from '@Modules/Product/components/CreateCombo/ComboInfo'
import ProductImages from '@Modules/Product/components/Edit/ProductImages'
import api from '@Modules/Product/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'

interface IEditComboProps {
    match: any
}

const EditCombo: React.FC<IEditComboProps> = ({ match }) => {
    const [form] = Form.useForm()
    const { id } = match.params
    const [detail, setDetail] = useState({})
    const [skus, setSkus] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<any[]>([])

    useEffect(() => {
        setNavigator(t('title.product_edit'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.product_edit'),
            },
        ])
        setDocumentTitle(t('title.product_edit'))
    }, [])

    const getDetail = useCallback(() => {
        setLoading(true)
        api.getSkuComboDetail(id)
            .then(res => {
                setDetail(get(res, 'data.data', {}))
                setSkus(processData(get(res, 'data.data.skus.data', [])))
                setFiles(get(res, 'data.data.image', []))
            })
            .catch(error => {
                processResponseError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id])

    useEffect(() => {
        getDetail()
    }, [])

    const processData = (data: any) => {
        const newData = map(data, item => {
            return {
                sku: {
                    ...get(item, 'sku.data', {}),
                },
                quantity:get(item,'quantity'),
                warehouse_stocks: map(get(item, 'sku.data.warehouse_stocks.data'), item => ({
                    ...item,
                    name: get(item, 'warehouse.data.name', ''),
                })),
            }
        })

        return newData
    }

    const handleUpdateCombo = () => {
        if (isEmpty(skus)) {
            return notification.error(t('product:message.sku_child_required'))
        }

        form.validateFields().then(values => {
            let formData = new FormData()

            Object.entries(values).forEach(([key, value]: any) => {
                if (value) {
                    formData.append(key, value)
                }
            })

            formData.delete('files')
            formData.delete('skus')

            if (form.getFieldValue('files')) {
                for (let i of form.getFieldValue('files')) {
                    formData.append('files[]', i)
                }
            }

            if (form.getFieldValue('skus')) {
                formData.append('skus', JSON.stringify(form.getFieldValue('skus')))
            }

            setLoading(true)

            api.updateSkuCombo(id, formData)
                .then(res => {
                    notification.success(t('message.update_success_by_attribute', { attribute: t('product:label.product') }))
                    getDetail()
                })
                .catch(err => {
                    const { response } = err
                    const code = get(response, 'data.data.code', '')
                    const serviceGroupMissing = get(response, 'data.data.service_groups', '')

                    if (Object.keys(code)[0] === 'already_exist') {
                        notification.error(t('errorMessages.already_exist', { attribute: t('product:label.code') }))
                    } else if (serviceGroupMissing) {
                        notification.error(t('order:message.service_group_missing'))
                    } else {
                        notification.error(t('message.create_failed', { attribute: t('product:label.product') }))
                    }
                })
                .finally(() => setLoading(false))
        })
    }

    const handleUpdateImage = (name: any, value: any) => {
        let formData = new FormData()
        let status = ''
        if (isArray(value)) {
            status = 'added'
            map(value, image => {
                formData.append('images[]', image)
            })
        } else {
            status = 'remove'
            const remove_file = get(value, 'url', '')
            if (remove_file) {
                formData.append('images_delete_url[]', remove_file)
            }
        }

        setLoading(true)
        api.updateImageSkuCombo(id, formData)
            .then(res => {
                notification.success(
                    status === 'added'
                        ? t('message.create_success', {
                              attribute: t(`product:label.image`),
                          })
                        : t('message.delete_success', {
                              attribute: t(`product:label.image`),
                          })
                )
                getDetail()
            })
            .catch(err => {
                notification.error(t('message.update_failed_by_attribute', { attribute: t(`product:label.image`) }))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Spin spinning={loading}>
            <Form form={form}>
                <div className="site-content">
                    <div className="d-flex justify-content-between mb-2">
                        <div>
                            <Link
                                className="_product-detail-back mb-4"
                                to={url.to('products.list')}
                            >
                                <ArrowLeftOutlined /> {t('btn.back')}
                            </Link>
                            <h3 className="text-fz-18 mt-1 mb-2">{t('breadcrumb.product_edit')}</h3>
                        </div>
                        <Button
                            loading={loading}
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={handleUpdateCombo}
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                        >
                            {t('btn.save_product')}
                        </Button>
                    </div>

                    <div className="bg-white rounded-12 p-4 box-shadow">
                        <div className="_product-edit-product-info">
                            <h3 className="mb-3">{t('title.product_info')}</h3>
                            {!isEmpty(detail) && <ComboInfo detail={detail}  isEdit/>}
                            <div className="d-flex align-items-center">
                                <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                                <span className="ml-2 number-circle _importing-document-product-list-total">{files?.length}</span>
                                <i className="ml-2">{t('product:description.picture')}</i>
                            </div>
                        </div>
                        <div className="mt-4 _product-edit-picture">
                            <ProductImages
                                onChange={handleUpdateImage}
                                name={t('product:label.image')}
                                accept="image/jpeg, image/png"
                                images={files}
                                maxFileSize={5}
                                multiple={true}
                            />
                        </div>
                    </div>

                    <ChildSkuList
                        skus={skus}
                        setSkus={setSkus}
                        form={form}
                    />
                </div>
            </Form>
        </Spin>
    )
}

export default EditCombo
