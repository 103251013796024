import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '@Modules/Auth/services';
import useProductDetailQuery from '@Modules/Product/Hooks/useProductDetailQuery';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

import ProductHistory from '../History';
import ProductDetail from './ProductDetail';

const { TabPane } = Tabs;

const ProductDetailOrigin = ({ match, history, location }) => {
    const { xs, sm } = useBreakpoint();
    const user = auth.user();
    const productId = match.params.productId;
    const skuId = match.params.skuId;
    const { error, isError, isLoading, data } = useProductDetailQuery(productId);
    const [activeKey, setActiveKey] = useState('1');
    const [product, setProduct] = useState({});
    const [skus, setSkus] = useState([]);

    useEffect(() => {
        setProduct(getVar(data, 'data.product', {}));
        setSkus(getVar(data, 'data.skus', []));
    }, [data]);

    const creator_id = getVar(product, 'creator_id', '');

    const handleChangeTab = activeKey => {
        setActiveKey(activeKey);
    };

    const skuInfo = find(skus, ['id', Number(skuId)]);

    return (
        <div className="site-content _product-detail">
            <div className="d-flex justify-content-between align-items-center">
                <Link className="_product-detail-back" to={url.to('products.list')}>
                    <ArrowLeftOutlined /> {t('btn.back')}
                </Link>
                {xs && !isEmpty(product) && user.id === creator_id && (
                    <Link className="_btn-edit" to={url.to('products.edit', { productId, skuId })}>
                        <Button type={'primary'} icon={<EditOutlined />}>
                            {t('btn.product_edit')}
                        </Button>
                    </Link>
                )}
            </div>
            <Tabs
                defaultActiveKey={activeKey}
                tabBarExtraContent={
                    activeKey === '1' &&
                    sm &&
                    !isEmpty(product) &&
                    user.id === creator_id && (
                        <Link className="_btn-edit" to={url.to('products.edit', { productId, skuId })}>
                            <Button type={'primary'} icon={<EditOutlined />}>
                                {t('btn.product_edit')}
                            </Button>
                        </Link>
                    )
                }
                onChange={handleChangeTab}
                className="mt-3 custom-tab-product"
            >
                <TabPane tab={<span>{t('title.product_info')}</span>} key="1">
                    <ProductDetail
                        productId={productId}
                        data={data}
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        product={product}
                        skuInfo={skuInfo}
                    />
                </TabPane>
                <TabPane tab={<span>{t('title.stock_history')}</span>} key="2">
                    <ProductHistory productId={productId} history={history} location={location} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default ProductDetailOrigin;
