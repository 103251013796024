import kiotvietImg from '@/resources/img/channel/kiotviet.png';
import lazadaImg from '@/resources/img/channel/lazada.png';
import shopeeImg from '@/resources/img/channel/shopee.png';
import tikiImg from '@/resources/img/channel/tiki.png';
import tiktokImg from '@/resources/img/channel/tiktok.png';
import shopbaseImg from '@/resources/img/channel/shopbase.png';
import sapoImg from '@/resources/img/channel/sapo.png';

export interface IChannel {
    SHOPEE: string;
    KIOTVIET: string;
    LAZADA: string;
    TIKI: string;
    TIKTOKSHOP: string;
    SHOPBASE: string;
    SAPO: string;
}

export const PURCHASING_ACCOUNT_STATUS = {
    ACTIVE: {
        value: 'active',
        color: '#09b8af',
    },
    DEACTIVE: {
        value: 'deactive',
        color: '#d9dbe9',
    },
    FAILED: {
        value: 'failed',
        color: '#ff4559',
    },
};

export const CHANNEL_LIST = [
    { code: 'SHOPEE', img: shopeeImg },
    { code: 'KIOTVIET', img: kiotvietImg },
    { code: 'LAZADA', img: lazadaImg },
    { code: 'TIKI', img: tikiImg },
    { code: 'TIKTOKSHOP', img: tiktokImg },
    { code: 'SHOPBASE', img: shopbaseImg },
    { code: 'SAPO', img: sapoImg },
];
export const CHANNEL_STATUS_COLOR = {
    ACTIVE: '#87d068',
    INACTIVE: '#bd0313',
    DISCONNECTED: '#bd0313',
};

export const CHANNEL: IChannel = {
    SHOPEE: 'SHOPEE',
    KIOTVIET: 'KIOTVIET',
    LAZADA: 'LAZADA',
    TIKI: 'TIKI',
    TIKTOKSHOP: 'TIKTOKSHOP',
    SHOPBASE: 'SHOPBASE',
    SAPO: "SAPO"
};
