import { Col, Row, Badge } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';

import { FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS } from '@Modules/Finance/services/constants';
import { FINANCE_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

const FinanceDetail = ({ orderInfo }) => {
    const { order, currency } = orderInfo;
    const cod_fee_amount = getVar(order, 'cod_fee_amount', 0);
    const finance_status = getVar(order, 'finance_status', 0);
    const total_amount = getVar(order, 'total_amount', 0);
    const paid_amount = getVar(order, 'paid_amount', 0);
    const service_amount = getVar(orderInfo, 'service_amount', 0);
    const shipping_amount = getVar(order, 'shipping_amount', 0);
    const other_fee = getVar(order, 'other_fee', 0);
    const cost_price = getVar(order, 'cost_price', 0);
    const cost_of_goods = getVar(order, 'cost_of_goods', 0);
    const extent_service_amount = getVar(order, 'extent_service_amount', 0);
    let remaining_amount = getVar(orderInfo, 'remaining_amount', 0);
    let return_goods_amount = 0;
    const return_goods_status = getVar(
        order,
        'finance_service_import_return_goods_status',
        FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS.UNPAID
    );
    if (return_goods_status === FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS.PAID)
        return_goods_amount = getVar(order, 'service_import_return_goods_amount', 0);

    const rowData = [
        {
            title: t('order:label.order_cod'),
            price: `${isEmpty(currency) ? total_amount : formatCurrency(total_amount || 0, currency)}`,
        },
        {
            title: t('order:payment_type.ADVANCE_PAYMENT'),
            price: `${isEmpty(currency) ? paid_amount : formatCurrency(paid_amount || 0, currency)}`,
        },
        {
            title: t('order:label.order_paid_amount'),
            price: `${isEmpty(currency) ? paid_amount : formatCurrency(paid_amount || 0, currency)}`,
        },
        {
            title: t('order:label.service_amount'),
            price: `${isEmpty(currency) ? service_amount : formatCurrency(service_amount || 0, currency)}`,
        },
        {
            title: t('order:label.cod_fee_amount'),
            price: `${isEmpty(currency) ? cod_fee_amount : formatCurrency(cod_fee_amount || 0, currency)}`,
        },
        {
            title: t('order:label.shipping_amount'),
            price: `${isEmpty(currency) ? shipping_amount : formatCurrency(shipping_amount || 0, currency)}`,
        },
        {
            title: t('order:label.other_fee'),
            price: `${isEmpty(currency) ? other_fee : formatCurrency(other_fee || 0, currency)}`,
        },
        {
            title: t('order:label.return_goods_amount'),
            price: `${isEmpty(currency) ? return_goods_amount : formatCurrency(return_goods_amount || 0, currency)}`,
        },
        {
            title: t('order:label.cost_price'),
            price: `${isEmpty(currency) ? cost_price : formatCurrency(cost_price || 0, currency)}`,
        },
        {
            title: t('order:label.cost_of_goods'),
            price: `${isEmpty(currency) ? cost_of_goods : formatCurrency(cost_of_goods || 0, currency)}`,
        },
        {
            title: t('order:label.operating_costs'),
            price: `${isEmpty(currency) ? extent_service_amount : formatCurrency(extent_service_amount || 0, currency)}`,
        },
    ];

    useEffect(() => { }, [orderInfo]);
    return (
        <>
            <Row className="_order-detail-finance-info-row mb-3">
                <Col span={12} className="font-weight-500  text-fz-16">
                    <span>{t('order:label.operating_costs')}</span>
                </Col>
                <Col span={12} className="text-right">
                    <Badge color={FINANCE_STATUS_COLORS[finance_status]} text={t(`product:FINANCE_STATUS.${finance_status}`)} />
                </Col>
            </Row>
            {rowData.map((item, index) => (
                <Row key={index} className="_order-detail-finance-info-row mb-2">
                    <Col span={12} className="text-color-label">
                        <span>{item.title}</span>
                    </Col>
                    <Col span={12} className="text-right">
                        <span>{item.price}</span>
                    </Col>
                </Row>
            ))}

            <Row className="_order-detail-finance-info-row mb-2">
                <Col span={12} className="font-weight-500 ">
                    <span>{t('order:label.rest')}</span>
                </Col>
                <Col span={12} className="text-right font-weight-500 ">
                    <span>{`${isEmpty(currency) ? remaining_amount : formatCurrency(remaining_amount || 0, currency)}`}</span>
                </Col>
            </Row>
        </>
    );
};

export default FinanceDetail;
