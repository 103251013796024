import lodash from "lodash";
import { t } from "@System/i18n";

const ERRORS = [
    "exists_or_invalid",
    "not_exist",
    "already_exist",
    "required",
    "duplicated",
    "invalid",
    "gte",
    "gt",
    "greater",
    "numeric",
    "empty",
    "exists",
    "in",
    "status_invalid",
    "not_to_access_order",
];


export function renderError(error) {
    const line = lodash.get(error, "line", "");
    const content = lodash.get(error, "errors", {});
    let messages = [];
    Object.entries(content).forEach(entry => {
        let [key, errors] = entry;
        ERRORS.forEach(errorKey => {
            if (errors.hasOwnProperty(errorKey)) {
                messages.push(t(`order:message.upload_file.${errorKey}`, {
                    attribute: t(`order:label.${key}`)
                }));
            }
        });
    });

    if (messages.length > 0)
        return t("product:message.upload_file.line", { line: line, content: messages.join(" ") });
    return t("product:message.upload_file.unknown_error");
}