import { Form, Input } from 'antd';
import React from 'react';

import SelectCategories from '@Modules/Category/components/SelectCategories';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function ProductInfo({ product }) {
    const category_id = getVar(product, 'category_id', undefined);

    return (
        <>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                name="name"
                wrapperCol={{ span: 12 }}
                label={t('product:label.name')}
                rules={[{ required: true, message: t('product:message.required', { attribute: t('product:label.name') }) }]}
            >
                <Input className="_dropshipping-info-name" placeholder={t('product:placeholder.name')} />
            </Form.Item>
            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                name="code"
                wrapperCol={{ span: 12 }}
                label={t('product:label.code')}
            >
                <Input className="_dropshipping-info-code" placeholder={t('order:placeholder.blank_system_init')} />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                name="category_id"
                wrapperCol={{ span: 12 }}
                label={t('product:label.category_id')}
            >
                <SelectCategories
                    className="_dropshipping-info-category-id"
                    value={category_id ? parseInt(category_id) : undefined}
                    placeholder={t('product:placeholder.category_id')}
                />
            </Form.Item>
        </>
    );
}
export default ProductInfo;
