import { Avatar, Table } from 'antd';
import React from 'react';

import { updateCollectionItem } from '@Modules/App/services/helpers';
import MapSkuToVariant from '@Modules/Product/components/MapSkuToVariant';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function PurchasingProductList({ dataSource, loading, setProducts, form }) {
    const purchasingOrderId = form.getFieldValue('purchasing_order_id')
    function updateProduct(updateInfo, data) {
        const products = updateCollectionItem(dataSource, data.purchasing_product_id, updateInfo, 'purchasing_product_id');
        setProducts(products);
    }
    const columns = [
        {
            className: '_purchasing-product-name',
            title: t('product:label.product'),
            dataIndex: 'purchasing_product_name',
            render: (text, record) => {
                const variant_properties = getVar(record, 'variant_properties', []);
                const image = getVar(record, 'image', '');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" className="mr-2" size={36} src={image} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                                {variant_properties.map((item, index) => {
                                    const originalName = getVar(item, 'originalName', '');
                                    const originalValue = getVar(item, 'originalValue', '');
                                    return <p key={index}>{`${originalName}: ${originalValue}`}</p>;
                                })}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_product-name',
            title: t('product:label.product'),
            dataIndex: 'sku_id',
            key: 'sku_id',
            width: "20%",
            render: (text, record) => <MapSkuToVariant productInfo={record} updateProduct={updateProduct} />,
        },
        {
            className: 'text-right text-nowrap _quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            width: '200px',
        },
    ];

    return (
        <div className="_purchasing-product-list">
            <h4>
                {`${t('heading.product_list')} `}
                <span className="number-circle _importing-document-product-list-total">{dataSource ? dataSource.length : 0}</span>
            </h4>
            <Table
                loading={loading}
                rowKey={record => record.purchasing_product_id}
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: 576 }}
                pagination={false}
                locale={{
                    emptyText: <p className="_purchasing-product-list-no-data">{(purchasingOrderId && !loading)  ? t('message.wait_warehouses_checked') : t('message.no_data_product')}</p>
                }}
            />
        </div>
    );
}
export default PurchasingProductList;
