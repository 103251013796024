import { DownOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Menu, Table } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import { dateFormatter } from '@Modules/App/services';
import api from '@Modules/Dropshipping/services/api';
import { PRODUCT_STATUS_COLORS } from '@Modules/Dropshipping/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

import CustomizedPagination from '../../../App/CustomizedPagination';

export default function List({ data, pagination, addFilter, filters, loading, refetch, currency }) {
    const [checkedList, setCheckedList] = useState([]);

    let getColumns = [
        {
            className: '_dropshipping-list-name text-wrap',
            title: t('product:label.product'),
            dataIndex: ['product', 'name'],
            key: 'name',
            width: '30%',
            render: (text, record) => {
                const image = getVar(record, 'product.images', []);
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={image.length > 0 ? image[0] : ''}
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0 }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={url.to('dropshipping.detail', { id: record?.product?.id })}>
                                    <strong style={{ fontWeight: 500 }} className="text-link">
                                        {text}
                                    </strong>
                                </Link>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_dropshipping-list-code',
            title: t('product:label.code'),
            dataIndex: ['product', 'code'],
            key: 'code',
        },
        {
            className: '_dropshipping-list-quantity_sku text-right',
            title: t('product:label.quantity_sku'),
            dataIndex: ['sku_total'],
            key: 'sku_total',
            render: text => (text ? text : '0'),
        },
        {
            className: '_dropshipping-list-status text-center',
            title: t('label.status'),
            dataIndex: ['product', 'status'],
            key: 'status',
            render: text =>
                text ? (
                    <Badge
                        className={(text === 'WAITING_FOR_QUOTE') | (text === 'WAITING_CONFIRM') && 'ml-4'}
                        color={PRODUCT_STATUS_COLORS[text]}
                        text={t(`product:DROPSHIP_STATUS.${text}`)}
                    />
                ) : (
                    '---'
                ),
        },
        {
            className: '_dropshipping-list-waiting-out text-left',
            title: t('label.created_at'),
            dataIndex: ['product', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_dropshipping-list-waiting-out text-right',
            title: t('product:label.estimated_costs'),
            dataIndex: ['product_price_active_details'],
            key: 'estimated_cost',
            render: (text, record) => {
                let min = text
                    ? Math.min.apply(
                          null,
                          text.map(item => item.total_price)
                      )
                    : 0;

                let max = text
                    ? Math.max.apply(
                          null,
                          text.map(item => item.total_price)
                      )
                    : 0;
                return (
                    <p>
                        {text.length !== 0
                            ? currency
                                ? ` ${formatCurrency(min, currency)} - ${formatCurrency(max, currency)}`
                                : ''
                            : ' ---'}
                    </p>
                );
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys: checkedList,
        onChange: (selectedRowKeys, selectedRows) => {
            setCheckedList(selectedRowKeys);
        },
        getCheckboxProps: record => ({
            disabled: get(record, 'can_change_status.WAITING_FOR_QUOTE') === false,
        }),
    };

    const handleChangeStatus = name => {
        const data = { ids: checkedList, status: name };
        api.changeStatusProductDropship(data)
            .then(res => {
                refetch();
                notification.success(
                    t('message.update_success_by_attribute', {
                        attribute: t(`label.request_status`),
                    })
                );
                setCheckedList([]);
            })
            .catch(err => {
                const { response } = err;

                let code = get(response, 'data.code', {});
                if (code === 'INPUT_INVALID') {
                    notification.error(t('message.no_data_product_change_status'));
                } else {
                    notification.error(t('message.update_failed_by_attribute', { attribute: t(`label.request_status`) }));
                }
            });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <div className="_btn_change_status" onClick={() => handleChangeStatus('WAITING_FOR_QUOTE')}>
                    {' '}
                    {t('product:btn.change_status_request_price')}
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        <div className="d-flex justify-content-between align-items-center">
                            {t('heading.product_list')}
                            <span className="number-circle _importing-document-product-list-total">
                                {getVar(pagination, 'total', 0)}
                            </span>
                        </div>
                    </h3>
                </div>
                <div className="data-box--body">
                    <div className="d-flex custom-collapse">
                        {checkedList.length !== 0 && (
                            <>
                                {t('btn.has_selected')}
                                <span className="font-weight-bold">
                                    &nbsp;{checkedList.length}&nbsp;
                                    {t('label.products').toLowerCase()}
                                </span>
                                ,&nbsp;
                                <Dropdown overlay={menu} trigger={['click']} className="btn-dropdown-change">
                                    <span className="text-cl-base font-weight-500" style={{ cursor: 'pointer' }}>
                                        {t('btn.select_action')} <DownOutlined />
                                    </span>
                                </Dropdown>
                            </>
                        )}
                    </div>
                    <Table
                        className="product-list-table pb-3"
                        dataSource={data}
                        rowClassName="_dropshipping-product-row"
                        rowKey={record => record.product.id}
                        columns={getColumns}
                        pagination={false}
                        rowSelection={{
                            ...rowSelection,
                        }}
                        loading={loading}
                        scroll={{ x: true }}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_order'}
                                    action={
                                        <Link to={url.to('dropshipping.create')}>
                                            <Button type="link" className="_dropshipping_add_product" style={{ padding: 0 }}>
                                                <strong className="pagination-customize">{t('btn.create_product')}</strong>
                                            </Button>
                                        </Link>
                                    }
                                />
                            ),
                        }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
