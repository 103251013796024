import React, {useEffect, useState} from 'react';
import {t} from "../../../system/i18n";
import {auth} from '../services';
import {url} from '../../../system/routing';
import notification from '../../../system/notification';
import Error403 from "@Modules/App/Error/403";

export default function LoginCallback() {
    const [error, setError] = useState();
    useEffect(loginCallback, []);

    function loginCallback() {
        const error = url.getQuery('error');
        if (error && error === "MERCHANT_NOT_FOUND") {
            setError(error);
            notification.error(t('auth:message.MERCHANT_NOT_FOUND'), 0);
            return;
        }
        const token = url.getQuery('token');
        if (!token) {
            notification.error(t('auth:message.login_token_invalid'), 0);
            return ;
        }

        auth.loginSuccess(token);

        notification.success(t('auth:message.auth_success'));
    }

    return error ? <Error403/> : null;
}
