import { ArrowLeftOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import ImportService from '@Modules/Dropshipping/components/Create/ImportService';
import ProductImages from '@Modules/Dropshipping/components/Create/ProductImages';
import ProductInfo from '@Modules/Dropshipping/components/Create/ProductInfo';
import ProductOptions from '@Modules/Dropshipping/components/Create/ProductOptions';
import ProductVariations from '@Modules/Dropshipping/components/Create/ProductVariations';
import WarehouseService from '@Modules/Dropshipping/components/Create/WarehouseService';
import api from '@Modules/Dropshipping/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

function ProductCreateDropShipping(props) {
    const [form] = Form.useForm();

    const [product, setProduct] = useState([]);

    const [fileList, setFileList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [serviceImport, setServiceImport] = useState([]);
    const [localOptions, setLocalOptions] = useState([]);
    const [localVariations, setLocalVariations] = useState([]);

    useEffect(() => {
        setNavigator(t('title.create_product_dropshipping'), [
            {
                name: t('title.product_list_dropshipping'),
                route: 'dropshipping.list',
            },
            {
                name: t('title.create_product_dropshipping'),
            },
        ]);
        setDocumentTitle(t('title.create_product_dropshipping'));
    }, []);

    const handleCreate = () => {
        let formData = new FormData();
        form.setFieldsValue({
            services: [...serviceList, ...serviceImport],
        });
        let arr = form.getFieldsValue(true);

        form.validateFields().then(values => {
            let isValid = true;
            if (!checkOptions()) {
                form.setFields([
                    {
                        name: 'options',
                        errors: [t('product:message.properties_required')],
                    },
                ]);
                isValid = false;
            }
            for (let key in arr) {
                formData.append(key, arr[key]);
            }
            formData.delete('files');
            formData.delete('services');

            for (let i of form.getFieldValue('services')) {
                formData.append('services[]', i);
            }

            if (form.getFieldValue('files')) {
                for (let i of form.getFieldValue('files')) {
                    formData.append('files[]', i);
                }
            }

            localOptions.forEach(item => {
                formData.append(`options[]`, JSON.stringify(item));
            });

            localVariations.forEach(item => {
                formData.append(`skus[]`, JSON.stringify(item));
            });
            if (!localOptions.every(ele => !isEmpty(ele.values)) || !checkOptions()) {
                notification.error(
                    t('errorMessages.required', {
                        attribute: `${t('common:title.properties')} ${t('common:title.dropshipping')}`,
                    })
                );
            } else if (isValid) {
                setLoading(true);
                api.createProductDropshipping(formData)
                    .then(res => {
                        notification.success(t('message.create_success', { attribute: t('product:label.product') }));
                        url.redirectTo('dropshipping.list');
                    })
                    .catch(err => {
                        const { response } = err;
                        const code = getVar(response, 'data.data.code', '');
                        if (Object.keys(code)[0] === 'already_exist') {
                            notification.error(t('errorMessages.already_exist', { attribute: t('product:label.code') }));
                        } else {
                            notification.error(t('message.create_failed', { attribute: t('product:label.product') }));
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
    };

    const handleChange = (name, value) => {
        if (name !== 'services') {
            form.setFieldsValue({
                [name]: value,
            });
        }
    };

    const checkOptions = () => {
        return localOptions.every(option => !isEmpty(option.label) && option.values.length > 0);
    };

    const addOption = () => {
        form.validateFields().then(values => {
            if (checkOptions() && localOptions.length < 3) {
                let newOptions = [...localOptions];
                newOptions.push({ id: null, label: '', values: [] });
                setLocalOptions(newOptions);
            }
        });
    };

    return (
        <Form form={form}>
            <div className="d-flex justify-content-between mb-2"></div>
            <div className="site-content">
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link className="_dropshipping-detail-back mb-4" to={url.to('dropshipping.list')}>
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18 mt-1 mb-2">{t('breadcrumb.create_product')}</h3>
                    </div>
                    <Button loading={loading} type="primary" icon={<SaveOutlined />} onClick={handleCreate}>
                        {t('btn.save_product')}
                    </Button>
                </div>
                <div className="bg-light-gray">
                    <div className="bg-white rounded-12 p-4">
                        <div className="_dropshipping-edit-dropshipping-info">
                            <h3 className="mb-3">{t('title.product_info')}</h3>
                            <ProductInfo product={product} />
                            <div className="d-flex align-items-center">
                                <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                                <span className="ml-2 number-circle _importing-document-dropshipping-list-total">
                                    {fileList.length}
                                </span>
                                <i className="ml-2">{t('product:description.picture')}</i>
                            </div>
                        </div>
                        <div className="mt-4 _dropshipping-edit-picture">
                            <ProductImages
                                name={t('product:label.image')}
                                accept="image/jpeg, image/png"
                                maxFileSize={5}
                                multiple={true}
                                fileList={fileList}
                                setFileList={setFileList}
                                form={form}
                            />
                        </div>
                    </div>
                    <Row gutter={16} className="mt-4">
                        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="bg-white rounded-12  p-4 mb-4 _dropshipping-edit-packing-service mb-4">
                                <div className="d-flex justify-content-between">
                                    <h3>{`${t('title.properties')}`}</h3>
                                    <div className="d-flex justify-content-end align-items-center">
                                        {localOptions.length < 3 && (
                                            <Button
                                                className="cursor-pointer mr-2 _dropshipping-option-add-btn"
                                                onClick={addOption}
                                            >
                                                <PlusOutlined /> {t('product:btn.option_add_btn')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <ProductOptions
                                    product={product}
                                    localOptions={localOptions}
                                    setLocalOptions={setLocalOptions}
                                    checkOptions={checkOptions}
                                    form={form}
                                />

                                <ProductVariations
                                    product={product}
                                    form={form}
                                    localOptions={localOptions}
                                    localVariations={localVariations}
                                    setLocalVariations={setLocalVariations}
                                    setLocalOptions={setLocalOptions}
                                />
                            </div>
                        </Col>

                        {/* <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="bg-white rounded-12  p-4 mb-4 _dropshipping-create-packing-service mb-4">
                                <div className="d-flex justify-content-between">
                                    <h3>{`${t('title.warehouse_service')}`}</h3>
                                </div>
                                <ImportService
                                    onChange={handleChange}
                                    setServiceImport={setServiceImport}
                                    serviceList={serviceList}
                                />
                            </div>
                            <div className="bg-white rounded-12  p-4 _dropshipping-create-packing-exporting mb-4">
                                <div className="d-flex justify-content-between">
                                    <h3>{`${t('title.export_service')}`}</h3>
                                </div>
                                <WarehouseService
                                    onChange={handleChange}
                                    setServiceList={setServiceList}
                                    serviceImport={serviceImport}
                                />
                            </div>
                        </Col> */}
                    </Row>
                </div>
                <div className="d-flex justify-content-end">
                    <Button loading={loading} type="primary" icon={<SaveOutlined />} onClick={handleCreate}>
                        {t('btn.save_product')}
                    </Button>
                </div>
            </div>
        </Form>
    );
}
export default ProductCreateDropShipping;
