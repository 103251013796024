import { Select } from 'antd';
import { FormInstance } from 'rc-field-form/lib/interface';
import React, { useState, useCallback, useEffect } from 'react';

import ServiceLocation from '@Modules/Location/services/ServiceLocation';
import { VIETNAM_CODE } from '@Modules/Location/services/constants';

import { t } from '@System/i18n';
import { SelectLocationKey } from '@Modules/Order/Interfaces';

interface ISelectProvinceProps {
    countryCode: string;
    onChange: (value: any, option: any, key: SelectLocationKey) => void;
    value?: number;
    form: FormInstance;
}

const ServiceLocationApi = new ServiceLocation();
const { Option } = Select;

const SelectProvince: React.FC<ISelectProvinceProps> = ({ countryCode, onChange, value, form }) => {
    const [loading, setLoading] = useState(false);
    const [provinces, setProvinces] = useState([]);

    const fetchProvince = useCallback(() => {
        setLoading(true);
        ServiceLocationApi.list('PROVINCE', countryCode)
            .then(res => {
                setProvinces(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [countryCode]);

    useEffect(() => {
        if (countryCode) {
            fetchProvince();
            form.setFields([{ name: 'receiver_province_id', value: value }]);
        }
    }, [form, countryCode, fetchProvince, value]);

    return (
        <Select
            className="_location-receiver-province-id"
            loading={loading}
            disabled={loading}
            placeholder={t(
                `warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'province_id' : 'other_province_id'}`
            )}
            onChange={(value: any, option: any) => onChange(value, option.code, 'province')}
            showSearch
            value={provinces.length > 0 && value ? value : undefined}
            optionFilterProp="label"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {provinces.length > 0 &&
                provinces.map((opt: any) => {
                    return (
                        <Option key={opt.id} value={opt.id} code={opt.code}>
                            {opt.label}
                        </Option>
                    );
                })}
        </Select>
    );
};

export default SelectProvince;
