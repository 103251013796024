import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import SelectCategories from '@Modules/Category/components/SelectCategories';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { CURRENCY_REGEX } from '@System/support/numberFormat';

function ProductInfo({ product, onChange, form, skuInfo }) {
    const [safeStock, setSafeStock] = useState();
    useEffect(() => {
        const name = getVar(product, 'name', '');
        form.setFieldsValue({ name });
    }, [form, product]);
    useEffect(() => {
        setSafeStock(getVar(skuInfo, 'safety_stock', undefined));
    }, [skuInfo]);

    const onChangeSafetyStock = e => {
        let newValue = e.target.value;

        if (CURRENCY_REGEX.test(newValue)) {
            setSafeStock(newValue);
        }
    };

    const category_id = getVar(product, 'category_id', undefined);

    return (
        <>
            <Form.Item labelAlign={'left'} labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} label={t('product:label.code')}>
                <p className="_product-info-code">{getVar(product, 'code', '')}</p>
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                label={t('product:label.name')}
                name="name"
                initialValue={getVar(product, 'name', '')}
                rules={[{ required: true, message: t('product:message.required', { attribute: t('product:label.name') }) }]}
            >
                <Input
                    className="_product-info-name"
                    onChange={e => form.setFieldsValue({ name: e.target.value })}
                    placeholder={t('product:placeholder.name')}
                    onBlur={e => onChange('name', e.target.value)}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                label={t('product:label.category_id')}
            >
                <SelectCategories
                    className="_product-info-category-id"
                    value={category_id ? parseInt(category_id) : undefined}
                    placeholder={t('product:placeholder.category_id')}
                    onChange={value => onChange('category_id', value)}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                label={t('product:label.safety_stock')}
            >
                <Input
                    style={{ width: '100%' }}
                    value={safeStock}
                    className="_product-info-safety-stock"
                    onChange={onChangeSafetyStock}
                    placeholder={t('product:placeholder.safety_stock')}
                    onBlur={() => onChange('safety_stock', safeStock)}
                />
            </Form.Item>
        </>
    );
}
export default ProductInfo;
