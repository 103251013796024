import { Select } from 'antd';
import React from 'react';

import { TRANSACTION_STATUS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';

const { Option } = Select;

const SelectTransactionStatus = props => {
  return (
    <Select
      {...props}
      value={props.value || undefined}
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {Object.keys(TRANSACTION_STATUS).map(opt => {
        return (
          <Option key={TRANSACTION_STATUS[opt]} value={TRANSACTION_STATUS[opt]}>
            {t(`finance:TRANSACTION_STATUS.${TRANSACTION_STATUS[opt]}`)}
          </Option>
        );
      })}
    </Select>
  );
};

export default SelectTransactionStatus;
