import { useQuery } from 'react-query';

import api from '@Modules/Finance/services/api';

import processResponseError from '@System/api/processResponseError';

const useFinanceTransactionQuery = params => {
    return useQuery(['finance-transaction-query', params], () => api.getFinanceTransaction(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useFinanceTransactionQuery;
