import logo from '@/resources/img/logo.svg';
import register from '@/resources/img/register.svg';
import Icon from '@ant-design/icons';
import { Image, Form, Input, Button, Select, Spin } from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import FlagCountryComponent from '@Modules/App/Language/FlagCountryComponent';
import { MARKETS } from '@Modules/App/services/constants';
import { auth } from '@Modules/Auth/services';
import api from '@Modules/Auth/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

import { COUNTRY_TELEPHONE_PREFIX } from '../services/constants';

import './register.scss';

/**
 * @return {null}
 * @return {null}
 */

const { Option } = Select;

export default function Register({ location }) {
    const params = queryString.parse(location.search, { arrayFormat: 'bracket' });
    const ref = getVar(params, 'ref', undefined);
    const market = getVar(params, 'market', MARKETS[0].key);
    const marketCodes = map(MARKETS, 'key');
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [phoneHead, setPhoneHead] = useState(COUNTRY_TELEPHONE_PREFIX.VIETNAM);
    const [banner, setBanner] = useState('');
    const [loadingBanner, setLoadingBanner] = useState(false);

    useEffect(() => {
        const getBanner = () => {
            setLoadingBanner(true);
            api.banner(window.location.hostname)
                .then(res => {
                    const urlImg = get(res, 'data.register_image_url');
                    setBanner(urlImg);
                })
                .catch(err => {
                    setBanner(register);
                })
                .finally(() => {
                    setLoadingBanner(false);
                });
        };
        getBanner();
    }, []);

    function handleSubmit() {
        form.validateFields().then(values => {
            const locationId = getVar(find(MARKETS, ['key', values.market]), 'code', MARKETS[0].code);
            setLoading(true);
            api.register({
                ...values,
                market: undefined,
                location_id: locationId,
                domain: window.location.hostname,
                phone: `${phoneHead}${values.phone}`,
            })
                .then(async () => {
                    auth.resetAuthData();
                    url.redirectTo('home');
                    notification.success(t('register:message.register_success'));
                    setLoading(false);
                })
                .catch(err => {
                    let { response } = err;
                    if (response.status === 400) {
                        let errorMessages = [];
                        let errors = get(response, 'data.data', {});
                        const formItem = {
                            duplicate_user: 'username',
                            duplicate_email: 'email',
                        };
                        const errorKey = getVar(errors, 'error.key', '');
                        if (errorKey) {
                            if (formItem[errorKey]) {
                                form.setFields([
                                    { name: formItem[errorKey], errors: [t(`register:message.${errorKey}`)] },
                                ]);
                            }
                        } else {
                            Object.keys(errors).forEach(item => {
                                let keyErrors = [];
                                Object.keys(errors[item]).forEach(error => {
                                    if (item === 'location') item = 'market';
                                    keyErrors.push(
                                        t(`register:message.${error}`, { attribute: t(`register:label.${item}`) })
                                    );
                                });
                                errorMessages.push({ name: item, errors: keyErrors });
                            });
                            form.setFields(errorMessages);
                        }
                    }
                    notification.error(t('register:message.register_fail'));
                    setLoading(false);
                });
        });
    }

    if (auth.isLogged()) {
        return <Redirect to={'/'} />;
    }

    const prefixSelector = (
        <Select
            style={{
                width: 100,
            }}
            value={phoneHead}
            onChange={value => setPhoneHead(value)}
            className="register-select-prefix"
        >
            <Option value={COUNTRY_TELEPHONE_PREFIX.VIETNAM} className="register-select-content">
                <Icon component={FlagCountryComponent('vi')} className="mr-2" />
                {COUNTRY_TELEPHONE_PREFIX.VIETNAM}
            </Option>
            <Option value={COUNTRY_TELEPHONE_PREFIX.THAILAND} className="register-select-content">
                <Icon component={FlagCountryComponent('th')} className="mr-2" />
                {COUNTRY_TELEPHONE_PREFIX.THAILAND}
            </Option>
            <Option value={COUNTRY_TELEPHONE_PREFIX.CAMBODIA} className="register-select-content">
                <Icon component={FlagCountryComponent('kh')} className="mr-2" />
                {COUNTRY_TELEPHONE_PREFIX.CAMBODIA}
            </Option>
        </Select>
    );

    const onChangeMarket = (value)=>{
        switch (value) {
            case 'th': 
                setPhoneHead(COUNTRY_TELEPHONE_PREFIX.THAILAND)
                break;
            case 'kh': 
                setPhoneHead(COUNTRY_TELEPHONE_PREFIX.CAMBODIA)
                break;
            default:
                setPhoneHead(COUNTRY_TELEPHONE_PREFIX.VIETNAM)
                break;
        }
    }


    return (
        <div className="register bg-gray">
            <div className="header">
                <Image width={68} height={68} src={logo} preview={false} />
            </div>

            <div className="register-logo d-flex align-items-center justify-content-center">
                <Spin spinning={loadingBanner}>
                    <Image src={banner} preview={false} />
                </Spin>
            </div>

            <div className="form-register">
                <h3 className="mb-3">{t('register:title.register')}</h3>
                <Form form={form} name="register" layout="vertical">
                    <Form.Item
                        label={t('register:label.market')}
                        name="market"
                        initialValue={marketCodes.includes(market) ? market : MARKETS[0].key}
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.market') }),
                            },
                        ]}
                    >
                        <Select
                            onChange={onChangeMarket}
                        >
                            {MARKETS.map((item, index) => {
                                return (
                                    <Option value={item.key} key={index}>
                                        {t('label.location_market.' + item.key)}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('register:label.username')}
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.username') }),
                                whitespace: false,
                            },
                            {
                                pattern: /^[A-Za-z0-9._]+$/,
                                message: t('register:message.not_special_character', {
                                    attribute: t('register:label.username'),
                                }),
                            },
                            {
                                min: 6,
                                message: t('validation:min.string', {
                                    attribute: t('register:label.username'),
                                    min: 6,
                                }),
                            },
                            {
                                max: 100,
                                message: t('validation:max.string', {
                                    attribute: t('register:label.username'),
                                    min: 100,
                                }),
                            },
                        ]}
                    >
                        <Input placeholder={t('register:placeholder.username')} />
                    </Form.Item>

                    <Form.Item
                        label={t('register:label.email')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.email') }),
                            },
                            {
                                pattern: /^[\w-\.]+[A-Za-z0-9]@([\w-]+\.)+[\w-]{2,4}$/,
                                message: t('validation:not_regex', { attribute: t('register:label.email') }),
                            },
                        ]}
                    >
                        <Input placeholder={t('register:placeholder.email')} />
                    </Form.Item>
                    <Form.Item
                        label={t('register:label.phone_number')}
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.phone_number') }),
                            },
                            phoneHead === COUNTRY_TELEPHONE_PREFIX.THAILAND && {
                                pattern: /^[0-9+-]+$/,
                                message: t('validation:phone_format', { attribute: t('register:label.phone_number') }),
                            },
                            phoneHead === COUNTRY_TELEPHONE_PREFIX.VIETNAM && {
                                pattern: /^\(?([3|5|7|8|9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{3})$/,
                                message: t('validation:phone_format', { attribute: t('register:label.phone_number') }),
                            },

                            //(84|0[3|5|7|8|9])+([0-9]{8})\b
                        ]}
                    >
                        <Input addonBefore={prefixSelector} placeholder={t('register:placeholder.phone_number')} />
                    </Form.Item>
                    <Form.Item
                        label={t('register:label.password')}
                        name="password"
                        rules={[
                            {
                                min: 6,
                                message: t('validation:min.string', {
                                    attribute: t('register:label.password'),
                                    min: 6,
                                }),
                            },
                            {
                                max: 69,
                                message: t('validation:max.string', {
                                    attribute: t('register:label.password'),
                                    min: 69,
                                }),
                            },
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.password') }),
                            },
                        ]}
                    >
                        <Input.Password placeholder={t('register:placeholder.password')} />
                    </Form.Item>

                    <Form.Item
                        label={t('register:label.re_password')}
                        name="re_password"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', { attribute: t('register:label.re_password') }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('register:message.password_not_match')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={t('register:placeholder.re_password')} />
                    </Form.Item>

                    <Form.Item label={t('register:label.ref')} name="ref" initialValue={ref}>
                        <Input placeholder={t('register:placeholder.ref')} disabled={!!ref} />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="register-btn-submit"
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            {t('register:btn.register')}
                        </Button>
                        <Button className="mt-2 btn-link" style={{ width: '100%' }}>
                            <Link to={url.to('login')}>{t('register:btn.login')}</Link>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
