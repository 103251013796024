import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useFinanceTransactionQuery from '@Modules/Finance/Hooks/useFinanceTransactionQuery'

import { t } from '@System/i18n'

import FormSearch from './FormSearch'
import List from './List'
import moment from 'moment'

const nowDay = new Date();
const thirtyDayAgo = new Date().setDate(nowDay.getDate() - 30);

const initFilter = {
    'created_at[from]': moment(thirtyDayAgo).format('YYYY-MM-DD'),
    'created_at[to]': moment(nowDay).format('YYYY-MM-DD')
}

const FinanceTransaction = ({ history, location }) => {
    const { filters, addFilter } = useFilter(history, location, initFilter)
    const [pagination, setPagination] = useState({})
    const [orders, setOrders] = useState([])
    const [currency, setCurrency] = useState([])
    const { data, isFetching: loading, refetch } = useFinanceTransactionQuery(filters)

    useEffect(() => {
        refetch()
    }, [filters, refetch])

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('breadcrumb.finance_transaction_history'),
            },
        ])
        setDocumentTitle(t('title.finance_transaction_history'))
    }, [])

    useEffect(() => {
        setOrders(get(data, 'data.transactions', []))
        setPagination(get(data, 'data.pagination', {}))
        setCurrency(get(data, 'data.currency', {}))
    }, [data])

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.finance_transaction_history')}</h3>
            </div>

            <FormSearch
                onSearch={addFilter}
                filters={filters}
                loading={loading}
            />
            <List
                data={orders}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                currency={currency}
                loading={loading}
            />
        </div>
    )
}

export default FinanceTransaction
