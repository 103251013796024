import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
import i18next from 'i18next';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React, { useState } from 'react';

import UploadFile from '@Modules/App/Upload/UploadFile';
import { renderError } from '@Modules/Product/components/UploadFile/errors';
import api from '@Modules/Product/services/api';
import { PRODUCT_EVENT } from '@Modules/Product/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';

export default function ImportCreateProducts({ type }) {
    const [visible, setVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleCancel() {
        setMessages([]);
        setVisible(false);
    }

    function handleUpload(file) {
        setMessages([]);
        const filename = get(file, 'name', '');
        let formData = new FormData();
        formData.append('file', file);
        api.createProductByUploadFile(formData)
            .then(res => {
                const messages = uniqWith(get(res, 'data.errors', []), isEqual);
                const hasInvalid = findIndex(messages, ['errors', 'invalid']);
                if (hasInvalid === -1) {
                    setMessages(messages);
                    if (messages.length === 0) {
                        events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_LIST, {});
                        notification.success(
                            t('order:message.upload_file.success', {
                                filename,
                            })
                        );
                        handleCancel();
                    }
                } else {
                    notification.error(
                        t('order:message.upload_file.fail', {
                            filename,
                        })
                    );
                }
            })
            .catch(err => {
                notification.error(t('common:message.server_error'));
            });
    }

    return (
        <>
            <Button type={type ? type : 'primary'} onClick={showModal}>
                {t('product:btn.import_product')}
            </Button>
            <Modal
                title={t('product:title.import_product')}
                visible={visible}
                onCancel={handleCancel}
                footer={false}
                maskClosable={false}
            >
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <p>{t('product:description.download_sample_file')}:</p>
                        <i>{t('product:description.updated_at', { date: '27/11/2021' })}</i>
                    </div>
                    <Button
                        type="primary"
                        href={process.env.PUBLIC_URL + `/sample_file/${i18next.language}/sample_import_create_product.xlsx`}
                        icon={<DownloadOutlined />}
                    >
                        {t('btn.download_sample_file')}
                    </Button>
                </div>
                <div>
                    <p className="mb-2">{t('product:description.upload_sample_file')}: </p>

                    <UploadFile
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        name="file import đơn hàng"
                        maxFileSize={5}
                        showUploadList={true}
                    />
                    {messages.length > 0 && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(item)}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
}
