import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input, Select, DatePicker } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import useSize from '@Modules/App/Hooks/useSize';


import { t } from '@System/i18n';
import SelectStatuses from '@Modules/Dropshipping/components/SelectStatuses';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const intitalFilters = {
    code: undefined,
    name: undefined,
    status: undefined,
    'created_at[from]': undefined,
    'created_at[to]': undefined,
};

function FormSearch({ onSearch, filters }) {
    const { isMobile, isTablet } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const { code, name, status } = input || {};

    const created_at_from = get(input, 'created_at[from]', '');
    const created_at_to = get(input, 'created_at[to]', '');
    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        });
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        onSearch(intitalFilters);
    }

    return (
        <div>
            <Form form={form} layout="vertical">
                <div className="bg-white rounded-12 p-4 mt-3 box-shadow">
                    <div className="search-box">
                        <Row gutter={20}>
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('product:label.code')}>
                                    <Input
                                        className="_dropshipping-search-code"
                                        name="code"
                                        value={code}
                                        placeholder={t('product:placeholder.code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('product:label.name')}>
                                    <Input
                                        className="_dropshipping-search-name"
                                        name="name"
                                        value={name}
                                        placeholder={t('product:placeholder.name')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item className={isMobile || isTablet ? 'mb-3' : 'mb-0'} label={t('label.created_at')}>
                                    <RangePicker
                                        className="_dropshipping-search-created-at"
                                        value={
                                            created_at_from && created_at_to
                                                ? [moment(created_at_from), moment(created_at_to)]
                                                : undefined
                                        }
                                        format={dateFormat}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        onChange={date => _onChangeDate('created_at', date)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.status')}>
                                    <SelectStatuses
                                        className="_dropshipping-search-status"
                                        placeholder={t('placeholder.status')}
                                        allowClear={true}
                                        value={status}
                                        onChange={value => _onChange('status', value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 6 }} className="text-right">
                                <Form.Item className="mb-0" label={isMobile || isTablet ? '' : ' '}>
                                    <Button className="mr-2 _dropshipping-search-btn-reset" onClick={_onReset}>
                                        <UndoOutlined /> {t('common:btn.set_again')}
                                    </Button>
                                    <Button type="primary" onClick={_onSubmit} className="_dropshipping-search-btn-search btn-primary">
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default FormSearch;
