import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Upload, Button } from 'antd';
import React, { useRef } from 'react';

import { t } from '@System/i18n';
import { isFunction } from 'lodash';

function UploadMulti(props) {
    const { setImages, form ,setFileList} = props;
    const files = useRef([]);

    function beforeUpload(file) {
        if (props.fileType) {
            if (!props.fileType.includes(file.type.toLowerCase())) {
                message.error(
                    t('validation:mimetypes', {
                        attribute: file.name || t('common:label.avatar'),
                        values: props.accept,
                    })
                );
                return false;
            }
        }

        if (props.maxFileSize) {
            const validMaxSize = file.size / 1024 / 1024 > props.maxFileSize;
            if (validMaxSize) {
                message.error(
                    t('validation:max.file', {
                        attribute: file.name || t('common:label.avatar'),
                        max: props.maxFileSize,
                    })
                );
                return false;
            }
        }

        return false;
    }

    function handleChange(event) {
        const { file, fileList } = event;
        if (file instanceof Blob) {
            files.current = [...files.current, file];
        } else {
            if (file.uid) files.current = files.current.filter(item => item.uid !== file.uid);
        }
        form.setFieldsValue({
            files: files.current,
        });
        if(isFunction(setFileList)){
            setFileList(fileList)
        }

        
        setImages(fileList);
        if (props.clearData) files.current = [];
    }

    return (
        <div>
            <Upload {...props} beforeUpload={beforeUpload} onChange={handleChange} multiple={true} accept="image/jpeg,image/png">
                {props.children ? (
                    props.children
                ) : (
                    <Button loading={props.loading} icon={<UploadOutlined />}>
                        Chọn file tải lên
                    </Button>
                )}
            </Upload>
        </div>
    );
}

export default UploadMulti;
