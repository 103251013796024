import Icon, {
    DownOutlined,
    CaretRightOutlined,
    LogoutOutlined,
    KeyOutlined,
    UserOutlined,
    WalletFilled,
} from '@ant-design/icons'
import { Avatar, Button, Popover } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import FlagCountryComponent from '@Modules/App/Language/FlagCountryComponent'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import { auth } from '../../Auth/services'
import useSize from '../Hooks/useSize'
import Language from '../Language'
import ConfigMenu from './ConfigMenu'

export default function UserInfo({ setIsShown }) {
    const { isXSmall, isSmall } = useSize()

    const [isVisible, setIsVisible] = useState(false)
    const [open, setOpen] = useState(true)

    const user = auth.user()
    const currency = getVar(user, 'currency', {})
    const balance = getVar(user, 'balance', 0)
    const currentLanguage = localStorage.getItem('i18nextLng') || 'vi'

    function logout(e) {
        e.preventDefault()
        auth.logout()
    }

    function _onClick(e) {
        e.preventDefault()
        setIsVisible(!isVisible)
    }

    const menuConfig = [
        {
            item: (
                <div className="d-flex justify-content-between align-items-center">
                    <span>{t('label.lang')}</span>{' '}
                    <div className="d-flex justify-content-start align-items-center">
                        <Icon
                            component={FlagCountryComponent(currentLanguage)}
                            className="mr-1"
                        />
                        <CaretRightOutlined className="mr-0" />
                    </div>
                </div>
            ),
            componentTab: <Language />,
        },
        {
            item: (
                <a href="#">
                    <UserOutlined className="mr-1" /> {t('title.account_info')}
                </a>
            ),
        },
        {
            item: (
                <a href="#">
                    <KeyOutlined className="mr-1" /> {t('title.change_pin')}
                </a>
            ),
        },
        {
            item: (
                <a
                    className="text-red"
                    href="#"
                    onClick={logout}
                >
                    <LogoutOutlined className="mr-1" /> {t('common:menu.logout')}
                </a>
            ),
        },
    ]

    return (
 
            <div className="user-info">
                {isXSmall || isSmall ? (
                    <a
                        className="ant-dropdown-link user-info text-fz-15"
                        onClick={() => setIsShown(true)}
                    >
                        <Avatar
                            shape="square"
                            src={user.avatar}
                        />
                    </a>
                ) : (
                    <ConfigMenu
                        items={menuConfig}
                        isDropDown={true}
                        visible={isVisible}
                        onVisible={setIsVisible}
                    >
                        <div className="d-flex justify-content-end align-items-center bg-white">
                            <div className="d-flex justify-content-end align-items-center flex-column">
                                <Button
                                    className="user-balance text-right ml-auto"
                                    icon={<WalletFilled />}
                                >
                                    <span>{formatCurrency(balance || 0, currency)}</span>
                                    <Link
                                        to={'/deposit'}
                                        className="ml-3 btn-link"
                                    >
                                        {t('label.deposit')}
                                    </Link>
                                </Button>
                            </div>

                            <div className="ml-3 user-info-dropdown d-flex justify-content-start align-items-center">
                                <Avatar
                                    shape="square"
                                    size={40}
                                    src={user.avatar}
                                />
                                <Button
                                    className="ant-dropdown-link text-fz-15"
                                    onClick={_onClick}
                                >
                                    <span className="user-dropdown">
                                        {user.name || user.username} <DownOutlined className="mx-1" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                       
                    </ConfigMenu>
                )}
            </div>
      
    )
}
