import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'

import api from '../services/api'

const useListSkuComboQuery = (params, activeCall = true) => {
    return useQuery(['ListSkuComboQuery', params], () => api.getSkuComboList(params), {
        retry: false,

        onError: err => {
            processResponseError(err)
        },
        enabled: activeCall,
    })
}

export default useListSkuComboQuery
