import api from "@Modules/Setting/services/api";
import { useMutation } from "react-query"
import { t } from '@System/i18n';
import notification from '@System/notification'

export const useCreateConfigWarehouseMutation = () => {
    return useMutation("sales_channel.create_config_warehouse", (payload) => api.createConfigWarehouseStore(payload.storeID, payload.warehouses),{
        onError: (errors) => {
            notification.error(errors?.response?.data?.message || t("setting:sales_channel.message.config_warehouse.failed"))
        }
    })
}

export default useCreateConfigWarehouseMutation;
