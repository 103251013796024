import { MoreOutlined, DownOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table, Avatar, Badge, Dropdown, Menu, Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import { dateFormatter } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import ImportPackageCreateMenu from '@Modules/ImportPackage/components/ImportPackageCreateMenu';
import PackingSlipPrint from '@Modules/ImportPackage/components/StampPrint/PackingSlipPrint';
import api from '@Modules/ImportPackage/services/api';
import { FINANCE_STATUS_COLORS, IMPORT_PACKAGE_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import ExportListImportPackage from "@Modules/ImportPackage/components/ExportFile/ExportListImportPackage";
import DynamicTable from '@Modules/App/DynamicTable';

export default function List({ purchasingPackages, pagination, addFilter, filters, loading, refetch }) {
    const user = auth.user();
    const currency = getVar(user, 'currency', {});
    const [checkedList, setCheckedList] = useState([]);

    function renderMoreAction(record) {
        const status = getVar(record, 'purchasing_package.status', '');
        const id = get(record, 'purchasing_package.id');
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="1">
                            <PackingSlipPrint data={record} btnType="link" />
                        </Menu.Item>
                        {status === 'INIT' && (
                            <Menu.Item key="2">
                                <Button type="link" onClick={() => handleChangeStatusOneItem('purchasing_package_ids', id)}>
                                    {t('product:btn.check_shipping')}
                                </Button>
                            </Menu.Item>
                        )}
                    </Menu>
                }
            >
                <span className="text-cl-base font-weight-500 mr-1" style={{ cursor: 'pointer', color: '#1A1A1A', fontSize: 14 }}>
                    <MoreOutlined />
                </span>
            </Dropdown>
        );
    }

    const getColumns = [
        {
            className: '_import-package-info',
            title: t('product:label.import_package'),
            dataIndex: ['purchasing_package', 'code'],
            key: 'import_package',
            disabled: true,
            render: (text, record) => {
                const count = getVar(record, 'purchasing_package_items', []).length;
                const image = getVar(record, 'images.0', '');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" className="mr-2" size={36} src={image} />
                        <div>
                            <span>
                                <Link params={{ id: get(record, 'purchasing_package.id') }} to={'import-package.detail'}>
                                    <strong style={{ fontWeight: 500 }} className="text-link">{`#${text}`}</strong>
                                </Link>
                            </span>
                            <p style={{ color: '#999999' }}>{t('label.has_product', { count })}</p>
                        </div>
                    </div>
                );
            },
        },

        {
            className: '_import-package-freight-bill',
            title: t('label.freight_bill_code'),
            dataIndex: ['purchasing_package', 'freight_bill_code'],
            key: 'freight_bill_code',
        },
        {
            className: '_import-package-declare-quantity text-right',
            title: t('product:label.declare_quantity'),
            dataIndex: ['purchasing_package', 'quantity'],
            key: 'declare_quantity',
            render: (text => {
                return text ? text : t('message.wait_warehouses_checked')
            })
        },
        {
            className: '_import-package-real-quantity text-right',
            title: t('product:label.real_quantity'),
            dataIndex: ['purchasing_package', 'received_quantity'],
            key: 'real_quantity',
        },
        {
            className: '_import-package-costs text-right',
            title: t('product:label.costs'),
            dataIndex: ['purchasing_package', 'service_amount'],
            key: 'service_amount',
            render: text => (!isEmpty(currency) ? formatCurrency(text || 0, currency) : text),
        },
        {
            className: '_import-package-created-at',
            title: t('label.created_at'),
            dataIndex: ['purchasing_package', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_import-package-warehouse',
            title: t('product:label.warehouse'),
            dataIndex: ['destination_warehouse', 'name'],
            key: 'warehouse',
        },
        {
            className: '_import-package-status',
            title: t('label.purchasing_package_status'),
            dataIndex: ['purchasing_package', 'status'],
            key: 'order.status',
            render: text => {
                return text ? (
                    <Badge
                        color={IMPORT_PACKAGE_STATUS_COLORS[text]}
                        text={t(`product:IMPORT_PACKAGE_STATUS.${text}`)}
                        className="ml-2"
                    />
                ) : null;
            },
        },
        {
            className: '_import-package-finance-status',
            title: t('label.finance_status'),
            dataIndex: ['purchasing_package', 'finance_status'],
            key: 'order.finance_status',
            render: text =>
                text ? <Badge color={FINANCE_STATUS_COLORS[text]} text={t(`product:FINANCE_STATUS.${text}`)} /> : null,
        },
        {
            className: '_import-package-action text-right',
            key: 'action',
            render: (text, record) => {
                return renderMoreAction(record);
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setCheckedList(selectedRowKeys);
        },
        getCheckboxProps: record => ({
            disabled: get(record, 'purchasing_package.status') !== 'INIT',
            name: record.import_package,
        }),
    };

    const handleChangeStatusOneItem = (name, id) => {
        const data = { [name]: [id] };

        api.changeStatusPackage(data)
            .then(res => {
                refetch();
                notification.success(
                    t('message.update_success_by_attribute', {
                        attribute: t(`label.purchasing_package_status`),
                    })
                );
            })
            .catch(err => {
                const { response } = err;
                let code = get(response, 'data.code', {});
                if (code === 'INPUT_INVALID') {
                    notification.error(t('message.no_data_package_change_status'));
                } else {
                    notification.error(
                        t('message.update_failed_by_attribute', { attribute: t(`label.purchasing_package_status`) })
                    );
                }
            });
    };
    const handleChangeStatus = () => {
        const data = { purchasing_package_ids: checkedList };
        api.changeStatusPackage(data)
            .then(res => {
                refetch();
                notification.success(
                    t('message.update_success_by_attribute', {
                        attribute: t(`label.purchasing_package_status`),
                    })
                );
                setCheckedList([]);
            })
            .catch(err => {
                const { response } = err;

                let code = get(response, 'data.code', {});
                if (code === 'INPUT_INVALID') {
                    notification.error(t('message.no_data_package_change_status'));
                } else {
                    notification.error(
                        t('message.update_failed_by_attribute', { attribute: t(`label.purchasing_package_status`) })
                    );
                }
            });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <div onClick={handleChangeStatus}> {t('product:btn.check_shipping')}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        <div className="d-flex justify-content-between align-items-center">
                            {t('title.list_import_package')}
                            <span className="number-circle _importing-document-product-list-total">
                                {getVar(pagination, 'total', 0)}
                            </span>
                        </div>
                    </h3>
                    <ExportListImportPackage filters={filters} />
                </div>

                <div className="data-box--body">
                    <div className="d-flex custom-collapse">
                        {checkedList.length !== 0 && (
                            <>
                                {t('btn.has_selected')}
                                <span className="font-weight-bold">
                                    &nbsp;{checkedList.length}&nbsp;
                                    {t('product:label.import_package').toLowerCase()}
                                </span>
                                ,&nbsp;
                                <Dropdown overlay={menu}>
                                    <span className="text-cl-base font-weight-500" style={{ cursor: 'pointer' }}>
                                        {t('btn.select_action')} <DownOutlined />
                                    </span>
                                </Dropdown>
                            </>
                        )}
                    </div>
                    <DynamicTable
                        initColumns={getColumns}
                        dataSource={purchasingPackages}
                        rowKey={record => record?.purchasing_package?.id}
                        className="order-list-table pb-3"
                        loading={loading}
                        rowSelection={{
                            ...rowSelection,
                        }}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_import_package'}
                                    action={
                                        <ImportPackageCreateMenu>
                                            <strong className="cursor-pointer pagination-customize">
                                                {t('btn.create_import_package')}
                                                <CaretDownOutlined className="ml-1" />
                                            </strong>
                                        </ImportPackageCreateMenu>
                                    }
                                />
                            ),
                        }}
                        scroll={{ x: true }}
                    />

                    {/* <Table
                        className="order-list-table pb-3"
                        dataSource={purchasingPackages}
                        rowKey={record => record?.purchasing_package?.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        rowSelection={{
                            ...rowSelection,
                        }}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_import_package'}
                                    action={
                                        <ImportPackageCreateMenu>
                                            <strong className="cursor-pointer pagination-customize">
                                                {t('btn.create_import_package')}
                                                <CaretDownOutlined className="ml-1" />
                                            </strong>
                                        </ImportPackageCreateMenu>
                                    }
                                />
                            ),
                        }}
                        scroll={{ x: true }}
                    /> */}
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
