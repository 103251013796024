import Icon from '@ant-design/icons';
import { DatePicker, Row, Col, Spin } from 'antd';
import 'chart.js/auto';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import useFilter from '@Modules/App/Hooks/useFilter';
import { auth } from '@Modules/Auth/services';
import useDashboardQuery from '@Modules/Dashboard/Hooks/useDashboardQuery';
import { DASHBOARD_CHART_CODE, DASHBOARD_STATS_CODE, DASHBOARD_STATS_INFO } from '@Modules/Dashboard/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

function Dashboard({ history, location }) {
    const nowDay = new Date();
    const user = auth.user();
    const sevenDayAgo = new Date().setDate(nowDay.getDate() - 7);
    const { filters, addFilter } = useFilter(history, location, {
        'created_at[from]': moment(sevenDayAgo).format('YYYY-MM-DD'),
        'created_at[to]': moment(nowDay).format('YYYY-MM-DD'),
    });
    const [summary, setSummary] = useState({});
    const [currency, setCurrency] = useState({});
    const [dataChart, setDataChart] = useState({});
    const { data, refetch, isFetching } = useDashboardQuery(filters);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    function _onChangeDate(date) {
        const dateFrom = getVar(date, '0', undefined);
        const dateTo = getVar(date, '1', undefined);
        const createdAtFrom = dateFrom ? dateFrom.format('YYYY-MM-DD') : moment(sevenDayAgo).format('YYYY-MM-DD');
        const createdAtTo = dateTo ? dateTo.format('YYYY-MM-DD') : moment(nowDay).format('YYYY-MM-DD');
        const timeFrom = moment(createdAtFrom);
        const timeTo = moment(createdAtTo);
        if (timeTo.diff(timeFrom, 'days') >= 31) {
            notification.error(t('message.not_over_one_month'));
        } else {
            addFilter({
                'created_at[from]': createdAtFrom,
                'created_at[to]': createdAtTo,
            });
        }
    }

    useEffect(() => {
        const statsInfo = getVar(data, 'data', {});
        const balance = getVar(user, 'balance', 0);
        setSummary({ ...statsInfo, balance });
        setCurrency(get(data, 'data.currency', {}));
        const stats = getVar(data, 'data.stats', {});
        const labels = [];
        const datasets = [];
        Object.keys(stats).forEach(key => {
            labels.push(key);
        });
        Object.keys(DASHBOARD_CHART_CODE).map((item, index) => {
            const statsArray = Object.values(stats);
            datasets.push({
                id: index,
                label: t(`dashboard:dashboard.label.${item}`),
                data: map(statsArray, item),
                backgroundColor: DASHBOARD_CHART_CODE[item].bgColor,
                borderColor: DASHBOARD_CHART_CODE[item].bdColor,
            });
        });

        setDataChart({
            labels,
            datasets,
        });
    }, [data]);

    const created_at_from = get(filters, 'created_at[from]', '');
    const created_at_to = get(filters, 'created_at[to]', '');
    const RightArrow = () => (
        <svg viewBox="0 0 1024 1024" width="12" height="12" fill="currentColor" aria-hidden={true}>
            <path d="M196.266667 896L588.8 512 196.266667 119.466667 315.733333 0l512 512-512 512z"></path>
        </svg>
    );
    const RightArrowIcon = () => <Icon component={RightArrow} />;
    return (
        <div className="">
            <Row className="d-flex justify-content-between _dashboard-content-header">
                <Col xs={{ span: 24 }} lg={{ span: 8 }} className="mb-4 mb-lg-0">
                    <h3 className="text-fz-18">{t('breadcrumb.dashboard')}</h3>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <RangePicker
                        value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                        onChange={date => _onChangeDate(date)}
                        className="_finance-search-created-at"
                        format={dateFormat}
                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                        style={{ width: '100%' }}
                        disabledDate={current => current && current > moment().endOf('day')}
                    />
                </Col>
            </Row>
            <Row className="mt-4 finance_order" gutter={16} justify="space-between" type="flex">
                {!isEmpty(summary) &&
                    DASHBOARD_STATS_INFO.map((item, index) => {
                        const value = summary[item.key];
                        return (
                            <Col key={index} className="mb-2 dashboard-box" xs={{ span: 12 }} sm={{ span: 12 }} xl={{ span: 6 }}>
                                <div className="dashboard_stats d-flex flex-column">
                                    <span>{t(`dashboard:dashboard.label.${item.key}`)}</span>
                                    <span className="stats_amount">
                                        <Spin spinning={isFetching}>
                                            {!isEmpty(currency) ? formatCurrency(value || 0, currency) : value}
                                        </Spin>
                                    </span>

                                    {/* <div className="d-flex align-items-center pt-2 pb-1 text-link">
                                        <Link to={url.to(item.route)} target="_blank" className="text-link"> */}
                                    <div className="d-flex align-items-center pt-2 pb-1 text-link">
                                        <Link to={url.to(item.route)} target="_blank" className="dashboard-director text-link">
                                            {item.name} <RightArrowIcon />
                                        </Link>
                                    </div>
                                </div> 
                            </Col>
                        );
                    })}
            </Row>

            <Row className="mt-4 _dashboard-stats finance_order" gutter={16} justify="space-between" type="flex">
                {!isEmpty(summary) &&
                    DASHBOARD_STATS_CODE.map((item, index) => {
                        const value = summary[item];
                        const order_total = summary['total_order'];
                        return (
                            <Col key={index} className="mb-2" xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div className="finance_stats d-flex flex-column">
                                    <span>
                                        {t(`dashboard:dashboard.label.${item}`) +
                                            (item === 'cod' ? '' : ` / ${t(`dashboard:dashboard.label.total_order`)}`)}
                                    </span>

                                    <span className="_dashboard-stats-amount stats_amount">
                                        <Spin spinning={isFetching}>
                                            {item === 'cod' ? (
                                                !isEmpty(currency) ? (
                                                    formatCurrency(value || 0, currency)
                                                ) : (
                                                    value
                                                )
                                            ) : (
                                                <>
                                                    {`${value}/${order_total} `}
                                                    <b style={{ color: '#1A1A1A' }}>{`(${
                                                        order_total > 0 ? parseFloat((value / order_total) * 100).toFixed(2) : 0
                                                    } %)`}</b>
                                                </>
                                            )}
                                        </Spin>
                                    </span>
                                </div>
                            </Col>
                        );
                    })}
            </Row>
            <div className="mt-4 p-4 bg-white canvas-dashboard box-shadow">
                {!isEmpty(dataChart) && (
                    <Chart
                        height={null}
                        type="line"
                        data={dataChart}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                title: {
                                    align: 'start',
                                    display: true,
                                    text: t('dashboard:dashboard.title.chart_by_day'),
                                },
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
}
export default Dashboard;
