import { Input } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'rc-field-form/lib/interface';
import React from 'react';

import { auth } from '@Modules/Auth/services';
import SelectShippingPartner from '@Modules/ShippingPartner/components/SelectShippingPartner';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

interface IOrderInfoProps {
    validatedRules: any;
    form: FormInstance<any>;
}

const formItemProps = {
    className: 'd-flex flex-row',
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const OrderInfo: React.FC<IOrderInfoProps> = ({ validatedRules, form }) => {
    const user = auth.user();

    return (
        <div className="bg-white rounded-12 p-4 box-shadow">
            <Form.Item>
                <h3>{t('title.order_edit')}</h3>
            </Form.Item>
            <Form.Item {...formItemProps} name="code" label={t('order:label.order_code')} rules={validatedRules.code}>
                <Input
                    name="code"
                    className="_order-info-order-code"
                    placeholder={t('order:placeholder.blank_system_init')}
                    disabled
                />
            </Form.Item>

            <Form.Item {...formItemProps} name="shipping_partner_id" label={t('shipping:label.shipping_partner')}>
                <SelectShippingPartner
                    name="shipping_partner_id"
                    className="_order-info-shipping-partner-id"
                    placeholder={t('shipping:placeholder.shipping_partner')}
                    location_id={getVar(user, 'location_id', undefined)}
                    disabled
                />
            </Form.Item>

            <Form.Item {...formItemProps} name="freight_bill" label={t('order:label.freight_bill_code')}>
                <Input
                    className="_order-info-freight-bill-code"
                    placeholder={t('order:placeholder.freight_bill_code')}
                    disabled
                />
            </Form.Item>

            <Form.Item
                {...formItemProps}
                name="warehouse_id"
                label={t('order:label.warehouse_id')}
                rules={validatedRules.warehouse_id}
            >
                <SelectWarehouse
                    className="_order-info-warehouse-id"
                    disabled
                    placeholder={t('order:placeholder.warehouse_id')}
                    onChange={(value: any) => form.setFieldsValue({ warehouse_id: value })}
                />
            </Form.Item>
        </div>
    );
};

export default OrderInfo;
