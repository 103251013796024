import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, Form, Input, Row, Spin, Table, Upload } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { get } from 'lodash';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import useProductDetailQuery from '@Modules/Dropshipping/Hooks/useDropshipDetailQuery';
import DropshippingServiceList from '@Modules/Dropshipping/components/Detail/DropshippingServiceList';
import HistoryPriceList from '@Modules/Dropshipping/components/Detail/HistoryPriceList';
import { PRODUCT_EVENT } from '@Modules/Dropshipping/services/constants';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

function ProductDetailDropShipping(props) {
    const { lg } = useBreakpoint();
    const user = auth.user();
    const { match, history, location } = props;
    const productId = match.params.id;

    const [product, setProduct] = useState({});
    const [options, setOptions] = useState({});
    const [skus, setSkus] = useState({});
    const [services, setServices] = useState([]);
    const [category, setCategory] = useState({});
    const [images, setImages] = useState([]);
    const [currency, setCurrency] = useState({});
    const [productActivePriceDetails, setProductActivePriceDetails] = useState([]);
    const [productActivePrice, setProductActivePrice] = useState();
    const { error, isError, isFetching, data, refetch } = useProductDetailQuery(productId);

    useEffect(() => {
        setNavigator(t('title.product_detail_dropshipping'), [
            {
                name: t('title.product_list_dropshipping'),
                route: 'dropshipping.list',
            },
            {
                name: t('title.product_detail_dropshipping'),
            },
        ]);

        return events.listen(PRODUCT_EVENT.RELOAD_PRODUCT_DETAIL_DROPSHIP, () => refetch());
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        setProduct(data?.data?.product);
        setDocumentTitle(t('title_page.dropshipping_detail', { code: getVar(data, 'data.product.code', '') }));
        setSkus(getVar(data, 'data.skus', []));
        setOptions(data?.data?.options);
        setServices(getVar(data, 'data.services', []));
        setCategory(getVar(data, 'data.category', {}));
        setCurrency(get(data, 'data.currency'));
        setProductActivePriceDetails(get(data, 'data.product_price_active_details'));
        setProductActivePrice(get(data, 'data.product_price_active'));
    }, [data]);



    useEffect(() => {
        setImages(refactorImage(getVar(product, 'images', [])));
    }, [product]);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    function refactorImage(images) {
        const newImages = [];
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 });
        });
        return newImages;
    }

    const service_export = filter(services, item => {
        if (item.type === SERVICES_TYPE.EXPORT) {
            return <p>{item.name}</p>;
        }
    });
    const service_import = filter(services, item => {
        if (item.type === SERVICES_TYPE.IMPORT) {
            return <p>{item.name}</p>;
        }
    });
    const columns = [
        {
            title: t('label.products'),
            className: '_product-column',
            dataIndex: ['sku', 'name'],
            key: 'name',
            width: '40%',
            render: (text, record, index) => {
                const option = get(record, 'sku.options');
                const images = get(record, 'sku.images[0]');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" src={images && images} className="mr-2" size={36} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <div style={{ fontWeight: '500' }}>
                                    <p className="_product-name">{text}</p>
                                    <p className="_product-option" style={{ fontWeight: '500', color: '#A0A3BD' }}>
                                        {option}
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: t('product:label.sku_code'),
            className: '_sku-column',
            dataIndex: ['sku', 'code'],
            key: 'code',
            width: '320px',
            render: text => (text ? text : ''),
        },
    ];
    const creator_id = getVar(product, 'creator_id', '');

    return (
        <div className="site-content _dropshipping-detail">
            <Spin spinning={isFetching}>
                <div className="d-flex justify-content-between">
                    <div>
                        <Link className="_dropshipping-detail-back mb-4" to={url.to('dropshipping.list', { id: productId })}>
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18">{t('breadcrumb.product_detail')}</h3>
                    </div>

                    {!isEmpty(product) && user.id === creator_id && (
                        <Link className="_btn-edit" to={url.to('dropshipping.edit', { id: productId })}>
                            <Button type={'primary'} icon={<EditOutlined />}>
                                {t('btn.product_edit')}
                            </Button>
                        </Link>
                    )}
                </div>
                <div className="bg-light-gray mt-3 _dropshipping-detail-info">
                    <div className="bg-white rounded-12 p-4 box-shadow">
                        <h3 className="mb-3">{t('title.product_info')}</h3>

                        <Form.Item
                            className="_dropshipping-detail-code"
                            labelAlign={'left'}
                            labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                            wrapperCol={{ span: 12 }}
                            label={t('product:label.code')}
                        >
                            <p>{getVar(product, 'code', '---')}</p>
                        </Form.Item>

                        <Form.Item
                            className="_dropshipping-detail-name"
                            labelAlign={'left'}
                            labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                            wrapperCol={{ span: 12 }}
                            label={t('product:label.name')}
                        >
                            <p>{getVar(product, 'name', '---')}</p>
                        </Form.Item>

                        <Form.Item
                            className="_dropshipping-detail-category"
                            labelAlign={'left'}
                            labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                            wrapperCol={{ span: 12 }}
                            label={t('product:label.category_id')}
                        >
                            <p>{getVar(category, 'name', '---')}</p>
                        </Form.Item>
                        <div className="d-flex align-items-center">
                            <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                            <span className="ml-2 number-circle _dropshipping-detail-picture-total">{images.length}</span>
                            <i className="ml-2">{t('product:description.picture')}</i>
                        </div>
                        <div className="mt-4">
                            <Upload
                                className="_dropshipping-detail-list-product"
                                listType="picture-card"
                                fileList={images}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <Row gutter={16} className="mt-4">
                        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="bg-white rounded-12  p-4 mb-4 _dropshipping-edit-packing-service mb-4 box-shadow">
                                <div className="d-flex justify-content-between">
                                    <h3>{`${t('title.properties')}`}</h3>
                                </div>

                                <div className="product-options mt-4 _product-option">
                                    {options?.length > 0 &&
                                        options.map((item, index) => (
                                            <Row className="d-flex mt-2" key={index} gutter={15}>
                                                <Col lg={{ span: 3 }} span={24}>
                                                    <Input
                                                        value={item.productOption.label}
                                                        disabled
                                                        style={{ fontWeight: '500', backgroundColor: '#fff', color: '#000' }}
                                                    />
                                                </Col>

                                                {item.options.map(val => (
                                                    <Col
                                                        xs={{ span: 12 }}
                                                        lg={{ span: 3 }}
                                                        span={8}
                                                        className={`d-flex justify-content-between ${lg ? 'mt-0' : 'mt-2'}`}
                                                        key={val.id}
                                                    >
                                                        <Input value={val.label} disabled style={{ color: '#000' }} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                    {options?.length === 0 && (
                                        <p className="text-center mt-4 mb-5">{t('product:message.not_options')}</p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <h3 className="mb-4">{t('product:title.variations_list')}</h3>
                                    <div className="option-values">
                                        {skus?.length > 0 && (
                                            <Table
                                                border={true}
                                                rowClassName="_dropshipping-product-skus-row"
                                                className="_list-dropshipping-skus-table mt-3"
                                                columns={columns}
                                                dataSource={skus}
                                                pagination={false}
                                                rowKey={record => record?.sku?.id}
                                                scroll={{ x: true }}
                                            />
                                        )}
                                        {skus?.length === 0 && (
                                            <p className="text-center mt-4 mb-5">{t('product:message.not_info_sku')}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="bg-white rounded-12  p-4 mb-4 box-import-services">
                                <div className="d-flex justify-content-between mb-4 title-import-services">
                                    <h3>{`${t('title.warehouse_service')}`}</h3>
                                </div>
                                <div className="_dropshipping-detail-list-import-service list-import-services">
                                    {service_import.length > 0 ? (
                                        map(service_import, (item, index) => {
                                            return (
                                                <p key={index} className="item-import-services">
                                                    <Badge status="default" /> {item.name}
                                                </p>
                                            );
                                        })
                                    ) : (
                                        <p className="mt-4">{t(`message.no_data_service`)}</p>
                                    )}
                                </div>
                            </div>
                            <div className="bg-white rounded-12 p-4 mb-4">
                                <div className="d-flex justify-content-between mb-4 box-shadow">
                                    <h3>{`${t('title.export_service')}`}</h3>
                                </div>
                                <div className="_dropshipping-detail-list-packing-service">
                                    {service_export.length > 0 ? (
                                        map(service_export, (item, index) => {
                                            return (
                                                <p key={index} className="item-export-services">
                                                    <Badge status="default" /> {item.name}
                                                </p>
                                            );
                                        })
                                    ) : (
                                        <p className="mt-4">{t(`message.no_data_service`)}</p>
                                    )}
                                </div>
                            </div>
                        </Col> */}
                        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                            <DropshippingServiceList
                                productActivePriceDetails={productActivePriceDetails}
                                productActivePrice={productActivePrice}
                                currency={currency}
                                product={product}
                                productId={productId}
                            />
                            <HistoryPriceList
                                productId={productId}
                                currency={currency}
                                history={history}
                                location={location}
                                productActivePrice={productActivePrice}
                            />
                        </Col>
                    </Row>
                </div>
            </Spin>
        </div>
    );
}
export default ProductDetailDropShipping;
