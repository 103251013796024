import {
    ArrowLeftOutlined,
    CaretRightOutlined,
    KeyOutlined,
    LogoutOutlined,
    UserOutlined,
    WalletFilled,
} from '@ant-design/icons';
import { Layout } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Link } from 'react-router-dom';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

import { auth } from '../../Auth/services';
import ConfigMenu from '../Header/ConfigMenu';
import UserInfo from '../Header/UserInfo';
import Language from '../Language';

const { Header } = Layout;

export default function ProfileMobile({ isShown, setIsShown }) {
    const user = auth.user();
    const currency = getVar(user, 'currency', {});
    const balance = getVar(user, 'balance', 0);

    function logout(e) {
        e.preventDefault();
        auth.logout();
    }

    const menuConfig = [
        {
            item: (
                <span className="d-flex justify-content-between align-items-center">
                    {t('label.lang')} <CaretRightOutlined className="mr-0" />
                </span>
            ),
            componentTab: <Language />,
        },
        {
            item: (
                <a href="#">
                    <UserOutlined className="mr-1" /> {t('title.account_info')}
                </a>
            ),
        },
        {
            item: (
                <a href="#">
                    <KeyOutlined className="mr-1" /> {t('title.change_pin')}
                </a>
            ),
        },
        {
            item: (
                <a className="text-red" href="#" onClick={logout}>
                    <LogoutOutlined className="mr-1" /> {t('common:menu.logout')}
                </a>
            ),
        },
    ];

    return (
        <div className={`mobile-tab ${isShown ? 'mobile-tab--shown' : ''}`}>
            <Header className="page-header">
                <div className="profile-mobile-tab--back text-fz-18 cursor-pointer" onClick={() => setIsShown(false)}>
                    <ArrowLeftOutlined />
                </div>

                <span>{user.name || user.username}</span>

                <UserInfo />
            </Header>
            <ConfigMenu visible={true} items={menuConfig} width={'100%'}>
                <div className={'gobiz-dropdown gobiz-dropdown--show mb-3'} style={{ width: '100%' }}>
                    <div className={`gobiz-dropdown--container`}>
                        <div className="gobiz-dropdown--main pt-1 pb-1">
                            <ul>
                                <li>
                                    <WalletFilled className="mr-2" />
                                    <span>{`${t('label.balance')}: ${
                                        !isEmpty(currency) ? formatCurrency(balance || 0, currency) : balance
                                    }`}</span>{' '}
                                    <Link to={'/deposit'} className="ml-2 btn-link" onClick={() => setIsShown(false)}>
                                        {t('label.deposit')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ConfigMenu>
        </div>
    );
}
