import { Form, Input } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

import Location from './Location';

const { TextArea } = Input;
export default function ReceivedInfo({ validatedRules, form }) {
    return (
        <div className="bg-white rounded-12 p-4 mt-4 mt-md-0 box-shadow">
            <Form.Item>
                <h3>{t('order:label.customer')}</h3>
            </Form.Item>

            <div>
                <Form.Item
                    className="mb-3"
                    name="receiver_name"
                    label={t('order:label.receiver_name')}
                    rules={validatedRules.receiver_name}
                >
                    <Input
                        className="_receive-info-name"
                        name="receiver_name"
                        placeholder={t('order:placeholder.receiver_name')}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="receiver_phone"
                    label={t('order:label.receiver_phone')}
                    rules={validatedRules.receiver_phone}
                >
                    <Input
                        name="receiver_phone"
                        className="_receive-info-phone"
                        placeholder={t('order:placeholder.receiver_phone')}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="receiver_address"
                    label={t('order:label.receiver_address')}
                    rules={validatedRules.receiver_address}
                >
                    <Input
                        className="_receive-info-address"
                        name="receiver_address"
                        placeholder={t('order:placeholder.receiver_address')}
                    />
                </Form.Item>

                <Location validatedRules={validatedRules} form={form} />

                <Form.Item className="mb-0" name="description" label={t('label.note')} rules={validatedRules.description}>
                    <TextArea
                        className="_receive-info-description"
                        name="description"
                        placeholder={t('placeholder.note')}
                        style={{ height: 100 }}
                    />
                </Form.Item>
            </div>
        </div>
    );
}
