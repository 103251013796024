import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import { ISettings } from '@Modules/Setting/Interfaces';

import { t } from '@System/i18n';
import ModalSetting from './ModalSetting';

interface IChannelSettingProps {
    storeId: number;
    settings: ISettings;
    refetchList: () => void;
    isMultiWarehouse?: boolean
}

const ChannelSetting: React.FC<IChannelSettingProps> = props => {
    const { storeId, settings, refetchList } = props;

    const [visible, setVisible] = useState(false);

    const handleHideModal = () => {
        setVisible(false);
    };

    const handleVisibleModal = () => {
        setVisible(true);
    };

    return (
        <>
            <Button icon={<SettingOutlined />} disabled={props.isMultiWarehouse} className="ml-2" onClick={handleVisibleModal}>
                {t('setting:sales_channel.btn.setting')}
            </Button>
            {visible && (
                <ModalSetting
                    handleHideModal={handleHideModal}
                    storeId={storeId}
                    settings={settings}
                    refetchList={refetchList}
                />
            )}
        </>
    );
};


export default ChannelSetting;
