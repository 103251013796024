import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useListDropshipQuery from '@Modules/Dropshipping/Hooks/useListDropshipQuery';

import { t } from '@System/i18n';
import { url } from '@System/routing';

import FormSearch from './FormSearch';
import List from './List';

export default function ListDropshipping({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {});
    const [pagination, setPagination] = useState({});
    const [products, setProducts] = useState([]);
    const [currency, setCurrency] = useState({});
    const { data, isFetching, refetch, isError, error } = useListDropshipQuery(filters);

    useEffect(() => {
        setNavigator(t('menu.dropshipping'), [
            {
                name: t('breadcrumb.dropshipping'),
            },
        ]);
        setDocumentTitle(t('title.dropshipping'));
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setProducts(get(data, 'data.products', []));
        setPagination(get(data, 'data.pagination', {}));
        setCurrency(get(data,'data.currency',{}))
    }, [data]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }
    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.dropshipping')}</h3>
                <Link to={url.to('dropshipping.create')}>
                    <Button icon={<PlusOutlined />} type="primary" className="_dropshipping_add_product">
                        {t('btn.create_product')}
                    </Button>
                </Link>
            </div>
            <FormSearch onSearch={addFilter} filters={filters} loading={isFetching} />
            <List
                data={products}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={isFetching}
                refetch={refetch}
                currency={currency}
            />
        </div>
    );
}
