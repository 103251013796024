import React from 'react';

import { IOrderProduct } from '@Modules/Order/Interfaces';

import ProductTable from './ProductTable';
import RecommendProduct from './RecommendProduct';
import { FormInstance } from 'antd/lib/form';

interface IProductOrderProps {
    products: IOrderProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IOrderProduct[]>>;
    validatedRules: any;
    form: FormInstance;
}

const ProductOrder: React.FC<IProductOrderProps> = ({ products, setProducts, validatedRules, form }) => {
    return (
        <div className="mb-2">
            <RecommendProduct products={products} setProducts={setProducts} form={form}/>
            <ProductTable products={products} setProducts={setProducts} validatedRules={validatedRules} form={form} />
        </div>
    );
};

export default ProductOrder;
