import { Button, Checkbox, Col, Empty, Form, Modal, Row, Select, Space, Spin, Typography } from 'antd'
import { every, isArray, isEmpty, sortBy, uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import useCreateConfigWarehouseMutation from '@Modules/Setting/Hooks/useCreateConfigWarehouseMutation'
import useWarehousePartnerListQuery from '@Modules/Setting/Hooks/useWarehousePartnerListQuery'
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses'

import { t } from '@System/i18n'
import notification from '@System/notification'

interface Props {
    refetchList: () => void
    storeID: number
    record: any
    isDisabled: boolean
}

const ConfigWarehouse = (props: Props) => {
    const [formRef] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [isConfigured, setIsConfigured] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [listWarehouse, setListWarehouse] = useState([])
    const [countMoreClickEdit, setCountMoreClickEdit] = useState<number>(0)
    const [loadingMoreEdit, setLoadingMoreEdit] = useState<boolean>(false)

    const { data: warehousePartner, isLoading: loadingWarehousePartner } = useWarehousePartnerListQuery(
        { storeID: props?.storeID, countMoreClickEdit: countMoreClickEdit },
        visible
    )
    const warehousePartnerRecord = props?.record?.multi_warehouse || []
    const listWarehousePartner = warehousePartner?.data || []
    const combinedWarehouse = isArray(warehousePartnerRecord)
        ? warehousePartnerRecord?.concat(listWarehousePartner)
        : listWarehousePartner
    const uniqueWarehouseData = uniqBy(combinedWarehouse, 'marketplace_warehouse_id')
    // const isConfigSetting = !isEmpty(listWarehousePartner)
    const isConfigSetting = false

    const createConfigWarehouse = useCreateConfigWarehouseMutation()

    const listHeader = [
        {
            title: t('setting:sales_channel.title.partner_warehouse'),
            span: isConfigSetting ? 8 : 12,
            className: '',
            classCol: '',
            required: false,
            display: true,
        },
        {
            title: t('setting:sales_channel.title.export_warehouse'),
            span: isConfigSetting ? 9 : 12,
            className: '',
            classCol: '',
            required: true,
            display: true,
        },
        {
            title: t('setting:sales_channel.title.synchronize_inventory'),
            span: 3,
            className: '',
            classCol: 'text-center',
            required: false,
            display: isConfigSetting,
        },
        {
            title: t('setting:sales_channel.title.synchronous_type'),
            span: 4,
            className: '',
            classCol: '!tw-pl-3.5',
            required: false,
            display: isConfigSetting,
        },
    ]

    useEffect(() => {
        if (createConfigWarehouse?.isLoading) {
            setLoadingMoreEdit(true)
        } else {
            if (countMoreClickEdit > 0) {
                setTimeout(() => {
                    setLoadingMoreEdit(false)
                }, 2000)
            } else {
                setLoadingMoreEdit(false)
            }
        }
    }, [countMoreClickEdit, createConfigWarehouse?.isLoading])

    useEffect(() => {
        if (visible && warehousePartner?.data?.length === 0) {
            setVisible(false)
            notification.error(t('setting:sales_channel.message.cannot_use_multi_warehouse'))
        }
    }, [warehousePartner?.data, visible])

    useEffect(() => {
        setIsLoading(true)
        ServiceWarehouses.list()
            .then((res: any) => {
                setListWarehouse(res)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (!isConfigured) {
            const dataWarehouse = uniqueWarehouseData?.map((el: any) => ({
                warehousePartner: {
                    value: el?.marketplace_warehouse_id,
                    key: el?.marketplace_warehouse_id,
                    marketplace_warehouse_name: el?.marketplace_warehouse_name,
                    marketplace_location_id: el?.marketplace_location_id,
                    marketplace_address_id: el?.marketplace_address_id,
                },
                warehouseSystem: el?.warehouse_id ? el?.warehouse_id : 0,
                syncType: 'estimate',
                checkbox: false,
            }))
            formRef.setFieldsValue({
                warehouse: dataWarehouse,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueWarehouseData, isConfigured])

    const doNotUseWarehouseValue = {
        warehouse: {
            id: 0,
            name: t('setting:sales_channel.label.do_not_use'),
        },
    }

    const getWarehousePartnerOptions = () => {
        let options: any = []
        if (isEmpty(uniqueWarehouseData)) {
            return []
        }
        options = (uniqueWarehouseData || []).map((item: any) => {
            return {
                value: item?.marketplace_warehouse_id,
                label: `${item?.marketplace_warehouse_name} (${item?.marketplace_warehouse_id})`,
            }
        })
        return sortBy(options, ['label'])
    }

    const getWarehouseOptions = () => {
        let options: any = []
        if (isEmpty(listWarehouse)) {
            return []
        }
        options = ([...listWarehouse, doNotUseWarehouseValue] || [doNotUseWarehouseValue]).map((item: any) => {
            return {
                value: item?.warehouse?.id,
                label: item?.warehouse?.id === 0 ? item?.warehouse?.name : `${item?.warehouse?.name} (${item?.warehouse?.id})`,
                disabled:
                    item?.warehouse?.id === 0
                        ? false
                        : formRef?.getFieldValue('warehouse')?.some((el: any) => el?.warehouseSystem === item?.warehouse?.id),
            }
        })
        return sortBy(options, ['label'])
    }

    const handleHideModal = () => {
        setVisible(false)
    }

    const handleVisibleModal = () => {
        setVisible(true)
        setCountMoreClickEdit((old: number) => old + 1)
    }

    const onCreateConfigWarehouse = (values: any) => {
        setIsConfigured(true)
        setCountMoreClickEdit((old: number) => old + 1)
        const isAllWarehouseSystemZero = every(values?.warehouse, { warehouseSystem: 0 })

        if (isAllWarehouseSystemZero) {
            setTimeout(() => {
                notification.error(t('setting:sales_channel.message.config_warehouse.at_least_one_warehouse'))
            }, 2000)
        } else {
            const dataRequest = {
                storeID: props?.storeID,
                warehouses: {
                    warehouses: values?.warehouse?.map((item: any) => ({
                        warehouse_id: item?.warehouseSystem,
                        marketplace_warehouse_name: item?.warehousePartner?.marketplace_warehouse_name,
                        marketplace_warehouse_id: `${item?.warehousePartner?.value}`,
                        marketplace_location_id: item?.warehousePartner?.marketplace_location_id,
                        marketplace_address_id: item?.warehousePartner?.marketplace_address_id,
                    })),
                },
            }
            createConfigWarehouse.mutateAsync(dataRequest as any).then((result: any) => {
                if (result?.status === 200) {
                    props.refetchList()
                    setIsConfigured(false)
                    setVisible(false)
                    formRef.resetFields()
                    notification.success(t('setting:sales_channel.message.config_warehouse.success'))
                }
            })
        }
    }

    return (
        <>
            <Button
                disabled={props?.isDisabled}
                icon={<i className="fa-solid fa-arrow-up-wide-short"></i>}
                className="ml-2"
                onClick={handleVisibleModal}
            >
                    <span className='tw-ml-2'>{`${t('setting:sales_channel.title.upgrade')}/${t('setting:sales_channel.title.multi_warehouse_settings')}`}</span>
            </Button>
            {visible && (
                <Modal
                    destroyOnClose
                    bodyStyle={{ paddingTop: 8 }}
                    maskClosable={false}
                    title={t(`${t('setting:sales_channel.title.upgrade')}/${t('setting:sales_channel.title.multi_warehouse_settings')}`)}
                    visible={visible}
                    footer={null}
                    width={isConfigSetting ? 770 : 620}
                    onCancel={() => {
                        setIsConfigured(false)
                        handleHideModal()
                        formRef.resetFields()
                    }}
                >
                    {loadingWarehousePartner ? (
                        <div className="tw-w-full tw-h-[500px] tw-flex tw-justify-center tw-items-center">
                            <Spin />
                        </div>
                    ) : (
                        <Form
                            form={formRef}
                            onFinish={onCreateConfigWarehouse}
                            layout="vertical"
                        >
                            <div className="tw-bg-gray-200 tw-p-2 !tw-pb-0 tw-border-b-black tw-mb-0">
                                <Row gutter={15}>
                                    {listHeader.map(
                                        (
                                            item: {
                                                title: string
                                                span: number
                                                className?: string
                                                classCol: string
                                                display: boolean
                                                required?: boolean
                                            },
                                            index: number
                                        ) =>
                                            item?.display ? (
                                                <Col
                                                    span={item.span}
                                                    className={item?.classCol}
                                                    key={index}
                                                >
                                                    <Typography.Text
                                                        strong
                                                        className={item?.className}
                                                    >
                                                        {item.title}
                                                        {item?.required && <span className="tw-ml-1 tw-text-red-500">*</span>}
                                                    </Typography.Text>
                                                </Col>
                                            ) : (
                                                ''
                                            )
                                    )}
                                </Row>
                            </div>
                            <Row className="tw-mt-2">
                                <Col span={24}>
                                    <Form.List name={`warehouse`}>
                                        {fields => (
                                            <div>
                                                {fields.map(({ name, ...restField }) => (
                                                    <Row
                                                        key={name}
                                                        gutter={[15, 8]}
                                                    >
                                                        <Col span={isConfigSetting ? 8 : 12}>
                                                            <Form.Item
                                                                noStyle
                                                                shouldUpdate={(pre, next) => pre?.warehouse !== next?.warehouse}
                                                            >
                                                                {() => {
                                                                    return (
                                                                        <Form.Item
                                                                            {...restField}
                                                                            className="!mb-0"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t('validation:required', {
                                                                                        attribute: t(
                                                                                            'setting:sales_channel.title.export_warehouse'
                                                                                        ),
                                                                                    }),
                                                                                },
                                                                            ]}
                                                                            name={[name, 'warehousePartner']}
                                                                        >
                                                                            <Select
                                                                                placeholder={t(
                                                                                    'setting:sales_channel.title.export_warehouse'
                                                                                )}
                                                                                suffixIcon={null}
                                                                                labelInValue
                                                                                disabled={true}
                                                                                options={getWarehousePartnerOptions()}
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                optionFilterProp="label"
                                                                                notFoundContent={<Empty />}
                                                                                filterOption={(input: any, option: any) => {
                                                                                    return (option?.label ?? '')
                                                                                        ?.toLowerCase()
                                                                                        .includes(input?.toLowerCase())
                                                                                }}
                                                                                filterSort={(optionA: any, optionB: any) => {
                                                                                    return (optionA?.label ?? '')
                                                                                        .toLowerCase()
                                                                                        .localeCompare(
                                                                                            (optionB?.label ?? '').toLowerCase()
                                                                                        )
                                                                                }}
                                                                            ></Select>
                                                                        </Form.Item>
                                                                    )
                                                                }}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={isConfigSetting ? 9 : 12}>
                                                            <Form.Item
                                                                noStyle
                                                                shouldUpdate={(pre, next) => pre?.warehouse !== next?.warehouse}
                                                            >
                                                                {() => {
                                                                    return (
                                                                        <Form.Item
                                                                            {...restField}
                                                                            className="!mb-0"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t('validation:required', {
                                                                                        attribute: t(
                                                                                            'setting:sales_channel.title.partner_warehouse'
                                                                                        ),
                                                                                    }),
                                                                                },
                                                                            ]}
                                                                            name={[name, 'warehouseSystem']}
                                                                        >
                                                                            <Select
                                                                                placeholder={t(
                                                                                    'setting:sales_channel.title.partner_warehouse'
                                                                                )}
                                                                                showSearch
                                                                                loading={isLoading}
                                                                                allowClear
                                                                                options={getWarehouseOptions()}
                                                                                onChange={() => {
                                                                                    const valueExportPartner =
                                                                                        formRef?.getFieldsValue()?.warehouse?.[
                                                                                            name
                                                                                        ]?.warehouseSystem
                                                                                    if (valueExportPartner === 0) {
                                                                                        formRef.setFields([
                                                                                            {
                                                                                                name: [
                                                                                                    'warehouse',
                                                                                                    name,
                                                                                                    'checkbox',
                                                                                                ],
                                                                                                value: false,
                                                                                            },
                                                                                            {
                                                                                                name: [
                                                                                                    'warehouse',
                                                                                                    name,
                                                                                                    'syncType',
                                                                                                ],
                                                                                                value: 'estimate',
                                                                                            },
                                                                                        ])
                                                                                    }
                                                                                }}
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                optionFilterProp="label"
                                                                                notFoundContent={<Empty />}
                                                                                filterOption={(input: any, option: any) => {
                                                                                    return (option?.label ?? '')
                                                                                        ?.toLowerCase()
                                                                                        .includes(input?.toLowerCase())
                                                                                }}
                                                                                filterSort={(optionA: any, optionB: any) => {
                                                                                    return (optionA?.label ?? '')
                                                                                        .toLowerCase()
                                                                                        .localeCompare(
                                                                                            (optionB?.label ?? '').toLowerCase()
                                                                                        )
                                                                                }}
                                                                            ></Select>
                                                                        </Form.Item>
                                                                    )
                                                                }}
                                                            </Form.Item>
                                                        </Col>
                                                        {isConfigSetting && (
                                                            <Col span={3}>
                                                                <Form.Item
                                                                    noStyle
                                                                    shouldUpdate={(pre, next) =>
                                                                        pre?.warehouse !== next?.warehouse
                                                                    }
                                                                >
                                                                    {({ getFieldValue }) => {
                                                                        return (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                className="!tw-mb-0 !tw-mr-3 tw-flex tw-justify-center tw-text-center"
                                                                                name={[name, 'checkbox']}
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox
                                                                                    disabled={
                                                                                        getFieldValue([
                                                                                            'warehouse',
                                                                                            name,
                                                                                            'warehouseSystem',
                                                                                        ]) === 0
                                                                                    }
                                                                                    onChange={e => {
                                                                                        !e.target.checked &&
                                                                                            formRef.setFields([
                                                                                                {
                                                                                                    name: [
                                                                                                        'warehouse',
                                                                                                        name,
                                                                                                        'syncType',
                                                                                                    ],
                                                                                                    value: 'estimate',
                                                                                                },
                                                                                            ])
                                                                                    }}
                                                                                ></Checkbox>
                                                                            </Form.Item>
                                                                        )
                                                                    }}
                                                                </Form.Item>
                                                            </Col>
                                                        )}
                                                        {isConfigSetting && (
                                                            <Col span={4}>
                                                                <Form.Item
                                                                    noStyle
                                                                    shouldUpdate={(pre, next) =>
                                                                        pre?.warehouse !== next?.warehouse ||
                                                                        pre?.warehouse[name]?.checkbox !==
                                                                            next?.warehouse[name]?.checkbox
                                                                    }
                                                                >
                                                                    {({ getFieldValue }) => {
                                                                        return (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                className="!tw-mb-0"
                                                                                name={[name, 'syncType']}
                                                                            >
                                                                                <Select
                                                                                    placeholder={t(
                                                                                        'setting:sales_channel.title.synchronous_type'
                                                                                    )}
                                                                                    disabled={
                                                                                        !getFieldValue([
                                                                                            'warehouse',
                                                                                            name,
                                                                                            'checkbox',
                                                                                        ])
                                                                                    }
                                                                                >
                                                                                    <Select.Option
                                                                                        value="actual"
                                                                                        className="tw-px-0"
                                                                                    >
                                                                                        {t('setting:sales_channel.label.actual')}
                                                                                    </Select.Option>
                                                                                    <Select.Option
                                                                                        value="estimate"
                                                                                        className="tw-px-0"
                                                                                    >
                                                                                        {t(
                                                                                            'setting:sales_channel.label.estimate'
                                                                                        )}
                                                                                    </Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        )
                                                                    }}
                                                                </Form.Item>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                ))}
                                            </div>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>

                            <Space className="tw-flex tw-justify-end tw-w-full">
                                <Button
                                    htmlType="button"
                                    disabled={loadingMoreEdit}
                                    loading={loadingMoreEdit}
                                    onClick={() => {
                                        setIsConfigured(false)
                                        handleHideModal()
                                        formRef.resetFields()
                                    }}
                                >
                                    {t('setting:sales_channel.btn.close')}
                                </Button>
                                <Button
                                    type="primary"
                                    disabled={loadingMoreEdit}
                                    loading={loadingMoreEdit}
                                    htmlType="submit"
                                >
                                    {t('setting:sales_channel.btn.save')}
                                </Button>
                            </Space>
                        </Form>
                    )}
                </Modal>
            )}
        </>
    )
}

export default ConfigWarehouse
