import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { Form, Row, Col, Button, Input, DatePicker } from 'antd'
import get from 'lodash/get'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import useSize from '@Modules/App/Hooks/useSize'
import SelectStatuses from '@Modules/Product/components/SelectStatuses'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { t } from '@System/i18n'

const { RangePicker } = DatePicker

const dateFormat = 'DD/MM/YYYY'

function FormSearch({ onSearch, filters, initialFilters, loading, isCombo }) {
    const { isMobile, isTablet } = useSize()
    const [form] = Form.useForm()
    const [input, setInput] = useState(filters)
    const { code, name, warehouse_id, status } = input || {}

    const created_at_from = get(input, 'created_at[from]', '')
    const created_at_to = get(input, 'created_at[to]', '')
    useEffect(() => {
        setInput(filters)
    }, [filters])

    function _onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        setInput({ ...input, [name]: value })
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        })
    }

    function _onChangeSelect(name, value) {
        let newFilter = { ...input, [name]: value }
        setInput(newFilter)
    }

    function _onSubmit() {
        onSearch(input)
    }

    function _onReset() {
        onSearch(initialFilters)
    }

    return (
        <Form
            form={form}
            layout="vertical">
            <div className="bg-white rounded-12 p-4 mt-3 box-shadow">
                <div className="search-box">
                    <Row gutter={20}>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 6 }}>
                            <Form.Item
                                className="mb-3"
                                label={t('product:label.sku_code')}>
                                <Input
                                    className="_products-search-code"
                                    name="code"
                                    value={code}
                                    placeholder={t('product:placeholder.sku_code')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 6 }}>
                            <Form.Item
                                className="mb-3"
                                label={t('product:label.name')}>
                                <Input
                                    className="_products-search-name"
                                    name="name"
                                    value={name}
                                    placeholder={t('product:placeholder.name')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 6 }}>
                            <Form.Item
                                className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                label={t('label.created_at')}>
                                <RangePicker
                                    className="_products-search-created-at"
                                    value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                    format={dateFormat}
                                    placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                    onChange={date => _onChangeDate('created_at', date)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                       {!isCombo &&  <Col
                            xs={{ span: 12 }}
                            lg={{ span: 6 }}>
                            <Form.Item
                                className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                label={t('label.warehouse')}>
                                <SelectWarehouse
                                    value={warehouse_id ? warehouse_id : []}
                                    allowClear={true}
                                    onChange={value => _onChangeSelect('warehouse_id', value)}
                                    placeholder={t('label.select_warehouses')}
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>}

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 24 }}
                            className="text-right mt-2">
                            <Button
                                className="mr-2 _products-search-btn-reset"
                                onClick={_onReset}
                                loading={loading}
                            >
                                
                                <UndoOutlined /> {t('common:btn.set_again')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={_onSubmit}
                                loading={loading}
                                className="_products-search-btn-search btn-primary">
                                <SearchOutlined /> {t('common:btn.search')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    )
}

export default FormSearch
