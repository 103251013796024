import axios from 'axios';

import { t } from '@System/i18n';

import notification from '../notification';

export default function processResponseError(error, handler = null) {
    if (!axios.isCancel(error)) {
        switch (error?.response?.status) {
            case 400: {
                handler ? handler(error) : notification.error(t('message.400'));
                return;
            }
            case 401:
            case 403: {
                notification.error(t(`message.${error.response.status}`));
                return;
            }
            case 404: {
                notification.error(t(`message.${error.response.status}`));
                return;
            }
            default: {
                notification.error(t('message.server_error'));
                return;
            }
        }
    }
}
