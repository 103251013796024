import lodash from 'lodash';
import { isEmpty } from 'lodash';
import { forEach } from 'lodash';

import { t } from '@System/i18n';
import notification from '@System/notification';

export function renderError(error) {
    const {
        response: { data },
    } = error;
    const errors = lodash.get(data, 'data.errors', '');
    let messages = [];

    Object.entries(errors).forEach(([key, value]) => {
        messages.push(
            t(`order:message.create.${value}`, {
                attribute: t(`order:label.${key}`),
            })
        );
    });

    if (!isEmpty(messages)) {
        forEach(messages, item => notification.error(item));
        return;
    }

    return notification.error(t('common:message.server_error'));
}
