import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { getVar } from '@System/support/helpers'

import { t } from '../../../../system/i18n'
import { PRODUCT_STATUS } from '../../services/constants'

function QuickFilter({ addFilter, filters, className, loading, data, isCombo }) {
    const [pagination, setPagination] = useState({})

    const { status, nearly_sold_out, not_yet_in_stock, out_of_stock, lack_of_export_goods } = filters

    useEffect(() => {
        setPagination(getVar(data, 'data.pagination', {}))
    }, [data])

    function onClick(name, value) {
        switch (name) {
            case 'status':
                return addFilter({
                    ...filters,
                    [name]: value,
                    nearly_sold_out: undefined,
                    not_yet_in_stock: undefined,
                    out_of_stock: undefined,
                    lack_of_export_goods: undefined,
                })
            case 'nearly_sold_out':
                return addFilter({
                    ...filters,
                    [name]: value,
                    status: undefined,
                    not_yet_in_stock: undefined,
                    out_of_stock: undefined,
                    lack_of_export_goods: undefined,
                })
            case 'not_yet_in_stock':
                return addFilter({
                    ...filters,
                    [name]: value,
                    nearly_sold_out: undefined,
                    status: undefined,
                    out_of_stock: undefined,
                    lack_of_export_goods: undefined,
                })
            case 'out_of_stock':
                return addFilter({
                    ...filters,
                    [name]: value,
                    not_yet_in_stock: undefined,
                    nearly_sold_out: undefined,
                    status: undefined,
                    lack_of_export_goods: undefined,
                })
            case 'lack_of_export_goods':
                return addFilter({
                    ...filters,
                    [name]: value,
                    not_yet_in_stock: undefined,
                    nearly_sold_out: undefined,
                    status: undefined,
                    out_of_stock: undefined,
                })
            default:
                return addFilter({ ...filters, [name]: value })
        }
    }

    return (
        <div className={className}>
            <Button
                className={`mr-2 _quick-filter-${PRODUCT_STATUS.ON_SELL} _quick-filter-btn`}
                type={PRODUCT_STATUS.ON_SELL === status ? 'primary' : 'default'}
                key={PRODUCT_STATUS.ON_SELL}
                onClick={() => onClick('status', PRODUCT_STATUS.ON_SELL)}
                loading={PRODUCT_STATUS.ON_SELL === status ? loading : false}
                style={{ minWidth: '100px' }}
            >
                {t(`product:status.${PRODUCT_STATUS.ON_SELL}`)}{' '}
                {PRODUCT_STATUS.ON_SELL === status ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
            </Button>
            <Button
                className={`mr-2 _quick-filter-${PRODUCT_STATUS.STOP_SELLING} _quick-filter-btn`}
                type={PRODUCT_STATUS.STOP_SELLING === status ? 'primary' : 'default'}
                key={PRODUCT_STATUS.STOP_SELLING}
                onClick={() => onClick('status', PRODUCT_STATUS.STOP_SELLING)}
                loading={PRODUCT_STATUS.STOP_SELLING === status ? loading : false}
                style={{ minWidth: '100px' }}
            >
                {t(`product:status.${PRODUCT_STATUS.STOP_SELLING}`)}{' '}
                {PRODUCT_STATUS.STOP_SELLING === status ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
            </Button>
            {!isCombo && (
                <>
                    <Button
                        className={`mr-2 _quick-filter-lack_of_export_goods _quick-filter-btn`}
                        type={lack_of_export_goods ? 'primary' : 'default'}
                        key={'lack_of_export_goods'}
                        onClick={() => onClick('lack_of_export_goods', 1)}
                        loading={lack_of_export_goods ? loading : false}
                        style={{ minWidth: '100px' }}
                    >
                        {t(`product:status.LACK_OF_EXPORT_GOODS`)}{' '}
                        {lack_of_export_goods ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
                    </Button>
                    <Button
                        className={`mr-2 _quick-filter-nearly-sold-out _quick-filter-btn`}
                        type={nearly_sold_out ? 'primary' : 'default'}
                        key={'nearly_sold_out'}
                        onClick={() => onClick('nearly_sold_out', 1)}
                        loading={nearly_sold_out ? loading : false}
                        style={{ minWidth: '100px' }}
                    >
                        {t(`product:status.NEARLY_OUT_STOCK`)}{' '}
                        {nearly_sold_out ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
                    </Button>
                    <Button
                        className={`mr-2 _quick-filter-out-of-stock  _quick-filter-btn`}
                        type={out_of_stock ? 'primary' : 'default'}
                        key={'out_of_stock'}
                        onClick={() => onClick('out_of_stock', 1)}
                        loading={out_of_stock ? loading : false}
                        style={{ minWidth: '100px' }}
                    >
                        {t(`product:status.OUT_OF_STOCK`)} {out_of_stock ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
                    </Button>
                    <Button
                        className={`mr-2 _quick-filter-not-yet-in-stock _quick-filter-btn`}
                        type={not_yet_in_stock ? 'primary' : 'default'}
                        key={'not_yet_in_stock'}
                        onClick={() => onClick('not_yet_in_stock', 1)}
                        loading={not_yet_in_stock ? loading : false}
                        style={{ minWidth: '100px' }}
                    >
                        {t(`product:status.NOT_YET_IN_STOCK`)}{' '}
                        {not_yet_in_stock ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
                    </Button>
                </>
            )}
        </div>
    )
}

export default QuickFilter
