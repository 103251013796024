import { DownloadOutlined } from '@ant-design/icons'
import { Badge, Button, Modal, Table, Tooltip } from 'antd'
import { get, isEmpty } from 'lodash'
import React from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import { dateFormatter } from '@Modules/App/services'
import ModalTransactionDetail from '@Modules/Finance/components/ModalTransactionDetail'
import ExportTransaction from '@Modules/Finance/components/Transaction/ExportTransaction'
import { TRANSACTION_STATUS, TRANSACTION_STATUS_COLORS } from '@Modules/ImportPackage/services/constants'

import { t } from '@System/i18n'
import { Link } from '@System/routing'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

const typeNotShowDetails = ['IMPORT_RETURN_GOODS_SERVICE', 'COD', 'SHIPPING', 'IMPORT_SERVICE', 'EXPORT_SERVICE', 'EXTENT', "COST_OF_GOODS"]

const List = ({ data, pagination, addFilter, filters, loading, currency }) => {
    const getColumns = [
        {
            className: '_finances-amount text-left',
            title: t('label.time'),
            dataIndex: ['timestamp'],
            key: 'timestamp',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_finances-collect-cod',
            title: t('label.transaction_type'),
            dataIndex: ['transactionType'],
            key: 'transactionType',
            render: (text, record) => {
                let status = get(record, 'purchaseUnits[0].customType')
                return text ? (
                    <Badge
                        color={TRANSACTION_STATUS_COLORS[status]}
                        text={t(`finance:TRANSACTION_STATUS.${TRANSACTION_STATUS[status]}`)}
                    />
                ) : (
                    '---'
                )
            },
        },
        {
            className: '_finances-packing-costs text-right',
            title: t('finance:label.amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                let status = get(record, 'purchaseUnits[0].customType')
                return text.toString() && text !== 0 ? (
                    !isEmpty(currency) ? (
                        <p style={{ color: TRANSACTION_STATUS_COLORS[status] }}>
                            {text > 0 ? `+${formatCurrency(text, currency)}` : formatCurrency(text, currency)}
                        </p>
                    ) : (
                        text
                    )
                ) : (
                    ''
                )
            },
        },
        {
            className: '_finances-shipping-costs text-right',
            title: t('finance:label.balance_at'),
            dataIndex: ['balanceAfter'],
            key: 'balanceAfter',
            render: (text, record) => {
                return <p style={{ fontWeight: 500 }}>{text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : formatCurrency('0', currency)}</p>
            },
        },
        {
            className: '_finances-remaining-amount text-right',
            title: t('label.note'),
            dataIndex: 'memo',
            key: 'memo',
            render: (text, record) => {
                let description = get(record, 'purchaseUnits[0].description')
                let status = get(record, 'purchaseUnits[0].customType')
                let statusInit = get(record, 'transactionType')
                return <p className="text-wrap">{renderDescription(status, description, text, statusInit)}</p>
            },
        },
        {
            className: '_finances-shipping-detail text-right',
            title: t('label.transaction_detail'),
            dataIndex: ['transactionType'],
            key: 'transactionType',
            render: (text, record) => {
                let purchaseUnits = get(record, 'purchaseUnits')
                let name = get(purchaseUnits[0], 'name')
                let splitCode = get(purchaseUnits[0], 'orderId') && get(purchaseUnits[0], 'orderId').split('-')[1]
                let splitType = get(purchaseUnits[0], 'orderId') && get(purchaseUnits[0], 'orderId').split('-')[0]
                let toLink = splitType === 'order' ? 'orders' : 'import-package'
                let status = get(record, 'purchaseUnits[0].customType')
                return typeNotShowDetails.includes(status) ? (
                    purchaseUnits.length <= 1 ? (
                        <Link
                            params={{ id: splitCode }}
                            to={`${toLink}.detail`}
                            isNewTab>
                            <strong style={{ fontWeight: 500 }}>{`#${name}`}</strong>
                        </Link>
                    ) : (
                        <Button
                            className="font-weight-500 cursor-pointer"
                            onClick={() => handleOpenModalTranSaction(purchaseUnits, status)}
                            type="link">
                            {t('label.view_detail')}
                        </Button>
                    )
                ) : (
                    ''
                )
            },
        },
        {
            className: '_finances-info',
            title: '#',
            dataIndex: ['id'],
            key: 'id',
            render: (text, record) => {
                return (
                    <div className="d-flex align-items-center">
                        <span>
                            <strong style={{ fontWeight: 500 }}>{`${text}`}</strong>
                        </span>
                    </div>
                )
            },
        },
    ]

    const isJson = str => {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    const renderDescription = (status, description, memo, statusInit) => {
        if (statusInit === 'GENESIS') return memo

        switch (status) {
            case 'COLLECT':
            case 'WITHDRAW':
            case 'DEPOSIT':
                return description
            case 'STORAGE_FEE':
                return `${t('finance:DOCUMENT_TYPE.STORAGE_FEE')} ${t('finance:label.day').toLowerCase()} ${
                    description && isJson(description) ? dateFormatter.date(JSON.parse(description)?.closing_time) : '---'
                }`
            case 'IMPORT_SERVICE':
            case 'EXPORT_SERVICE':
            case 'IMPORT_RETURN_GOODS_SERVICE':
            case 'SHIPPING':
            case 'COD':
            case 'EXTENT':
                return (
                    <>
                        {description && isJson(description)
                            ? JSON.parse(description).type
                                ? `${t('finance:label.confirm_voucher')} ${t(`finance:DOCUMENT_TYPE.${JSON.parse(description).type}`)} #${
                                      JSON.parse(description).code
                                  }`
                                : '---'
                            : description}
                    </>
                )
            default:
                return '---'
        }
    }
    const handleOpenModalTranSaction = (purchaseUnits, status) => {
        Modal.confirm({
            title: t('label.transaction_detail'),
            icon: false,
            closable: true,
            width: 600,
            height: 500,
            content: (
                <ModalTransactionDetail
                    purchaseUnits={purchaseUnits}
                    currency={currency}
                    status={status}
                />
            ),
            cancelText: t('product:btn.close'),
            okButtonProps: { style: { display: 'none' } },
        })
    }

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {`${t('finance:title.transaction_list')} `}
                        <span className="number-circle _importing-document-product-list-total pl-2 pr-2">{getVar(pagination, 'total', 0)}</span>
                    </h3>

                    <ExportTransaction filters={filters}/>
                </div>
                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3"
                        dataSource={data}
                        rowKey={record => record.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <p>{t(`message.${loading ? 'loading' : 'no_transactions'}`)}</p>,
                        }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </div>
        </div>
    )
}

export default List
