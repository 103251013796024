import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

import { t } from '@System/i18n'
import notification from '@System/notification'

const LeavePageBlocker = ({ when }) => {
    const message = t('message.page_leave')

    useEffect(() => {
        if (!when) return () => {}

        const beforeUnloadCallback = event => {
            notification.warning(t('message.request_process'))
            event.preventDefault()
            event.returnValue = message
            return message
        }

        window.addEventListener('beforeunload', beforeUnloadCallback)
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadCallback)
        }
    }, [when, message])

    return (
        <Prompt
            when={when}
            message={message}
        />
    )
}

LeavePageBlocker.propTypes = {
    when: PropTypes.bool.isRequired,
}

export default LeavePageBlocker
