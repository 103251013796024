import React from "react";
import i18n, {t} from "../../../system/i18n";
import {LANGUAGES} from "../services/constants";
import Icon from '@ant-design/icons';
import FlagCountryComponent from "./FlagCountryComponent";

function Language() {

    const handleChange = (key) => {
        i18n.changeLanguage(key, window.location.reload())
    };

    return (
        <div className="language-box">
            {
                LANGUAGES.map((item, index) => {
                    return (
                        <p key={index}>
                            <a onClick={() => handleChange(item.key)} className="d-flex align-items-center">
                                <Icon component={FlagCountryComponent(item.key)} className="mr-1"/> {t("label.language." + item.key)}
                            </a>
                        </p>
                    );
                })
            }
        </div>
    );
}

export default Language;
