import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import ImportCreateProducts from '@Modules/Product/components/UploadFile/ImportCreateProducts';
import ProductCreate from '@Modules/Product/screens/Create';

import { t } from '@System/i18n';
import { url } from '@System/routing';

export default function CreateProductByImport({ children }) {
    const selectedAction = (
        <Menu className="menu-dropdown-action">
            <Menu.Item key={1} className=" mb-3 mb-xl-0">
                <Link to={url.to('products.create')}>
                    <Button type={'link'} icon={<PlusCircleOutlined />}>
                        {t('btn.create_product_manual')}
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item key={2} className=" mb-3 mb-xl-0">
                <ImportCreateProducts type={'link'} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
            {children ? (
                children
            ) : (
                <Button icon={<PlusOutlined />} type="primary">
                    {t('btn.create_product')}
                </Button>
            )}
        </Dropdown>
    );
}
