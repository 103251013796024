import { Skeleton, Table } from 'antd'
import React from 'react'

const SkeletonSkuList = () => {
    const data = [
        { label: 'cc', key: '1' },
        { label: 'cc', key: '2' },
        { label: 'cc', key: '3' },
    ]

    const columns = [
        {
            className: '_list-product-name text-left',
            dataIndex: ['sku'],
            key: 'label',
            render: (text: any) => (
                <div className="d-flex items-center list-sku-skeleton">
                    <Skeleton.Avatar
                        active
                        size="large"
                        shape="square"
                        className="mr-1"
                    />
                    <Skeleton
                        active
                        paragraph={{ rows: 1 }}
                    />
                </div>
            ),
        },
        {
            className: '_list-product-sku-code',
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            render: (text: any) => (
                <div className="list-sku-skeleton">
                    <Skeleton
                        active
                        paragraph={{ rows: 1 }}
                    />
                </div>
            ),
        },
        {
            className: 'text-right _list-product-action',
            key: 'action',
            dataIndex: ['sku'],
            render: (text: any, record: any) => (
                <div className="list-sku-skeleton">
                     <Skeleton.Button active size="large" shape="square"  />
                </div>
            ),
        },
    ]

    return (
        <Table
            showHeader={false}
            className="add-sku-table-modal pb-2 mt-3 add-product-import-package"
            dataSource={data}
            rowKey={record => record?.key}
            columns={columns}
            pagination={false}
            scroll={{ x: 576, y: 500 }}
        />
    )
}

export default SkeletonSkuList
