import auth from './auth.json';
import common from './common.json';
import order from './order.json';
import product from './product.json';
import validation from './validation.json';
import warehouse from './warehouse.json';
import shipping from './shipping.json';
import finance from './finance.json';
import setting from './setting.json';
import dashboard from './dashboard.json';
import register from './register.json';

export default {
    common,
    validation,
    auth,
    order,
    product,
    warehouse,
    shipping,
    finance,
    setting,
    dashboard,
    register
};
