import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useListFinanceOrderQuery from '@Modules/Finance/Hooks/useListFinanceOrderQuery';

import { t } from '@System/i18n';
import getTheme from '@System/support/helpers';

import List from './FBU/List';
import Summary from './FBU/Summary';
import FormSearch from './FormSearch';
import ListVela from './Vela/List';
import SummaryVela from './Vela/Summary';

export default function FinanceStatistic({ history, location }) {
    let theme = getTheme();

    const { filters, addFilter } = useFilter(history, location, {});
    const [pagination, setPagination] = useState({});
    const [orders, setOrders] = useState([]);

    const { data, isFetching, refetch, isError, error } = useListFinanceOrderQuery(filters);

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('breadcrumb.order_financial_management'),
            },
        ]);
        setDocumentTitle(t('title.order_financial_management'));
    }, []);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useEffect(() => {
        setOrders(get(data, 'data.orders', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.order_financial_management')}</h3>
            </div>
            <FormSearch onSearch={addFilter} filters={filters} loading={isFetching} />
            {theme && theme.code === 'vela' ? (
                <>
                    <SummaryVela filters={filters} />
                    <ListVela
                        orders={orders}
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={isFetching}
                    />
                </>
            ) : (
                <>
                    <Summary filters={filters} />
                    <List orders={orders} pagination={pagination} addFilter={addFilter} filters={filters} loading={isFetching} />
                </>
            )}
        </div>
    );
}
