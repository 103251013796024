import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

const ModalConfirm = props => {
    const { onOk, handleCancelModal, loadingBtn } = props;
    return (
        <Modal
            title={t('product:label.cancel_package')}
            visible={true}
            onCancel={handleCancelModal}
            className="ant-modal ant-modal-confirm ant-modal-confirm-confirm custom-confirm-modal"
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <p className="confirm-body">
                <ExclamationCircleOutlined/>
                {t('product:message.confirm_cancel_package')}
            </p>
      
            <div className="confirm-btn-group">
                <Button className="mr-2" onClick={handleCancelModal}>
                    {t('btn.back')}
                </Button>
                <Button type="primary" loading={loadingBtn} onClick={onOk}>
                    {t('btn.confirm')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalConfirm;
