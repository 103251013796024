import { Checkbox, Spin } from 'antd';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';

import useListServiceByTypeQuery from '@Modules/Services/Hooks/useListServiceByTypeQuery';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function PackingServiceInfo({ onChange, serviceSelected, serviceType, setServiceImport,serviceExport }) {
    const [values, setValues] = useState([]);
    const [services, setServices] = useState([]);
    const { isFetching, data } = useListServiceByTypeQuery(serviceType);

    useEffect(() => {
        const services = getVar(data, 'data.services', []);
        if (!isEmpty(services)) {
            setServices(refactorData(services));
        }
    }, [data]);

    useEffect(() => {
        setValues(
            map(
                filter(serviceSelected, item => item.type === serviceType),
                'id'
            )
        );
        setServiceImport(
            map(
                filter(serviceSelected, item => item.type === serviceType),
                'id'
            )
        );
    }, [serviceSelected, serviceType]);

    function refactorData(data) {
        const newData = [];
        data.map(item => {
            newData.push({
                label: item.service.name,
                value: item.service.id,
            });
        });
        return newData;
    }

    function handleOnChange(checkedValues) {

        const newServiceIds = concat(checkedValues, serviceExport);
        setServiceImport(checkedValues);
        setValues(checkedValues);
        onChange('services', newServiceIds);
    }

    return services.length > 0 ? (
        <Checkbox.Group className="_packing-service-info mt-4" options={services} value={values} onChange={handleOnChange} />
    ) : (
        <div className="mt-4 ">{t(`message.${isFetching ? 'loading' : 'no_data_service'}`)}</div>
    );
}
export default PackingServiceInfo;
