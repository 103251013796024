import { ArrowLeftOutlined } from '@ant-design/icons'
import { Form, Spin } from 'antd'
import { flatten, uniqBy } from 'lodash'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import useProductDetailQuery from '@Modules/Product/Hooks/useProductDetailQuery'
import ImportServiceInfo from '@Modules/Product/components/Edit/ImportServiceInfo'
import ProductImages from '@Modules/Product/components/Edit/ProductImages'
import ProductInfo from '@Modules/Product/components/Edit/ProductInfo'
import ServicePackEdit from '@Modules/Product/components/Edit/ServicePackEdit'
import TransportServiceInfo from '@Modules/Product/components/Edit/TransportServiceInfo'
import api from '@Modules/Product/services/api'
import { SERVICES_TYPE } from '@Modules/Services/services/constants'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

function ProductEdit(props) {
    const user = auth.user()
    const { match } = props
    const [form] = Form.useForm()
    const productId = match.params.productId
    const skuId = match.params.skuId
    const [product, setProduct] = useState({})
    const [skus, setSkus] = useState([])
    const [services, setServices] = useState([])
    const [serviceListRequired, setServiceListRequired] = useState([])
    const [servicePacks, setServicePacks] = useState({})
    const [loadingPacks, setLoadingPacks] = useState(true)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [images, setImages] = useState([])
    const [dataSource, setDataSource] = useState([])
    const { error, isError, isLoading, isFetching, data, refetch } = useProductDetailQuery(productId)

    useEffect(() => {
        setNavigator(t('title.product_edit'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.product_edit'),
            },
        ])
        setDocumentTitle(t('title.product_edit'))
    }, [])

    useEffect(() => {
        setDocumentTitle(t('title_page.product_edit', { code: getVar(product, 'code', '') }))
        setImages(getVar(product, 'images', []))
    }, [product])

    const getServicePack = useCallback(async () => {
        setLoadingPacks(true)
        try {
            const res = await api.getServicePack()
            setServicePacks(res.data)
        } catch (error) {
            processResponseError(error)
        } finally {
            setLoadingPacks(false)
        }
    }, [])

    useEffect(() => {
        getServicePack()
    }, [])

    useEffect(() => {
        setProduct(getVar(data, 'data.product', {}))
        setSkus(getVar(data, 'data.skus', []))
        setDataSource(data?.data)
        setServices(getVar(data, 'data.services', []))
    }, [data])

    useEffect(() => {
        const serviceRequired = services.filter(item => item.service.is_required)
        setServiceListRequired(map(serviceRequired, 'service.id'))
    }, [services])

    if (isError) {
        const status = error.response.status
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    const creator_id = getVar(product, 'creator_id', '')

    if (!isEmpty(product) && user.id !== creator_id) {
        return <Error403 />
    }

    function handleUpdateProduct(name, value) {
        let formData = new FormData()
        let status = ''
        if (name === 'file') {
            if (isArray(value)) {
                status = 'added'
                map(value, image => {
                    formData.append('files[]', image)
                })
            } else {
                status = 'removed'
                const remove_file = getVar(value, 'url', '')
                if (remove_file) {
                    formData.append('removed_files[]', remove_file)
                }
            }
        } else if (name === 'services') {
            if (value.length > 0) {
                map(uniqBy([...value, ...serviceListRequired]), item => {
                    formData.append('services[]', item)
                })
            } else {
                formData.append('services[]', null)
            }
        } else {
            formData.append(name, value)
        }
        form.validateFields().then(() => {
            setLoadingEdit(true)
            api.updateProductDetail(productId, formData)
                .then(res => {
                    if (status) {
                        notification.success(
                            status === 'added'
                                ? t('message.create_success', {
                                      attribute: t(`product:label.image`),
                                  })
                                : t('message.delete_success', {
                                      attribute: t(`product:label.image`),
                                  })
                        )
                    } else {
                        notification.success(
                            t('message.update_success_by_attribute', {
                                attribute: status ? t(`product:label.image.${status}`) : t(`product:label.${name}`),
                            })
                        )
                    }
                    setProduct(getVar(res, 'data.product', {}))
                    setServices(getVar(res, 'data.services', []))
                    refetch()
                })
                .catch(err => {
                    const code = getVar(err, 'response.data.code', '')
                    if (code === 'INPUT_INVALID') {
                        renderError(getVar(err, 'response.data.data', {}))
                    } else {
                        notification.error(t('message.update_failed_by_attribute', { attribute: t(`product:label.${name}`) }))
                    }
                })
                .finally(() => {
                    setLoadingEdit(false)
                    // getServicePack()
                })
        })
    }

    function handleUpdateSafeStock(value) {
        api.updateSafeStock(skuId, { safety_stock: value })
            .then(res => {
                notification.success(
                    t('message.update_success_by_attribute', {
                        attribute: t(`product:label.safety_stock`),
                    })
                )
                // setProduct(getVar(res, 'data.product', {}));
                // setServices(getVar(res, 'data.services', []));
            })
            .catch(err => {
                const code = getVar(err, 'response.data.code', '')
                if (code === 'INPUT_INVALID') {
                    renderError(getVar(err, 'response.data.data', {}))
                } else {
                    notification.error(t('message.update_failed_by_attribute', { attribute: t(`product:label.safety_stock`) }))
                }
            })
    }

    function handleUpdate(name, value) {
        if (name === 'safety_stock') {
            return handleUpdateSafeStock(value)
        } else {
            return handleUpdateProduct(name, value)
        }
    }

    function renderError(errors) {
        Object.keys(errors).forEach(item => {
            let key_errors = []

            Object.keys(errors[item]).forEach(error => {
                let name = item
                if (item === 'file') name = 'image'
                if (item === 'service_groups' && error === 'missing') {
                    key_errors.push(t('order:message.service_group_missing'))
                } else {
                    key_errors.push(t(`product:message.${error}`, { attribute: t(`product:label.${name}`) }))
                }
            })
            notification.error(key_errors.join(', '))
        })
    }

    const skuInfo = find(skus, ['id', Number(skuId)])

    return (
        <div className="site-content">
            <Spin spinning={isLoading}>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link
                            className="_product-detail-back"
                            to={url.to('products.detail', { productId, skuId })}
                        >
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18 mt-1">{t('breadcrumb.product_edit')}</h3>
                    </div>
                </div>

                <div className="bg-light-gray">
                    <div className="bg-white rounded-12 p-4">
                        <div className="_product-edit-product-info">
                            <h3 className="mb-3">{t('title.product_info')}</h3>
                            <Form form={form}>
                                <ProductInfo
                                    product={product}
                                    onChange={handleUpdate}
                                    form={form}
                                    skuInfo={skuInfo}
                                />
                            </Form>

                            <div className="d-flex align-items-center">
                                <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                                <span className="ml-2 number-circle _importing-document-product-list-total">{images.length}</span>
                                <i className="ml-2">{t('product:description.picture')}</i>
                            </div>
                        </div>

                        <div className="mt-4 _product-edit-picture">
                            <ProductImages
                                onChange={handleUpdate}
                                name={t('product:label.image')}
                                accept="image/jpeg, image/png"
                                images={images}
                                maxFileSize={5}
                                multiple={true}
                                productId={productId}
                            />
                        </div>
                    </div>

                    <div className="bg-white rounded-12 mt-4 p-4 _product-edit-transporting-service">
                        <div className="d-flex">
                            <h3 className="">{`${t('title.transporting_service')}`}</h3>
                        </div>
                        <TransportServiceInfo
                            onChange={handleUpdate}
                            services={services}
                            product={product}
                            skuInfo={skuInfo}
                        />
                    </div>
                    {!isEmpty(servicePacks.service_pack)
                        ? servicePacks?.service_pack_prices?.map(
                              item =>
                                  item.type !== 'EXTENT' && (
                                      <div
                                          className="bg-white rounded-12  p-4 mb-4_product-edit-packing-service mt-4"
                                          key={item.type}
                                      >
                                          <div className="d-flex justify-content-between">
                                              <h3>{t(`order:SERVICE_TYPE.${item.type}`)}</h3>
                                          </div>

                                          <ServicePackEdit
                                              type={item.type}
                                              onChange={handleUpdate}
                                              serviceSelected={services}
                                              dataSource={dataSource}
                                              setServiceSelected={setServices}
                                              data={item.service_pack_prices}
                                              loading={loadingPacks || loadingEdit}
                                          />
                                      </div>
                                  )
                          )
                        : !loadingPacks && (
                              <>
                                  <div className="bg-white rounded-12 mt-4 p-4 _product-edit-packing-service-importing">
                                      <div className="d-flex justify-content-between">
                                          <h3>{`${t('product:label.service_importing')}`}</h3>
                                      </div>

                                      <ImportServiceInfo
                                          type={SERVICES_TYPE.IMPORT}
                                          onChange={handleUpdate}
                                          serviceSelected={services}
                                          dataSource={dataSource}
                                          setServiceSelected={setServices}
                                      />
                                  </div>

                                  <div className="bg-white rounded-12 mt-4 p-4 _product-edit-packing-service-exporting">
                                      <div className="d-flex justify-content-between">
                                          <h3>{`${t('product:label.service_exporting')}`}</h3>
                                      </div>

                                      <ImportServiceInfo
                                          type={SERVICES_TYPE.EXPORT}
                                          onChange={handleUpdate}
                                          serviceSelected={services}
                                          dataSource={dataSource}
                                          setServiceSelected={setServices}
                                      />
                                  </div>

                                  <div className="bg-white rounded-12 mt-4 p-4 _product-edit-packing-service-storage">
                                      <div className="d-flex justify-content-between">
                                          <h3>{`${t('product:label.storage_service')}`}</h3>
                                      </div>

                                      <ImportServiceInfo
                                          type={SERVICES_TYPE.STORAGE}
                                          onChange={handleUpdate}
                                          serviceSelected={services}
                                          dataSource={dataSource}
                                          setServiceSelected={setServices}
                                      />
                                  </div>
                              </>
                          )}
                </div>
            </Spin>
        </div>
    )
}
export default ProductEdit
