import { useQuery } from 'react-query';

import api from '@Modules/ImportPackage/services/api';

import processResponseError from '@System/api/processResponseError';

const useDetailImportPackageQuery = id =>
    useQuery(['import-package-detail', id], () => api.getDetailImportPackage(id), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useDetailImportPackageQuery;
