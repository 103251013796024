import { useQuery } from 'react-query';

import api from '@Modules/Product/services/api';

import processResponseError from '@System/api/processResponseError';

const useProductHistoryQuery = (id, params) =>
    useQuery(['product-history', id, params], () => api.getProductHistory(id, params), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useProductHistoryQuery;
