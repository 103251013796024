import { t } from '@System/i18n';
import { Link, url } from '@System/routing';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import { Avatar, Modal, Table } from 'antd'
import { isEmpty } from 'lodash';
import React from 'react'

const ModalListSku = ({ dataList, currency }) => {

    const columns = [
        {
            className: '_product-info-sku-id',
            title: t('product:label.product'),
            dataIndex: ['sku', 'code'],
            render: (text, record) => {
                const images = getVar(record, 'sku.product.images', []);
                const name = getVar(record, 'sku.name', []);
                const productId = getVar(record, 'sku.product_id', undefined);
                const skuId = getVar(record, 'sku.id', undefined);
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={images.length > 0 ? images[0] : ''}
                            className="mr-2"
                            size={36}
                            style={{ minWidth: '36px' }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to="products.detail" params={{ productId, skuId }}>
                                    <strong style={{ fontWeight: 500 }} onClick={()=>Modal.destroyAll()}>{text}</strong>
                                </Link>
                                <p style={{ color: '#999999', whiteSpace: 'pre-line' }}>{name}</p>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: 'text-right text-nowrap _product-info-quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
        },
        {
            className: 'text-right text-nowrap _product-info-price',
            title: t('order:label.price'),
            dataIndex: 'price',
            render: text => <p>{!isEmpty(currency) ? formatCurrency(text || 0, currency) : text}</p>,
        },
        {
            className: 'text-right text-nowrap _product-info-sum-price',
            title: t('order:label.sum_price'),
            dataIndex: 'order_amount',
            render: text => <p>{!isEmpty(currency) ? formatCurrency(text || 0, currency) : text}</p>,
        },
    ];

    return (
        <Table rowKey={record => record?.id} columns={columns} dataSource={dataList} pagination={false} scroll={{ x: true }}/>
    )
}

export default ModalListSku