import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Table } from 'antd';
import React, { useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import api from '@Modules/Dropshipping/services/api';
import { PRODUCT_EVENT, SERVICES_PRICE_COLORS } from '@Modules/Dropshipping/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { formatCurrency } from '@System/support/numberFormat';

import DropshippingService from '../Edit/DropshippingService';

const DropshippingServiceList = ({ productActivePrice, productId, productActivePriceDetails, currency, product }) => {
    const [loading, setLoading] = useState(false);
    const ColumnsWithType =
        productActivePrice?.type === 'COMBO'
            ? {
                  className: '_quote-create-service-box-table-combo',
                  title: t('product:label.combo'),
                  dataIndex: 'combo',
                  key: 'combo',
              }
            : {
                  className: '_quote-create-table-varians',
                  title: t('product:label.sku'),
                  dataIndex: ['sku', 'code'],
                  key: 'sku_id',
              };

    let columns = [
        ColumnsWithType,
        {
            className: '_quote-create-service-box-table-cost_price text-right',
            title: t('product:label.cost_price'),
            dataIndex: 'cost_price',
            key: 'cost_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-packing_service text-right',
            title: t('product:label.service_packing_price'),
            dataIndex: 'service_packing_price',
            key: 'service_packing_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-transport_service text-right',
            title: t('product:label.service_shipping_price'),
            dataIndex: 'service_shipping_price',
            key: 'service_shipping_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-sum text-right',
            title: t('product:label.total_price'),
            dataIndex: 'total_price',
            key: 'total_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
    ];

    const confirmModal = () => {
        Modal.confirm({
            title: t('product:label.confirm_status_quotation'),
            icon: <ExclamationCircleOutlined />,
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            okButtonProps: { className: 'dropshipping-new-price-modal_btn-ok', loading: loading },
            cancelButtonProps: { className: 'dropshipping-new-price-modal_btn-cancel' },
            onOk: () => handleChangeStatusQuotation(productActivePrice?.id),
        });
    };
    const handleChangeStatusQuotation = productPriceId => {
        setLoading(true)
        api.changeStatusQuotation(productPriceId)
            .then(res => {
                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_DETAIL_DROPSHIP, {});
                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_HISTORY_DROPSHIP, {});
                notification.success(t('product:message.change_status_quotation_success'));
            })
            .catch(err => {
                notification.error(t('product:message.change_status_quotation_failed'));
            }).finally(()=>{
                setLoading(false)
            });
    };
    return (
        <div className="bg-white rounded-12  p-4 mb-4 box-dropship-services box-shadow">
            <div className="d-flex mb-4 title-dropship-services">
                <h3>{`${t('product:title.dropshipping_service')}`}</h3>
            </div>
            {product && product.status === 'NEW' ? (
                <DropshippingService productId={productId} />
            ) : (
                <>
                    <div className="d-flex justify-content-between flex-wrap">
                        <div className="d-flex">
                            <p className="mr-2">{t('product:label.new_quotation')}</p>
                            <p className="font-weight-500 mr-2 dropshipping-new-price_id">
                                {productActivePrice && '#' + productActivePrice.id}
                            </p>
                            <Badge
                                color={SERVICES_PRICE_COLORS[productActivePrice?.status]}
                                className="_dropship-service-product-active_status"
                                text={t(`product:SERVICES_PRICE_STATUS.${productActivePrice?.status}`)}
                            />
                        </div>
                        {productActivePrice?.status === 'WAITING_CONFIRM' && (
                            <Button type="primary" onClick={confirmModal}>
                                {t('product:btn.confirm_quotation')}
                            </Button>
                        )}
                    </div>
                    <Table
                        className="_dropship-service-list_new-table-service-box pb-3 mt-4"
                        rowKey={record => record?.id}
                        columns={columns}
                        dataSource={productActivePrice?.status !== 'CANCELED' ? productActivePriceDetails : []}
                        scroll={{ x: true }}
                        pagination={false}
                        locale={{ emptyText: <TableEmpty className="_product-list-empty" /> }}
                    />
                </>
            )}
        </div>
    );
};

export default DropshippingServiceList;
