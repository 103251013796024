import { api } from '@System/api';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createImportPackage: data => api.post('/merchant/purchasing-packages', data),
    createImportPackageByPurchase: (id, data) => api.put(`merchant/purchasing-orders/${id}`, data),
    getImportPackages: params => api.get('/merchant/purchasing-packages', { params, singleRequest: true, loading: false }),
    getDetailImportPackage: importPackageId =>
        api.get(`/merchant/purchasing-packages/${importPackageId}`, { singleRequest: true, loading: false }),
    changeStatusPackage: data => api.post('/merchant/purchasing-packages/to-transporting', data),
    exportListImportPackage: params =>
        api.get('/merchant/purchasing-packages/export', { params, singleRequest: true, loading: false, responseType: 'blob' }),
    cancelPackage: (id) => api.put(`merchant/purchasing-packages/${id}/cancel`,{}),    
    createBatchOfGoods: (idSku, data) => api.post(`/skus/${idSku}/batch-of-goods`, data),
    getBatchOfGoods: (id) => api.get(`/skus/${id}/batch-of-goods`, { }),
};
