import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import useSize from '@Modules/App/Hooks/useSize'

import { t } from '@System/i18n'

import SelectTransactionStatus from '../../components/SelectTransactionStatus'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

const FormSearch = ({ onSearch, filters, loading }) => {
    const nowDay = new Date()
    const thirtyDayAgo = new Date().setDate(nowDay.getDate() - 30)
    const { isMobile, isTablet } = useSize()
    const [form] = Form.useForm()
    const [input, setInput] = useState(filters)

    const { query, types } = input || {}
    const created_at_from = get(input, 'created_at[from]', '')
    const created_at_to = get(input, 'created_at[to]', '')

    useEffect(() => {
        setInput(filters)
    }, [filters])

    function _onChange(name, value) {
        const filters = { ...input, [name]: value }
        setInput(filters)
    }

    function _onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        _onChange(name, value)
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        })
        onSearch({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        })
    }

    function _onSubmit() {
        onSearch(input)
    }

    function _onReset() {
        onSearch({
            query: undefined,
            types: undefined,
            'created_at[from]': moment(thirtyDayAgo).format('YYYY-MM-DD'),
            'created_at[to]': moment(nowDay).format('YYYY-MM-DD'),
        })
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical">
                <div className="bg-white rounded-12 p-4 mt-3  box-shadow">
                    <div className="search-box">
                        <Row gutter={20}>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                    className="mb-3"
                                    label={t('finance:label.transaction_code')}>
                                    <Input
                                        name="query"
                                        value={query}
                                        placeholder={t('finance:placeholder.transaction_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                    className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                    label={t('label.time')}>
                                    <RangePicker
                                        className="_finance-search-created-at"
                                        value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                        format={dateFormat}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        onCalendarChange={date => _onChangeDate('created_at', date)}
                                        style={{ width: '100%' }}
                                        defaultValue={[moment(thirtyDayAgo), moment(nowDay)]}
                                        
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                    className="mb-3"
                                    label={t('label.transaction_type')}>
                                    <SelectTransactionStatus
                                        className="_finance-search-status"
                                        placeholder={t('finance:placeholder.all')}
                                        allowClear={true}
                                        value={types}
                                        onChange={value => _onChange('types', value)}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                <Form.Item className="mb-3" label={t('order:label.order_code')}>
                                    <Input
                                        name="order_code"
                                        value={order_code}
                                        placeholder={t('order:placeholder.order_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                <Form.Item className="mb-3" label={t('product:label.package_code')}>
                                    <Input
                                        name="package_code"
                                        value={package_code}
                                        placeholder={t('product:placeholder.package_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col> */}
                            <Col
                                xs={{ span: 24 }}
                                lg={{ offset: 16, span: 8 }}
                                className="text-right">
                                <Form.Item
                                    className="mb-0"
                                    label={isMobile || isTablet ? '' : ' '}>
                                    <Button
                                        className="mr-2 _finance-search-btn-reset"
                                        onClick={_onReset}>
                                        <UndoOutlined /> {t('common:btn.set_again')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={_onSubmit}
                                        className="_finance-search-btn-search"
                                        loading={loading}>
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default FormSearch
