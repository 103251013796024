import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin, Tabs } from 'antd';
import { get, isEmpty, map } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useDetailOrderQuery from '@Modules/Order/Hooks/useOrderDetailQuery';
import { IOrderProduct } from '@Modules/Order/Interfaces';
import OrderInfo from '@Modules/Order/components/Edit/OrderInfo';
import ProductOrder from '@Modules/Order/components/Edit/ProductOrder';
import ReceivedInfo from '@Modules/Order/components/Edit/ReceiverInfo';
import api from '@Modules/Order/services/api';
import {
    EDIT_STATUS,
    INIT_PRODUCT_ORDER,
    ORDER_DETAIL_KEYS,
    ORDER_STATUS,
    VALIDATED_RULES,
} from '@Modules/Order/services/constants';

import useValidatedMessages from '@System/hooks/useValidatedMessages';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';

interface IEditOrderProps {
    match: any;
}

const { TabPane } = Tabs;

const EditOrder: React.FC<IEditOrderProps> = ({ match }) => {
    const [form] = Form.useForm();
    const orderId = get(match, 'params.id');
    const validateMessages = useValidatedMessages();
    const history = useHistory();
    const { error, isError, isFetching, data } = useDetailOrderQuery(orderId);

    const [products, setProducts] = useState<IOrderProduct[]>(INIT_PRODUCT_ORDER);
    const [locations, setLocations] = useState({});
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});

    useEffect(() => {
        setNavigator(t('title.detail_order'), [
            {
                name: t('breadcrumb.list-orders'),
                route: 'orders.list',
            },
            {
                name: t('title.order_edit'),
            },
        ]);
        setDocumentTitle(t('title.order_edit'));
    }, []);

    useEffect(() => {
        setOrderDetail(get(data, 'data.order'));
    }, [data]);

    useEffect(() => {
        const processData = () => {
            const orderSku = get(data, 'data.order_skus');

            const orderDetail = [
                ...map(ORDER_DETAIL_KEYS, key => ({ name: key, value: get(data, `data.order.${key}`, '') })),
                { name: 'receiver_province_id', value: get(data, 'data.receiver_province.id', '') },
                { name: 'receiver_district_id', value: get(data, 'data.receiver_district.id', '') },
                { name: 'receiver_ward_id', value: get(data, 'data.receiver_ward.id', '') },
            ];

            const productSkus = map(orderSku, item => ({
                code: get(item, 'sku.code'),
                name: get(item, 'sku.name'),
                price: get(item, 'orderSku.price', 0),
                quantity: get(item, 'orderSku.quantity', 0),
                id: get(item, 'orderSku.sku_id'),
                image: get(item, 'product.image.0', ''),
            }));

            form.setFields(orderDetail);

            setLocations({
                countryCode: get(data, 'data.receiver_country.code', undefined),
                provinceId: get(data, 'data.receiver_province.id', undefined),
                provinceCode: get(data, 'data.receiver_province.code', undefined),
                districtCode: get(data, 'data.receiver_district.code', undefined),
                districtId: get(data, 'data.receiver_district.id', undefined),
                wardId: get(data, 'data.receiver_ward.id', undefined),
                wardCode: get(data, 'data.receiver_ward.code', undefined),
                receiverPostalCode: get(data, "data.order.receiver_postal_code", undefined)
            });
            setProducts(productSkus);
        };
        processData();
    }, [data, form]);

    const handleUpdateOrder = () => {
        form.validateFields().then(values => {
            const data = { ...values, orderSkus: { ...products } };
            setLoadingUpdate(true);

            api.updateOrder(data, orderId)
                .then(() => {
                    notification.success(t('order:message.order_update_success'));
                    history.push(`/orders/detail/${orderId}`);
                })
                .catch(() => {
                    notification.error(t('order:message.order_update_failed'));
                })
                .finally(() => {
                    setLoadingUpdate(false);
                });
        });
    };

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    if (!isEmpty(orderDetail)) {
        if (get(orderDetail, 'marketplace_code') !== 'MANUAL') {
            return <Error403 />;
        }

        if (!EDIT_STATUS.includes(get(orderDetail, 'status'))) {
            return <Error403 />;
        }
    }

    return (
        <div className="site-content _order-detail-content">
            <Spin spinning={isFetching}>
                <Form className="form" validateMessages={validateMessages} form={form} layout="vertical">
                    <div className="d-flex justify-content-between">
                        <h3>
                            <Link to={url.to('orders.list')} className="_order-list-back">
                                <ArrowLeftOutlined /> {t('btn.back')}
                            </Link>
                        </h3>

                        <Button
                            type="primary"
                            className="ml-2 btn-save_order"
                            icon={<SaveOutlined />}
                            onClick={handleUpdateOrder}
                            loading={loadingUpdate}
                        >
                            {t('order:btn.save_order')}
                        </Button>
                    </div>
                    <div className="bg-light-gray mt-3 _order-detail-body">
                        <Row gutter={20}>
                            <Col xs={{ span: 24 }} lg={{ span: 17 }}>
                                <OrderInfo validatedRules={VALIDATED_RULES} form={form} />

                                <Tabs
                                    className="heading-fz-14 customize-order-ant-tabs mt-3 _order-detail-tab"
                                    defaultActiveKey="1"
                                    type="card"
                                >
                                    <TabPane
                                        tab={t('order:title.order_info')}
                                        key="1"
                                        className="_order-detail-tab-product-info"
                                    >
                                        <ProductOrder
                                            products={products}
                                            setProducts={setProducts}
                                            validatedRules={VALIDATED_RULES}
                                            form={form}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 7 }}>
                                <ReceivedInfo validatedRules={VALIDATED_RULES} form={form} locations={locations} />
                            </Col>
                        </Row>
                    </div>
                    <Form.Item name="inspected" hidden children={<Input />} />
                </Form>
            </Spin>
        </div>
    );
};

export default EditOrder;
