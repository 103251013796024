import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

import { catchErrors } from '@Modules/App/services/helpers';
import SelectSku from '@Modules/Product/components/SelectSku';
import api from '@Modules/Product/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

export default function MapSkuToVariant({ productInfo, updateProduct }) {
    const { purchasing_product_id, purchasing_order_id } = productInfo;
    const sku_info = get(productInfo, 'sku_info', {});
    const [loading, setLoading] = useState(false);
    const [isEdited, setIsEdit] = useState(false);

    function _onChange(sku_id) {
        if (!sku_id) {
            _onBlur();
            return;
        }

        setLoading(true);
        api.mapSkuToVariant(purchasing_order_id, purchasing_product_id, {
            sku_id,
            id: purchasing_order_id,
            item_id: purchasing_product_id,
        })
            .then(res => {
                const sku_id = getVar(res, 'data.purchasing_variant.sku_id', undefined);
                const sku_info = getVar(res, 'data.purchasing_variant.sku', {});
                updateProduct({ sku_id, sku_info }, productInfo);
                setIsEdit(false);
                notification.success(t('product:message.map_sku.success'));
            })
            .catch(catchErrors(t('product:message.map_sku.fail')))
            .finally(() => setLoading(false));
    }

    function _onBlur() {
        if (sku_info && isEdited) setIsEdit(false);
    }

    function _onClick() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            cancelText: t('btn.cancel'),
            className: '_map-sku-confirm-modal',
            okText: t('btn.confirm'),
            title: t('product:message.map_sku.warm_title'),
            content: t('product:message.map_sku.warm_content'),
            onOk: () => setIsEdit(true),
            width: 480,
        });
    }
    return !isEmpty(sku_info) && !isEdited ? (
        <>
            <span>
                ({sku_info.code}) - {sku_info.name}
            </span>
            <span className="cursor-pointer ml-2 text-primary _btn-map-sku" onClick={_onClick}>
                <EditOutlined style={{ color: 'blue' }} />
            </span>
        </>
    ) : (
        <SelectSku
            className="_select-sku-mapping"
            onChange={_onChange}
            onBlur={_onBlur}
            style={{ width: '200px' }}
            loading={loading}
            placeholder={t('product:placeholder.search_product_by_name')}
        />
    );
}
