import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import useExportListOrderQuery from '@Modules/Order/Hooks/useExportListOrderQuery';

import { t } from '@System/i18n';

function ExportListOrder({ filters }) {
    const { refetch, isFetching } = useExportListOrderQuery(filters);
    return (
        <Button
            onClick={refetch}
            type="primary"
            loading={isFetching}
            icon={<DownloadOutlined />}
            className="_btn-export-list-import-package"
        >
            {t('btn.export_excel')}
        </Button>
    );
}
export default ExportListOrder;
