import { Badge, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { url } from '@System/routing';

import { ExpandContext } from './configs';

export default function MenuItem({ icon, url: path, name, onClick, selected = false, isMenuSub = true }) {
    return (
        <ExpandContext.Consumer>
            {({ showName, showIcon }) => (
                <li
                    className={`main-menu--list--${isMenuSub ? 'item' : 'title menu-item-title'} ${
                        showIcon ? 'text-center text-fz-18' : ''
                    }`}
                >
                    {showIcon ? (
                        <Tooltip placement="right" title={name}>
                            <Link
                                className={`${selected ? 'selected' : ''}`}
                                to={url.to(path)}
                                onClick={onClick ? onClick : () => {}}
                            >
                                <span className={showName ? 'mr-2' : ''}>{icon ? icon : <Badge status="default" />}</span>{' '}
                                {showName ? name : ''}
                            </Link>
                        </Tooltip>
                    ) : (
                        <Link
                            className={`${selected ? 'selected' : ''}`}
                            to={url.to(path)}
                            onClick={onClick ? onClick : () => {}}
                        >
                            <span className={showName ? 'mr-2' : ''}>{icon ? icon : <Badge status="default" />}</span>{' '}
                            {showName ? name : ''}
                        </Link>
                    )}
                </li>
            )}
        </ExpandContext.Consumer>
    );
}
