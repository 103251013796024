import { FileExcelOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Upload, message, Typography } from 'antd';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';
import React, { useState } from 'react';

import { trans } from '@System/i18n';

const { Text } = Typography;
const { Dragger } = Upload;

export default function UploadFile({ maxFileSize, fileType, name, accept, onChange, loading, setFileList, fileList }) {

    function checkFile(file) {
        let fileFize = file.size / 1024 / 1024;
        if (maxFileSize && fileFize > maxFileSize) {
            message.error(
                trans('validation:max.file', {
                    attribute: <strong>{name || trans('common:label.avatar')}</strong>,
                    max: <strong>{maxFileSize}</strong>,
                })
            );
            return false;
        }

        if (fileType && fileType.indexOf(file.type) < 0) {
            message.error(
                trans('validation:mimetypes', {
                    attribute: <strong>{name || trans('listProducts:label.products_excel')}</strong>,
                    values: <strong>{accept}</strong>,
                })
            );
            return false;
        }

        return true;
    }

    function _onChange(info) {
        if (checkFile(info.file) && isFunction(onChange)) {
            onChange(info.file);
        }

        setFileList([info.file]);
    }

    return (
        <Dragger
            accept={accept}
            className={clsx({
                'd-none': fileList.length,
            })}
            action={null}
            fileList={fileList}
            itemRender={(_, file) => (
                <div className="upload-list-item">
                    <div>
                        <FileExcelOutlined />
                        <Text className="">{file.name}</Text>
                    </div>

                    <Upload
                        accept={accept}
                        action={null}
                        beforeUpload={() => false}
                        fileList={fileList}
                        showUploadList={false}
                        onChange={_onChange}
                    >
                        <Button className="p-0" type="link">
                            {trans('label.select_again')}
                        </Button>
                    </Upload>
                </div>
            )}
            name="file"
            beforeUpload={() => false}
            onChange={_onChange}
        >
            {!fileList.length && (
                <>
                    <p className="ant-upload-drag-icon mb-2">{loading ? <LoadingOutlined /> : <InboxOutlined />}</p>
                    <p className="ant-upload-text">{trans('label.click_or_drag_file')}</p>
                </>
            )}
        </Dragger>
    );
}
