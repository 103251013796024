import createUseQuery from '@Modules/App/Hooks/createUseQuery';
import api from "@Modules/ShippingPartner/services/api";

const useShippingPartnersQuery = (params, settings) =>
    createUseQuery({
        name: ['shipping-partners', params],
        query: () => api.getShippingPartners(params),
        settings: {
            ...settings,
            enabled: false,
        },
    });

export default useShippingPartnersQuery;
