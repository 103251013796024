import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get } from 'lodash';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useStoresQuery from '@Modules/Setting/Hooks/useStoresQuery';
import { IAccount } from '@Modules/Setting/Interfaces';
import AddConnectionModal from '@Modules/Setting/components/SalesChannel/AddConnectionModal';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';

import List from './List';

interface ISalesChannelProps {
    history: any;
    location: any;
}

const SalesChannel: React.FC<ISalesChannelProps> = ({ history, location }) => {
    const { data, isFetching, isError, error, refetch } = useStoresQuery();
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setNavigator(t('title.sales_channel'), [
            {
                name: t('breadcrumb.sales_channel'),
            },
        ]);
        setDocumentTitle(t('title.sales_channel'));
    }, []);

    useEffect(() => {
        const queryParams = parse(location.search);

        if (queryParams?.error) {
            notification.error(
                t(`setting:sales_channel.message.${queryParams.error}`, {
                    seller: queryParams.merchant_name,
                })
            );

            history.replace(location.pathname);
        }

        if (queryParams?.shop_exist) {
            notification.error(t(`setting:sales_channel.message.channel_exist`));
            return;
        }

        if (queryParams?.store_id) {
            notification.success(t(`setting:sales_channel.message.create_account_success`));
            return url.redirectTo('settings.sales_channel');
        }
    }, [location]);

    useEffect(() => {
        setAccounts(get(data, 'data.stores', []));
    }, [data]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    const handleVisible = () => {
        setVisible(true);
    };

    const handleHide = () => {
        setVisible(false);
    };

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.sales_channel')}</h3>
            </div>
            <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
                <div className="data-box">
                    <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                        <h3 className="text-fz-18">
                            <div className="d-flex justify-content-between align-items-center">
                                {t('heading.sales_channel')}
                                <span className="number-circle _importing-document-product-list-total">
                                    {accounts?.length}
                                </span>
                            </div>
                        </h3>
                        <Button type="primary" icon={<PlusOutlined />} onClick={handleVisible}>
                            {t('setting:sales_channel.btn.create_account')}
                        </Button>
                    </div>
                    <List accounts={accounts} loading={isFetching} createAccount={handleVisible} refetch={refetch} />
                </div>
            </div>
            <AddConnectionModal refetch={refetch} onCancel={handleHide} visible={visible} />
        </div>
    );
};

export default SalesChannel;
