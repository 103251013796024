import { Col, Row, Table, DatePicker } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { dateFormatter } from '@Modules/App/services';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

import api from '../services/api';
import PaginationWithParam from './PaginationWithParam';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const ModalStorageFee = ({ sku_id, currency, warehouse_id }) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState({
        warehouse_id,
    });

    useEffect(() => {
        const getStorageFee = async () => {
            setLoading(true);
            try {
                const res = await api.getStorageCost(sku_id, params);
                setData(get(res, 'data.sku_storage_fee_dailies'));
                setPagination(get(res, 'data.pagination'));
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        getStorageFee();
    }, [params, sku_id, warehouse_id]);

    let columns = [
        {
            className: '_storage-fee-closing_time text-left',
            title: t('product:label.day'),
            dataIndex: 'closing_time',
            key: 'closing_time',
            render: (text, record) => <p>{text ? dateFormatter.date(text) : '---'}</p>,
        },
        {
            className: '_storage-fee-quantity text-right',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => <p>{text ? text : ''}</p>,
        },
        {
            className: '_storage-fee-cbm text-right',
            title: <div>{t('product:label.cbm')}</div>,
            dataIndex: 'volume',
            key: 'volume',
            render: (text, record) => <p>{text ? text : ''}</p>,
        },
        {
            className: '_storage-fee-inventory text-right',
            title: t('product:label.storage_fee'),
            dataIndex: 'fee',
            key: 'fee',
            render: (text, record) => <p>{text ? formatCurrency(text, currency ? currency : null) : ''}</p>,
        },
    ];

    const _onChangeDate = (name, dates) => {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        if (dates) {
            setParams({
                ...params,
                [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
                [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
            });
        } else {
            setParams({
                warehouse_id,
            });
        }
    };

    return (
        <div className="data-box--body">
            <Row className="flex justify-content-end mt-4 mb-4">
                <Col xs={{ span: 16 }} sm={{ span: 10 }} md={{ span: 8 }}>
                    <RangePicker
                        className="_products-search-created-at"
                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                        format={dateFormat}
                        style={{ width: '100%' }}
                        onChange={date => _onChangeDate('closing_time', date)}
                    />
                </Col>
            </Row>
            <Table
                className="_storage-fee-table pb-3"
                dataSource={data}
                rowKey={record => record?.warehouse_id}
                columns={columns}
                pagination={false}
                loading={loading}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className="_storage-fee-empty" loading={loading} /> }}
            />
            <PaginationWithParam pagination={pagination} addFilter={setParams} filters={params} />
        </div>
    );
};

export default ModalStorageFee;
