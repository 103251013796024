import lodash from 'lodash'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment } from 'react'
import sha256 from 'crypto-js/sha256';
import notification from '@System/notification'

import { trans } from './../i18n'

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue)

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => getEnv(param, defaultValue)

/**
 * Create number sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const numberSorter = param => (a, b) => a[param] - b[param]

/**
 * Create string sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const stringSorter = param => (a, b) => {
    let aValue = a[param].toUpperCase()
    let bValue = b[param].toUpperCase()

    if (aValue < bValue) {
        return -1
    }

    if (aValue > bValue) {
        return 1
    }

    return 0
}

export const notifyErrorFromServer = (error, defaultMessage, prefix) => {
    let message = defaultMessage

    const code = error?.response?.data?.code
    const errors = error?.response?.data?.data

    if (code !== 'EXCEPTION' && lodash.isObject(errors) && errors && Object.keys(errors).length) {
        const firstErrorKey = Object.keys(errors)[0]
        let translatedErrors = []

        if (lodash.isObject(errors[firstErrorKey])) {
            for (const error of Object.keys(errors[firstErrorKey])) {
                translatedErrors.push(trans(`${prefix}.${firstErrorKey}.${error}`))
            }
        } else {
            translatedErrors = [errors[firstErrorKey]]
        }

        message = translatedErrors.map((translatedError, index) => (
            <Fragment key={index}>
                {translatedError}
                {index !== translatedErrors.length - 1 && <br />}
            </Fragment>
        ))
    }

    notification.error(message)
}

export const downloadBlobData = (data, fileName, fileType) => {
    if (!data) {
        return
    }

    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.setAttribute('href', downloadUrl)
    link.setAttribute('download', `${fileName}.${fileType}`)

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
}

export const convertBlobDataToCsv = (data, fileName) => {
    downloadBlobData(data, fileName, 'csv')
}

export const convertBlobDataToExcel = (data, fileName) => {
    downloadBlobData(data, fileName, 'xlsx')
}

export const getVar = (object, param, defaultValue = null) => {
    let value = lodash.get(object, param)

    return value === undefined || value === null || value === '' ? defaultValue : value
}

export const renderImportErrors = (error, type) => {
    const line = lodash.get(error, 'line', '')
    const content = lodash.get(error, 'errors', {})
    let messages = []

    Object.entries(content).forEach(entry => {
        const [key, errors] = entry

        Object.keys(errors).forEach(errorKey => {
            messages.push(
                trans(`import_errors.${errorKey}`, {
                    attribute: trans(`${type}:label.${key}`),
                })
            )
        })
    })

    if (messages.length > 0) {
        return trans('import_errors.line', { line: line, content: messages.join(' ') })
    }

    return trans('import_errors.unknown_error')
}

export default function getTheme() {
    const domain = window.location.hostname
    const themeInfo = find(JSON.parse(getConfig('REACT_APP_THEMES', JSON.stringify([]))), item => item.domains.includes(domain))
    return isEmpty(themeInfo) ? null : themeInfo
}

export const classes = (currentClass, restClass = {}) => {
    if (isEmpty(restClass)) return currentClass

    let newClass = ''

    Object.entries(restClass).map(([key, val]) => (newClass += val ? key : ''))

    return currentClass + ' ' + newClass
}

export const millisecondTime = millisecond => {
    return {
        second: millisecond / 1000,
        minutes: millisecond / 60 / 1000,
        hours: millisecond / 60 / 60 / 1000,
        day: millisecond / 24 / 60 / 60 / 1000,
    }
}


/**
 * Pseudo-random string generator
 * http://stackoverflow.com/a/27872144/383904
 * Default: return a random alpha-numeric string
 * 
 * @param {Integer} len Desired length
 * @param {String} an Optional (alphanumeric), "a" (alpha), "n" (numeric)
 * @return {String}
 */
export const  randomString = (len, an)=> {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an === "a" ? 10 : 0,
      max = an === "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = Math.random() * (max - min) + min << 0;
      str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
    }
    return str;
}

export const generatorHashKey = (data = {},key = "") => {
    return sha256(JSON.stringify(data) + key).toString()
}