import { Select } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';

import useShippingPartnersQuery from '@Modules/ShippingPartner/Hooks/useShippingPartnersQuery';

import { getVar } from '@System/support/helpers';

const { Option } = Select;

export default function SelectShippingPartner(props) {
    const { location_id } = props;
    let [statuses, setStatuses] = useState([]);
    const { data, refetch, isFetching } = useShippingPartnersQuery({ location_id: location_id });

    useEffect(() => {
        refetch();
    }, [location_id]);

    useEffect(() => {
        setStatuses(getVar(data, 'data.shippingPartners', []));
    }, [data]);

    return (
        <Select
            loading={isFetching}
            {...props}
            value={statuses.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {statuses.length > 0 &&
                statuses.map(opt => {
                    const name = lodash.get(opt, 'name', '');
                    const code = lodash.get(opt, 'code', '');
                    return (
                        <Option key={opt.id} value={opt.id}>
                            {code + (name ? ' - ' + name : '')}
                        </Option>
                    );
                })}
        </Select>
    );
}
