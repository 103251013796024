import { DeleteOutlined } from '@ant-design/icons'
import { Avatar, Col, Form, Input, InputNumber, Row, Table } from 'antd'
import { map } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useSize from '@Modules/App/Hooks/useSize'
import { auth } from '@Modules/Auth/services'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { classes, getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import CurrencyInput from '../../../App/Utilities/CurrencyInput'
import { updateCollectionItem } from '../../../App/services/helpers'
import AddProduct from './AddProduct'

export default function ProductInfo({ orderSkus, setOrderSkus, currentSeller, validatedRules, form }) {
    const user = auth.user()
    const currency = getVar(user, 'currency', {})
    const [total, setTotal] = useState(undefined)
    const [discount, setDiscount] = useState(0)
    useEffect(() => {
        let totalOrder = calculateTotal(orderSkus)
        setTotal(totalOrder)
        if (orderSkus.length === 0) {
            setDiscount(0)
            form.setFields([{ name: 'discount_amount', value: undefined }])
        }
        form.setFields([{ name: 'product_quantity', value: orderSkus.length > 0 ? orderSkus.length : '' }])
        if (orderSkus.length > 0) form.validateFields(['product_quantity'])
    }, [orderSkus])

    useEffect(() => {
        form.setFieldsValue({ total_amount: total ? (total > discount ? total - discount : undefined) : '' })
    }, [total, discount])

    /**
     * Nếu thay đổi currentSeller(seller hiện tại) của đơn hàng thì cập nhật lại giá các sku trong đơn theo seller
     */
    useEffect(() => {
        if (currentSeller) {
            let tmpSkus = orderSkus.map(item => {
                let price = ''
                let priceByMerchant = item.prices.find(subitem => subitem.merchant_id === currentSeller.id)
                if (priceByMerchant) price = priceByMerchant.retail_price

                return { ...item, price }
            })
            setOrderSkus(tmpSkus)
        }
    }, [currentSeller])

    function _onBlur(sku_id, name, value) {
        if (parseFloat(value)) {
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, { [name]: parseFloat(value) }, 'sku_id'))
        } else {
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, { [name]: 0 }, 'sku_id'))
        }
    }

    function _onRemove(e, sku_id) {
        e.preventDefault()
        setOrderSkus(orderSkus.filter(sku => sku.sku_id !== parseInt(sku_id)))
    }

    // Thành tiền ở từng line sản phẩm = Số lượng * Đơn giá + (Số lượng * Đơn giá * Thuế) - Số tiền chiết khấu
    function calculateTotalOnRow(sku) {
        let pricePerQuantity = parseInt(sku.quantity) * (sku.price ? parseFloat(sku.price) : 0)
        return pricePerQuantity + pricePerQuantity * (sku.tax / 100) - sku.discount_amount
    }

    // Tổng thành tiền
    function calculateTotal() {
        if (orderSkus.length === 0) return 0

        return orderSkus.reduce((total, sku) => {
            let totalRow = calculateTotalOnRow(sku)
            return total + totalRow
        }, 0)
    }

    let getColumns = [
        {
            title: t('product:label.product'),
            dataIndex: 'code',
            key: 'code',
            className: '_product-info-code d-flex align-items-center',
            width: 430,
            render: (text, record) => {
                const image = getVar(record, 'image', '')
                const name = getVar(record, 'name', [])
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={image}
                            className="mr-2"
                            size={36}
                            style={{ minWidth: '36px' }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={url.to('products.detail', { productId: record?.product_id, skuId: record?.sku_id })}>
                                    <strong style={{ fontWeight: 500 }}>{text}</strong>
                                </Link>
                                <p style={{ color: '#999999', whiteSpace: 'pre-line' }}>{name}</p>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            title: t('label.quantity'),
            dataIndex: 'quantity',
            className: '_product-info-quantity',
            key: 'quantity',
            width: 180,
            render: (text, record) => {
                return (
                    <InputNumber
                        name="quantity"
                        value={text}
                        className="w-100"
                        onBlur={e => _onBlur(record?.sku_id, 'quantity', e.target.value ? e.target.value : 1)}
                        onChange={value => _onBlur(record?.sku_id, 'quantity', value)}
                        step={1}
                        min={1}
                    />
                )
            },
        },
        {
            title: t('order:label.price'),
            dataIndex: 'price',
            className: '_product-info-price',
            key: 'price',
            width: 180,
            render: (text, record) => {
                return (
                    <CurrencyInput
                        currency={currency}
                        value={text || 0}
                        name="price"
                        className="text-right w-100"
                        onBlur={value => _onBlur(record?.sku_id, 'price', value)}
                    />
                )
            },
        },
        {
            title: t('order:label.sum_price'),
            className: 'text-right _product-info-total-amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            width: 120,
            render: (text, record) => {
                const info = calculateTotalOnRow(record)
                return isEmpty(currency) ? info : formatCurrency(info || 0, currency)
            },
        },
        {
            className: 'text-right _product-info-action',
            dataIndex: 'delete',
            key: 'delete',
            width: 60,
            render: (text, record) => {
                return (
                    <span
                        className={'text-red tw-cursor-pointer'}
                        onClick={e => _onRemove(e, record?.sku_id)}
                    >
                        <DeleteOutlined />
                    </span>
                )
            },
        },
    ]

    const expandedRowRender = (record, data) => {
        return map(data, item => {
            const image = getVar(item, 'sku.data.image', '')
            const name = getVar(item, 'sku.data.name', [])
            const code = getVar(item, 'sku.data.code', [])
            const skuId = getVar(item, 'sku.data.id', [])
            const productId = getVar(item, 'sku.data.product_id', [])
            const quantity = getVar(item, 'quantity', 0)

            const price = getVar(item, 'sku.data.price', 0)

            const quantityParentSku = getVar(record, 'quantity', 0)

            return (
                <div
                    className="d-flex tw-ml-16"
                    style={{ borderBottom: '1px solid #e5e7f1' }}
                    key={code}
                >
                    <td style={{ padding: '15px 10px', width: window.innerWidth > 1700 ? 430 : 400 }}>
                        <div className="d-flex align-items-center">
                            <Avatar
                                shape="square"
                                src={image}
                                className="mr-2"
                                size={36}
                                style={{ minWidth: '36px' }}
                            />
                            <div className="d-flex flex-wrap">
                                <span>
                                    <Link
                                        to={url.to('products.detail', {
                                            productId: productId,
                                            skuId: skuId,
                                        })}
                                    >
                                        <strong style={{ fontWeight: 500 }}>{code}</strong>
                                    </Link>
                                    <p style={{ color: '#999999', whiteSpace: 'pre-line' }}>{name}</p>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td
                        className="ant-table-cell d-flex align-items-center tw-w-48"
                        style={{ padding: '15px 10px' }}
                    >
                        <span className={classes("tw-px-2 tw-w-full tw-text-end ",{
                            "tw-mr-5":window.innerWidth < 1700,
                            "tw-mr-4":window.innerWidth > 1700
                        })}>{quantityParentSku * quantity}</span>
                    </td>
                    <td
                        className="ant-table-cell d-flex align-items-center tw-w-48"
                        style={{ padding: '15px 10px' }}
                    >
                        <span className={classes("tw-px-2 tw-w-full tw-text-end ",{
                            "tw-mr-7":window.innerWidth < 1700,
                            "tw-mr-4":window.innerWidth > 1700
                        })}>
                            {isEmpty(currency) ? price : formatCurrency(price || 0, currency)}
                        </span>
                    </td>
                </div>
            )
        })
    }

    return (
        <div className="bg-white rounded-12">
            <div>
                <AddProduct
                    setOrderSkus={setOrderSkus}
                    orderSkus={orderSkus}
                    form={form}
                    currentSeller={currentSeller}
                />

                <Table
                    className="_product-info-table create-order-product-table pb-2 mt-3"
                    dataSource={orderSkus}
                    rowKey={record => record?.sku_id}
                    columns={getColumns}
                    pagination={false}
                    scroll={{ x: true }}
                    locale={{
                        emptyText: <p className="_no-data">{t('message.no_data_product')}</p>,
                    }}
                    expandable={{
                        expandedRowRender: record => {
                            return expandedRowRender(record, record?.skus?.data)
                        },

                        rowExpandable: record => {
                            const isCombo = !isEmpty(getVar(record, 'skus.data', []))
                            return isCombo
                        },
                    }}
                    footer={() => (
                        <>
                            <Form.Item
                                className="mb-0 hidden_input"
                                name="product_quantity"
                                rules={validatedRules.product_quantity}
                            >
                                <InputNumber
                                    name="product_quantity"
                                    placeholder={t('order:placeholder.product_quantity')}
                                />
                            </Form.Item>
                            <Row
                                gutter={24}
                                className="text-right d-flex align-items-center justify-content-between _product-info-total-sum-price"
                            >
                                <Col
                                    xs={{ span: 12 }}
                                    xl={{ span: 18 }}
                                >
                                    <p>{t('order:label.total_sum_price')}:</p>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    md={{ span: 6 }}
                                >
                                    {isEmpty(currency)
                                        ? total > 0
                                            ? total
                                            : 0
                                        : formatCurrency(total > 0 ? total : 0, currency)}
                                </Col>
                            </Row>
                            <Row
                                gutter={24}
                                className="text-right mt-2 d-flex align-items-center justify-content-between _product-info-discount"
                            >
                                <Col
                                    xs={{ span: 12 }}
                                    xl={{ span: 18 }}
                                >
                                    <p>{t('order:label.discount')}:</p>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    md={{ span: 6 }}
                                >
                                    <Form.Item
                                        className="mb-0"
                                        name="discount_amount"
                                    >
                                        <CurrencyInput
                                            currency={currency}
                                            name="discount_amount"
                                            style={{ width: '100%' }}
                                            className="text-right _product-info-input-discount"
                                            onBlur={value => {
                                                let newValue = value || 0
                                                form.setFields([{ name: 'discount_amount', value: newValue }])
                                                setDiscount(value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                gutter={24}
                                className="text-right mt-2 d-flex align-items-center justify-content-between _product-info-total-payment"
                            >
                                <Col
                                    xs={{ span: 12 }}
                                    xl={{ span: 18 }}
                                >
                                    <p className="label required">{t('order:label.total_amount')}:</p>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    md={{ span: 6 }}
                                >
                                    <Form.Item
                                        className="mb-0"
                                        name="total_amount"
                                        rules={validatedRules.total_amount}
                                    >
                                        <CurrencyInput
                                            currency={currency}
                                            name="total_amount"
                                            style={{ width: '100%' }}
                                            className="text-right _product-info-input-total-payment"
                                            onBlur={value => {
                                                let newValue = value || undefined
                                                form.setFields([{ name: 'total_amount', value: newValue }])
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </div>
        </div>
    )
}
