import { CloseOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Divider, Dropdown, Menu, Row, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ColumnsType } from 'antd/lib/table'
import { filter, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'

import { t } from '@System/i18n'

export interface IColumn extends ColumnsType {
    title: string
    dataIndex: string
    key: string
    width?: number
    render?: (text: string, record: any, index: number) => any
    disabled?: boolean
}

interface ISettingColumns {
    columns: IColumn[]
    setColumns: (columns: IColumn[]) => void
    onChangeActiveKeys?: (keys: string[]) => void
}

const CheckboxGroup = Checkbox.Group

const SettingColumns: React.FC<ISettingColumns> = ({ columns, setColumns, onChangeActiveKeys }) => {
    const [visible, setVisible] = useState(false)
    const [checkedList, setCheckedList] = useState<any>([])
    const [indeterminate, setIndeterminate] = useState(true)
    const [checkAll, setCheckAll] = useState(false)

    useEffect(() => {
        const disabledColumns = map(columns, 'key')
        setCheckedList(disabledColumns)
        setCheckAll(true)
        setIndeterminate(false)
    }, [columns])

    const onChange = (list: any[]) => {
        const columnsCheck: any = filter(columns, item => list.includes(item.key))
        setCheckedList(list)
        if (onChangeActiveKeys) {
            onChangeActiveKeys(list)
        }

        setIndeterminate(!!list.length && list.length < columns.length)
        setCheckAll(list.length === columns.length)
        setColumns(columnsCheck)
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        const disabledColumns: any = filter(columns, (item: any) => item?.disabled)

        if (e?.target?.checked) {
            setCheckedList(map(columns, (item: any) => item.key))
            setIndeterminate(false)
            if (onChangeActiveKeys) {
                onChangeActiveKeys(map(columns, (item: any) => item.key))
            }
            setColumns(columns)
        } else {
            setCheckedList(map(disabledColumns, 'key'))
            setIndeterminate(!isEmpty(disabledColumns) ? true : false)
            if (onChangeActiveKeys) {
                onChangeActiveKeys(map(disabledColumns, 'key'))
            }
            setColumns(disabledColumns)
        }

        setCheckAll(e.target.checked)
    }

    const handleVisibleDropdown = () => {
        setVisible(true)
    }

    const handleHideDropdown = () => {
        setVisible(false)
    }

    const menus = (
        <Menu className="dynamic-table-menu">
            <div className="text-right">
                <Button
                    type="link"
                    danger
                    onClick={handleHideDropdown}
                >
                    <CloseOutlined />
                </Button>
            </div>
            <div>
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                >
                    {t('btn.check_all')}
                </Checkbox>
                <Divider className="my-3" />
                <CheckboxGroup
                    value={checkedList}
                    onChange={onChange}
                    className="w-100"
                >
                    <Row gutter={[16, 10]}>
                        {map(columns, (item: any, index: number) => {
                            return (
                                <Col
                                    key={item.key}
                                    span={12}
                                >
                                    <Checkbox
                                        value={item.key}
                                        disabled={item.disabled}
                                    >
                                        {item.title}
                                    </Checkbox>
                                </Col>
                            )
                        })}
                    </Row>
                </CheckboxGroup>
            </div>
        </Menu>
    )

    return (
        <div>
            <Dropdown
                overlay={menus}
                trigger={['click']}
                visible={visible}
            >
                <Tooltip title={t('label.setting_columns')}>
                    <Button
                        shape="circle"
                        icon={<SettingOutlined />}
                        onClick={handleVisibleDropdown}
                    />
                </Tooltip>
            </Dropdown>
        </div>
    )
}

export default SettingColumns
