import { CheckOutlined, LoadingOutlined, PlusOutlined, SaveFilled, SearchOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, Modal, Table } from 'antd'
import { find, get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import useDebounce from '@Modules/App/Hooks/useDebounce'
import useListProductQuery from '@Modules/Product/Hooks/useListProductQuery'
import { PRODUCT_STATUS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'

import SkeletonSkuList from './SkeletonSkuList'

interface IModalSkusProps {
    setSkus: React.Dispatch<React.SetStateAction<string[]>>
    skus: any[]
    visible: boolean
    handleHideModal: () => void
}
const ModalSkus: React.FC<IModalSkusProps> = ({ skus, setSkus, visible, handleHideModal }) => {
    const [data, setData] = useState([])

    const [filters, setFilters] = useState({ status: PRODUCT_STATUS.ON_SELL })
    const [page, setPage] = useState({})

    const [keyword, setKeyword] = useState('')
    const { data: products, isFetching } = useListProductQuery(filters)

    const keywordAfter = useDebounce(keyword.trim(), { wait: 500 })

    useEffect(() => {
        setData(get(products, 'data.products', []))
        setPage(get(products, 'data.pagination', {}))
    }, [products])

    useEffect(() => {
        const getData = () => {
            setFilters(prev => ({
                ...prev,
                keyword: keywordAfter,
            }))
        }
        if (!isEmpty(keywordAfter)) {
            getData()
        }
    }, [keywordAfter])

    const onChangePage = (val: any) => {
        setFilters(prev => ({
            ...prev,
            ...val,
        }))
    }

    const onChangeKeyword = (value: string) => {
        setKeyword(value)

        if (!value) {
            setFilters({ status: PRODUCT_STATUS.ON_SELL })
            return
        }
    }

    const columns = [
        {
            className: '_list-product-name text-left',
            dataIndex: ['sku'],
            key: 'label',
            render: (text: any) => {
                const image = get(text, 'images.0', '')
                const name = get(text, 'name', '')
                return (
                    <div className="d-flex align-items-center">
                        <div>
                            <Avatar
                                src={image}
                                shape="square"
                                className="mr-2"
                                size={36}
                            />
                        </div>
                        <div>
                            <span>
                                <strong style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}>{name}</strong>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_list-product-sku-code text-left',
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            render: (text: any) => {
                return (
                    <>
                        <div>SKU:</div>
                        <div>{text}</div>
                    </>
                )
            },
        },
        {
            className: 'text-right _list-product-action',
            key: 'action',
            dataIndex: ['sku'],
            render: (text: any, record: any) => {
                const existsSku = find(skus, ['sku.id', text?.id])
                if (existsSku) {
                    return (
                        <Button
                            className="_list-product-action-disabled-added"
                            disabled
                            icon={<CheckOutlined />}
                        >
                            {t('btn.added')}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            className="_list-product-action-added"
                            onClick={() => handleAddProduct(record)}
                            icon={<PlusOutlined />}
                        >
                            {t('product:btn.add_sku')}
                        </Button>
                    )
                }
            },
        },
    ]

    const handleAddProduct = (skus: any) => {
        setSkus((prev: any) => [...prev, skus])
    }

    return (
        <>
            {visible && (
                <Modal
                    className="_modal-search-product"
                    title={t('product:title.add_product')}
                    visible={true}
                    onCancel={handleHideModal}
                    width={800}
                    footer={[
                        <Button
                            className="_modal-btn-finished"
                            type={'primary'}
                            key={'ok'}
                            onClick={handleHideModal}
                            icon={<SaveFilled />}
                        >
                            {t('btn.finished')}
                        </Button>,
                    ]}
                >
                    <Input
                        className="_input-search-product-"
                        size={'large'}
                        value={keyword}
                        placeholder={t('product:placeholder.search_product_by_name_code')}
                        prefix={isFetching ? <LoadingOutlined style={{ color: 'rgba(0,0,0,.45)' }} /> : <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                        allowClear={true}
                        onChange={e => onChangeKeyword(e.target.value)}
                    />

                    {isFetching ? (
                        <SkeletonSkuList />
                    ) : (
                        <>
                            <Table
                                showHeader={false}
                                className="add-sku-table-modal pb-2 mt-3 add-product-import-package"
                                dataSource={data}
                                rowKey={record => record?.sku?.id}
                                columns={columns}
                                loading={isFetching}
                                pagination={false}
                                scroll={{ x: 576, y: 500 }}
                                locale={{
                                    emptyText: <p>{t('message.no_data_product')}</p>,
                                }}
                            />
                            <CustomizedPagination
                                pagination={page}
                                addFilter={onChangePage}
                                filters={filters}
                            />
                        </>
                    )}
                </Modal>
            )}
        </>
    )
}

export default ModalSkus
