import axios from 'axios';
import SingleRequestHandler from './SingleRequestHandler';
import i18next from "i18next";
import { generatorHashKey } from '@System/support/helpers';
import { IdempotencyStorage } from '@Modules/Order/Hooks/useGeneratorKey';
import { get } from 'lodash';

export default class ApiFactory {
    constructor(baseUrl, auth = null) {
        this.baseUrl = baseUrl;
        this.auth = auth;
    }

    make() {
        let auth = this.auth;
        let singleRequestHandler = new SingleRequestHandler();

        var api = axios.create({
            baseURL: this.baseUrl,
        });

        api.interceptors.request.use(config => {
            const key =  get(IdempotencyStorage.getValue(), 'key')

            if (config?.idempotency) {
                config.headers = {
                    ...config.headers,
                    'Idempotency-Key': generatorHashKey(config?.data,key)
                };
            }

            config.params = config.params ? {
                ...config.params
            } : {};
            config.params.locale = i18next.language;

            if (auth) {
                config.headers = {
                    ...config.headers,
                    'Access-Control-Allow-Headers': 'Authorization',
                    'Authorization': 'Bearer ' + auth.getToken(),
                };
            }

            let cancelToken = axios.CancelToken.source();
            config.cancelToken = cancelToken.token;

            // Handle single request
            if (config.singleRequest) {
                singleRequestHandler.handle(config.method + '::' + config.url, cancelToken.cancel);
            }

            return config;
        }, error => {
            return Promise.reject(error);
        });

        api.interceptors.response.use(response => {
            return response;
        }, error => {
            if (!axios.isCancel(error)) {
                return Promise.reject(error);
            }
        });

        return api;
    }

}
