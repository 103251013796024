import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, DatePicker, Col, Button, Input, Select } from 'antd';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import split from 'lodash/split';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import useSize from '@Modules/App/Hooks/useSize';
import SelectStatuses from '@Modules/Order/components/SelectStatuses';

import { t } from '@System/i18n';

import { PAYMENT_TYPE } from '../../services/constants';
import SelectShop from "@Modules/Setting/components/SalesChannel/SelectShop";

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

const initialFilters = {
    code: undefined,
    receiver_name: undefined,
    status: undefined,
    payment_type: undefined,
    freight_bill: undefined,
    'created_at[from]': undefined,
    'created_at[to]': undefined,
    sku_code: undefined,
    name_store: undefined
};

function FormSearch({ onSearch, filters }) {
    const { isMobile, isTablet } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const { code, receiver_name, status, payment_type, freight_bill, sku_code, name_store } = input || {};
    const created_at_from = get(input, 'created_at[from]', '');
    const created_at_to = get(input, 'created_at[to]', '');

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        if (name === 'sku_code') {
            const value_array = value ? split(value, ' ') : undefined;
            setInput({ ...input, [name]: value, sku_code: value_array });
        } else {
            setInput({ ...input, [name]: value });
        }
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        });
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        onSearch(initialFilters);
    }

    return (
        <div>
            <Form form={form} layout="vertical">
                <div className="bg-white rounded-12 p-4 mt-3 box-shadow">
                    <div className="search-box">
                        <Row gutter={20}>
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('order:label.order_code')}>
                                    <Input
                                        className="_orders-search-order-code"
                                        name="code"
                                        value={code}
                                        placeholder={t('order:placeholder.order_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('order:label.freight_bill_code')}>
                                    <Input
                                        className="_orders-search-freight-bill-code"
                                        name="freight_bill"
                                        value={freight_bill}
                                        placeholder={t('order:placeholder.freight_bill_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('order:label.sku_code')}>
                                    <Input
                                        className="_orders-search-sku-code"
                                        name="sku_code"
                                        value={isArray(sku_code) ? sku_code.join(' ') : undefined}
                                        placeholder={t('order:placeholder.sku_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                <Form.Item className={isMobile || isTablet ? 'mb-3' : 'mb-0'} label={t('label.created_at')}>
                                    <RangePicker
                                        className="_orders-search-created-at"
                                        value={
                                            created_at_from && created_at_to
                                                ? [moment(created_at_from), moment(created_at_to)]
                                                : undefined
                                        }
                                        format={dateFormat}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        onChange={date => _onChangeDate('created_at', date)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.order_status')}>
                                    <SelectStatuses
                                        className="_orders-search-status"
                                        placeholder={t('order:placeholder.status')}
                                        allowClear={true}
                                        value={status}
                                        onChange={value => _onChange('status', value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('order:label.customer')}>
                                    <Input
                                        className="_orders-search-receiver-name"
                                        name="receiver_name"
                                        value={receiver_name}
                                        placeholder={t('order:placeholder.customer')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item label={t('order:label.payment_type')} className="mb-3">
                                    <Select
                                        className="_orders-search-payment-type"
                                        placeholder={t('order:placeholder.payment_type')}
                                        onChange={value => _onChange('payment_type', value)}
                                        value={payment_type}
                                        optionFilterProp="children"
                                        allowClear={true}
                                    >
                                        {Object.keys(PAYMENT_TYPE).map((opt, index) => {
                                            return (
                                                <Option key={index} value={PAYMENT_TYPE[opt]}>
                                                    {t(`order:payment_type.${PAYMENT_TYPE[opt]}`)}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('order:label.store_id')}>
                                    <SelectShop
                                        className="_orders-search-sales-channel"
                                        name="name_store"
                                        value={name_store}
                                        placeholder={t('order:placeholder.shop_name')}
                                        onChange={value => _onChange('name_store', value)}
                                        onPressEnter={_onSubmit}
                                        nameValue
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ offset: 4, span: 8 }} className="text-right">
                                <Form.Item className="mb-0" label={isMobile || isTablet ? '' : ' '}>
                                    <Button className="mr-2 _orders-search-btn-reset" onClick={_onReset}>
                                        <UndoOutlined /> {t('common:btn.set_again')}
                                    </Button>
                                    <Button type="primary" onClick={_onSubmit} className="_orders-search-btn-search btn-primary">
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default FormSearch;
