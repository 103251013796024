import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import get from 'lodash/get';
import React, { useState } from 'react';

import api from '@Modules/Order/services/api';
import { ORDER_EVENT } from '@Modules/Order/services/constants';

import { events } from '@System/events';
import { trans } from '@System/i18n';

/**
 * @return {null}
 * @return {null}
 */

export default function CancelOrder({ orderInfo }) {
    const { can_cancel, order } = orderInfo || {};
    const [loading, setLoading] = useState(false);
    function handleOk() {
        setLoading(true);
        api.cancelOrder(order.id)
            .then(() => {
                events.dispatch(ORDER_EVENT.RELOAD_ORDER_DETAIL, {});
                notification.success({message: trans('order:message.cancel_order_success')});
            })
            .catch(err => {
                const status = get(err, 'response.status', null);
                if (status === 403) notification.error({ message: trans('common:message.403') });
                else if (status === 404) notification.error({ message: trans('common:message.404') });
                else notification.error({ message: trans('order:message.cancel_order_fail') });
            })
            .finally(() => setLoading(false));
    }

    function handleConfirm() {
        Modal.confirm({
            title: trans('order:message.confirm_cancel_order', { code: <b>{`#${order?.code}`}</b> }),
            okText: trans('btn.confirm'),
            cancelText: trans('btn.cancel'),
            onOk: handleOk,
        });
    }

    if (!can_cancel) return null;

    return (
        <div className="_cancel-order">
            <Button
                danger={true}
                className="_btn-cancel-order"
                loading={loading}
                onClick={handleConfirm}
                icon={<DeleteOutlined />}
            >
                {trans('order:btn.cancel_order')}
            </Button>
        </div>
    );
}
