import { DatePicker, Divider, Form, Input } from 'antd'
import React, { useState } from 'react'

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput'
import { auth } from '@Modules/Auth/services'
import { PAYMENT_METHOD, PAYMENT_TYPE } from '@Modules/Order/services/constants'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

import RadioPaymentType from '../RadioPaymentType'
import SelectPaymentMethod from '../SelectPaymentMedthod'
import moment from 'moment'

export default function PaymentInfo({ validatedRules, paidTotal, form }) {
    const [paymentType, setPaymentType] = useState()
    const [paymentMethod, setPaymentMethod] = useState('')

    const user = auth.user()
    const currency = getVar(user, 'currency', {})

    return (
        <div className="border box-shadow bg-white pt-3 pr-4 pl-4 pb-4 mt-4">
            <h3 className="mb-3">{t('order:label.payment_information')}</h3>
            <div>
                <Form.Item
                    className="mb-1 payment_type"
                    name="payment_type"
                >
                    <RadioPaymentType
                        onChange={e => setPaymentType(e.target.value)}
                    />
                </Form.Item>
                {paymentType === PAYMENT_TYPE.PAYMENT_TYPE_ADVANCE_PAYMENT && (
                    <>
                        <Divider className="mb-2 mt-2" />

                        <Form.Item
                            className="mb-1 payment_method"
                            name="payment_method"
                            label={t('order:label.payment_method')}
                            rules={[
                                {
                                    required:  true,
                                    message: t('order:message.required', { attribute: t(`order:label.payment_method`) }),
                                },
                            ]}
                        >
                            <SelectPaymentMethod
                                placeholder={t('order:placeholder.method')}
                                onChange={value => setPaymentMethod(value)}
                            />
                        </Form.Item>

                        <Form.Item
                            className="mb-1 payment_time"
                            name="payment_time"
                            label={t('order:label.payment_time')}
                            rules={[
                                {
                                    required:  true,
                                    message: t('order:message.required', { attribute: t(`order:label.payment_time`) }),
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder={t('order:placeholder.payment_time')}
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>

                        <Form.Item
                            className="mb-1 payment_amount"
                            name="payment_amount"
                            label={t('order:label.payment_amount')}
                            rules={[
                                {
                                    required:  true,
                                    message: t('order:message.required', { attribute: t(`order:label.payment_amount`) }),
                                },
                            ]}
                        >
                            <CurrencyInput
                                placeholder={t('order:placeholder.payment_amount')}
                                style={{ width: '100%' }}
                                currency={currency}
                                // onBlur={value => form.setFields([{ name: 'payment_amount', value }])}
                            />
                        </Form.Item>

                        <Form.Item
                            className="mb-1 standard_code"
                            name="standard_code"
                            label={t('order:label.standard_code')}
                            rules={[
                                {
                                    required:  paymentMethod === PAYMENT_METHOD.METHOD_BANK_TRANSFER,
                                    message: t('order:message.required', { attribute: t(`order:label.standard_code`) }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t('order:placeholder.standard_code')}
                                onBlur={e => form.setFields([{ name: 'standard_code', value: e.target.value }])}
                            />
                        </Form.Item>
                        {
                            paymentMethod === PAYMENT_METHOD.METHOD_BANK_TRANSFER &&
                            <>
                                <Form.Item
                                    className="mb-1 bank_name"
                                    name="bank_name"
                                    label={t("order:label.bank_name")}
                                    rules={[
                                        {
                                            required:  true,
                                            message: t('order:message.required', { attribute: t(`order:label.bank_name`) }),
                                        },
                                    ]}
                                >
                                    <Input
                                        name="bank_name"
                                        placeholder={t("order:placeholder.bank_name")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-1 bank_account"
                                    name="bank_account"
                                    label={t("order:label.bank_account")}
                                    rules={[
                                        {
                                            required:  true,
                                            message: t('order:message.required', { attribute: t(`order:label.bank_account`) }),
                                        },
                                    ]}
                                >
                                    <Input
                                        name="bank_account"
                                        placeholder={t("order:placeholder.bank_account")}
                                    />
                                </Form.Item>
                            </>
                        }
                        <Form.Item
                            className="mb-1 payment_note"
                            name="payment_note"
                            label={t('order:label.payment_note')}
                            rules={validatedRules.payment_note}
                        >
                            <Input.TextArea
                                placeholder={t('order:placeholder.payment_note')}
                                rows={3}
                                disabledDate={current => current && current > moment().endOf('day')}
                            />
                        </Form.Item>
                    </>
                )}
            </div>
        </div>
    )
}
