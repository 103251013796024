import { Form, Input, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { t } from '@System/i18n';

const EditableContext = React.createContext(null);

const ProductVariations = ({ product, localOptions, localVariations, setLocalVariations, form }) => {
    const { lg } = useBreakpoint();
    const prevLocalOptions = useRef([]);
    const prevLocalVariations = useRef([]);

    useEffect(() => {
        if (_.isEmpty(prevLocalOptions.current)) {
            prevLocalOptions.current = localOptions;
        } else {
            createVariations();
            prevLocalOptions.current = localOptions;
            prevLocalVariations.current = localVariations;
        }

        // eslint-disable-next-line
    }, [localOptions]);

    function createVariations() {
        let variations = [];
        /**
         * Tạo lại mảng các biến thể theo thuộc tính mới được thêm vào
         */
        localOptions.forEach(option => {
            let { label, values, code } = option;
            if (!_.isEmpty(label) && !_.isEmpty(values)) {
                if (variations.length === 0) {
                    values.forEach(value => {
                        return variations.push({
                            id: null,
                            code: code,
                            option_values: [{ id: value.id, label: value.label, option_label: option.label }],
                        });
                    });
                } else {
                    let new_variations = [];
                    values.forEach(value => {
                        variations.forEach(variation => {
                            let { option_values, code } = variation;
                            new_variations.push({
                                id: variation.id,
                                code: code,
                                option_values: [
                                    ...option_values,
                                    { id: value.id, label: value.label, option_label: option.label },
                                ],
                            });
                        });
                    });
                    variations = [...new_variations];
                }
            }
        });

        /**
         * Cập nhật lại id và code cho các biến thể mới được tạo lại dựa vào các biến thể cũ
         * Lấy ra biến thể cũ có các giá trị thuộc tính trùng với biến thể mới để cập nhật
         */
        let existedSkuIds = [];
        let variationsFormat = variations.map(item => {
            let currentItem = localVariations.find(localItem => {
                let gteCheck = item.option_values.length >= localItem.option_values.length;
                let optValues = _.get(gteCheck ? localItem : item, 'option_values', []);
                let optValueIds = _.map(_.get(gteCheck ? item : localItem, 'option_values', []), 'id');
                return !existedSkuIds.includes(localItem.id) && optValues.every(value => optValueIds.includes(value.id));
            });

            if (currentItem) {
                existedSkuIds.push(currentItem.id);
                return {
                    ...item,
                    key: currentItem.id,
                    id: currentItem.id,
                    code: currentItem.code,
                };
            } else {
                return { ...item, key: v4() };
            }
        });
        setLocalVariations(_.sortBy(variationsFormat, ['id']));
    }

    const columns = [
        {
            title: t('product:label.sku'),
            className: '_product-column',
            dataIndex: ['option_values'],
            key: ['option_values'],
            width: '50%',
            textWrap: 'word-break',
            render: (text, record, index) => {
                let name = form.getFieldValue('name');
                return name ? (
                    <div style={{ fontWeight: '500' }}>
                        <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{name}</div>
                        {text.map((item, index) => (
                            <span style={{ fontWeight: '500', color: '#A0A3BD' }}>
                                {index !== 0 ? ', ' + item.label : item.label}
                            </span>
                        ))}
                    </div>
                ) : (
                    ''
                );
            },
        },
        {
            title: t('product:label.sku_code'),
            className: '_sku-column',
            dataIndex: 'code',
            key: 'code',
            width: '320px',
            editable: true,
        },
    ];

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ title, editable, children, dataIndex, record, index, handleSave, handleBlur, ...restProps }) => {
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editable) {
                form.setFieldsValue({ [dataIndex]: record[dataIndex] });
            }
        }, [editable]);

        const save = () => {
            form.validateFields().then(values => {
                let { code } = values;
                handleSave({ ...record, ...values }, index);
            });
        };

        let childNode = children;

        if (editable) {
            childNode = (
                <Form.Item name={dataIndex} className="mb-0">
                    <Input
                        className="_dropshipping-variants-weight-input"
                        onPressEnter={save}
                        onBlur={save}
                        placeholder={t('product:placeholder.sku_blank_system')}
                        style={{ width:lg ? '50%' :'320px'}}
                    />
                </Form.Item>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const onChangeSku = (data, index) => {
        const { code } = data;
        const newLocalVariations = [...localVariations];
        newLocalVariations[index] = { ...data, code };
        setLocalVariations(newLocalVariations);
    };

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                index,
                handleSave: onChangeSku,
            }),
        };
    });
    return (
        <div className="mt-4">
            <h3 className="mb-4">{t('product:title.variations_list')}</h3>
            {localVariations.length > 0 && (
                <Table
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    border={true}
                    className="list-dropshipping-table mt-3"
                    columns={tableColumns}
                    dataSource={localVariations}
                    pagination={false}
                    scroll={{ x: true }}
                    rowKey={record => record?.key}
                />
            )}
            {localVariations.length === 0 && <p className="text-center mt-4 mb-5">{t('product:message.not_info_sku')}</p>}
        </div>
    );
};

export default ProductVariations;