import lodash from 'lodash'

import { t } from '@System/i18n'

const ERRORS = [
    'not_exist',
    'already_exist',
    'required',
    'sku_already_exist',
    'order_already_exist',
    'duplicated',
    'invalid',
    'gte',
    'gt',
    'greater',
    'numeric',
    'empty',
    'exists',
    'user_merchant',
    'in',
    'finished_without_freight_bill',
    'finished_with_freight_bill',
    'status_invalid',
    'fail_when_auto_inspection',
    'receiver_country_invalid',
    'receiver_province_invalid',
    'receiver_district_invalid',
    'receiver_ward_invalid',
    'not_sold_by_merchant',
    'required_with',
    'date_format',
]

const DROPSHIPPING_ERRORS = [
    'warning_not_dropship',
    'warning_not_product_price',
    'warning_not_found_product_price_detail',
    'product_price_combo',
]

export function renderError(error) {
    const line = lodash.get(error, 'line', '')
    const content = lodash.get(error, 'errors', {})
    let messages = []
    Object.entries(content).forEach(entry => {
        let [key, errors] = entry
        if (DROPSHIPPING_ERRORS.includes(key)) {
            messages.push(t(`order:message.upload_file.${key}`))
        } else {
            ERRORS.forEach(errorKey => {
                if (errors.hasOwnProperty(errorKey)) {
                    if (errorKey === 'invalid' && key === 'order_no_freight_bill') {
                        messages.push(t(`order:message.upload_file.order_no_freight_bill`))
                    } else {
                        if (errorKey === 'duplicated') {
                            let message = t(`order:message.upload_file.${errorKey}`, {
                                attribute: t(`order:label.${key}`),
                                value: lodash.get(error, key, ''),
                            })
                            if (key === 'order_code') {
                                const merchant_code = lodash.get(error, 'merchant_code', '')
                                if (!lodash.isEmpty(merchant_code)) {
                                    message = t(`order:message.upload_file.${errorKey}_order_code_merchant`, {
                                        attribute: t(`order:label.${key}`),
                                        value: lodash.get(error, key, ''),
                                        merchant: merchant_code,
                                    })
                                }
                            }
                            messages.push(message)
                        } else if (errorKey === 'required_with') {
                            messages.push(
                                t(`order:message.upload_file.required_with`, {
                                    attribute: t(`order:label.${key}`),
                                    with: t(`order:label.${lodash.get(errors, `${errorKey}.values`, '')}`),
                                })
                            )
                        } else {
                            messages.push(
                                t(`order:message.upload_file.${errorKey}`, {
                                    attribute: t(`order:label.${key}`),
                                    value: lodash.get(errors, `${errorKey}.value`, ''),
                                })
                            )
                        }
                    }
                }
            })
        }
    })

    if (messages.length > 0) return t('order:message.upload_file.line', { line: line, content: messages.join(' ') })
    return t('order:message.upload_file.unknown_error')
}
