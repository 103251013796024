import { CaretDownOutlined } from '@ant-design/icons'
import { Avatar, Image, Modal, Table, Tooltip, Typography } from 'antd'
import { find, get, isEmpty } from 'lodash'
import map from 'lodash/map'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import DynamicTable from '@Modules/App/DynamicTable'
import EmptyTableData from '@Modules/App/Table/EmptyTableData'
import { auth } from '@Modules/Auth/services'
import CreateProductByImport from '@Modules/Product/components/CreateProductByImport'
import ModalStorageFee from '@Modules/Product/components/ModalStorageFee'
import { CHANNEL, CHANNEL_LIST } from '@Modules/Setting/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { classes, getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import CustomizedPagination from '../../../App/CustomizedPagination'
import ChangeStatusProduct from './ChangeStatusProduct'

const { Text, Paragraph } = Typography

export default function ProductList({ onSearch, filters, rowSelection, selectedRowKeys, data, loading }) {
    // const { data, isFetching, isError, error, refetch } = useListProductQuery(filters)
    const [pagination, setPagination] = useState({})
    const [products, setProducts] = useState([])
    const [statistic, setStatistic] = useState({})
    const [columnsActive, setColumnsActive] = useState([])

    const user = auth.user()

    useEffect(() => {
        setPagination(getVar(data, 'data.pagination', {}))
        setProducts(getVar(data, 'data.products', []))
        setStatistic(getVar(data, 'data.statistics', []))
    }, [data])

    const renderTitleChannel = (stores, marketPlaceCode) => {
        if (isEmpty(stores)) {
            return marketPlaceCode
        }

        return map(stores, (name, index) => {
            return index !== stores?.length - 1 ? name + ', ' : name
        })
    }

    const columns = useMemo(
        () => [
            {
                className: '_product-list-status',
                title: t('label.status'),
                dataIndex: ['sku', 'status'],
                key: 'status',
                width: '140px',
                render: (status, record) => {
                    const skuId = get(record, 'sku.product_id')
                    const productSystem = get(record, 'sku.merchant_id') === 0

                    return (
                        <ChangeStatusProduct
                            productSystem={productSystem}
                            skuId={skuId}
                            status={status}
                        />
                    )
                },
            },
            {
                className: '_product-list-product text-wrap',
                title: t('product:label.product'),
                dataIndex: ['sku', 'name'],
                key: 'product',
                width: '300px',
                disabled: true,
                render: (text, record) => {
                    const images = getVar(record, 'product.images', [])
                    return (
                        <div className="d-flex align-items-center">
                            <Avatar
                                shape="square"
                                src={images.length > 0 ? images[0] : ''}
                                className="mr-2"
                                size={36}
                                style={{ flexShrink: 0 }}
                            />
                            <div className="d-flex flex-wrap">
                                <span>
                                    <Link
                                        to={url.to('products.detail', {
                                            productId: record?.sku?.product_id,
                                            skuId: record?.sku?.id,
                                        })}
                                    >
                                        <strong
                                            style={{ fontWeight: 500 }}
                                            className="text-link"
                                        >
                                            {text}
                                        </strong>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    )
                },
            },
            {
                className: '_product-list-sku-code',
                title: t('product:label.sku_code'),
                dataIndex: ['sku', 'code'],
                key: 'sku_code',
                width: '220px',
                disabled: true,
                ellipsis: {
                    showTitle: false,
                },
                render: sku => (
                    <div>
                        <Tooltip
                            placement="topLeft"
                            title={
                                <Paragraph
                                    copyable={{
                                        text: sku,
                                    }}
                                    className="ant-typography-tooltip"
                                >
                                    {sku}
                                </Paragraph>
                            }
                        >
                            {sku}
                        </Tooltip>
                    </div>
                ),
            },
            {
                className: '_product-list-sales_channel',
                title: t('product:label.sales_channel'),
                dataIndex: ['sku', 'sell_channel'],
                key: 'sales_channel',
                width: '150px',
                render: data => {
                    return (
                        <div className="d-flex">
                            {map(data, (text, idx) => {
                                const marketPlaceCode = get(text, 'channel')
                                const channelInfo = find(CHANNEL_LIST, ['code', marketPlaceCode])
                                const img = getVar(channelInfo, 'img', '')
                                const stores = get(text, 'stores')

                                return (
                                    <Tooltip
                                        placement="top"
                                        title={renderTitleChannel(stores, marketPlaceCode)}
                                        key={idx}
                                    >
                                        <div
                                            key={idx}
                                            className="cursor-pointer mr-1"
                                        >
                                            {img && (
                                                <Image
                                                    src={img}
                                                    alt=""
                                                    preview={false}
                                                    className={classes('sales-channel-image', {
                                                        lazada: marketPlaceCode === CHANNEL?.LAZADA,
                                                    })}
                                                />
                                            )}
                                        </div>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    )
                },
            },
            {
                className: '_product-list-warehouse',
                title: t('product:label.warehouse'),
                dataIndex: ['warehouse_stocks'],
                key: 'warehouse',
                width: '200px',
                render: text => {
                    if (text.length > 0) {
                        return map(text, (item, index) => {
                            return (
                                <div
                                    className="warehouse_stocks"
                                    key={index}
                                >
                                    {getVar(item, 'name', '')}
                                </div>
                            )
                        })
                    } else {
                        return <div className="warehouse_stocks">{t('label.none_stock')}</div>
                    }
                },
            },
            {
                className: '_product-list-inventory',
                title: t('product:label.inventory'),
                dataIndex: ['warehouse_stocks'],
                key: 'inventory',
                width: '120px',
                render: text => {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks"
                                key={index}
                            >
                                {getVar(item, 'real_quantity', t('label.not_available'))}
                            </div>
                        )
                    })
                },
            },
            {
                className: '_product-list-waiting-out',
                title: t('product:label.waiting_out'),
                dataIndex: ['warehouse_stocks'],
                key: 'waiting_out',
                width: '120px',
                render: text => {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks"
                                key={index}
                            >
                                {getVar(item, 'packing_quantity', t('label.not_available'))}
                            </div>
                        )
                    })
                },
            },
            {
                className: '_product-list-waiting-in',
                title: t('product:label.waiting_in'),
                dataIndex: ['warehouse_stocks'],
                key: 'waiting_in',
                width: '120px',
                render: text => {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks"
                                key={index}
                            >
                                {getVar(item, 'purchasing_quantity', t('label.not_available'))}
                            </div>
                        )
                    })
                },
            },
            {
                className: '_product-list-availability',
                title: t('product:label.availability'),
                dataIndex: ['warehouse_stocks'],
                key: 'availability',
                width: '120px',
                render: (text, record) => {
                    return map(text, (item, index) => {
                        return (
                            <div
                                className="warehouse_stocks warehouse_stocks-available_inventory"
                                key={index}
                            >
                                {getVar(item, 'available_inventory', '0')}
                            </div>
                        )
                    })
                },
            },
            {
                className: '_product-list-waiting-in',
                title: t('product:label.total_price'),
                dataIndex: ['warehouse_stocks'],
                key: 'prices',
                width: '150px',
                render: (text, record) => {
                    const name = get(record, 'sku.name')
                    const sku_id = get(record, 'sku.id')

                    return map(text, (item, index) => (
                        <div
                            className="cursor-pointer font-weight-500 warehouse_stocks warehouse_stocks-total_prices"
                            onClick={() => handleOpenModal(sku_id, name, item.name, item.id)}
                            key={index}
                        >
                            {item
                                ? formatCurrency(item?.total_storage_fee || 0, !isEmpty(user?.currency) ? user?.currency : null)
                                : '---'}
                        </div>
                    ))
                },
            },
        ],
        [user?.currency]
    )

    useEffect(() => {
        setColumnsActive(map(columns, 'key'))
    }, [columns])

    const handleOpenModal = (sku_id, name, warehouse_name, warehouse_id) => {
        Modal.confirm({
            title: `${t('product:label.storage_fee')} ${name} - ${warehouse_name}`,
            icon: false,
            closable: true,
            width: 900,
            content: (
                <ModalStorageFee
                    sku_id={sku_id}
                    currency={user.currency}
                    warehouse_id={warehouse_id}
                />
            ),
            okButtonProps: { style: { display: 'none' } },
            cancelText: t('btn.close'),
        })
    }

    const onChangeActiveKeys = keys => {
        setColumnsActive(keys)
    }

    return (
        <>
            <DynamicTable
                onChangeActiveKeys={onChangeActiveKeys}
                initColumns={columns}
                dataSource={products}
                rowKey={record => record.sku.id}
                className="product-list-table pb-3"
                loading={loading}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                scroll={{ x: 768 }}
                locale={{
                    emptyText: (
                        <EmptyTableData
                            loading={loading}
                            message={'no_data_order'}
                            action={
                                <CreateProductByImport>
                                    <strong className="cursor-pointer pagination-customize">
                                        {t('btn.create_product')}
                                        <CaretDownOutlined className="ml-1" />
                                    </strong>
                                </CreateProductByImport>
                            }
                        />
                    ),
                }}
                /*summary={pageData => {
                    return (
                        !isEmpty(pageData) && (
                            <>
                                <Table.Summary.Row className="text-color-tenant font-weight-500">
                                    {columnsActive.includes('status') && <Table.Summary.Cell />}
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    {columnsActive.includes('sales_channel') && <Table.Summary.Cell />}
                                    {columnsActive.includes('warehouse') && (
                                        <Table.Summary.Cell>{t('product:label.sum')}</Table.Summary.Cell>
                                    )}

                                    {columnsActive.includes('inventory') && (
                                        <Table.Summary.Cell>
                                            <Text className="text-color-tenant font-weight-500">{statistic?.real_quantity}</Text>
                                        </Table.Summary.Cell>
                                    )}
                                    {columnsActive.includes('waiting_out') && (
                                        <Table.Summary.Cell>
                                            <Text className="text-color-tenant font-weight-500">
                                                {statistic?.packing_quantity}
                                            </Text>
                                        </Table.Summary.Cell>
                                    )}
                                    {columnsActive.includes('waiting_in') && (
                                        <Table.Summary.Cell>
                                            <Text className="text-color-tenant font-weight-500">
                                                {statistic?.purchasing_quantity}
                                            </Text>
                                        </Table.Summary.Cell>
                                    )}
                                    {columnsActive.includes('availability') && (
                                        <Table.Summary.Cell>
                                            <Text className="text-color-tenant font-weight-500">
                                                {statistic?.available_inventory}
                                            </Text>
                                        </Table.Summary.Cell>
                                    )}
                                    {columnsActive.includes('prices') && (
                                        <Table.Summary.Cell>
                                            <Text className="text-color-tenant font-weight-500">
                                                {formatCurrency(
                                                    statistic?.total_storage_fee || 0,
                                                    !isEmpty(user?.currency) ? user?.currency : null
                                                )}
                                            </Text>
                                        </Table.Summary.Cell>
                                    )}
                                </Table.Summary.Row>
                            </>
                        )
                    )
                }}*/
            />
            {/* <Table
                className="product-list-table pb-3"
                dataSource={products}
                rowKey={record => record.sku.id}
                columns={columns}
                pagination={false}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                loading={loading}
                scroll={{ x: 768 }}
                locale={{
                    emptyText: (
                        <EmptyTableData
                            loading={loading}
                            message={'no_data_order'}
                            action={
                                <CreateProductByImport>
                                    <strong className="cursor-pointer pagination-customize">
                                        {t('btn.create_product')}
                                        <CaretDownOutlined className="ml-1" />
                                    </strong>
                                </CreateProductByImport>
                            }
                        />
                    ),
                }}
                summary={pageData => {
                    return (
                        !isEmpty(pageData) && (
                            <>
                                <Table.Summary.Row className="text-color-tenant font-weight-500">
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell>{t('product:label.sum')}</Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text className="text-color-tenant font-weight-500">{statistic?.real_quantity}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text className="text-color-tenant font-weight-500">{statistic?.packing_quantity}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text className="text-color-tenant font-weight-500">
                                            {statistic?.purchasing_quantity}
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text className="text-color-tenant font-weight-500">
                                            {statistic?.available_inventory}
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text className="text-color-tenant font-weight-500">
                                            {formatCurrency(
                                                statistic?.total_storage_fee || 0,
                                                !isEmpty(user?.currency) ? user?.currency : null
                                            )}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )
                    )
                }}
            /> */}
            <CustomizedPagination
                pagination={pagination}
                addFilter={onSearch}
                filters={filters}
            />
        </>
    )
}
