import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import { VIETNAM_CODE } from '@Modules/Location/services/constants'
import { ILocation, SelectLocationKey } from '@Modules/Order/Interfaces'

import { t } from '@System/i18n'

import SelectDistrict from './SelectDistrict'
import SelectProvince from './SelectProvince'
import SelectWard from './SelectWard'

interface ILocationProps {
    validatedRules: any
    form: any
    initLocations: any
}

const Location: React.FC<ILocationProps> = ({ validatedRules, form, initLocations }) => {
    const [locations, setLocations] = useState<ILocation>({
        province: {
            code: '',
            id: 0,
        },
        district: {
            code: '',
            id: 0,
        },
        ward: {
            code: '',
            id: 0,
        },
        receiverPostalCode: '',
    })

    useEffect(() => {
        setLocations(prev => {
            prev.province = {
                code: initLocations.provinceCode,
                id: initLocations.provinceId,
            }
            prev.district = {
                code: initLocations.districtCode,
                id: initLocations.districtId,
            }
            prev.ward = {
                code: initLocations.wardCode,
                id: initLocations.wardId,
            }

            return { ...prev }
        })

        form.setFields([{ name: 'receiver_postal_code', value: initLocations.receiverPostalCode }])
    }, [
        initLocations.districtCode,
        initLocations.districtId,
        initLocations.provinceCode,
        initLocations.provinceId,
        initLocations.wardCode,
        initLocations.wardId,
        initLocations.receiverPostalCode,
    ])

    const onChangeSelect = (value: any, code: string, key: SelectLocationKey) => {
        setLocations(prev => {
            prev[key].code = code
            prev[key].id = value

            if (key === 'province') {
                prev.district.id = 0
                prev.district.code = ''

                form.setFields([
                    { name: 'receiver_district_id', value: null },
                    { name: 'receiver_ward_id', value: null },
                ])
            }

            if (key === 'province' || key === 'district') {
                prev.ward.id = 0
                prev.ward.code = ''
            }

            return { ...prev }
        })

        if (key === 'district') {
            form.setFields([{ name: 'receiver_ward_id', value: null }])
        }
    }

    return (
        <>
            <Form.Item
                className="mb-3"
                name="receiver_postal_code"
                label={t(`label.postcode`)}
                initialValue={initLocations.receiver_postal_code}
            >
                <Input
                    placeholder={t('placeholder.postcode')}
                />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="receiver_province_id"
                label={t(`warehouse:label.${initLocations.countryCode === VIETNAM_CODE ? 'province_id' : 'other_province_id'}`)}
                rules={validatedRules['receiver_province_id']}
            >
                <SelectProvince
                    countryCode={initLocations.countryCode}
                    onChange={onChangeSelect}
                    value={locations.province.id}
                    form={form}
                />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="receiver_district_id"
                label={t(`warehouse:label.${initLocations.countryCode === VIETNAM_CODE ? 'district_id' : 'other_district_id'}`)}
                rules={validatedRules['receiver_district_id']}
            >
                <SelectDistrict
                    countryCode={initLocations.countryCode}
                    onChange={onChangeSelect}
                    value={locations.district.id}
                    provinceCode={locations.province.code}
                />
            </Form.Item>
            <Form.Item
                className="mb-3"
                name="receiver_ward_id"
                label={t(`warehouse:label.${initLocations.countryCode === VIETNAM_CODE ? 'ward_id' : 'other_ward_id'}`)}
                rules={validatedRules['receiver_ward_id']}
            >
                <SelectWard
                    countryCode={initLocations.countryCode}
                    onChange={onChangeSelect}
                    value={locations.ward.id}
                    districtCode={locations.district.code}
                />
            </Form.Item>
        </>
    )
}

export default Location
