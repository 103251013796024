import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin, Tabs } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useDetailOrderQuery from '@Modules/Order/Hooks/useOrderDetailQuery';
import CancelOrder from '@Modules/Order/components/CancelOrder';
import CustomerInfo from '@Modules/Order/components/Detail/CustomerInfo';
import FinanceDetail from '@Modules/Order/components/Detail/FinanceDetail';
import OrderInfo from '@Modules/Order/components/Detail/OrderInfo';
import ProductList from '@Modules/Order/components/Detail/ProductList';
import { EDIT_STATUS, ORDER_EVENT } from '@Modules/Order/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const { TabPane } = Tabs;

function OrderDetail(props) {
    const { match } = props;
    const orderId = match.params.id;
    const [order, setOrder] = useState({});
    const { error, isError, isLoading, data, refetch } = useDetailOrderQuery(orderId);

    const [orderStatus, setOrderStatus] = useState('');

    useEffect(() => {
        setNavigator(t('title.detail_order'), [
            {
                name: t('breadcrumb.list-orders'),
                route: 'orders.list',
            },
            {
                name: t('title.detail_order'),
            },
        ]);
        setDocumentTitle(t('title.detail_order'));
        return events.listen(ORDER_EVENT.RELOAD_ORDER_DETAIL, () => refetch());
    }, []);

    useEffect(() => {
        setOrder(getVar(data, 'data', {}));
        setOrderStatus(get(data, 'data.order.status'));
    }, [data]);

    useEffect(() => {
        setDocumentTitle(t('title_page.order_detail', { order_code: getVar(order, 'order.code', '') }));
    }, [order]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content _order-detail-content">
            <Spin spinning={isLoading}>
                <div className="d-flex justify-content-between">
                    <h3>
                        <Link to={url.to('orders.list')} className="_order-list-back">
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                    </h3>
                    <div className="d-flex align-items-center">
                        <CancelOrder orderInfo={order} />
                        {get(order, 'order.marketplace_code') === 'MANUAL' && EDIT_STATUS.includes(orderStatus) && (
                            <Link to={url.to('orders.edit', { id: orderId })}>
                                <Button type="primary" className="ml-2" icon={<EditOutlined />}>
                                    {t('btn.edit_order')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="bg-light-gray mt-3 _order-detail-body">
                    <Row gutter={20}>
                        <Col xs={{ span: 24 }} lg={{ span: 17 }}>
                            <div className="bg-white rounded-12 pt-3 pr-4 pb-4 pl-4">
                                <OrderInfo orderInfo={order} className="_order-detail-info" />
                            </div>

                            <Tabs
                                className="heading-fz-14 customize-order-ant-tabs mt-3 _order-detail-tab"
                                defaultActiveKey={1}
                                type="card"
                            >
                                <TabPane
                                    tab={t('order:title.order_info')}
                                    key="1"
                                    className="_order-detail-tab-product-info"
                                >
                                    <ProductList orderInfo={order} />
                                </TabPane>
                            </Tabs>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 7 }}>
                            <div className="bg-white rounded-12 pt-3 pr-4 pb-4 pl-4 _order-detail-customer-info">
                                <CustomerInfo orderInfo={order} />
                            </div>
                            <div className="bg-white rounded-12 p-4 mt-4 _order-detail-finance-info">
                                <FinanceDetail orderInfo={order} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </div>
    );
}
export default OrderDetail;
