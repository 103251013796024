import { Form, Select, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import ServiceLocation from '@Modules/Location/services/ServiceLocation'
import ServiceCountrySellerInfo from '@Modules/Location/services/ServiceSellerCountryInfo'
import { VIETNAM_CODE } from '@Modules/Location/services/constants'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const { Option } = Select
const ProvincesServ = new ServiceLocation()
const DistrictServ = new ServiceLocation()
const WardServ = new ServiceLocation()

export default function Location({ validatedRules, form }) {
    const [countryCode, setCountryCode] = useState()
    let [provinces, setProvinces] = useState([])
    let [loadingProvince, setLoadingProvince] = useState(false)

    let [districts, setDistricts] = useState([])
    let [loadingDistrict, setLoadingDistrict] = useState(false)

    let [wards, setWards] = useState([])
    let [loadingWard, setLoadingWard] = useState(false)

    const [provinceId, setProvinceId] = useState(null)
    const [districtId, setDistrictId] = useState(null)

    useEffect(() => {
        fetchSellerCountry()
    }, [])

    useEffect(() => {
        if (countryCode) {
            fetchProvince()
        }
    }, [countryCode])

    /**
     * Tải quận huyện nếu tỉnh thành thay đổi
     */
    useEffect(() => {
        if (provinceId) fetchDistrict()
        else setDistricts([])
    }, [provinceId])

    /**
     * Tải lại phường xã nếu quận huyện thay đổi
     */
    useEffect(() => {
        if (districtId) fetchWard()
        else setWards([])
    }, [districtId])

    function fetchSellerCountry() {
        ServiceCountrySellerInfo.list().then(res => {
            setCountryCode(getVar(res, 'code'))
        })
    }

    function fetchProvince() {
        setLoadingProvince(true)
        ProvincesServ.list('PROVINCE', countryCode)
            .then(res => {
                setProvinces(res)
            })
            .finally(() => {
                setLoadingProvince(false)
            })
    }

    function fetchDistrict() {
        setLoadingDistrict(true)
        let province = provinces.find(item => item.id === provinceId)
        DistrictServ.list('DISTRICT', province.code)
            .then(res => {
                setDistricts(res)
            })
            .finally(() => {
                setLoadingDistrict(false)
            })
    }

    function fetchWard() {
        setLoadingWard(true)
        let district = districts.find(item => item.id === districtId)
        WardServ.list('WARD', district.code)
            .then(res => {
                setWards(res)
            })
            .finally(() => {
                setLoadingWard(false)
            })
    }

    function selectProvince(value) {
        setProvinceId(value)
        setDistrictId(null)
        form.setFields([
            { name: 'receiver_district_id', value: null },
            { name: 'receiver_ward_id', value: null },
        ])
    }

    function selectDistrict(value) {
        setDistrictId(value)
        form.setFields([{ name: 'receiver_ward_id', value: null }])
    }

    return (
        <>
            <Form.Item
                className="mb-3"
                name="receiver_postal_code"
                label={t(`label.postcode`)}
            >
                <Input placeholder={t("placeholder.postcode")}/>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="receiver_province_id"
                label={t(`warehouse:label.${countryCode === VIETNAM_CODE ? 'province_id' : 'other_province_id'}`)}
                rules={validatedRules['receiver_province_id']}
            >
                <Select
                    className="_location-receiver-province-id"
                    loading={loadingProvince}
                    name="receiver_province_id"
                    placeholder={t(`warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'province_id' : 'other_province_id'}`)}
                    onChange={selectProvince}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {provinces.length > 0 &&
                        provinces.map(opt => {
                            return (
                                <Option
                                    key={opt.id}
                                    value={opt.id}
                                >
                                    {opt.label}
                                </Option>
                            )
                        })}
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="receiver_district_id"
                label={t(`warehouse:label.${countryCode === VIETNAM_CODE ? 'district_id' : 'other_district_id'}`)}
                rules={validatedRules['receiver_district_id']}
            >
                <Select
                    className="_location-receiver-district-id"
                    loading={loadingDistrict}
                    name="receiver_district_id"
                    placeholder={t(`warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'district_id' : 'other_district_id'}`)}
                    onChange={selectDistrict}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {districts.length > 0 &&
                        districts.map(opt => {
                            return (
                                <Option
                                    key={opt.id}
                                    value={opt.id}
                                >
                                    {opt.label}
                                </Option>
                            )
                        })}
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="receiver_ward_id"
                label={t(`warehouse:label.${countryCode === VIETNAM_CODE ? 'ward_id' : 'other_ward_id'}`)}
                rules={validatedRules['receiver_ward_id']}
            >
                <Select
                    loading={loadingWard}
                    name="receiver_ward_id"
                    className="_location-receiver-ward-id"
                    placeholder={t(`warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'ward_id' : 'other_ward_id'}`)}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {wards.length > 0 &&
                        wards.map(opt => {
                            return (
                                <Option
                                    key={opt.id}
                                    value={opt.id}
                                >
                                    {opt.label}
                                </Option>
                            )
                        })}
                </Select>
            </Form.Item>
        </>
    )
}
