import { useQuery } from 'react-query';

import api from '@Modules/Setting/services/api';

import processResponseError from '@System/api/processResponseError';

const useAccountPurchasingListQuery = params => {
    return useQuery(['account-purchase-list', params], () => api.getPurchaseAccounts(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useAccountPurchasingListQuery;
