import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import api from '../services/api';

const useListPurchasingOrderQuery = (id, params) => {
    return useQuery(['purchasing-order', id], () => api.getPurchasingOrders({ purchasing_account_id: id, ...params }), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListPurchasingOrderQuery;
