import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import { t } from './../../../system/i18n';
import { notifyErrorFromServer } from './../../../system/support/helpers';
import notification from '@System/notification';

const createUseMutation =
    ({ namespace = '', mutation, settings }) =>
    payload => {
        if (!payload) {
            payload = {};
        }

        if (!has(payload, 'notifyWhenSuccess')) {
            payload.notifyWhenSuccess = true;
        }

        if (!has(payload, 'notifyWhenError')) {
            payload.notifyWhenError = true;
        }

        const formattedConfig = omit(
            {
                ...settings,
                ...payload,
                onSuccess: ({ data }) => {
                    if (isFunction(payload?.onSuccess)) {
                        payload.onSuccess(data);
                    }

                    if (payload.notifyWhenSuccess) {
                        notification.success(t(`${namespace}.success`));
                    }
                },
                onError: error => {
                    if (isFunction(payload?.onError)) {
                        payload.onError(error);
                    }

                    if (payload.notifyWhenError) {
                        notifyErrorFromServer(error, t(`${namespace}.failed`), `${namespace}.errors`);
                    }
                },
            },
            ['notifyWhenSuccess', 'notifyWhenError']
        );

        return useMutation(mutation, formattedConfig);
    };

export default createUseMutation;
