import { CheckOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row } from 'antd';
import map from 'lodash/map';
import React from 'react';

import { auth } from '@Modules/Auth/services';
import { ONBOARD_STEP } from '@Modules/Dashboard/services/constants';

import { t, trans } from '@System/i18n';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';

function Onboard({ data }) {
    const user = auth.user();
    const info = getVar(data, 'data', {});
    let keyActive = '';
    map(ONBOARD_STEP, (item, index) => {
        if (!keyActive && info[item.key] === 0) {
            keyActive = item.key;
        }
    });
    return (
        <div className="pl-4 pr-4">
            <div className="_onboard-content-header">
                <h3 className="text-fz-18">
                    {trans('title.hello', { name: <strong style={{ fontWeight: 600, fontSize: 21 }}>{user.name}</strong> })}
                </h3>
                <p>{t('dashboard:onboard.description.main')}</p>
            </div>
            <Row gutter={20} className="mt-4 d-flex justify-content-between align-items-stretch _onboard-step">
                {ONBOARD_STEP.map((item, index) => {
                    return (
                        <Col xs={{ span: 24 }} lg={{ span: 8 }} className={`mb-4 mb-lg-0 _onboard-step-col`} key={index}>
                            <div
                                className={`dashboard-step text-center bg-white min-h-100 p-4 ${
                                    item.key === keyActive ? 'dashboard-active' : 'dashboard-disable'
                                }`}
                            >
                                <Image className={`pt-4 _onboard-step-image`} width={200} height={150} src={item.img} preview={false}/>
                                <div className={`text-center pt-4 pb-2 _onboard-step-description`}>
                                    <h3>{t(`dashboard:onboard.title.${item.name}`)}</h3>
                                    <p>{t(`dashboard:onboard.description.${item.name}`)}</p>
                                </div>
                                <div className="d-flex justify-content-center py-2 _onboard-step-action">
                                    {item.key === keyActive ? (
                                        <>
                                            <Button className="ml-2 _onboard-step-tutorial text-link" type="link">
                                                <Link isNewTab={true} href={item.tutorial_link}>
                                                    {t(`dashboard:onboard.title.tutorial`)}
                                                </Link>
                                            </Button>
                                            <Button className="ml-2 _onboard-step-redirect btn-primary" type="primary">
                                                <Link to={item.link}>
                                                    <CheckOutlined /> {t(`dashboard:onboard.btn.${item.name}`)}
                                                </Link>
                                            </Button>
                                        </>
                                    ) : info[item.key] > 0 ? (
                                        <Button
                                            type="text"
                                            style={{ color: '#09B2AA' }}
                                            className="ml-2 _onboard-step-completed"
                                            icon={<CheckOutlined />}
                                        >
                                            {t(`dashboard:onboard.btn.completed`)}
                                        </Button>
                                    ) : (
                                        <Button className="ml-2 _onboard-step-unlock" type="text" icon={<UnlockOutlined />}>
                                            {t(`dashboard:onboard.btn.unlock`)}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>

            {/*<Row gutter={20} className="mt-4 bg-white p-4">*/}
            {/*    <Col>*/}
            {/*        <Image width={200} src={DashboardTutorialImg} />*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <div className="align-self-center mt-4 mt-lg-0">*/}
            {/*            <h3>Hướng dẫn sử dụng</h3>*/}
            {/*            <a>Hướng dẫn sử dụng tính năng đối soát đơn hoàn</a>*/}
            {/*            <p>*/}
            {/*                Kinh doanh online là một ý tưởng tuyệt vời dành cho những người đam mê kiếm tiền hay đơn giản là*/}
            {/*                có nhiều thời gian rảnh rỗi...*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    );
}
export default Onboard;
