import { t } from '@System/i18n'
import { Select, Spin } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import api from '../../services/api'

const { Option } = Select

const SelectBatchOfGoods = ({ skuId, onChange, value, selectedList }) => {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])

    useEffect(() => {
        fetchBatchData()
    }, [value])

    const fetchBatchData = () => {
        setFetching(true)
        api.getBatchOfGoods(skuId)
            .then(result => {
                setOptions(get(result, 'data.batch_of_goods', []))
            })
            .catch(err => {})
            .finally(() => {
                setFetching(false)
            })
    }

    const onDropdownVisibleChange = () => {
        fetchBatchData()
    }
    const onChangeBatch = (value, option) => {
        onChange(value, option)
    }

    return (
        <Select
            style={{ minWidth: 180 }}
            loading={fetching}
            value={value ? value : undefined}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            // onDropdownVisibleChange={onDropdownVisibleChange}
            onFocus={onDropdownVisibleChange}
            showSearch
            optionFilterProp="children"
            onChange={onChangeBatch}
            placeholder={t("product:placeholder.select_batch")}
            filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
        >
            {options.map(item => (
                <Option
                    key={item.id}
                    value={item.id}
                    sku_child_id={item.sku_child_id}
                    disabled={selectedList.includes(item.id)}
                >
                    {item.code}
                </Option>
            ))}
        </Select>
    )
}

export default SelectBatchOfGoods
