import { TagOutlined, InboxOutlined, BarChartOutlined, SettingOutlined, DashboardOutlined } from '@ant-design/icons'
import React from 'react'

import { t } from '@System/i18n'

import { ALLOW_MODULES } from './constants'

export const ExpandContext = React.createContext(false)

const menu = [
    {
        name: t('menu.dashboard'),
        icon: <DashboardOutlined />,
        url: 'home',
        allowModules: [ALLOW_MODULES.DASHBOARD],
        permissions: ['tes']
    },
    {
        name: t('menu.warehouse_manager'),
        icon: <InboxOutlined />,
        allowModules: [
            ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK,
            ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP,
            ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS,
        ],
        submenu: [
            {
                name: t('common:menu.stock'),
                url: 'products.list',
                allowModules: [ALLOW_MODULES.WAREHOUSE_MANAGE_WAREHOUSE_STOCK],
            },
            {
                name: t('common:menu.dropshipping'),
                url: 'dropshipping.list',
                allowModules: [ALLOW_MODULES.WAREHOUSE_MANAGE_PRODUCT_DROPSHIP],
            },
            {
                name: t('menu.import_goods'),
                url: 'import-package.list',
                allowModules: [ALLOW_MODULES.WAREHOUSE_MANAGE_IMPORT_GOODS],
            },
        ],
    },
    {
        name: t('common:menu.orders'),
        icon: <TagOutlined />,
        allowModules: [ALLOW_MODULES.ORDER_MANAGE_ORDER],
        submenu: [
            {
                name: t('common:menu.orders'),
                url: 'orders.list',
                allowModules: [ALLOW_MODULES.ORDER_MANAGE_ORDER],
            },
        ],
    },
    {
        name: t('common:menu.finances'),
        icon: <BarChartOutlined />,
        allowModules: [ALLOW_MODULES.FINANCE_FINANCE_ORDER, ALLOW_MODULES.FINANCE_HISTORY_TRANSACTION],
        submenu: [
            {
                name: t('common:menu.finance_order'),
                url: 'finances.statistic',
                allowModules: [ALLOW_MODULES.FINANCE_FINANCE_ORDER],
            },
            {
                name: t('common:menu.transaction_history'),
                url: 'finances.transaction',
                allowModules: [ALLOW_MODULES.FINANCE_HISTORY_TRANSACTION],
            },
        ],
    },
    {
        name: t('common:menu.setting'),
        icon: <SettingOutlined />,
        allowModules: [ALLOW_MODULES.SETUP_ACCOUNT, ALLOW_MODULES.SETUP_STORE],
        submenu: [
            {
                name: t('common:menu.account'),
                url: 'settings.account',
                allowModules: [ALLOW_MODULES.SETUP_ACCOUNT],
            },
            {
                name: t('common:menu.sales_channel'),
                url: 'settings.sales_channel',
                allowModules: [ALLOW_MODULES.SETUP_STORE],
            },
        ],
    },
]
export default menu
