import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Table, Tooltip, Typography } from 'antd';
import { filter, find, get, isEmpty, map, sumBy, uniq } from 'lodash';
import React, { memo, useEffect, useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import useListServiceByTypeQuery from '@Modules/Services/Hooks/useListServiceByTypeQuery';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

const { Text } = Typography;

const ListServicePrice = ({ type, setServiceList, serviceSelected, serviceListRequired, setServiceListRequired }) => {
    const [dataSource, setDataSource] = useState([]);
    const [currency, setCurrency] = useState({});
    const [checkedList, setCheckedList] = useState([]);
    const [checkedItem, setCheckedItem] = useState([]);
    const { isFetching, data } = useListServiceByTypeQuery(type);

    useEffect(() => {
        const services = getVar(data, 'data.services', []);
        setDataSource(filter(services, item => !isEmpty(item.servicePrices)));
        setCurrency(get(data, 'data.currency'));
    }, [data]);

    useEffect(() => {
        const newList = filter(dataSource, item => item.service.is_required === true);
        const listDisable = map(newList, item => item.service.id);

        setCheckedItem(newList);
        setCheckedList(listDisable);
        setServiceList([...serviceSelected, ...listDisable]);
        setServiceListRequired([...serviceListRequired, ...listDisable]);
    }, [dataSource, setServiceList, setServiceListRequired]);

    let columns = [
        {
            className: '_dropshipping-list-service-name text-wrap',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            width: '50%',
            render: (text, record) => (text ? text : ''),
        },
        {
            className: '_dropshipping-list-estimated_costs text-left',
            title: (
                <p className="flex align-items-center">
                    {t('product:label.estimated_costs')}
                    <Tooltip title={t('message.cost_depends_on_size_service_used')}>
                        <QuestionCircleOutlined className="ml-1 cursor-pointer" />
                    </Tooltip>
                </p>
            ),
            dataIndex: 'servicePrices',
            key: 'prices',
            width: '50%',
            render: (text, record) => {
                const defaultPrice = find(text, ['is_default', true]);
                return <p>{defaultPrice ? (!isEmpty(currency) ? formatCurrency(defaultPrice.price, currency) : '') : '---'}</p>;
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys: checkedList,

        onChange: (selectedRowKeys, selectedRows) => {

            const serviceIds = map(dataSource, 'service.id');
            const serviceRequiredIds = map(filter(dataSource, 'service.is_required'), 'service.id');

            const otherServiceId = filter(serviceSelected, item => !serviceIds.includes(item));
            setCheckedList(uniq([...selectedRowKeys, ...serviceRequiredIds]));
            setServiceList(uniq([...otherServiceId, ...selectedRowKeys, ...serviceRequiredIds]));
        },

        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required');
            const id = get(record, 'service.id');

            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked={disabledRow} disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record, id)} />
                    )}
                </>
            );
        },
    };

    const handleChangeCheckbox = (checked, selectedRows, id) => {
        if (checked) {
            const newListId = serviceSelected.filter(item => item !== id);
            const newListItem = checkedItem.filter(item => item.service.id !== id);

            setCheckedItem(newListItem);
            setServiceList(newListId);
            setCheckedList(newListId);
        } else {
            setCheckedItem([selectedRows, ...checkedItem]);
            setCheckedList([...checkedList, id]);
            setServiceList([...serviceSelected, id]);
        }
    };

    return (
        <>
            <Table
                className="product-list-edit-table pb-3"
                dataSource={dataSource}
                rowKey={record => record?.service?.id}
                columns={columns}
                loading={isFetching}
                pagination={false}
                rowSelection={{
                    ...rowSelection,
                }}
                summary={pageData => {
                    const serviceCheckedIds = filter(pageData, data => checkedList.includes(data.service.id));
                    const getTrueValue = serviceCheckedIds.map(val => val.servicePrices.filter(i => i.is_default === true)[0]);


                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">{t('label.estimated_sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">
                                <Text>{!isEmpty(currency) ? formatCurrency(sumBy(getTrueValue, 'price'), currency) : '---'}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className="_dropshipping-list-empty" /> }}
            />
        </>
    );
};

export default memo(ListServicePrice);
