import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'

import useDebounce from '@Modules/App/Hooks/useDebounce'

import { t } from '@System/i18n'

import ModalSkus from './ModalSkus'

interface IAddSkusProps {
    setSkus: React.Dispatch<React.SetStateAction<string[]>>
    skus: any[]
}

const AddSkus: React.FC<IAddSkusProps> = ({ skus, setSkus }) => {
    const [visible, setVisible] = useState(false)

    const handleShowModal = () => {
        setVisible(true)
    }

    const handleHideModal = () => {
        setVisible(false)
    }

    return (
        <div>
            <Button
                type="primary"
                onClick={handleShowModal}
                className="bg-dark border-dark"
            >
                <PlusOutlined /> {t('product:btn.add_sku')}
            </Button>
            <ModalSkus
                skus={skus}
                setSkus={setSkus}
                visible={visible}
                handleHideModal={handleHideModal}
            />
        </div>
    )
}

export default AddSkus
