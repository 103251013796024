import { PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Modal, Button, Select, Form } from 'antd';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useState } from 'react';

import useToggle from '@Modules/App/Hooks/useToggle';
import UploadFile from '@Modules/App/Upload/UploadFileV2';
import { renderError } from '@Modules/Order/components/UploadFile/errors';
import api from '@Modules/Order/services/api';
import { ORDER_EVENT, SAMPLE_IMPORT_CREATE_ORDER_FILE } from '@Modules/Order/services/constants';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';
import { map } from 'lodash';

const { Option } = Select;

export default function ImportCreateOrders({ btnType = 'primary' }) {
    const [file, setFile] = useState();
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, { setLeft, setRight: showModal }] = useToggle(false);
    const [messages, setMessages] = useState([]);
    const [warningOrders, setWarningOrders] = useState([]);
    const [validOrders, setValidOrders] = useState([]);
    const [confirmValue, setConfirmValue] = useState('');
    const [sampleFileInfo, setSampleFileInfo] = useState(find(SAMPLE_IMPORT_CREATE_ORDER_FILE, ['key', 'default']));
    const [channel, setChannel] = useState('default');
    const [warehouseId, setWarehouseId] = useState();
    const [errors, setErrors] = useState({});
    const [hideAllButton, setHideAllButton] = useState(false)


    function handleCancel() {
        setMessages([]);
        setWarningOrders([]);
        setValidOrders([]);
        setLeft();
        setFile(undefined);
        setFileList([]);
        setChannel('default');
        setSampleFileInfo(find(SAMPLE_IMPORT_CREATE_ORDER_FILE, ['key', 'default']));
    }

    const handleUpload = useCallback(file => {
        setMessages([]);
        setWarningOrders([]);
        setValidOrders([]);
        setFile(file);
        setErrors(prevState => {
            return { ...prevState, file: undefined };
        });
    }, []);

    function handleOk() {
        setMessages([]);
        setWarningOrders([]);
        setValidOrders([]);
        const errors = {};
        if (!warehouseId) {
            errors.warehouse_id = t('order:message.upload_file.required', { attribute: t(`order:label.warehouse_id`) });
        }
        if (!channel) {
            errors.channel = t('order:message.upload_file.required', { attribute: t(`order:label.channel`) });
        }
        if (!file) {
            errors.file = t('order:message.upload_file.required', { attribute: t('order:label.file_create_order') });
        }

        if (isEmpty(errors)) {
            const filename = get(file, 'name', '');
            let formData = new FormData();
            formData.append('file', file);
            formData.append('chanel', channel);
            formData.append('warehouse_id', warehouseId);
            setLoading(true);
            api.createOrdersByUploadFile(formData)
                .then(res => {
                    const messages = uniqWith(get(res, 'data.result.errors', []), isEqual);
                    const warningOrders = get(res, 'data.result.warning_orders', []);
                    setValidOrders(get(res, 'data.result.valid_orders', []));
                    const hasInvalid = findIndex(messages, ['errors', 'invalid']);
                    if (hasInvalid === -1) {
                        setMessages(messages);
                        setWarningOrders(warningOrders);
                        if (messages.length === 0 && warningOrders.length === 0) {
                            handleConfirm('both');
                        }
                    } else {
                        notification.error(
                            t('order:message.upload_file.fail', {
                                filename,
                            })
                        );
                    }
                })
                .catch(err => {
                    notification.error(t('common:message.server_error'));
                })
                .finally(() => setLoading(false));
        } else {
            setErrors(errors);
        }
    }

    function handleChangeItem(name, value) {
        if (name === 'channel') {
            const channelInfo = find(SAMPLE_IMPORT_CREATE_ORDER_FILE, ['key', value]);
            setSampleFileInfo(channelInfo);
            setChannel(value);
        }
        if (name === 'warehouse_id') setWarehouseId(value);
        if (value) {
            setErrors(prevState => {
                return { ...prevState, [name]: undefined };
            });
        } else {
            setErrors(prevState => {
                return { ...prevState, [name]: t('order:message.upload_file.required', { attribute: t(`order:label.${name}`) }) };
            });
        }
    }

    function renderWarningOrder(data) {
        const messages = [];
        const warnings = getVar(data, 'warnings', []);
        const sku_code = getVar(data, 'sku_code', '');
        const line = getVar(data, 'line', 0);
        warnings.map(item => {
            return messages.push(t(`order:message.upload_file.${item.message}`, { sku_code }));
        });
        return t('order:message.upload_file.line', { line: line, content: messages.join(' ') });
    }

    function handleConfirm(bash) {
        const filename = get(file, 'name', '');
        setConfirmValue(bash);
        setLoading(true);
        api.importBashOrder({ bash })
            .then(res => {
                const messages = uniqWith(get(res, 'data.result.errors', []), isEqual);
                if (messages.length === 0) {
                    events.dispatch(ORDER_EVENT.RELOAD_ORDER_LIST, {});
                    notification.success(
                        t('order:message.upload_file.success', {
                            filename,
                        })
                    );
                    handleCancel();
                } else {
                    notification.error(t('common:message.server_error'));
                }
            })
            .catch((error) => {
                const code = get(error, "response.data.code")
                const listOrdersError = map(messages, 'order_code').join('')


                if(code === "INPUT_INVALID"){
                    notification.error(t('order:message.error_import_bash_order', {order: listOrdersError}));
                    setHideAllButton(true)
                }else{
                    notification.error(t('common:message.server_error'));
                }

               
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Button type={btnType} onClick={showModal} icon={<PlusCircleOutlined />}>
                {t('btn.create_order_by_import')}
            </Button>
            {visible && (
                <Modal
                    title={t('order:title.import_order')}
                    visible={visible}
                    onCancel={handleCancel}
                    maskClosable={false}
                    width={650}
                    footer={[
                        <>
                            <Button onClick={handleCancel}>{t('btn.cancel')}</Button>
                           {
                            hideAllButton ? null :  <>
                            {
                                !isEmpty(validOrders) && !isEmpty(messages) && 
                                    <Button
                                        loading={loading && confirmValue === 'valid'}
                                        type="primary"
                                        onClick={() => handleConfirm('valid')}
                                    >
                                        {t('order:btn.create_order_valid')}
                                    </Button>
                                
                            }
                            {warningOrders.length > 0 ? (
                                <>
                                    <Button
                                        loading={loading && confirmValue === 'both'}
                                        type="primary"
                                        onClick={() => handleConfirm('both')}
                                    >
                                        {t('order:btn.create_all_order')}
                                    </Button>
                                    {
                                        validOrders.length > 0 &&
                                        <Button
                                            loading={loading && confirmValue === 'valid'}
                                            type="primary"
                                            onClick={() => handleConfirm('valid')}
                                        >
                                            {t('order:btn.create_valid_order')}
                                        </Button>
                                    }
                                </>
                            ) : (
                                <Button loading={loading} type="primary" onClick={handleOk}>
                                    {t('btn.ok')}
                                </Button>
                            )}</>
                           }
                        </>,
                    ]}
                >
                    <p className="mb-2">{t('order:title.choose_order_your_channel')}:</p>
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Item
                            validateStatus={getVar(errors, 'channel', false) ? 'error' : false}
                            help={getVar(errors, 'channel', false)}
                        >
                            <Select
                                value={channel}
                                placeholder={t('order:placeholder.select_your_channel')}
                                optionFilterProp="children"
                                onChange={value => handleChangeItem('channel', value)}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {SAMPLE_IMPORT_CREATE_ORDER_FILE.map((item, index) => {
                                    return (
                                        <Option key={item.key}>{t(`order:SAMPLE_IMPORT_CREATE_ORDER_FILE.${item.name}`)}</Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        {!isEmpty(sampleFileInfo) && (
                            <Button
                                type="primary"
                                href={process.env.PUBLIC_URL + `${sampleFileInfo.urlPath}`}
                                icon={<DownloadOutlined />}
                            >
                                {t('btn.download_sample_file')}
                            </Button>
                        )}
                    </div>

                    <Form.Item
                        label={t('order:label.warehouse_id')}
                        required={true}
                        validateStatus={getVar(errors, 'warehouse_id', false) ? 'error' : false}
                        help={getVar(errors, 'warehouse_id', false)}
                    >
                        <SelectWarehouse
                            className="_order-info-warehouse-id"
                            allowClear={true}
                            placeholder={t('order:placeholder.warehouse_id')}
                            onChange={value => handleChangeItem('warehouse_id', value)}
                        />
                    </Form.Item>

                    <UploadFile
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        name={t('order:label.file_create_order')}
                        maxFileSize={5}
                        showUploadList={true}
                        setFileList={setFileList}
                        fileList={fileList}
                    />
                    {getVar(errors, 'file', false) && (
                        <Form.Item
                            className="_file-create-order mb-0"
                            validateStatus={getVar(errors, 'file', false) ? 'error' : false}
                            help={getVar(errors, 'file', false)}
                        />
                    )}

                    {warningOrders.length > 0 && (
                        <div className="pt-3">
                            {warningOrders.map((item, index) => {
                                return (
                                    <p key={index} className="text-warning">
                                        - {renderWarningOrder(item)}
                                    </p>
                                );
                            })}
                        </div>
                    )}

                    {messages.length > 0 && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(item)}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
}
