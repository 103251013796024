import { QuestionCircleOutlined } from '@ant-design/icons'
import { Checkbox, Table, Tooltip, Typography } from 'antd'
import { filter,  get, isEmpty, map, sumBy, uniq, uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { auth } from '@Modules/Auth/services'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'


const { Text } = Typography

const ServicePack = ({ servicePacks, data, loading, serviceSelected, setServiceList }) => {
    const user = auth.user()
    const currency = getVar(user, 'currency', {})
    const [checkedList, setCheckedList] = useState([])
    const [checkedItem, setCheckedItem] = useState([])
    useEffect(() => {
        const serviceIds = map(data, 'service.id')
        setCheckedItem(data)
        setCheckedList(serviceIds)
    }, [data])

    let columns = [
        {
            className: '_dropshipping-list-service-name text-wrap',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            width: '50%',
            render: (text, record) => (text ? text : ''),
        },
        {
            className: '_dropshipping-list-estimated_costs text-left',
            title: (
                <p className="flex align-items-center">
                    {t('product:label.estimated_costs')}
                    <Tooltip title={t('message.cost_depends_on_size_service_used')}>
                        <QuestionCircleOutlined className="ml-1 cursor-pointer" />
                    </Tooltip>
                </p>
            ),
            dataIndex: 'service_price',
            key: 'service_price',
            width: '50%',
            render: (text, record) => {
                return <p>{text ? (!isEmpty(currency) ? formatCurrency(text?.price || 0, currency) : '') : '---'}</p>
            },
        },
    ]


    const rowSelection = {
        selectedRowKeys: checkedList,

        onChange: (selectedRowKeys, selectedRows) => {
            const serviceIds = map(data, 'service.id')
            const serviceRequiredIds = map(filter(data, 'service.is_required'), 'service.id')
            const serviceRequired = filter(data, 'service.is_required')
            const otherServiceId = filter(serviceSelected, item => !serviceIds.includes(item))

            setCheckedItem(uniqBy([...selectedRows, ...serviceRequired]), 'service.id')
            setCheckedList(uniq([...selectedRowKeys, ...serviceRequiredIds]))
            setServiceList(uniq([...otherServiceId, ...selectedRowKeys, ...serviceRequiredIds]))
        },

        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required')
            const id = get(record, 'service.id')

            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox
                                checked={disabledRow}
                                disabled={disabledRow}
                            />
                        </Tooltip>
                    ) : (
                        <Checkbox
                            checked={checked}
                            onChange={() => handleChangeCheckbox(checked, record, id)}
                        />
                    )}
                </>
            )
        },
    }

    const handleChangeCheckbox = (checked, selectedRows, id) => {
        if (checked) {
            const newListId = serviceSelected.filter(item => item !== id)
            const newListItem = checkedItem.filter(item => item.service.id !== id)

            setCheckedItem(newListItem)
            setServiceList(newListId)
            setCheckedList(checkedList.filter(item => item !== id))
        } else {
            setCheckedItem([selectedRows, ...checkedItem])
            setCheckedList([...checkedList, id])
            setServiceList([...serviceSelected, id])
        }
    }

    return (
        <>
            <Table
                className="product-list-edit-table pb-3"
                dataSource={data || []}
                rowKey={record => record?.service?.id}
                columns={columns}
                loading={loading}
                pagination={false}
                rowSelection={{
                    ...rowSelection,
                }}
                summary={pageData => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">{t('label.estimated_sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">
                                <Text>
                                    {!isEmpty(currency)
                                        ? formatCurrency(sumBy(checkedItem, 'service_price.price'), currency)
                                        : '---'}
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                }}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className="_dropshipping-list-empty" /> }}
            />
        </>
    )
}

export default ServicePack
