import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import SessionStorageApi, { RETURNED_TYPE } from '@System/support/SessionStorageApi'

export const IdempotencyStorage = new SessionStorageApi('Idempotency-Key', RETURNED_TYPE.JSON)

const useGeneratorKey = () => {
    const [uniqueKey, setUniqueKey] = useState('')

    const generatorKey = () => {
        const data = {
            key: v4(),
            status: false,
        }
        IdempotencyStorage.setValue(data)
        setUniqueKey(data)
    }

    useEffect(() => {
        generatorKey()

        return ()=> IdempotencyStorage.reset()
    }, [])

    return {
        uniqueKey,
        generatorKey,
    }
}

export default useGeneratorKey
