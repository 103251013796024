import { Avatar, Form, Table } from 'antd';
import React from 'react';

import { pageSizeOptions } from '@Modules/App/services/constants';
import { getPageSizeScale } from '@Modules/App/services/helpers';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function PurchasingPackageList({ dataSource, loading }) {
    const pagination = {
        pageSizeOptions: pageSizeOptions,
        locale: { items_per_page: '' },
        defaultPageSize: getPageSizeScale(),
        defaultCurrent: 1,
        showSizeChanger: true,
    };
    const columns = [
        {
            className: '_import-package',
            title: t('product:label.import_package'),
            dataIndex: 'code',
            render: (text, record) => {
                const image = getVar(record, 'image', '');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" className="mr-2" size={36} src={image} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_freight-bill-code',
            title: t('product:label.freight_bill_code'),
            dataIndex: 'freight_bill_code',
        },
        {
            className: 'text-right text-nowrap _weight',
            title: t('product:label.weight'),
            dataIndex: 'weight',
            width: '200px',
            render: (text) => {
                return <p>{`${text ? `${text * 1000} (g)` : ''}`}</p>;
            },
        },
        {
            className: 'text-right text-nowrap _quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            width: '200px',
            render: (text) => {
                return text ? text : t('message.wait_warehouses_checked')
            },
        },
    ];

    return (
        <div className="mb-3 _purchasing-package-list">
            <h4>
                {`${t('title.list_import_package')} `}
                <span className="number-circle _importing-document-product-list-total">{dataSource ? dataSource.length : 0}</span>
            </h4>
            <Table
                loading={loading}
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: 576 }}
                pagination={false}
                locale={{
                    emptyText: <p className="_purchasing-package-list-no-data">{t('message.no_data_product')}</p>,
                }}
            />
        </div>
    );
}
export default PurchasingPackageList;
