import { api as apiSystem } from '@System/api';
import { ISettings } from '../Interfaces';

const api = {
    getPurchaseAccounts: (params: any) =>
        apiSystem.get('/merchant/purchasing-managers/purchasing-accounts', { params }),
    addPurchaseAccount: (data: any) => apiSystem.post('/merchant/purchasing-managers/purchasing-accounts', data),
    deletePurchaseAccount: (id: any) => apiSystem.delete(`merchant/purchasing-managers/purchasing-accounts/${id}`),
    getStores: () => apiSystem.get(`/merchant/stores`),
    createStores: (data: any) => apiSystem.post(`/merchant/stores`, data),
    getOauthUrl: (channel: any, params: any) =>
        apiSystem.get(`/merchant/marketplaces/${channel}/oauth-url`, { params }),
    deleteStore: (storeId: number) => apiSystem.delete(`/merchant/stores/${storeId}`),
    syncProductByChannel: (storeId: number) => apiSystem.post(`/merchant/stores/${storeId}/sync-products`),
    updateExportWarehouseStore: (storeId: number, data: any) =>
        apiSystem.put(`/merchant/stores/${storeId}/warehouse`, data),
    syncStockMerchant: (storeId: number, data: ISettings) => apiSystem.put(`/merchant/stores/${storeId}/settings`, data),
    getWarehousePartners: (storeId: number) =>
        apiSystem.get(`merchant/stores/${storeId}/warehouse-marketplaces`),
    createConfigWarehouseStore: (storeId: number, data: any) => apiSystem.post(`/merchant/stores/${storeId}/warehouse-marketplaces`, data),
};
export default api;
