import { useQuery } from 'react-query';

import { auth } from '@Modules/Auth/services';
import api from '@Modules/Services/services/api';

import processResponseError from '@System/api/processResponseError';

const useListServiceByTypeQuery = type => {
    const user = auth.user();
    return useQuery(['service_by_type', type], () => api.getListService({ type, country_id: user.location_id, status: 'ACTIVE' }), {
        enabled: !!type,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListServiceByTypeQuery;
