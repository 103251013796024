import { Spin } from 'antd';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useOnBoardingQuery from '@Modules/Dashboard/Hooks/useOnBoardingQuery';
import Dashboard from '@Modules/Dashboard/screens/Dashboard';
import Onboard from '@Modules/Dashboard/screens/Onboard';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function Overall({ history, location }) {
    const [showOnboard, setShowOnboard] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const { data, isFetching } = useOnBoardingQuery();

    useEffect(() => {
        setDocumentTitle(t('title_page.dashboard'));
    }, []);
    useEffect(() => {
        let isShowOnboard = 0;
        const info = getVar(data, 'data', {});
        if (!isEmpty(info)) {
            setShowInfo(true);
            forEach(info, item => {
                if (parseInt(item) === 0) {
                    isShowOnboard++;
                }
            });
        }
        if (isShowOnboard) {
            setShowOnboard(true);
        } else {
            setShowOnboard(false);
        }
    }, [data]);
    return (
        <div className="site-content _dashboard-content">
            <Spin spinning={isFetching}>
                {showInfo ? (
                    showOnboard ? (
                        <Onboard data={data} loading={isFetching} />
                    ) : (
                        <Dashboard history={history} location={location} />
                    )
                ) : (
                    ''
                )}
            </Spin>
        </div>
    );
}
export default Overall;
