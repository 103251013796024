import { Radio, Space } from 'antd';
import React from 'react';

import { PAYMENT_TYPE } from '@Modules/Order/services/constants';

import { trans } from '@System/i18n';
import { omit } from 'lodash';

export default function RadioPaymentType(props) {
    return (
        <Radio.Group {...props} className='_payment_type_radio'>
            <Space direction="horizontal">
                {Object.keys(omit(PAYMENT_TYPE, 'PAYMENT_TYPE_DEFERRED_PAYMENT')).map((opt, index) => {
                    return (
                        <Radio key={index} value={PAYMENT_TYPE[opt]} className={`${PAYMENT_TYPE[opt]}`}>
                            {trans(`order:payment_type.${PAYMENT_TYPE[opt]}`)}
                        </Radio>
                    );
                })}
            </Space>
        </Radio.Group> 
    );
}
