import { DeleteOutlined } from '@ant-design/icons';
import { Badge, Table, Button } from 'antd';
import React from 'react';

import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import { pageSizeOptions } from '@Modules/App/services/constants';
import { getPageSizeScale } from '@Modules/App/services/helpers';
import { PURCHASING_ACCOUNT_STATUS } from '@Modules/Setting/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function List({ accounts, loading, onDelete, handleOpenModal }) {
    const pagination = {
        pageSizeOptions: pageSizeOptions,
        locale: { items_per_page: '' },
        defaultPageSize: getPageSizeScale(),
        defaultCurrent: 1,
        showSizeChanger: true,
    };
    let getColumns = [
        {
            className: '_account-list-account text-wrap',
            title: t('label.account'),
            dataIndex: 'username',
            key: 'username',
        },
        {
            className: '_account-list-service',
            title: t('label.service'),
            dataIndex: ['purchasing_service', 'name'],
            key: 'purchasing_service',
            render: (text, record) => {
                const code = getVar(record, 'purchasing_service.code', '');
                return <p>{`${text} ${code ? `(${code})` : ''}`}</p>;
            },
        },
        {
            className: '_account-list-status text-right',
            title: t('label.status'),
            dataIndex: 'status',
            render: text =>
                text ? (
                    <Badge
                        color={PURCHASING_ACCOUNT_STATUS[text.toUpperCase()]?.color}
                        text={t(`setting:purchasing_account.status.${text}`)}
                    />
                ) : null,
        },
        {
            className: '_account-list-action text-right',
            key: 'action',
            render: (_, __, index) => (
                <Button danger onClick={() => onDelete(index)} icon={<DeleteOutlined />}>
                    {t('btn.delete')}
                </Button>
            ),
        },
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-fz-18">
                        {t('heading.account_connect')}
                        <span className="number-circle _account-list-total ml-2">{accounts ? accounts.length : 0}</span>
                    </h4>
                </div>
                <div className="data-box--body">
                    <Table
                        className="pb-3"
                        dataSource={accounts}
                        rowKey={record => record.id}
                        columns={getColumns}
                        pagination={pagination}
                        loading={loading}
                        scroll={{ x: true }}
                        locale={{
                            emptyText: (
                                <EmptyTableData
                                    loading={loading}
                                    message={'no_data_purchasing_account'}
                                    action={
                                        <Button className="_add-btn" type="link" style={{ padding: 0 }} onClick={handleOpenModal}>
                                            <strong className="pagination-customize">{t('btn.add_account')}</strong>
                                        </Button>
                                    }
                                />
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default List;
