import _ from "lodash";
import {t} from "../../../system/i18n";
import notification from '@System/notification';
import BaseServiceData from "../../App/services/BaseServiceData";
import axios from "axios";
import api from "@Modules/Setting/services/api";

class ServicePurchasingAccount extends BaseServiceData {

    constructor() {
        super();
    }

    runApi() {
        return api.getPurchaseAccounts({status: "active"})
            .then(res => _.get(res, "data.purchasing_accounts", []))
            .catch(err => {
                if (!axios.isCancel(err)) {
                    notification.error(t("common:message.fetch_fail", {attribute: t("label.purchasing_accounts").toLowerCase()}));
                    return Promise.reject(err);
                }
            });
    }
}

export default new ServicePurchasingAccount();
