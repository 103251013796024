import { useQuery } from 'react-query';

import api from '@Modules/ImportPackage/services/api';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel } from '@System/support/helpers';

const useExportListImportPackageQuery = params =>
    useQuery(['ExportListImportPackage', params], () => api.exportListImportPackage(params), {
        retry: false,
        enabled: false,
        onError: err => {
            processResponseError(err);
        },
        onSuccess: res => {
            convertBlobDataToExcel(res.data, 'import-package-list');
            notification.success(t('message.export_excel_success'));
        },
    });

export default useExportListImportPackageQuery;
