import { CaretDownOutlined } from '@ant-design/icons';
import { dateFormatter } from '@Modules/App/services';
import EmptyTableData from '@Modules/App/Table/EmptyTableData';
import ChannelSetting from '@Modules/Setting/components/SalesChannel/ChannelSetting';
import ConfigWarehouse from '@Modules/Setting/components/SalesChannel/ConfigWarehouse';
import DeleteConnection from '@Modules/Setting/components/SalesChannel/DeleteConnection';
import ReConnection from '@Modules/Setting/components/SalesChannel/ReConnection';
import SyncProductsByChannel from '@Modules/Setting/components/SalesChannel/SyncProductsByChannel';
import { IAccount, ISettings, StoreStatus } from '@Modules/Setting/Interfaces';
import api from '@Modules/Setting/services/api';
import { CHANNEL_LIST, CHANNEL_STATUS_COLOR } from '@Modules/Setting/services/constants';

import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { Avatar, Badge, Button, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import find from 'lodash/find';
import React from 'react';


interface IListProps {
    accounts: IAccount[];
    loading: boolean;
    refetch: () => void;
    createAccount: () => void;
}

const List: React.FC<IListProps> = ({ accounts, loading, refetch, createAccount }) => {
    function handleChangeWarehouse(storeId: number, value: number) {
        api.updateExportWarehouseStore(storeId, { warehouse_id: value })
            .then(() => {
                notification.success(
                    t('message.update_success_by_attribute', { attribute: t('label.export_warehouse') })
                );
                refetch();
            })
            .catch(() => {
                notification.error(t('message.update_failed_by_attribute', { attribute: t('label.export_warehouse') }));
            });
    }
    let columns = [
        {
            className: '_store-column',
            title: t('setting:sales_channel.label.store'),
            key: 'store_name',
            dataIndex: ['store', 'marketplace_store_id'],
            render: (text: string, record: IAccount) => {
                const storeName = get(record, 'store.shop_name');
                return (
                    <div className="d-flex align-items-center">
                        {storeName ? storeName : text}
                    </div>
                );
            },
        },
        {
            className: '_channel-column',
            title: t('setting:sales_channel.label.channel'),
            key: 'channel_name',
            dataIndex: ['store', 'marketplace_code'],
            render: (text: any) => {
                const channelInfo = find(CHANNEL_LIST, ['code', text]);
                const img = get(channelInfo, 'img');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" src={img} className="mr-2" size={24} style={{ flexShrink: 0 }} />
                        {t(`setting:sales_channel.channel_list.${text}`)}
                    </div>
                );
            },
            width: '20%',
        },
        {
            className: '_export-warehouse-column',
            title: t('label.export_warehouse'),
            key: 'export_warehouse',
            dataIndex: ['store', 'warehouse_id'],
            render: (text: any, record: IAccount) => {
                const storeId = get(record, 'store.id', undefined);
                if (!isEmpty(record?.multi_warehouse)) {
                    return ""
                }
                return (
                    <SelectWarehouse
                        value={text ? text : undefined}
                        style={{ width: '100%' }}
                        className="_warehouse-id"
                        placeholder={t('order:placeholder.warehouse_id')}
                        onChange={(value: number) => handleChangeWarehouse(storeId, value)}
                    />
                )
            },
        },
        {
            className: '_status-column',
            title: t('label.status'),
            key: 'status',
            dataIndex: ['store', 'status'],
            render: (text: StoreStatus) => (
                <Badge
                    color={CHANNEL_STATUS_COLOR[text as keyof typeof CHANNEL_STATUS_COLOR]}
                    text={t(`setting:sales_channel.status.${text}`)}
                />
            ),
        },
        {
            className: '_expired-at-column',
            title: t('label.connect_expired_at'),
            key: 'expired_at',
            dataIndex: ['store', 'connection_expired_at'],
            render: (text: string) => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_actions-column text-right',
            key: 'actions',
            dataIndex: ['store', 'id'],
            width: '100px',
            render: (text: any, record: IAccount) => {
                const canReconnect = get(record, 'store.can_reconnect');
                const settings:ISettings = get(record, 'settings');
                const isMultiWarehouse = !isEmpty(record?.multi_warehouse)
                const storeID = record?.store?.id
                const isDisabled = ["LAZADA", "TIKI"]?.includes(record?.store?.marketplace_code || "")
                return (
                    <>
                        {canReconnect && <ReConnection store={record?.store} onSuccess={refetch} />}
                        <DeleteConnection className="ml-2" storeId={text} onSuccessDelete={refetch} />
                        <SyncProductsByChannel className="ml-2" storeId={text} />
                        <ChannelSetting storeId={text} settings={settings} refetchList={refetch} isMultiWarehouse={isMultiWarehouse} />
                        <ConfigWarehouse refetchList={refetch} storeID={storeID} record={record} isDisabled={isDisabled} />
                    </>
                );
            },
        },
    ];

    return (
        <div className="data-box--body">
            <Table
                rowKey={(record:IAccount) => record?.store?.id}
                columns={columns}
                dataSource={accounts}
                pagination={false}
                loading={loading}
                scroll={{ x: true }}
                locale={{
                    emptyText: (
                        <EmptyTableData
                            loading={loading}
                            message={'no_sales_channel_account'}
                            action={
                                <Button type={'link'} style={{ padding: 'inherit' }} onClick={createAccount}>
                                    <strong className="cursor-pointer pagination-customize">
                                        {t('setting:sales_channel.btn.create_account')}
                                        <CaretDownOutlined className="ml-1" />
                                    </strong>
                                </Button>
                            }
                        />
                    ),
                }}
            />
        </div>
    );
};

export default List;
