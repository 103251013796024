export const STATISTIC_CODE_VELA = [
    {
        reality:'paid_amount',
        expected: ''
    },
    {
        reality:'gross_profit',
        expected: ''
    },
    {
        reality:'sale_amount',
        expected: 'sale_expected_amount'
    },
    {
        reality:'shipping_amount',
        expected: 'expected_shipping_amount'
    },
    {
        reality:'extent_service_amount',
        expected: 'extent_service_expected_amount'
    }, 
    {
        reality:'return_amount',
        expected: ''
    },
];

export const STATISTIC_CODE_FBU = [
    'paid_amount',
    'service_amount',
    'shipping_amount',
    'remaining_amount',
    'return_amount',
];

export const FINANCE_SERVICE_IMPORT_RETURN_GOODS_STATUS = {
    PAID: "PAID",
    UNPAID: "UNPAID"
};
