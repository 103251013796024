import { Select } from 'antd';
import findIndex from 'lodash/findIndex';
import React, { useEffect, useState } from 'react';

import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses';

import { t } from '@System/i18n';

const { Option } = Select;

export default function SelectWarehouse(props) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceWarehouses.list()
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={
                data.length > 0 && props.value
                    ? props.mode === 'multiple'
                        ? props.value.map(item => +item)
                        : props.value
                    : undefined
            }
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {data.map(item => (
                <Option key={item.warehouse.id} value={item.warehouse.id}>
                    {item.warehouse.name +
                        (item.warehouse.code
                            ? ' (' + (item.warehouse.code === 'default' ? t('label.default') : item.warehouse.code) + ')'
                            : '')}
                </Option>
            ))}
        </Select>
    );
}
