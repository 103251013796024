import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { isEmpty } from 'lodash';
import React, { ReactNode } from 'react';

import { t } from '@System/i18n';
import { classes } from '@System/support/helpers';

interface IModalConfirmProps {
    okButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    onVisibleChange: React.Dispatch<React.SetStateAction<boolean>>;
    visible: boolean;
    content?: ReactNode;
    className?: string;
    title?: string;
}

const ModalConfirm: React.FC<IModalConfirmProps> = ({
    okButtonProps,
    cancelButtonProps,
    onVisibleChange,
    visible,
    content,
    className,
    title,
}) => {
    return (
        <>
            {visible && (
                <Modal
                    title={title ?? ''}
                    visible={true}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.confirm')}
                    className={classes('custom-confirm-modal', {
                        [className ?? '']: !isEmpty(className),
                    })}
                    closable
                    onCancel={()=>onVisibleChange(false)}
                >
                    <div>
                        <ExclamationCircleOutlined />
                        {content}
                    </div>

                    <div className="confirm-btn-group">
                        <Button
                            {...cancelButtonProps}
                            className={classes('mr-2', {
                                [cancelButtonProps?.className ?? '']: cancelButtonProps?.className,
                            })}
                            onClick={()=>onVisibleChange(false)}
                        >
                            {t('btn.back')}
                        </Button>
                        <Button type="primary" {...okButtonProps}>
                            {t('btn.confirm')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ModalConfirm;
