import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, InputNumber, Row, Table, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { get, isEmpty, sumBy } from 'lodash';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import { auth } from '@Modules/Auth/services';
import { IOrderProduct } from '@Modules/Order/Interfaces';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

interface IProductTableProps {
    products: IOrderProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IOrderProduct[]>>;
    validatedRules: any;
    form: FormInstance;
}

const ProductTable: React.FC<IProductTableProps> = ({ products, setProducts, validatedRules, form }) => {
    const { xs } = useBreakpoint();
    const user = auth.user();
    const currency = get(user, 'currency', {});
    const total = sumBy(products, item => parseInt(item.quantity) * parseInt(item.price)) || 0;
    const isInspected = form.getFieldValue('inspected');

    useEffect(() => {
        if (isEmpty(products)) {
            form.setFields([
                { name: 'discount_amount', value: 0 },
                { name: 'total_amount', value: 0 },
            ]);
        }
    }, [form, products]);

    useEffect(() => {
        form.setFields([{ name: 'product_quantity', value: !isEmpty(products) ? products.length : '' }]);
        if (products.length > 0) form.validateFields(['product_quantity']);
    }, [form, products]);

    const columns = [
        {
            title: t('product:label.product'),
            dataIndex: 'code',
            key: 'code',
            className: '_product-info-code',
            render: (text: any, record: any) => {
                const image = get(record, 'image', '');
                const name = get(record, 'name', []);

                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" src={image} className="mr-2" size={36} style={{ minWidth: '36px' }} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <Link to={`/products/detail/${record?.product_id}/${record?.id}`}>
                                    <strong style={{ fontWeight: 500 }}>{text}</strong>
                                </Link>
                                <p style={{ color: '#999999', whiteSpace: 'pre-line' }}>{name}</p>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: t('label.quantity'),
            dataIndex: 'quantity',
            className: '_product-info-quantity',
            key: 'quantity',
            render: (text: any, record: any) => {
                return (
                    <Tooltip title={isInspected && t('order:message.order_process_contact_warehouse')}>
                        <InputNumber
                            name="quantity"
                            value={text}
                            className="text-right"
                            onChange={value => onChangeInput(record.id, 'quantity', value?.toString()!)}
                            onBlur={onCalculatorTotal}
                            maxLength={8}
                            step={1}
                            min={1}
                            style={{ width: '100%' }}
                            disabled={isInspected}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t('order:label.price'),
            dataIndex: 'price',
            className: '_product-info-price',
            key: 'price',
            render: (text: any, record: any) => {
                return (
                    <Tooltip
                        title={isInspected && t('order:message.order_process_contact_warehouse')}
                        children={
                            <div>
                                <CurrencyInput
                                    currency={currency}
                                    value={text}
                                    name="price"
                                    style={{ width: '100%' }}
                                    className="text-right"
                                    onChange={(value: string) => onChangeInput(record.id, 'price', value)}
                                    onBlur={onCalculatorTotal}
                                    maxLength={10}
                                    disabled={isInspected}
                                />
                            </div>
                        }
                    />
                );
            },
        },
        {
            title: t('order:label.sum_price'),
            className: 'text-center _product-info-total-amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text: any, record: any) => {
                const { price, quantity } = record;
                const total = parseFloat(price) * parseInt(quantity);
                return isEmpty(currency) ? total : formatCurrency(total, currency);
            },
        },
        {
            className: 'text-right _product-info-action',
            dataIndex: 'delete',
            key: 'delete',
            render: (text: any, record: any) => {
                return (
                    <Tooltip title={isInspected && t('order:message.order_process_contact_warehouse')}>
                        <Button
                            type="text"
                            className="text-danger"
                            disabled={isInspected}
                            onClick={() => onRemoveProduct(record.id)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                );
            },
        },
    ];

    const onCalculatorTotal = () => {
        const discountAmount = form.getFieldValue('discount_amount');
        form.setFields([{ name: 'total_amount', value: total > discountAmount ? total - discountAmount : '0' }]);
    };

    const onChangeInput = (id: string, name: string, value: string) => {
        setProducts(updateCollectionItem(products, id, { [name]: parseFloat(value) }, 'id'));
    };
    const onRemoveProduct = (id: string) => {
        const newListProduct = products.filter(sku => sku.id !== id);
        const total = sumBy(newListProduct, item => parseInt(item.quantity) * parseInt(item.price)) || 0;
        const discountAmount = form.getFieldValue('discount_amount');

        form.setFields([{ name: 'total_amount', value: total > discountAmount ? total - discountAmount : '0' }]);
        setProducts(newListProduct);
    };

    return (
        <Table
            className="_product-info-table create-order-product-table pb-2 mt-3"
            dataSource={products}
            rowKey={record => record.id}
            columns={columns}
            pagination={false}
            scroll={xs ? { x: 576 } : undefined}
            locale={{
                emptyText: <p className="_no-data">{t('message.no_data_product')}</p>,
            }}
            footer={() => (
                <>
                    <Form.Item
                        className="mb-0 hidden_input"
                        name="product_quantity"
                        rules={validatedRules.product_quantity}
                    >
                        <InputNumber name="product_quantity" placeholder={t('order:placeholder.product_quantity')} />
                    </Form.Item>
                    <Row
                        gutter={24}
                        className="text-right d-flex align-items-center justify-content-between _product-info-total-sum-price"
                    >
                        <Col xs={{ span: 12 }} xl={{ span: 18 }}>
                            <p>{t('order:label.total_sum_price')}:</p>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 6 }}>
                            {isEmpty(currency) ? total : formatCurrency(total || 0, currency)}
                        </Col>
                    </Row>
                    <Row
                        gutter={24}
                        className="text-right mt-2 d-flex align-items-center justify-content-between _product-info-discount"
                    >
                        <Col xs={{ span: 12 }} xl={{ span: 18 }}>
                            <p>{t('order:label.discount')}:</p>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 6 }}>
                            <Form.Item className="mb-0" name="discount_amount">
                                <CurrencyInput
                                    currency={currency}
                                    name="discount_amount"
                                    style={{ width: '100%' }}
                                    className="text-right _product-info-input-discount"
                                    min={0}
                                    onBlur={(value: string) => {
                                        let newValue = value || '0';
                                        form.setFields([{ name: 'discount_amount', value: newValue }]);
                                        form.setFields([
                                            {
                                                name: 'total_amount',
                                                value: total > +newValue ? total - +newValue : '',
                                            },
                                        ]);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        gutter={24}
                        className="text-right mt-2 d-flex align-items-center justify-content-between _product-info-total-payment"
                    >
                        <Col xs={{ span: 12 }} xl={{ span: 18 }}>
                            <p className="label required">{t('order:label.total_amount')}:</p>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 6 }}>
                            <Form.Item className="mb-0" name="total_amount" rules={validatedRules.total_amount}>
                                <CurrencyInput
                                    currency={currency}
                                    name="total_amount"
                                    style={{ width: '100%' }}
                                    className="text-right _product-info-input-total-payment"
                                    min={0}
                                    onBlur={(value: string) => {
                                        let newValue = value || '0';
                                        form.setFields([{ name: 'total_amount', value: newValue.toString() }]);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        />
    );
};

export default ProductTable;
