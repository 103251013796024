import createUseQuery from '@Modules/App/Hooks/createUseQuery';
import api from '@Modules/PurchasingManager/services/api';

const usePurchasingServicesQuery = () =>
    createUseQuery({
        name: 'purchasing-services',
        query: () => api.purchasingServices(),
    });

export default usePurchasingServicesQuery;
