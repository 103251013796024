import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';
import api from '../services/api';

const useDropshipDetailPriceQuery = (id,params) =>
    useQuery(['product-detail-price', id,params], () => api.getDropshippingDetailPrice(id,params), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useDropshipDetailPriceQuery;
