import React from 'react';
import {auth} from '../services';
import Error403 from '../../App/Error/403';

export const can = (permissions) => (payload, next) => {
    return !auth.can(permissions) ? payload.response.render(<Error403/>) : next(payload);
};

export const canAny = (permissions) => (payload, next) => {
    return !auth.canAny(permissions) ? payload.response.render(<Error403/>) : next(payload);
};

export const canModuleMenu = (modules) => (payload, next) => {
    return !auth.canAnyModuleMenu(modules) ? payload.response.render(<Error403/>) : next(payload);
};

export const canAnyModuleMenu = (modules) => (payload, next) => {
    return !auth.canAnyModuleMenu(modules) ? payload.response.render(<Error403/>) : next(payload);
};
