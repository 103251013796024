export const ALLOW_MODULES = {
    DASHBOARD:'DASHBOARD',
    WAREHOUSE_MANAGE_WAREHOUSE_STOCK:'WAREHOUSE_MANAGE:WAREHOUSE_STOCK',
    WAREHOUSE_MANAGE_PRODUCT_DROPSHIP:'WAREHOUSE_MANAGE:PRODUCT_DROPSHIP',
    WAREHOUSE_MANAGE_IMPORT_GOODS:'WAREHOUSE_MANAGE:IMPORT_GOODS',
    ORDER_MANAGE_ORDER:'ORDER_MANAGE:ORDER',
    FINANCE_FINANCE_ORDER:'FINANCE:FINANCE_ORDER',
    FINANCE_HISTORY_TRANSACTION:'FINANCE:HISTORY_TRANSACTION',
    SETUP_ACCOUNT:'SETUP:ACCOUNT',
    SETUP_STORE:'SETUP:STORE',
}
