export const IMPORT_PACKAGE_STATUS = {
    TRANSPORTING: 'TRANSPORTING',
    IMPORTED: 'IMPORTED',
    INIT: 'INIT',
    PUTAWAY: 'PUTAWAY',
    READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
    REQUEST_FOR_DELIVERY: 'REQUEST_FOR_DELIVERY',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    MIA: 'MIA',
    INACTIVE: 'INACTIVE',
    WAIT_FOR_RETURN: 'WAIT_FOR_RETURN',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    WAIT_FOR_LIQUIDATION: 'WAIT_FOR_LIQUIDATION',
    LIQUIDATED: 'LIQUIDATED',
    REFUSED: 'REFUSED',
    CANCELED: 'CANCELED'
};

export const IMPORT_PACKAGE_STATUS_COLORS = {
    TRANSPORTING: '#008DF9',
    IMPORTED: '#09B8AF',
    INIT: '#f9a400',
    PUTAWAY: '#06d087',
    READY_FOR_DELIVERY: '#bdbdbd',
    REQUEST_FOR_DELIVERY: '#ed6e52',
    DELIVERING: '#4fff37',
    DELIVERED: '#1ef8ff',
    MIA: '#ff4559',
    INACTIVE: '#ff4559',
    WAIT_FOR_RETURN: '#ffa634',
    RETURNING: '#1e8eff',
    RETURNED: '#ff4559',
    WAIT_FOR_LIQUIDATION: '#ffa634',
    LIQUIDATED: '#ff4559',
    REFUSED: '#ff4559',
    CANCELED: '#bdbdbd'
};

export const FINANCE_STATUS = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
};

export const FINANCE_STATUS_COLORS = {
    UNPAID: '#f9364a',
    PAID: '#0cb80b',
};

export const TRANSACTION_STATUS = {
    IMPORT_SERVICE: 'IMPORT_SERVICE',
    EXPORT_SERVICE: 'EXPORT_SERVICE',
    SHIPPING: 'SHIPPING',
    COD: 'COD',
    DEPOSIT: 'DEPOSIT',
    WITHDRAW: 'WITHDRAW',
    STORAGE_FEE: "STORAGE_FEE",
    IMPORT_RETURN_GOODS_SERVICE: 'IMPORT_RETURN_GOODS_SERVICE',
    EXTENT: 'EXTENT',
    COLLECT: 'COLLECT',
    COST_OF_GOODS: 'COST_OF_GOODS'
};

export const TRANSACTION_STATUS_COLORS = {
    IMPORT_SERVICE: '#f9364a',
    EXPORT_SERVICE: '#f9364a',
    SHIPPING: '#f9364a',
    WITHDRAW: '#f9364a',
    COD: '#09B2AA',
    DEPOSIT: '#09B2AA',
    STORAGE_FEE:'#f9364a',
    IMPORT_RETURN_GOODS_SERVICE:'#f9364a',
    EXTENT: '#f9364a',
    COLLECT: '#f9364a',
    COST_OF_GOODS: '#f9364a'
};

export const INPUT_INVALID = "INPUT_INVALID"