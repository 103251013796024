import { Checkbox, Form, Spin } from 'antd';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';

import useListServiceByTypeQuery from '@Modules/Services/Hooks/useListServiceByTypeQuery';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function ImportService({ onChange ,setServiceImport,serviceList}) {
  const [values, setValues] = useState([]);
  const [services, setServices] = useState([]);
  const { isFetching, data } = useListServiceByTypeQuery(SERVICES_TYPE.IMPORT);

  useEffect(() => {
    const services = getVar(data, 'data.services', []);
    if (!isEmpty(services)) {
      setServices(refactorData(services));
    }
  }, [data]);

  function refactorData(data) {
    const newData = [];
    data.map(item => {
      newData.push({
        label: item.service.name,
        value: item.service.id,
      });
    });
    return newData;
  }

  function handleOnChange(checkedValues) {
   
    onChange('services', [...checkedValues,...serviceList]);
    setValues(checkedValues);
    setServiceImport(checkedValues)
  }

  return services.length > 0 ? (
    <Checkbox.Group className="_packing-service-info mt-4 _service_import" options={services} value={values} onChange={handleOnChange} />
  ) : (
    <div className="mt-4 ">{t(`message.${isFetching ? 'loading' : 'no_data_service'}`)}</div>
  );
}
export default ImportService;
