import { Table } from 'antd';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { auth } from '@Modules/Auth/services';
import { IMPORT_PACKAGE_STATUS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function ServiceList({ product, services }) {
    const status = getVar(product, 'purchasing_package.status', null);
    const user = auth.user();
    const currency = getVar(user, 'currency', {});

    const columns = [
        {
            className: '_service-list-name',
            title: t('product:label.service'),
            dataIndex: 'name',
        },
        {
            className: '_service-list-price-name',
            title: t('product:label.price_level'),
            dataIndex: 'service_price_name',
        },
        {
            className: '_service-list-sku-apply text-wrap',
            title: t('product:label.sku_apply'),
            dataIndex: 'skus',
            render: text => {
                return text.length > 0 ? map(text, 'sku_code').join(', ') : '';
            },
        },
        {
            className: 'text-nowrap _service-list-price',
            title: status === IMPORT_PACKAGE_STATUS.IMPORTED ? t('product:label.unit_price') : t('product:label.temporary_price'),
            dataIndex: 'price',
            render: text => {
                const price = text
                    ? `${formatCurrency(text, currency || null)} / ${t('label.has_product', { count: 1 })}`
                    : t('label.free');
                return <p>{price}</p>;
            },
        },
        {
            className: 'text-center _service-list-quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
        },
        {
            className: 'text-right _service-list-amount',
            title: status === IMPORT_PACKAGE_STATUS.IMPORTED ? t('product:label.costs') : t('product:label.estimated_costs'),
            dataIndex: 'amount',
            render: text => {
                return <p>{text ? formatCurrency(text, currency) : t('label.free')}</p>;
            },
        },
    ];

    return (
        <div>
            <Table
                className="_service-list"
                dataSource={services}
                columns={
                    status === IMPORT_PACKAGE_STATUS.IMPORTED
                        ? columns
                        : columns.filter(col => col.dataIndex !== 'service_price_name')
                }
                pagination={false}
                scroll={{ x: 576 }}
                footer={() => {
                    return (
                        <div className="d-flex justify-content-between">
                            <strong>{t('product:label.sum_total_costs')}</strong>
                            <strong>
                                {sumBy(services, 'amount') && formatCurrency(sumBy(services, 'amount'), currency || null)}
                            </strong>
                        </div>
                    );
                }}
                locale={{
                    emptyText: <p>{t(`message.no_data_service`)}</p>,
                }}
            />
        </div>
    );
}
export default ServiceList;
