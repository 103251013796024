import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import SelectCategories from './SelectCategories';

function ProductInfo({ product, onChange, form }) {
    const [name, setName] = useState(getVar(product, 'name', ''));

    useEffect(() => {
        setName(getVar(product, 'name', ''));
        form.setFieldsValue({name});
    }, [form, name, product]);

    const category_id = getVar(product, 'category_id', undefined);

    return (
        <Form form={form}>
            <Form.Item labelAlign={'left'} labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} label={t('product:label.code')}>
                <p className="_product-info-code">{getVar(product, 'code', '')}</p>
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                name="name"
                label={t('product:label.name')}
                rules={[{ required: true, message: t('product:message.required', { attribute: t('product:label.name') }) }]}
            >
                <Input
                    className="_product-info-name"
                    onChange={e => form.setFieldsValue({name:e.target.value})}
                    placeholder={t('product:placeholder.name')}
                    onBlur={e => onChange('name', e.target.value)}
                />
            </Form.Item>

            <Form.Item
                labelAlign={'left'}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                label={t('product:label.category_id')}
            >
                <SelectCategories
                    className="_product-info-category-id"
                    value={category_id ? parseInt(category_id) : undefined}
                    placeholder={t('product:placeholder.category_id')}
                    onChangeValue={onChange}
                />
            </Form.Item>
        </Form>
    );
}
export default ProductInfo;
