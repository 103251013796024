import { Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';

import ServiceLocation from '@Modules/Location/services/ServiceLocation';
import { VIETNAM_CODE } from '@Modules/Location/services/constants';

import { t } from '@System/i18n';
import { SelectLocationKey } from '@Modules/Order/Interfaces';

interface ISelectDistrictProps {
    countryCode: string;
    onChange: (value: any, option: any, key: SelectLocationKey) => void;
    value?: number;
    provinceCode: string;
}

const ServiceLocationApi = new ServiceLocation();
const { Option } = Select;

const SelectDistrict: React.FC<ISelectDistrictProps> = ({ countryCode, onChange, value, provinceCode }) => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        const fetchDistrict = () => {
            setLoading(true);

            ServiceLocationApi.list('DISTRICT', provinceCode)
                .then(res => {
                    setDistricts(res);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        if (provinceCode) {
            fetchDistrict();
        }
    }, [provinceCode]);

    return (
        <Select
            className="_location-receiver-district-id"
            loading={loading}
            disabled={loading}
            placeholder={t(
                `warehouse:placeholder.${countryCode === VIETNAM_CODE ? 'district_id' : 'other_district_id'}`
            )}
            onChange={(value: any, option: any) => onChange(value, option.code, 'district')}
            showSearch
            value={districts.length > 0 && value ? value : undefined}
            optionFilterProp="label"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {districts.length > 0 &&
                districts.map((opt: any) => {
                    return (
                        <Option key={opt.id} value={opt.id} code={opt.code}>
                            {opt.label}
                        </Option>
                    );
                })}
        </Select>
    );
};

export default SelectDistrict;
