import { Avatar, Table } from 'antd';
import clsx from 'clsx';
import React from 'react';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function ProductList({ dataSource, loading }) {
    const columns = [
        {
            className: '_product-list-code',
            title: t('product:label.sku_code'),
            dataIndex: 'code',
            render: (text, record) => {
                const image = getVar(record, 'product.images.0', '');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar src={image} shape="square" className="mr-2" size={36} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_product-list-name',
            title: t('product:label.name'),
            dataIndex: 'name',
        },
        {
            className: 'text-right text-nowrap _product-list-declare-quantity',
            title: t('product:label.declare_quantity'),
            dataIndex: 'quantity',
            width: '200px',
        },
        {
            className: 'text-right text-nowrap _product-list-real-quantity',
            title: t('product:label.real_quantity'),
            dataIndex: 'received_quantity',
            width: '200px',
            render: (text, record) => {
                const declare_quantity = getVar(record, 'quantity', 0);
                const diff = text === undefined || text === null ? 0 : text - declare_quantity;
                return (
                    <p
                        className={clsx('', {
                            'text-red': diff < 0,
                            'text-green': diff > 0,
                        })}
                    >{`${text === undefined || text === null ? t('label.no_text') : text} ${
                        diff !== 0 ? `(${(diff > 0 ? '+' : '') + diff})` : ''
                    }`}</p>
                );
            },
        },
    ];

    return (
        <Table
            loading={loading}
            className="_product-list-table"
            rowKey={record => record.sku_id}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 576 }}
            pagination={false}
            locale={{
                emptyText: <p>{t('message.wait_warehouses_checked')}</p>,
            }}
        />
    );
}

export default ProductList;
