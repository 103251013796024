import { Switch, Tooltip } from 'antd'
import React, { useState } from 'react'

import { PRODUCT_EVENT, PRODUCT_STATUS } from '@Modules/Dashboard/services/constants'
import api from '@Modules/Product/services/api'

import { events } from '@System/events'
import { t } from '@System/i18n'
import notification from '@System/notification'

const ChangeStatusProduct = ({ productSystem, status, skuId }) => {
    const [loading, setLoading] = useState(false)

    const onChangeStatus = status => {
        setLoading(true)
        api.updateStatusSellProduct(skuId, { status })
            .then(res => {
                notification.success(t('product:message.update_status_success'))

                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_LIST, {})
            })
            .catch(() => {
                notification.error(t('product:message.update_status_failed'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Tooltip title={productSystem && t('product:message.product_sys_not_stop_sell')}>
            <Switch
                checked={status === PRODUCT_STATUS.ON_SELL}
                loading={loading}
                onChange={() =>
                    onChangeStatus(status === PRODUCT_STATUS.ON_SELL ? PRODUCT_STATUS.STOP_SELLING : PRODUCT_STATUS.ON_SELL)
                }
                disabled={productSystem}
                checkedChildren={t(`product:status.ON_SELL`)}
                unCheckedChildren={t(`product:status.STOP_SELLING`)}
            />
        </Tooltip>
    )
}

export default ChangeStatusProduct
