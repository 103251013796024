import { QuestionCircleOutlined } from '@ant-design/icons';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { auth } from '@Modules/Auth/services';
import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';
import { Checkbox, Table, Tooltip, Typography } from 'antd';
import { filter, get, isEmpty, map, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';

const { Text } = Typography

const ServicePackEdit = ({ type, onChange, serviceSelected, setServiceSelected, data, loading }) => {
    const user = auth.user()
    const currency = getVar(user, 'currency', {})
    const [checkedList, setCheckedList] = useState([])

    useEffect(() => { 
        setCheckedList(
            map(
                filter(map(serviceSelected, 'service'), item => item.type === type),
                'id'
            )
        )
    }, [serviceSelected, type])

    const columns = [
        {
            className: '_dropshipping-list-service-name text-wrap',
            title: t('product:label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            width: '50%',
            render: (text, record) => (text ? text : ''),
        },
        {
            className: '_dropshipping-list-estimated_costs text-left',
            title: (
                <p className="flex align-items-center">
                    {t('product:label.estimated_costs')}
                    <Tooltip title={t('message.cost_depends_on_size_service_used')}>
                        <QuestionCircleOutlined className="ml-1 cursor-pointer" />
                    </Tooltip>
                </p>
            ),
            dataIndex: 'service_price',
            key: 'service_price',
            width: '50%',
            render: (text, record) => {
                // const defaultPrice = find(text, ['is_default', true])
                return <p>{text ? (!isEmpty(currency) ? formatCurrency(text?.price || 0, currency) : '') : '---'}</p>
            },
        },
    ]

    const rowSelection = {
        selectedRowKeys: checkedList,

        onChange: (selectedRowKeys, selectedRows) => {
            // setCheckedList(selectedRowKeys)
            const listId = map(data, 'service.id')
            const otherServiceId = map(
                filter(serviceSelected, item => !listId.includes(item.service.id)),
                'service.id'
            )

            onChange('services', [...otherServiceId, ...selectedRowKeys])
        },

        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required')
            const id = get(record, 'service.id')

            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox
                                checked={disabledRow}
                                disabled={disabledRow}
                            />
                        </Tooltip>
                    ) : (
                        <Checkbox
                            checked={checked}
                            onChange={() => handleChangeCheckbox(checked, record, id)}
                        />
                    )}
                </>
            )
        },
    }

    const handleChangeCheckbox = (checked, selectedRows, id) => {
        const listId = map(serviceSelected, 'service.id')

        if (checked) {
            const newListId = listId.filter(item => item !== id)
            onChange('services', newListId)
        } else {
            onChange('services', [...listId, id])
        }
    }

    return (
        <>
            <Table
                className="product-list-edit-table pb-3"
                dataSource={data}
                rowClassName="_dropshipping-product-row"
                rowKey={record => record?.service?.id}
                columns={columns}
                loading={loading}
                pagination={false}
                rowSelection={{
                    ...rowSelection,
                }}
                summary={pageData => {
                    const serviceByType = filter(serviceSelected, item => item.service.type === type)
                    const perPrice = map(serviceByType, 'service_prices').flat()
                    const sumPrice = sumBy(perPrice, 'price')

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">{t('label.estimated_sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell className="font-weight-bold">
                                <Text>{!isEmpty(currency) ? formatCurrency(sumPrice, currency ? currency : null) : '---'}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                }}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className="_dropshipping-list-empty" /> }}
            />
        </>
    )
}

export default ServicePackEdit
