import { Button } from 'antd'
import React, { useState } from 'react'

import ExportListProduct from '@Modules/Product/components/ExportFile/ExportListProduct'
import ExportListReport from '@Modules/Product/components/ExportFile/ExportListReport'

import { t } from '@System/i18n'
import { classes, getVar } from '@System/support/helpers'

import ComboList from './ComboList'
import ProductList from './ProductList'

export default function List({
    onSearch,
    filters,
    loadingProduct,
    dataProduct,
    activeKey,
    handleChangeTab,
    dataSkuCombo,
    loadingSkuCombo,
}) {
    const tabs = [
        {
            title: t('heading.product_list'),
            key: 'product-list',
            quantity: getVar(dataProduct, 'data.pagination.total', 0),
            loading: loadingProduct,
        },
        {
            title: t('product:label.sku_combo_list'),
            key: 'combo-list',
            quantity: getVar(dataSkuCombo, 'data.pagination.total', 0),
            loading: loadingSkuCombo,
        },
    ]

    return (
        <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-4">
                    {/* <h3 className="text-fz-18">
                        <div className="d-flex justify-content-between align-items-center">
                            {t('heading.product_list')}
                            <span className="number-circle _importing-document-product-list-total">
                                {getVar(dataProduct, 'data.pagination.total', 0)}
                            </span>
                        </div>
                    </h3> */}
                    <div>
                        {tabs.map(tab => (
                            <Button
                                className={classes(`tab-list-product mr-2`, {
                                    active: tab.key === activeKey,
                                })}
                                key={tab.key}
                                onClick={() => handleChangeTab(tab.key)}
                                loading={tab.loading}
                            >
                                <span> {tab.title} </span>
                                {tab.key === activeKey && <span className="tab-list-product-quantity ml-2">{tab.quantity}</span>}
                            </Button>
                        ))}
                    </div>

                    {activeKey === 'product-list' && (
                        <div>
                            <ExportListReport />
                            <ExportListProduct filters={filters} />
                            
                        </div>
                    )}
                </div>
            </div>

            {activeKey === 'product-list' && (
                <ProductList
                    filters={filters}
                    loading={loadingProduct}
                    onSearch={onSearch}
                    data={dataProduct}
                />
            )}

            {activeKey === 'combo-list' && (
                <ComboList
                    data={dataSkuCombo}
                    loading={loadingSkuCombo}
                    onSearch={onSearch}
                    filters={filters}
                />
            )}
        </div>
    )
}
