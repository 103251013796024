import { Avatar, Badge, Table, Tooltip, Typography } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import DynamicTable from '@Modules/App/DynamicTable'
import EmptyTableData from '@Modules/App/Table/EmptyTableData'
import { auth } from '@Modules/Auth/services'
import api from '@Modules/Product/services/api'
import { PRODUCT_EVENT, PRODUCT_STATUS_COLORS } from '@Modules/Product/services/constants'

import { events } from '@System/events'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import CustomizedPagination from '../../../App/CustomizedPagination'

const { Text, Paragraph } = Typography

export default function ComboList({ onSearch, filters, loading, rowSelection, selectedRowKeys, data }) {
    const user = auth.user()
    const currency = getVar(user, 'currency', {})

    const [pagination, setPagination] = useState({})
    const [products, setProducts] = useState([])

    const [loadingSwitch, setLoadingSwitch] = useState(false)

    useEffect(() => {
        setPagination(getVar(data, 'data.pagination', {}))
        setProducts(getVar(data, 'data.data', []))
    }, [data])

    const onChangeStatus = (skuId, status) => {
        setLoadingSwitch(true)
        api.updateStatusSellProduct(skuId, { status })
            .then(res => {
                notification.success(t('product:message.update_status_success'))

                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_LIST, {})
            })
            .catch(() => {
                notification.error(t('product:message.update_status_failed'))
            })
            .finally(() => {
                setLoadingSwitch(false)
            })
    }

    const columns = [
        // {
        //     className: '_product-list-status',
        //     title: t('label.status'),
        //     dataIndex: ['sku_combo', 'status'],
        //     key: 'status',
        //     width: '140px',
        //     render: (status, record) => {
        //         const skuId = get(record, 'sku.product_id')
        //         const productSystem = get(record, 'sku.merchant_id') === 0

        //         return (
        //             <Tooltip title={productSystem && t('product:message.product_sys_not_stop_sell')}>
        //                 <Switch
        //                     checked={status === PRODUCT_STATUS.ON_SELL}
        //                     loading={loadingSwitch}
        //                     onChange={() =>
        //                         onChangeStatus(
        //                             skuId,
        //                             status === PRODUCT_STATUS.ON_SELL ? PRODUCT_STATUS.STOP_SELLING : PRODUCT_STATUS.ON_SELL
        //                         )
        //                     }
        //                     disabled={productSystem}
        //                     checkedChildren={t(`product:status.ON_SELL`)}
        //                     unCheckedChildren={t(`product:status.STOP_SELLING`)}
        //                 />
        //             </Tooltip>
        //         )
        //     },
        // },
        {
            className: '_product-list-product text-wrap',
            title: t('product:label.product'),
            dataIndex: 'name',
            key: 'product',
            width: '300px',
            disabled: true,
            render: (text, record) => {
                const images = getVar(record, 'image.0', [])
                const code = getVar(record, 'code')

                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={!isEmpty(images) ? images : ''}
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0 }}
                        />
                        <div>
                            <span>
                                <Link
                                    to={url.to('products.detail-combo', {
                                        id: record?.id,
                                    })}
                                >
                                    <strong
                                        style={{ fontWeight: 500 }}
                                        className="text-link"
                                    >
                                        {text}
                                    </strong>
                                </Link>
                            </span>
                            <div>
                                <Tooltip
                                    placement="topLeft"
                                    title={
                                        <Paragraph
                                            copyable={{
                                                text: code,
                                            }}
                                            className="ant-typography-tooltip"
                                        >
                                            {code}
                                        </Paragraph>
                                    }
                                >
                                    <Text
                                        style={{
                                            maxWidth: 220,
                                        }}
                                        ellipsis={true}
                                    >
                                        {code}
                                    </Text>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: ['skus', 'data'],
            key: 'skus',
            width: '220px',
            render: skus => {
                return (
                    <div>
                        <div className="d-flex flex-column">
                            <span className="combo-tag mb-1">{t('label.combo')}</span>
                            {skus.map(sku => (
                                <Tooltip
                                    placement="topLeft"
                                    title={
                                        <Paragraph
                                            copyable={{
                                                text: get(sku, 'sku.data.code'),
                                            }}
                                            className="ant-typography-tooltip"
                                        >
                                            {get(sku, 'sku.data.code')}
                                        </Paragraph>
                                    }
                                    className="mb-1"
                                    key={get(sku, 'sku.data.id')}
                                >
                                    <Text
                                        style={{
                                            maxWidth: 220,
                                        }}
                                        ellipsis={true}
                                    >
                                        {get(sku, 'sku.data.code')}
                                    </Text>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-product_type',
            title: t('product:label.product_type'),
            dataIndex: ['category', 'data', 'name'],
            key: 'sku_code',
            width: '220px',
            render: text => {
                return <span>{text ? text : t('noText')}</span>
            },
        },
        {
            className: '_product-list-sku-code',
            title: t('product:label.price'),
            dataIndex: 'price',
            key: 'price',
            width: '220px',
            render: price => (
                <span className="text-color-tenant font-weight-500">
                    {!isEmpty(currency) ? formatCurrency(price || 0, currency) : price}
                </span>
            ),
        },
        {
            className: '_product-list-status',
            title: t('label.status'),
            dataIndex: 'status',
            key: 'status',
            width: '140px',
            render: text =>
                text ? (
                    <Badge
                        color={PRODUCT_STATUS_COLORS[text]}
                        text={t(`product:PRODUCT_STATUS.${text}`)}
                    />
                ) : null,
        },
    ]
    return (
        <>
            <DynamicTable
                initColumns={columns}
                dataSource={products || []}
                rowKey={record => record?.id}
                className="list-prepare-table pb-3"
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                loading={loading}
                scroll={{ x: 768 }}
                locale={{
                    emptyText: (
                        <EmptyTableData
                            loading={loading}
                            message={'no_data_product'}
                            action={
                                <Link to={url.to('products.create-combo')}>
                                    <strong className="cursor-pointer pagination-customize">
                                        {t('btn.create_product_combo')}
                                    </strong>
                                </Link>
                            }
                        />
                    ),
                }}
            />
            {/* <Table
                className="product-list-table pb-3"
                dataSource={products || []}
                rowKey={record => record?.id}
                columns={columns}
                pagination={false}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                loading={loading}
                scroll={{ x: 768 }}
                locale={{
                    emptyText: (
                        <EmptyTableData
                            loading={loading}
                            message={'no_data_product'}
                            action={
                                <Link to={url.to('products.create-combo')}>
                                    <strong className="cursor-pointer pagination-customize">
                                        {t('btn.create_product_combo')}
                                    </strong>
                                </Link>
                            }
                        />
                    ),
                }}
            /> */}
            <CustomizedPagination
                pagination={pagination}
                addFilter={onSearch}
                filters={filters}
            />
        </>
    )
}
