import { SaveFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import React, { useEffect, useState } from 'react';

import { t } from '@System/i18n';

const ModalUploadImageSKU = ({
    visible,
    handleCancel,
    fileList,
    localVariations,
    setLocalVariations,
    currentKey,
    handleUpdate,
}) => {
    const [optionsImage, setOptionImage] = useState([]);
    
    const handleChangeImage = id => {
        let new_arr = localVariations.map(val => {
            if (val.key === currentKey) {
                val = { ...val, images: fileList.filter(item => item.uid === id).map(val => val.url) };
            }
            return val;
        });

        setOptionImage(new_arr);
        setLocalVariations(new_arr);
    };

    const handleSumitModal = () => {
        handleUpdate('skus', optionsImage);
        handleCancel();
    };
    return (
        <>
            {visible && (
                <Modal
                    className="_modal-search-product"
                    title={t('product:title.add_image_product')}
                    visible={visible}
                    onCancel={handleCancel}
                    width={800}
                    footer={[
                        <Button
                            className="_modal-btn-finished"
                            type={'primary'}
                            key={'ok'}
                            onClick={handleSumitModal}
                            icon={<SaveFilled />}
                        >
                            {t('btn.finished')}
                        </Button>,
                    ]}
                >
                    <div className="d-flex">
                        {fileList.length !== 0
                            ? fileList.map(val => (
                                  <div
                                      className="p-1 mr-2"
                                      style={{ width: 104, border: '1px solid #d9d9d9', cursor: 'pointer' }}
                                      onClick={() => handleChangeImage(val.uid)}
                                  >
                                      <img alt="" src={val.url} style={{ width: '100%' }} />
                                  </div>
                              ))
                            : t('label.no_data')}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ModalUploadImageSKU;
