import React, {useEffect, useState} from "react";
import {CaretLeftOutlined} from "@ant-design/icons";
import {t} from "@System/i18n";
import _ from "lodash";

const defaultActiveTab = -1;

export default function ConfigMenu({children, items, width, isDropDown, visible, onVisible}) {
    const [isMoved, setIsMoved] = useState(false);
    const [activeTab, setActiveTab] = useState(defaultActiveTab);

    useEffect(() => {
        document.addEventListener('click', _onClickOut);
    }, []);

    const isTrue = item => {
        let class_name = item.className;
        if (_.isEmpty(item.className))
            return false;

        return ["gobiz-config-menu", "gobiz-dropdown", "gobiz-dropdown--main"].some(item => class_name.search(item) > -1);
    }

    function _onClickOut(e) {
        let elements = e.path;
        if (elements && elements.length > 0 && !elements.some(isTrue) && typeof onVisible === "function")
            onVisible(false);
    }

    function _onMove(index) {
        setIsMoved(!isMoved);
        if (!isMoved)
            setActiveTab(index);
        else
            setTimeout(() => setActiveTab(index), 300);
    }

    function _onHide(e) {
        if (e.target.tagName === "A" && typeof onVisible === "function")
            onVisible(false);
    }

    return (
        <div className={`gobiz-config-menu ${isDropDown ? "is-drop-down" : ""}`}>
            {children}
            <div
                onClick={_onHide}
                className={`gobiz-dropdown ${visible ? "gobiz-dropdown--show" : "gobiz-dropdown--hide"}`}
                style={{width: width ? width : 220}}
            >
                <div className={`gobiz-dropdown--container ${isMoved ? "gobiz-dropdown--container--moved" : ""}`}>
                    <div className="gobiz-dropdown--main pt-1 pb-1">
                        <ul>
                            {
                                items && items.length > 0 &&
                                items.map((item, index) => {
                                    return item.componentTab ? (
                                        <li key={index}><span className="cursor-pointer" onClick={() => _onMove(index)}>{item.item}</span></li>
                                    ) : (
                                        <li key={index}>{item.item}</li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                    {
                        items && items.length > 0 &&
                        items.map((item, index) => {
                            if (!item.componentTab)
                                return null;

                            return (
                                <div key={index} className={`gobiz-dropdown--item pt-1 pb-1 ${activeTab === index ? "gobiz-dropdown--active" : ""}`}>
                                    <p><span className="cursor-pointer d-block" onClick={() => _onMove(defaultActiveTab)}><CaretLeftOutlined /> {t("btn.back")}</span></p>
                                    {item.componentTab}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}
