import React from 'react';
import ReactDOM from 'react-dom';

import { tracker } from '@Config/tracker';

import './system/i18n';
import RoutingProvider from './system/routing/RoutingProvider';

import './resources/scss/main.scss';
import getTheme, {getEnv} from "@System/support/helpers";
import i18next from 'i18next';

let theme = getTheme();
if (theme && theme?.code === "vela") {
    document.documentElement.style.setProperty('--background-color', '#E8262D');
    document.documentElement.style.setProperty('--text-active', '#FF4559');
    document.documentElement.style.setProperty('--text-hover', '#C21515');
    document.documentElement.style.setProperty('--menu-active', '#E8262D');
    document.documentElement.style.setProperty('--menu-hover', '#C21515');
    document.documentElement.style.setProperty('--btn-active', '#E8262D');
    document.documentElement.style.setProperty('--btn-hover-color', '#C21515');
    document.documentElement.style.setProperty('--link-color', '#FF4559');
    document.documentElement.style.setProperty('--link-hover-color', '#C21515');
    document.documentElement.style.setProperty('--bg-color-light', '#ffd6da87');

    document.title = theme?.title;
    document.getElementById('favicon').setAttribute('href', process.env.PUBLIC_URL + '/favicon-vela.ico');
} else {
    document.title = 'Fulfillment by Ubox';
    document.getElementById('favicon').setAttribute('href', process.env.PUBLIC_URL + '/favicon.ico');
}

if (getEnv('REACT_APP_TRACKING')) {
    tracker.start();

    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'G-4TW4BJX2T0');
    if (window.location.hostname === 'admin.vela.asia' || window.location.hostname === 'pos.vela.asia') {
        gtag('config', 'G-JTEBRPVDD0');
    }
}
//Detect add html attribute lang
document.documentElement.lang = i18next.language;




ReactDOM.render(<RoutingProvider />, document.getElementById('root'));
