import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import api from '../services/api';
import { convertBlobDataToExcel } from "@System/support/helpers";
import {t} from "@System/i18n";
import notification from "@System/notification";

const useExportListOrderQuery = params => {
    return useQuery(['ExportListOrderQuery', params], () => api.exportListOrder(params), {
        retry: false,
        enabled: false,
        onError: err => {
            processResponseError(err);
        },
        onSuccess: res => {
            convertBlobDataToExcel(res.data, 'order-list');
            notification.success(t('message.export_excel_success'));
        },
    });
};

export default useExportListOrderQuery;
