import { api as ApiSystem } from '@System/api';

const api = {
    getOrders: params => ApiSystem.get('/merchant/orders', { params, singleRequest: true, loading: false  }),
    createOrdersByUploadFile: data => ApiSystem.post('/merchant/orders/import', data),
    getOrderDetail: orderId => ApiSystem.get(`/merchant/orders/${orderId}`, { singleRequest: true, loading: false }),
    getPurchasingOrderDetail: id =>
        ApiSystem.get(`merchant/purchasing-orders/${id}`, { singleRequest: true, loading: false }),
    getPurchasingOrders: params =>
        ApiSystem.get('/merchant/purchasing-orders', { params, singleRequest: true, loading: false }),
    cancelOrder: orderId => ApiSystem.put(`/merchant/orders/${orderId}/cancel`),
    getHistoryOrder: id => ApiSystem.get(`/merchant/orders/${id}/logs`, { singleRequest: true, loading: false }),
    createOrder: data => ApiSystem.post(`merchant/orders`, data, { idempotency : true }),
    exportListOrder: params =>
        ApiSystem.get(`merchant/orders/export`, { params, singleRequest: true, loading: false, responseType: 'blob' }),
    uploadFreightBill: data => ApiSystem.post('/merchant/orders/import-freight-bill', data),
    importBashOrder: data => ApiSystem.post('/merchant/orders/import-bash-order', data),
    checkingBeforeCreateOrder: data => ApiSystem.post('/merchant/orders/checking-before-create', data),
    updateOrder: (data, id) => ApiSystem.post(`/merchant/orders/${id}`, data),
};

export default api;
