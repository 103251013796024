import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import map from 'lodash/map';
import React, { useState } from 'react';

import { t } from '@System/i18n';

import UploadMulti from './UploadMuti';

const fileType = ['image/jpeg', 'image/png'];

function UpdateFile(props) {
    const { maxFileSize, form, setFileList } = props;
    const [images, setImages] = useState([]);

    return (
        <div className="d-flex text-wrap align-items-left">
            <div style={{ cursor: 'pointer' }}>
                <Form.Item className="mb-0">
                    <UploadMulti
                        className="_product-images-upload"
                        listType="picture-card"
                        fileType={fileType}
                        maxFileSize={maxFileSize}
                        showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
                        fileList={images}
                        setImages={setImages}
                        setFileList={setFileList}
                        form={form}
                    >
                        <div className="_product-image-upload">
                            <CloudUploadOutlined />
                            <div className="mt-3">{t('label.upload_image')}</div>
                        </div>
                    </UploadMulti>
                </Form.Item>
            </div>
            {/* <Upload
                className="_product-detail-list-product"
                listType="picture-card"
                fileList={images}
                onRemove={handleRemoveImage}
            /> */}
        </div>
    );
}

export default UpdateFile;
