import { useQuery } from 'react-query';

import api from '@Modules/Order/services/api';

import processResponseError from '@System/api/processResponseError';

const useDetailOrderQuery = id =>
    useQuery(['detail-order', id], () => api.getOrderDetail(id), {
        retry: false,
        enabled: !!id,
        onError: err => {
            processResponseError(err);
        },
    });

export default useDetailOrderQuery;
