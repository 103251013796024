import { Modal, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { TRANSACTION_STATUS, TRANSACTION_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';
import { Link } from '@System/routing';
import { formatCurrency } from '@System/support/numberFormat';

const ModalTransactionDetail = ({ purchaseUnits, currency, status }) => {
    const [type, setType] = useState();

    useEffect(() => {
        const refactorData = () => {
            let splitType = get(purchaseUnits, '0.orderId') && get(purchaseUnits, '0.orderId').split('-')[0];
            setType(splitType);
        };

        refactorData();
    }, [purchaseUnits]);
    const columns = [
        {
            className: '_finances-amount',
            title: type === 'purchasing_package' ? t('product:label.package_code') : t('order:label.order_code'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let splitCode = get(record, 'orderId') && get(record, 'orderId').split('-')[1];
                let splitType = get(record, 'orderId') && get(record, 'orderId').split('-')[0];
                let toLink = splitType === 'order' ? 'orders' : 'import-package';

                return (
                    <span>
                        <Link params={{ id: splitCode }} to={`${toLink}.detail`} isNewTab>
                            <strong style={{ fontWeight: 500 }}>{`#${text}`}</strong>
                        </Link>
                    </span>
                );
            },
        },
        {
            className: '_finances-collect-cod',
            title: t(`finance:TRANSACTION_STATUS.${TRANSACTION_STATUS[status]}`),
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                let status = get(record, 'customType');
                return text.toString() && text !== 0 ? (
                    !isEmpty(currency) ? (
                        <p style={{ color: TRANSACTION_STATUS_COLORS[status] }}>
                            {text > 0 ? `+${formatCurrency(text, currency)}` : formatCurrency(text, currency)}
                        </p>
                    ) : (
                        text
                    )
                ) : (
                    ''
                );
            },
        },
    ];
    return (
        <Table
            className="transactions-list-table"
            dataSource={purchaseUnits}
            rowKey={record => record?.id}
            columns={columns}
            pagination={false}
            locale={{
                emptyText: <p>{t(`message.no_data_order`)}</p>,
            }}
            scroll={{ x: true }}
        />
    );
};

export default ModalTransactionDetail;
