import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Spin } from 'antd'
import { flatten, isEmpty, map, uniq } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import Loading from '@Modules/App/Loading'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useProductDetailQuery from '@Modules/Product/Hooks/useProductDetailQuery'
import ListServicePrice from '@Modules/Product/components/Create/ListServicePrice'
import ProductImages from '@Modules/Product/components/Create/ProductImages'
import ProductInfo from '@Modules/Product/components/Create/ProductInfo'
import ServicePack from '@Modules/Product/components/Create/ServicePack'
import TransportServiceInfo from '@Modules/Product/components/Create/TransportServiceInfo'
import api from '@Modules/Product/services/api'
import { SERVICES_TYPE } from '@Modules/Services/services/constants'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

function ProductCreate(props) {
    const { match } = props
    const productId = match.params.id
    const [product, setProduct] = useState([])

    const [fileList, setFileList] = useState([])
    const { error, isError, isLoading, data } = useProductDetailQuery(productId)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const [serviceList, setServiceList] = useState([])
    const [serviceListRequired, setServiceListRequired] = useState([])
    const [servicePacks, setServicePacks] = useState({})
    const [loadingPacks, setLoadingPacks] = useState(true)

    useEffect(() => {
        setNavigator(t('title.create_product'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.create_product'),
            },
        ])
        setDocumentTitle(t('title.create_product'))
    }, [])

    useLayoutEffect(() => {
        const getServicePack = async () => {
            setLoadingPacks(true)
            try {
                const res = await api.getServicePack()
                setServicePacks(res.data)

                if (!isEmpty(res.data?.service_pack_prices)) {
                    const serviceAll = []

                    res.data?.service_pack_prices.forEach(item => {
                        serviceAll.push(item.service_pack_prices)
                    })
                    setServiceList(map(flatten(serviceAll), 'service.id'))
                }
            } catch (error) {
                processResponseError(error)
            } finally {
                setLoadingPacks(false)
            }
        }
        getServicePack()
    }, [])

    useEffect(() => {
        setProduct(data?.data?.product)
    }, [data])

    if (isError) {
        const status = error.response.status
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    function handleCreate() {
        let formData = new FormData()
        let arr = form.getFieldsValue(true)
        const serviceData = [...serviceList, ...serviceListRequired]

        form.validateFields().then(values => {

            if(isEmpty(serviceData)){
                notification.error(t('order:message.service_group_missing'))
                return;
            }

            for (let key in arr) {
                formData.append(key, arr[key])
            }
            formData.delete('files')
            formData.delete('services')

            for (let i of uniq(serviceData)) {
                formData.append('services[]', i)
            }

            if (form.getFieldValue('files')) {
                for (let i of form.getFieldValue('files')) {
                    formData.append('files[]', i)
                }
            }
            setLoading(true)
            api.createProductManual(formData)
                .then(res => {
                    notification.success(t('message.create_success', { attribute: t('product:label.product') }))
                    url.redirectTo('products.list')
                })
                .catch(err => {
                    const { response } = err
                    const code = getVar(response, 'data.data.code', '')
                    const serviceGroupMissing = getVar(response, 'data.data.service_groups', '')

                    if (Object.keys(code)[0] === 'already_exist') {
                        notification.error(t('errorMessages.already_exist', { attribute: t('product:label.code') }))
                    } else if (serviceGroupMissing) {
                        notification.error(t('order:message.service_group_missing'))
                    } else {
                        notification.error(t('message.create_failed', { attribute: t('product:label.product') }))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const handleChange = (name, value) => {
        if (name !== 'services') {
            form.setFieldsValue({
                [name]: value,
            })
        }
    }

    return (
        <Form form={form}>
            <div className="d-flex justify-content-between mb-2" />
            <div className="site-content">
                <Spin spinning={isLoading}>
                    <div className="d-flex justify-content-between mb-2">
                        <div>
                            <Link
                                className="_product-detail-back mb-4"
                                to={url.to('products.list')}
                            >
                                <ArrowLeftOutlined /> {t('btn.back')}
                            </Link>
                            <h3 className="text-fz-18 mt-1 mb-2">{t('breadcrumb.create_product')}</h3>
                        </div>
                        <Button
                            loading={loading}
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={handleCreate}
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                        >
                            {t('btn.save_product')}
                        </Button>
                    </div>
                    <div className="bg-light-gray">
                        <div className="bg-white rounded-12 p-4">
                            <div className="_product-edit-product-info">
                                <h3 className="mb-3">{t('title.product_info')}</h3>
                                <ProductInfo product={product} />
                                <div className="d-flex align-items-center">
                                    <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                                    <span className="ml-2 number-circle _importing-document-product-list-total">
                                        {fileList.length}
                                    </span>
                                    <i className="ml-2">{t('product:description.picture')}</i>
                                </div>
                            </div>
                            <div className="mt-4 _product-edit-picture">
                                <ProductImages
                                    name={t('product:label.image')}
                                    accept="image/jpeg, image/png"
                                    maxFileSize={5}
                                    multiple={true}
                                    productId={productId}
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    form={form}
                                />
                            </div>
                        </div>
                        <Row
                            gutter={16}
                            className="mt-4"
                        >
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                lg={{ span: 24 }}
                            >
                                <div className="bg-white rounded-12 p-4 _product-edit-transporting-service mb-4">
                                    <div className="d-flex">
                                        <h3 className="">{`${t('title.transporting_service')}`}</h3>
                                    </div>
                                    <TransportServiceInfo onChange={handleChange} />
                                </div>
                            </Col>

                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                lg={{ span: 24 }}
                            >
                                {!isEmpty(servicePacks.service_pack) ? (
                                    servicePacks?.service_pack_prices?.map(
                                        item =>
                                            item.type !== 'EXTENT' && (
                                                <div
                                                    className="bg-white rounded-12  p-4 mb-4_product-edit-packing-service mb-4"
                                                    key={item.type}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <h3>{t(`order:SERVICE_TYPE.${item.type}`)}</h3>
                                                    </div>

                                                    <ServicePack
                                                        type={item.type}
                                                        setServiceList={setServiceList}
                                                        serviceSelected={serviceList}
                                                        serviceListRequired={serviceListRequired}
                                                        setServiceListRequired={setServiceListRequired}
                                                        data={item.service_pack_prices}
                                                        loading={loadingPacks}
                                                    />
                                                </div>
                                            )
                                    )
                                ) : (
                                   !loadingPacks &&  <>
                                        <div className="bg-white rounded-12  p-4 mb-4_product-edit-packing-service mb-4">
                                            <div className="d-flex justify-content-between">
                                                <h3>{`${t('title.warehouse_service')}`}</h3>
                                            </div>

                                            <ListServicePrice
                                                type={SERVICES_TYPE.IMPORT}
                                                setServiceList={setServiceList}
                                                serviceSelected={serviceList}
                                                serviceListRequired={serviceListRequired}
                                                setServiceListRequired={setServiceListRequired}
                                            />
                                        </div>
                                        <div className="bg-white rounded-12  p-4  _product-edit-packing-service mb-4">
                                            <div className="d-flex justify-content-between">
                                                <h3>{`${t('title.export_service')}`}</h3>
                                            </div>
                                            <ListServicePrice
                                                type={SERVICES_TYPE.EXPORT}
                                                setServiceList={setServiceList}
                                                serviceSelected={serviceList}
                                                serviceListRequired={serviceListRequired}
                                                setServiceListRequired={setServiceListRequired}
                                            />
                                        </div>
                                        <div className="bg-white rounded-12  p-4  _product-edit-storage-service mb-4">
                                            <div className="d-flex justify-content-between">
                                                <h3>{`${t('title.storage_service')}`}</h3>
                                            </div>
                                            <ListServicePrice
                                                type={SERVICES_TYPE.STORAGE}
                                                setServiceList={setServiceList}
                                                serviceSelected={serviceList}
                                                serviceListRequired={serviceListRequired}
                                                setServiceListRequired={setServiceListRequired}
                                            />
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="text-right"
                            loading={loading}
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={handleCreate}
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                        >
                            {t('btn.save_product')}
                        </Button>
                    </div>
                </Spin>
            </div>
        </Form>
    )
}
export default ProductCreate
