import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Table } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import api from '@Modules/Dropshipping/services/api';
import { PRODUCT_EVENT, SERVICES_PRICE_COLORS } from '@Modules/Dropshipping/services/constants';

import processResponseError from '@System/api/processResponseError';
import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { formatCurrency } from '@System/support/numberFormat';

const ModalPriceDetail = ({ id, currency, productActiveCurrent, refetchHistory }) => {
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [productActivePrice, setProductActivePrice] = useState([]);
    const [productPriceDetail, setProductPriceDetail] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await api.getDropshippingDetailOnePrice(id);
                setProductActivePrice(get(res, 'data.product_price'));
                setProductPriceDetail(get(res, 'data.price_details'));
            } catch (error) {
                processResponseError(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [id]);

    const ColumnsWithType =
        productActivePrice?.type === 'COMBO'
            ? {
                  className: '_quote-create-service-box-table-combo',
                  title: t('product:label.combo'),
                  dataIndex: 'combo',
                  key: 'combo',
              }
            : {
                  className: '_quote-create-table-varians',
                  title: t('product:label.sku'),
                  dataIndex: ['sku', 'code'],
                  key: 'sku_id',
              };

    let columns = [
        ColumnsWithType,
        {
            className: '_quote-create-service-box-table-cost_price text-right',
            title: t('product:label.cost_price'),
            dataIndex: 'cost_price',
            key: 'cost_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-packing_service text-right',
            title: t('product:label.service_packing_price'),
            dataIndex: 'service_packing_price',
            key: 'service_packing_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-transport_service text-right',
            title: t('product:label.service_shipping_price'),
            dataIndex: 'service_shipping_price',
            key: 'service_shipping_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
        {
            className: '_quote-create-service-box-table-sum text-right',
            title: t('product:label.total_price'),
            dataIndex: 'total_price',
            key: 'total_price',
            render: text => (text ? formatCurrency(text, currency) : formatCurrency('0', currency)),
        },
    ];
    const confirmModal = name => {
        Modal.confirm({
            title:
                productActiveCurrent && productActiveCurrent.status === 'ACTIVE'
                    ? t('product:message.confirm_change_status_price', { attribute1: id, attribute2: productActiveCurrent.id })
                    : t('product:label.confirm_status_quotation'),
            icon: <ExclamationCircleOutlined />,
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            okButtonProps: { className: '_btn-accept_modal' },
            onOk: () => handleChangeStatusQuotation(productActivePrice?.id),
        });
    };

    const handleChangeStatusQuotation = productPriceId => {
        setLoadingStatus(true)
        api.changeStatusQuotation(productPriceId)
            .then(res => {
                Modal.destroyAll();
                refetchHistory();
                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_DETAIL_DROPSHIP, {});
                notification.success(t('product:message.confirm_accept_quotation_success'));
            })
            .catch(err => {
                notification.error(t('product:message.confirm_accept_quotation_error'));
            }).finally(()=>{
                setLoadingStatus(false)
            });
    };

    return (
        <div className="bg-white rounded-12  p-4 mb-4 box-dropship-services" style={{ border: '1px solid #f4f4f4' }}>
            <div className="d-flex justify-content-between ">
                <div className="d-flex">
                    <p className="font-weight-500 mr-2">{productActivePrice && '#' + productActivePrice.id}</p>
                    <Badge
                        color={SERVICES_PRICE_COLORS[productActivePrice?.status]}
                        className="_dropship-service-product-active_status"
                        text={t(`product:SERVICES_PRICE_STATUS.${productActivePrice?.status}`)}
                    />
                </div>
                {productActivePrice?.status === 'WAITING_CONFIRM' && (
                    <Button type="primary" onClick={confirmModal} loading={loadingStatus}>
                        {t('product:btn.confirm_quotation')}
                    </Button>
                )}
            </div>
            <Table
                className="_dropship-service-list_new-table-service-box pb-3 mt-4"
                rowKey={record => record?.id}
                columns={columns}
                dataSource={productPriceDetail}
                scroll={{ x: true }}
                pagination={false}
                loading={loading}
                locale={{ emptyText: <TableEmpty className="_product-list-empty" /> }}
            />
        </div>
    );
};

export default ModalPriceDetail;
