import { SyncOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React from 'react';

import useSyncProductMutation from '@Modules/Setting/Hooks/useSyncProductMutation';

import { t } from '@System/i18n';

function SyncProductsByChannel({ className, storeId }) {
    const { isLoading, mutate: syncProduct } = useSyncProductMutation({
        notifyWhenError: false,
        notifyWhenSuccess: false,
        onSuccess: () => {
            notification.success({ message: t('setting:sales_channel.message.sync_product.success'), duration: 6 });
        },
        onError: () => {
            notification.error({ message: t('setting:sales_channel.message.sync_product.failed'), duration: 6 });
        },
    });

    return (
        <Button
            icon={<SyncOutlined />}
            disabled={isLoading}
            type="primary"
            ghost
            loading={isLoading}
            className={`${className}`}
            onClick={syncProduct.bind(this, storeId)}
        >
            {t('setting:sales_channel.btn.sync_product')}
        </Button>
    );
}
export default SyncProductsByChannel;
