import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import { IChannelResult, IStores } from '@Modules/Setting/Interfaces';
import useChannelOAuthUrlQuery from '@Modules/Setting/components/SalesChannel/useChannelOAuthUrlQuery';


import { t } from '@System/i18n';
import notification from '@System/notification';
import { CHANNEL } from '@Modules/Setting/services/constants';

interface IProps {
    store: IStores;
    onSuccess: () => void;
}

const ReConnection: React.FC<IProps> = ({ store, onSuccess }) => {
    const { marketplace_code, warehouse_id } = store;

    const { isFetching: loadingOAuthUrl, refetch: getOAuthUrl } = useChannelOAuthUrlQuery(
        marketplace_code,
        { channel: marketplace_code, warehouse_id },
        {
            onSuccess: ({ data }: { data: IChannelResult }) => {
                if (
                    marketplace_code === CHANNEL.SHOPEE ||
                    marketplace_code === CHANNEL.LAZADA ||
                    marketplace_code === CHANNEL.TIKI ||
                    marketplace_code === CHANNEL.TIKTOKSHOP
                ) {
                    window.location.href = data?.url;
                } else {
                    if (data.code === 200) {
                        notification.success(t('setting:sales_channel.message.create_account_success'));
                        onSuccess();
                    } else {
                        notification.error(data.message);
                    }
                }
            },
        }
    );

    return (
        <Button
            disabled={loadingOAuthUrl}
            loading={loadingOAuthUrl}
            onClick={() => getOAuthUrl()}
            icon={<SyncOutlined />}
        >
            {t('setting:sales_channel.btn.reconnect')}
        </Button>
    );
};

export default ReConnection;
