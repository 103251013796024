import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'

import { t } from '@System/i18n'

import { EVENTS } from '../../../layouts/constants'
import { events } from '../../../system/events'
import Navigator from '../Navigator'
import UserInfo from './UserInfo'
import getTheme from '@System/support/helpers'

const { Header } = Layout

function PageHeader({ _onExpand, isShown, setIsShown }) {
    const [data, setData] = useState({})
    const theme = getTheme();
    useEffect(() => {
        return events.listen(EVENTS.HEADER_BREADCRUMB, (event, payload) => setData(payload))
    }, [])

    return (
        <Header className="page-header box-shadow">
            <div className="d-flex justify-content-between">
                <div className="align-items-center justify-content-center d-none d-md-flex">
                    {/*<h2>{data.title || ""}</h2>*/}
                    <Navigator data={data.navigator} />
                </div>

                <div
                    className="menu-mobile-btn d-md-none"
                    onClick={_onExpand}
                >
                    <span></span>
                </div>

                <UserInfo
                    isShown={isShown}
                    setIsShown={setIsShown}
                />
            </div>

           {theme && theme?.code === "vela" ? null : <p className="text-warning-balance text-center">{t('message.warning_balance_user')}</p>}
        </Header>
    )
}

export default PageHeader
