import ImportingOrderImg from '@/resources/img/create_importing_order_tutorial.svg';
import CreateOrderImg from '@/resources/img/create_order_tutorial.svg';
import ImportProductImg from '@/resources/img/import_product_tutorial.svg';

import { t } from '@System/i18n';

export const PRODUCT_STATUS = {
    DRAFT: 'DRAFT',
    ON_SELL: 'ON_SELL',
    STOP_SELLING: 'STOP_SELLING',
};

export const PRODUCT_STATUS_COLORS = {
    ON_SELL: '#06d087',
    DRAFT: '#8E101513',
    STOP_SELLING: '#bdbdbd',
};

export const PRODUCT_EVENT = {
    RELOAD_PRODUCT_LIST: 'RELOAD_PRODUCT_LIST',
};

export const ONBOARD_STEP = [
    {
        key: 'product_total',
        name: 'import_product',
        img: ImportProductImg,
        link: 'products.list',
        tutorial_link: 'https://www.youtube.com/watch?v=yAGaqlLqGqk&t=17s',
    },
    {
        key: 'purchasing_package_total',
        name: 'import_package',
        img: ImportingOrderImg,
        link: 'import-package.list',
        tutorial_link: 'https://www.youtube.com/watch?v=CcMwMqWXKs8',
    },
    {
        key: 'order_total',
        name: 'create_order',
        img: CreateOrderImg,
        link: 'orders.list',
        tutorial_link: 'https://www.youtube.com/watch?v=qTVL5VWwF6Q',
    },
];
export const DASHBOARD_STATS_CODE = ['total_delivered', 'total_returned'];
export const DASHBOARD_STATS_INFO = [
    { key: 'balance', route: 'finances.transaction', name: t('dashboard:dashboard.menu.balance_history') },
    { key: 'expected_cod', route: 'finances.statistic', name: t('dashboard:dashboard.menu.finance_order') },
    { key: 'expected_order_fee', route: 'finances.statistic', name: t('dashboard:dashboard.menu.finance_order') },
    { key: 'expected_import_fee', route: 'import-package.list', name: t('dashboard:dashboard.menu.import_package_list') },
    { key: 'cost_price', route: 'finances.statistic', name: t('dashboard:dashboard.menu.finance_order') },
];

export const DASHBOARD_CHART_CODE = {
    total_order: { bdColor: '#FCB823', bgColor: '#FCB823' },
    total_delivering: { bdColor: '#008DF9', bgColor: '#008DF9' },
    total_delivered: { bdColor: '#09B8AF', bgColor: '#09B8AF' },
};

export const CURRENCY_VND = {
    "code": "VIE",
    "precision": 0,
    "format": "{amount} VND",
    "thousands_separator": ".",
    "decimal_separator": ",",
}