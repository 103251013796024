import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { get } from 'lodash'
import React, { useState } from 'react'

import api from '@Modules/Finance/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { convertBlobDataToExcel, millisecondTime } from '@System/support/helpers'

interface IExportTransactionProps {
    filters: any
}

const ExportTransaction: React.FC<IExportTransactionProps> = ({ filters }) => {
    const [loading, setLoading] = useState(false)

    const onExport = () => {
        const createdFrom = get(filters, 'created_at[from]')
        const createdTo = get(filters, 'created_at[to]')

        const valueDate = +new Date(createdTo) - +new Date(createdFrom)
        const days = millisecondTime(valueDate).day

        if (days > 30) {
            notification.warning(t('finance:message.export_one_month'))
        } else {
            setLoading(true)
            api.exportTransactionList(filters)
                .then(res => {
                    convertBlobDataToExcel(res.data, 'order-list')
                    notification.success(t('message.export_excel_success'))
                })
                .catch(err => {
                    processResponseError(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <Button
            className="_btn-export-list-import-package"
            type="primary"
            icon={<DownloadOutlined />}
            loading={loading}
            onClick={onExport}>
            {t('btn.export_excel')}
        </Button>
    )
}

export default ExportTransaction
