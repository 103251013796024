import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';
import api from "@Modules/Dashboard/services/api";

const useOnBoardingQuery = () => {
    return useQuery(['on-boarding'], () => api.getOnBoarding(), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useOnBoardingQuery;
