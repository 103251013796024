import { MoreOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { t } from '@System/i18n'
import { url } from '@System/routing'

import ImportCreateProducts from './UploadFile/ImportCreateProducts'

interface ICreateProductButtonProps {}

const CreateProductButton: React.FC<ICreateProductButtonProps> = props => {
    const selectedAction = (
        <Menu className="menu-dropdown-action">
            <Menu.Item
                key={1}
                className=" mb-3 mb-xl-0"
            >
                <ImportCreateProducts type={'link'} />
            </Menu.Item>
            <Menu.Item
                key={2}
                className=" mb-3 mb-xl-0"
            >
                <Link to={url.to('products.create-combo')}>
                    <Button type={'link'}>{t('btn.create_product_combo')}</Button>
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="d-flex align-items-center">
            <Link to={url.to('products.create')}>
                <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    className="mr-2"
                >
                    {t('btn.create_product')}
                </Button>
            </Link>

            <Dropdown
                overlay={selectedAction}
                trigger={['click']}
                className="_dropdown-action"
            >
                <button className="ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger">
                    <MoreOutlined />
                </button>
            </Dropdown>
        </div>
    )
}

export default CreateProductButton
