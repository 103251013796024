import { Col, Form, Row, Spin, Upload, Tooltip } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import ImportServiceInfo from '@Modules/Product/components/Detail/ImportServiceInfo';
import { SERVICES_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function ProductDetail(props) {
    const { data, error, isError, isLoading, product, skuInfo } = props;

    const [services, setServices] = useState([]);

    const [serviceExport, setServiceExport] = useState([]);
    const [serviceStorage, setServiceStorage] = useState([]);
    const [serviceImport, setServiceImport] = useState([]);
    const [serviceReturnGoods, setServiceReturnGoods] = useState([]);

    const [category, setCategory] = useState({});
    const [images, setImages] = useState([]);
    const confirm_weight_volume = isEmpty(skuInfo) ? false : getVar(skuInfo, 'confirm_weight_volume', false);

    useEffect(() => {
        setNavigator(t('title.product_detail'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.product_detail'),
            },
        ]);
        setDocumentTitle(t('title.product_detail'));
    }, []);

    useEffect(() => {
        setServices(getVar(data, 'data.services', []));
        setCategory(getVar(data, 'data.category', {}));
    }, [data]);

    useEffect(() => {
        setDocumentTitle(t('title_page.product_detail', { code: getVar(product, 'code', '') }));
        setImages(refactorImage(getVar(product, 'images', [])));
    }, [product]);

    const refactorServicePrices = useCallback(() => {
        let newArrExport = [];
        let newArrImport = [];
        let newArrStorage = [];
        let newArrReturnGoods = [];

        const service = map(services, 'service_prices').flat();

        filter(services, item => {
            if (item.service.type === SERVICES_TYPE.EXPORT) {
                const itemPrices = filter(service, val => val.service_code === item.service.code);

                newArrExport.push({ service: { ...item.service }, itemPrices: { ...itemPrices[0] } });
            }
            if (item.service.type === SERVICES_TYPE.IMPORT) {
                const itemPrices = filter(service, val => val.service_code === item.service.code);
                newArrImport.push({ service: { ...item.service }, itemPrices: { ...itemPrices[0] } });
            }
            if (item.service.type === SERVICES_TYPE.STORAGE) {
                const itemPrices = filter(service, val => val.service_code === item.service.code);
                newArrStorage.push({ service: { ...item.service }, itemPrices: { ...itemPrices[0] } });
            }
            if (item.service.type === SERVICES_TYPE.IMPORTING_RETURN_GOODS) {
                const itemPrices = filter(service, val => val.service_code === item.service.code);
                newArrReturnGoods.push({ service: { ...item.service }, itemPrices: { ...itemPrices[0] } });
            }
        });

        setServiceExport(newArrExport);
        setServiceImport(newArrImport);
        setServiceStorage(newArrStorage);
        setServiceReturnGoods(newArrReturnGoods);

    }, [services]);

    useEffect(() => {
        refactorServicePrices();
    }, [refactorServicePrices]);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    function refactorImage(images) {
        const newImages = [];
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 });
        });
        return newImages;
    }
    const dataWeightVolume = confirm_weight_volume ? skuInfo : product;

    const weight = getVar(dataWeightVolume, 'weight', undefined);
    const length = getVar(dataWeightVolume, 'length', undefined);
    const width = getVar(dataWeightVolume, 'width', undefined);
    const height = getVar(dataWeightVolume, 'height', undefined);

    const service_transport = filter(services, item =>
        (item.service.type === SERVICES_TYPE.TRANSPORT)
    );

    return (
        <Spin spinning={isLoading}>
            <div className="bg-light-gray mt-1 _product-detail-info">
                <div className="bg-white rounded-12 p-4">
                    <h3 className="mb-3">{t('title.product_info')}</h3>
                    <Form.Item
                        className="_product-detail-code"
                        labelAlign={'left'}
                        labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                        wrapperCol={{ span: 12 }}
                        label={t('product:label.code')}
                    >
                        <p>{getVar(product, 'code', '')}</p>
                    </Form.Item>

                    <Form.Item
                        className="_product-detail-name"
                        labelAlign={'left'}
                        labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                        wrapperCol={{ span: 12 }}
                        label={t('product:label.name')}
                    >
                        <p>{getVar(product, 'name', '')}</p>
                    </Form.Item>

                    <Form.Item
                        className="_product-detail-category"
                        labelAlign={'left'}
                        labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                        wrapperCol={{ span: 12 }}
                        label={t('product:label.category_id')}
                    >
                        <p>{getVar(category, 'name', '')}</p>
                    </Form.Item>
                    <Form.Item
                        className="_product-detail-safety-stock"
                        labelAlign={'left'}
                        labelCol={{ xs: { span: 8 }, lg: { span: 4 } }}
                        wrapperCol={{ span: 12 }}
                        label={t('product:label.safety_stock')}
                    >
                        <p>{getVar(skuInfo, 'safety_stock', '')}</p>
                    </Form.Item>

                    <div className="d-flex align-items-center">
                        <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                        <span className="ml-2 number-circle _product-detail-picture-total">{images.length}</span>
                        <i className="ml-2">{t('product:description.picture')}</i>
                    </div>
                    <div className="mt-4">
                        <Upload
                            className="_product-detail-list-product"
                            listType="picture-card"
                            fileList={images}
                            disabled={true}
                        />
                    </div>
                </div>
                <Row gutter={16} className="mt-4">
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="bg-white rounded-12 p-4  mb-4">
                            <div className="d-flex">
                                <h3 className="">{`${t('title.transporting_service')}`}</h3>
                            </div>
                            <div className="mt-4">
                                <Form.Item
                                    className="_product-detail-transporting-service"
                                    labelAlign={'left'}
                                    label={t('product:label.service')}
                                >
                                    {service_transport.length > 0 ? (
                                        map(service_transport, item => {
                                            return <p className="ml-1">{item.service.name}</p>;
                                        })
                                    ) : (
                                        <p className="ml-1">{t(`message.no_data_service`)}</p>
                                    )}
                                </Form.Item>

                                <Form.Item
                                    className="_product-detail-weight"
                                    labelAlign={'left'}
                                    label={
                                        <>
                                            {confirm_weight_volume && (
                                                <Tooltip title={t('product:message.confirmed_weight_volume')} className="mr-2">
                                                    <CheckCircleFilled style={{ color: 'green' }} />
                                                </Tooltip>
                                            )}
                                            {t('product:label.weight')}
                                        </>
                                    }
                                    name="weight"
                                >
                                    <p className="ml-1">{weight ? weight * 1000 : ''}</p>
                                </Form.Item>

                                <Form.Item
                                    labelAlign={'left'}
                                    label={
                                        <>
                                            {confirm_weight_volume && (
                                                <Tooltip title={t('product:message.confirmed_weight_volume')} className="mr-2">
                                                    <CheckCircleFilled style={{ color: 'green' }} />
                                                </Tooltip>
                                            )}{' '}
                                            {`${t('product:label.dimension')} (mm)`}
                                        </>
                                    }
                                >
                                    <Row>
                                        <Col span={8}>
                                            <Form.Item className="_product-detail-length" label={t('product:label.length')}>
                                                <p className="ml-1">{length ? length * 1000 : ''}</p>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item className="_product-detail-width pl-2" label={t('product:label.width')}>
                                                <p className="ml-1">{width ? width * 1000 : ''}</p>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item className="pl-2 _product-detail-height" label={t('product:label.height')}>
                                                <p className="ml-1">{height ? height * 1000 : ''}</p>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="bg-white rounded-12  p-4 mb-4 box-import-services">
                            <div className="d-flex justify-content-between mb-4 title-import-services">
                                <h3>{`${t('title.warehouse_service')}`}</h3>
                            </div>
                            <div className="_product-detail-list-import-service list-import-services">
                                <ImportServiceInfo dataSource={serviceImport} />
                            </div>
                        </div>
                        <div className="bg-white rounded-12 p-4 mb-4">
                            <div className="d-flex justify-content-between mb-4">
                                <h3>{`${t('title.export_service')}`}</h3>
                            </div>
                            <div className="_product-detail-list-packing-service">
                                <ImportServiceInfo dataSource={serviceExport} />
                            </div>
                        </div>
                        <div className="bg-white rounded-12 p-4 mb-4">
                            <div className="d-flex justify-content-between mb-4">
                                <h3>{`${t('title.storage_service')}`}</h3>
                            </div>
                            <div className="_product-detail-list-storage-service">
                                <ImportServiceInfo dataSource={serviceStorage} />
                            </div>
                        </div>
                        <div className="bg-white rounded-12 p-4">
                            <div className="d-flex justify-content-between mb-4">
                                <h3>{`${t('SERVICE_TYPE.IMPORTING_RETURN_GOODS')}`}</h3>
                            </div>
                            <div className="_product-detail-list-return-service">
                                <ImportServiceInfo dataSource={serviceReturnGoods} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
}
export default ProductDetail;