import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import ProductImages from '@Modules/Product/components/Create/ProductImages'
import ComboInfo from '@Modules/Product/components/CreateCombo/ComboInfo'
import api from '@Modules/Product/services/api'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'

import ChildSkuList from '../../components/CreateCombo/ChildSkuList'

interface IProductCreateComboProps {}

const ProductCreateCombo: React.FC<IProductCreateComboProps> = props => {
    const [form] = Form.useForm()
    const [skus, setSkus] = useState<any[]>([])
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setNavigator(t('title.create_product'), [
            {
                name: t('title.product_list'),
                route: 'products.list',
            },
            {
                name: t('title.create_product_combo'),
            },
        ])
        setDocumentTitle(t('title.create_product_combo'))
    }, [])

    const handleCreateCombo = () => {
        if (isEmpty(skus)) {
            return notification.error(t('product:message.sku_child_required'))
        }

        form.validateFields().then(values => {
            let formData = new FormData()

            Object.entries(values).forEach(([key, value]: any) => {
                if (value) {
                    formData.append(key, value)
                }
            })

            formData.delete('files')
            formData.delete('skus')

            if (form.getFieldValue('files')) {
                for (let i of form.getFieldValue('files')) {
                    formData.append('files[]', i)
                }
            }

            if (form.getFieldValue('skus')) {
                formData.append('skus', JSON.stringify(form.getFieldValue('skus')))
            }

            setLoading(true)

            api.createSkuCombo(formData)
                .then(res => {
                    notification.success(t('message.create_success', { attribute: t('product:label.product') }))
                    url.redirectTo('products.list')
                })
                .catch(err => {
                    const { response } = err
                    const code = get(response, 'data.data.code', '')
                    const serviceGroupMissing = get(response, 'data.data.service_groups', '')

                    if (Object.keys(code)[0] === 'already_exist') {
                        notification.error(t('errorMessages.already_exist', { attribute: t('product:label.code') }))
                    } else if (serviceGroupMissing) {
                        notification.error(t('order:message.service_group_missing'))
                    } else {
                        notification.error(t('message.create_failed', { attribute: t('product:label.product') }))
                    }
                })
                .finally(() => setLoading(false))
        })
    }

    return (
        <Form form={form}>
            <div className="site-content">
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link
                            className="_product-detail-back mb-4"
                            to={url.to('products.list')}
                        >
                            <ArrowLeftOutlined /> {t('btn.back')}
                        </Link>
                        <h3 className="text-fz-18 mt-1 mb-2">{t('breadcrumb.create_product')}</h3>
                    </div>
                    <Button
                        loading={loading}
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={handleCreateCombo}
                        disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                    >
                        {t('btn.save_product')}
                    </Button>
                </div>

                <div className="bg-white rounded-12 p-4 box-shadow">
                    <div className="_product-edit-product-info">
                        <h3 className="mb-3">{t('title.product_info')}</h3>
                        <ComboInfo/>
                        <div className="d-flex align-items-center">
                            <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                            <span className="ml-2 number-circle _importing-document-product-list-total">{files.length}</span>
                            <i className="ml-2">{t('product:description.picture')}</i>
                        </div>
                    </div>
                    <div className="mt-4 _product-edit-picture">
                        <ProductImages
                            name={t('product:label.image')}
                            accept="image/jpeg, image/png"
                            maxFileSize={5}
                            multiple={true}
                            setFileList={setFiles}
                            form={form}
                        />
                    </div>
                </div>

                <ChildSkuList
                    skus={skus}
                    setSkus={setSkus}
                />
            </div>
        </Form>
    )
}

export default ProductCreateCombo
