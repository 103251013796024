import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React, { useState } from 'react';

import api from '@Modules/Product/services/api';

import processResponseError from '@System/api/processResponseError';

import { t } from '../../../system/i18n';
import { PRODUCT_STATUS } from "@Modules/Product/services/constants";

const { Option } = Select;

export default function SelectSku(props) {
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState(undefined);

    const fetchSkus = debounce(value => {
        setData([]);
        if (value && value.length >= 2) {
            setFetching(true);
            api.getProducts({name: value, paginate: 0, status: PRODUCT_STATUS.ON_SELL  })
                .then(res => {
                    const products = get(res, 'data.products', []);
                    setData(
                        products.map(item => {
                            return item.sku;
                        })
                    );
                })
                .catch(err => {
                    processResponseError(err);
                })
                .finally(() => {
                    setFetching(false);
                });
        }
    }, 500);

    function onChange(value) {
        setFetching(false);
        setValue(value);
        props.onChange(value);
    }

    return (
        <Select
            {...props}
            allowClear={true}
            showSearch
            value={value}
            placeholder={props.placeholder ? props.placeholder : t('product:placeholder.sku_name')}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={value => fetchSkus(value)}
            onChange={value => onChange(value)}
            style={{ width: '100%' }}
            dropdownClassName={"_select-sku"}
        >
            {data.map(sku => (
                <Option key={sku.id}>
                    {sku.name + (sku.code ? ' (' + (sku.code === 'default' ? t('order:label.default') : sku.code) + ')' : '')}
                </Option>
            ))}
        </Select>
    );
}
