import ServiceCategories from '@Modules/Category/services/ServiceCategories';
import { Select } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';


const { Option } = Select;

export default function SelectCategories(props) {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState();

    useEffect(() => {
        setIsLoading(true);
        ServiceCategories.list()
            .then(res => {
                setCategories(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (val)=>{
        setValue(val)
        props.onChangeValue('category_id',val)
    }

    return (
        <Select
            loading={isLoading}
            {...props}
            value={categories.length > 0 &&value ? parseInt(value) : undefined}
            showSearch
            optionFilterProp="label"
            onChange={handleChange}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {categories.length > 0 &&
                categories.map(opt => {
                    return (
                        <Option key={opt.id} value={opt.id}>
                            {opt.name}
                        </Option>
                    );
                })}
        </Select>
    );
}
