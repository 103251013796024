import { useQuery } from 'react-query';

import api from '@Modules/ImportPackage/services/api';

import processResponseError from '@System/api/processResponseError';

const useListImportPackageQuery = params => {
    return useQuery(['useListImportPackageQuery', params], () => api.getImportPackages(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListImportPackageQuery;
