import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import PackingSlipStamp from '@Modules/ImportPackage/components/StampPrint/PackingSlipPrint/PackingSlipStamp';

import { t } from '@System/i18n';
import notification from '@System/notification';

const PackingSlipPrint = ({ data, className = '', btnType = 'primary' }) => {
    const packingStampRef = useRef();

    const printStamp = useReactToPrint({
        content: () => packingStampRef.current,
    });

    const handlePrint = () => {
        if (!isEmpty(data)) {
            setTimeout(() => {
                printStamp();
            }, 1000);
        } else {
            notification.error(t('order:print_shipping_stamp.errors.ids.required'));
        }
    };
    return (
        <>
            <Button className={`_print-stamp-btn ${className}`} type={btnType} onClick={handlePrint} icon={<PrinterOutlined />}>
                {t('btn.print_packing_slip')}
            </Button>
            <div className="d-none">
                <PackingSlipStamp ref={packingStampRef} data={data} />
            </div>
        </>
    );
};

export default PackingSlipPrint;
