import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import api from '@Modules/Dropshipping/services/api';
import notification from '@System/notification';
import { t } from '@System/i18n';
import { get } from 'lodash';
import { events } from '@System/events';
import { PRODUCT_EVENT } from '@Modules/Dropshipping/services/constants';

function DropshippingService({ productId }) {
    const [loading, setLoading] = useState(false);

    const handelChangeServiceDropship = () => {
        // setServiceDropship(!serviceDropship);
        const data = { ids: [productId], status: 'WAITING_FOR_QUOTE' };
        setLoading(true)
        api.changeStatusProductDropship(data)
            .then(res => {
                events.dispatch(PRODUCT_EVENT.RELOAD_PRODUCT_DETAIL_DROPSHIP, {});
                notification.success(
                    t('message.update_success_by_attribute', {
                        attribute: t(`label.request_status`),
                    })
                );
 
            })
            .catch(err => {
                const { response } = err;
                let code = get(response, 'data.code', {});
                if (code === 'INPUT_INVALID') {
                    notification.error(t('message.no_data_product_change_status'));
                } else {
                    notification.error(t('message.update_failed_by_attribute', { attribute: t(`label.request_status`) }));
                }
            }).finally(()=>{
                setLoading(false)
            });
    };

    return (
        <>
            <div className="mt-4 mb-4">
                {t('product:message.not_info_request_price')}
            </div>
            <div className="_btn-edit">
                <Button type={'primary'} className='btn-edit-request-price' onClick={handelChangeServiceDropship} loading={loading}>
                    {t('product:btn.request_price')}
                </Button>
            </div>
        </>
    );
}

export default DropshippingService;
