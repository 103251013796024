import api from "./api";
import _ from "lodash";
import {t} from "../../../system/i18n";
import notification from '@System/notification';
import BaseServiceData from "../../App/services/BaseServiceData";

class ServiceCategories extends BaseServiceData {

    runApi() {
        return api.getCategories()
            .then(res => _.get(res, "data.categories", []))
            .catch(err => {
                notification.error(t("common:message.fetch_fail", {attribute: t("common:label.product_category").toLowerCase()}));
                return Promise.reject(err);
            });
    }
}

export default new ServiceCategories();
