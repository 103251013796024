import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Row, Spin } from 'antd';
import { isNil } from 'lodash';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Link as LinkRoute } from 'react-router-dom';
import { v4 } from 'uuid';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useDetailImportPackageQuery from '@Modules/ImportPackage/Hooks/useDetailImportPackageQuery';
import ImportPackageInfo from '@Modules/ImportPackage/components/Detail/ImportPackageInfo';
import ModalConfirm from '@Modules/ImportPackage/components/Detail/ModalConfirm';
import ProductList from '@Modules/ImportPackage/components/Detail/ProductList';
import ServiceList from '@Modules/ImportPackage/components/Detail/ServiceList';
import api from '@Modules/ImportPackage/services/api';
import { IMPORT_PACKAGE_STATUS, IMPORT_PACKAGE_STATUS_COLORS } from '@Modules/ImportPackage/services/constants';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';

function ImportPackageDetail(props) {
    const { match } = props;
    const importPackageId = match.params.id;
    const [products, setProducts] = useState([]);
    const [detailInfo, setDetailInfo] = useState([]);
    const [services, setServices] = useState([]);
    const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
    const { error, isError, isFetching, data, refetch } = useDetailImportPackageQuery(importPackageId);
    const [loadingCancel, setLoadingCancel] = useState(false);

    useEffect(() => {
        setNavigator(t('menu.import_package_detail'), [
            {
                name: t('title.list_import_package'),
                route: 'import-package.list',
            },
            {
                name: t('title.import_package_detail'),
            },
        ]);
        setDocumentTitle(t('title.import_package_detail'));
    }, []);

    useEffect(() => {
        setDetailInfo(data?.data);
    }, [data]);

    useEffect(() => {
        setProducts(get(detailInfo, 'purchasing_package_items', []));
        const services = get(detailInfo, 'services', []);
        setServices(refactorService(services));
        setDocumentTitle(t('title_page.import_package_detail', { code: getVar(detailInfo, 'purchasing_package.code', '') }));
    }, [detailInfo]);

    function refactorService(data) {
        const services = [];
        data.map(item => {
            const service_prices = getVar(item, 'service_prices', []);
            service_prices.map(service_price => {
                services.push({
                    key: v4(),
                    name: getVar(item, 'service.name', ''),
                    quantity: getVar(service_price, 'quantity', ''),
                    price: getVar(service_price, 'price', 0),
                    amount: getVar(service_price, 'amount', 0),
                    service_price_name: getVar(service_price, 'label', ''),
                    skus: getVar(service_price, 'skus', []),
                });
            });
        });
        return services;
    }

    const handleCancelPackage = async () => {
        setLoadingCancel(true);
        try {
            await api.cancelPackage(importPackageId);
            refetch();
            hideConfirmCancelPackage();
            notification.success(t('product:message.cancel_package_success'));
        } catch (error) {
            notification.error(t('product:message.cancel_package_failed'));
        } finally {
            setLoadingCancel(false);
        }
    };

    const visibleConfirmCancelPackage = () => {
        setVisibleModalConfirm(true);
    };

    const hideConfirmCancelPackage = () => {
        setVisibleModalConfirm(false);
    };

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    const code = getVar(detailInfo, 'purchasing_package.code', '');
    const status = getVar(detailInfo, 'purchasing_package.status', '');

    return (
        <div className="site-content _detail-import-package">
            <div className="d-flex justify-content-between mb-3">
                <h3>
                    <Link to={'import-package.list'} className="_detail-import-package-back-list">
                        <ArrowLeftOutlined /> {t('btn.back')}
                    </Link>
                </h3>
                <div>
                    {status === IMPORT_PACKAGE_STATUS.INIT && (
                        <Button className="mr-2" onClick={visibleConfirmCancelPackage} loading={loadingCancel}>
                            {t('btn.cancel')}
                        </Button>
                    )}
                    {!get(detailInfo, 'purchasing_package.purchasing_order_id', true) && (
                        <LinkRoute to={`/import-package/create-duplicate/${importPackageId}`}>
                            <Button type="primary" icon={<EditOutlined />}>{t('btn.duplicate')}</Button>
                        </LinkRoute>
                    )}
                </div>
            </div>
            <Spin spinning={isFetching}>
                <div className="bg-light-gray _detail-import-package-info">
                    <Row className="bg-white rounded-12 p-4 box-shadow">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <h3 className="mb-3">
                                {`${t('title.import_package_detail')} ${code ? `#${code}` : ''}`}{' '}
                                {status ? (
                                    <Badge
                                        className="ml-4"
                                        color={IMPORT_PACKAGE_STATUS_COLORS[status]}
                                        text={t(`product:IMPORT_PACKAGE_STATUS.${status}`)}
                                    />
                                ) : (
                                    ''
                                )}
                            </h3>
                            <ImportPackageInfo dataSource={detailInfo} services={services} />
                        </Col>
                    </Row>

                    <div className="bg-white rounded-12 p-4 mt-4 box-shadow">
                        <div className="d-flex justify-content-between mb-3">
                            <h3>
                                {`${t('heading.product_list')} `}
                                <span className="number-circle _detail-import-package-info-product-total">
                                    {products ? products.length : 0}
                                </span>
                            </h3>
                        </div>
                        <ProductList loading={isFetching} dataSource={products} />
                    </div>

                    <div
                        className="bg-white rounded-12 p-4 mt-4 _detail-import-package-service-list box-shadow"
                        id="import_package_detail_service_list"
                    >
                        <div className="d-flex">
                            <h3 className="">{`${t('title.option_service_import')}`}</h3>
                        </div>
                        <ServiceList product={detailInfo} services={services} />
                    </div>
                </div>
            </Spin>
            {visibleModalConfirm && (
                <ModalConfirm
                    onOk={handleCancelPackage}
                    handleCancelModal={hideConfirmCancelPackage}
                    loadingBtn={loadingCancel}
                    cancelButtonProps={{ className: '_modal-confirm-delete-package-btn-cancel' }}
                    okButtonProps={{ className: '_modal-confirm-delete-package-btn-ok' }}
                />
            )}
        </div>
    );
}
export default ImportPackageDetail;
