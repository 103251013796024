import React from "react";
import {Breadcrumb} from "antd";
import {Link} from "../../../system/routing";
import {t} from "../../../system/i18n";

function Navigator({data}) {

    return (
        <Breadcrumb className="breadcrumb-header align-items-center">
            <Breadcrumb.Item key={1}>
                <Link to={"home"}>{t("common:breadcrumb.home")}</Link>
            </Breadcrumb.Item>
            {
                data && data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <Breadcrumb.Item key={index}>
                                {
                                    item.route ?
                                        <Link to={item.route}>{item.name}</Link> :
                                        <span>{item.name}</span>
                                }
                            </Breadcrumb.Item>
                        );
                    })
            }
        </Breadcrumb>
    );
}

export default Navigator;
